import React, { Component } from 'react';
import './PasswordReset.css';
import { Segment, Label, Button, Form, Message } from 'semantic-ui-react';

class PasswordReset extends Component{
    constructor(props){
        super(props);
        this.state = {
            pass1: "",
            pass2: "",
            passNoMatch: true,
            submitLoading: ""
        }
        //
        this.handlePass1Change = this.handlePass1Change.bind(this);
        this.handlePass2Change = this.handlePass2Change.bind(this);
        this.submitPasswordReset = this.submitPasswordReset.bind(this);
        this.redirectBackToLogin = this.redirectBackToLogin.bind(this);        
    }

    handlePass1Change(event){
        this.setState({
            pass1: event.target.value
        })
        if(event.target.value !== "" && event.target.value === this.state.pass2) {
            this.setState({
                passNoMatch: false
            })
        }
        else {
            this.setState({
                passNoMatch: true
            })
        }
    }
    handlePass2Change(event){
        this.setState({
            pass2: event.target.value
        })
        if(event.target.value !== "" && event.target.value === this.state.pass1) {
            this.setState({
                passNoMatch: false
            })
        }
        else {
            this.setState({
                passNoMatch: true
            })
        }
    }
    //
    submitPasswordReset(url){
        console.log(this.props.url);
        var token = this.props.location.search.split("token=")[1]; //FETCH FROM URL PARAM
        console.log(this.props.location);
        console.log("url token = " + token);
        //
        this.setState({
            submitLoading: "loading disabled"
        });
        //
        fetch(url + "/Account/ResetPassword", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token,
                password: this.state.pass2
            }),            
        });
        setTimeout(this.redirectBackToLogin, 1000);
    }
    //
    redirectBackToLogin(){
        this.props.history.push("/");
    }

    render(){
        console.log(this.props);
        return(
            <div>
                <div className="Reset-Segment-Wrapper">
                    <Segment className="Reset-Style">
                        <Label className="" attached="top"><h3 className="">Reset Your Password</h3></Label>
                        <br/><br/><br/>
                        <div>
                            <Form error={this.state.passNoMatch} success={!this.state.passNoMatch}>
                                <Form.Field>
                                    <label>New Password</label>
                                    <input placeholder="New Password" type="password" value={this.state.pass1} onChange={this.handlePass1Change} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Confirm Password</label>
                                    <input placeholder="Confirm Password" type="password" value={this.state.pass2} onChange={this.handlePass2Change} />
                                    <Message
                                        error
                                        header='Error'
                                        content='Password Mis-match!'
                                    />
                                    <Message
                                        success
                                        header='Success'
                                        content="Passwords Match!"
                                        />
                                </Form.Field>
                            </Form>
                        </div>                       
                        <br/>
                        <Button className={"Button-Submit " + this.state.submitLoading} disabled={this.state.passNoMatch} onClick={() => this.submitPasswordReset(this.props.url)}>Submit</Button>
                        <br/><br/>
                    </Segment>
                </div>
            </div>
        );
    }
}

export default PasswordReset;