import React, { Component } from 'react';
import './MattersPage.css';
import { Segment, Label, Button, Icon, Dimmer, Loader, Grid, Header, Modal, TextArea } from 'semantic-ui-react';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import Promise from 'promise-polyfill';
import "isomorphic-fetch"

if (!window.Promise) {
    window.Promise = Promise;
}

class MattersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            matterDataDimmerActive: true,
            months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            fileNames: [],
            filesModalOpen: false,
            amendNotesModalOpen: false,
            amendNotesSaveButtonCSS: "",
            amendNotesCancelButtonCSS: "Matters-Button-CancelNotes",
            amendNotesButtonCSS: "Matters-Button-AmendNotes-Hidden",
            novexNotesDivParentCSS: "Matter-Div-NovexNotes-Parent",
            novexNotesModalOpen: false,
            novexNotesButtonCSS: "Matters-Button-NovexNotes",
            novexNoteData: [],
            casePortalNotes: "",
            notesCharsMax: 320,
            notesCharsRemaining: 320,
            refreshTimer: 900000,
            shouldRefresh: false,
            glossaryFileURL: "https://api.portal.equilaw.uk.com/portalfiles/Portal%20Terminology%202021.pdf",
            glossaryButtonCSS: "",
            bookButton_CSS: (localStorage.roleId === "1" || localStorage.roleId === "2") ? "" : "Matters-Hidden",
            onHoldReasonButton_CSS: "",
            holdReasonModalOpen: false,
            caseHoldReason: "",
        }
        this.fetchMatterData = this.fetchMatterData.bind(this);
        this.fetchMatterData_Wrapper = this.fetchMatterData_Wrapper.bind(this);
        this.fetchMattersRefresh = this.fetchMattersRefresh.bind(this);
        this.makePlaceholderFilter = this.makePlaceholderFilter.bind(this);
        this.prepAndOpenNotesModal = this.prepAndOpenNotesModal.bind(this);
        this.updateCharsRemaining = this.updateCharsRemaining.bind(this);
        this.portalNotesOnChangeHandler = this.portalNotesOnChangeHandler.bind(this);
        this.glossaryDownload = this.glossaryDownload.bind(this);
        //
        this.expanderComponent = this.expanderComponent.bind(this);
        this.onClickBookApptButton = this.onClickBookApptButton.bind(this);
        //
        this.fetchMatterData_Wrapper();
        //
        // setTimeout(() => {
        //     this.setState({
        //         //amendNotesButtonCSS: "Matters-Button-AmendNotes",
        //         novexNotesButtonCSS: "Matters-Button-NovexNotes",
        //     })
        // }, 250);

        setTimeout(() => {
            this.fetchMattersRefresh();
        });
    }
    //
    saveNotes() {
        this.setState({
            amendNotesSaveButtonCSS: "loading disabled"
        });
        setTimeout(() => {
            this.setState({
                amendNotesSaveButtonCSS: ""
            });
        }, 1000);
    }
    //
    fetchMattersRefresh() {
        if (this.state.shouldRefresh) {
            this.setState({
                shouldRefresh: false,
                matterDataDimmerActive: true,
            });
            //console.log("refreshing matters....");
            this.fetchMatterData_Wrapper();
        }
        //else console.log("Skipping refresh...");
        setTimeout(() => {
            this.fetchMattersRefresh();
        }, this.state.refreshTimer);
    }
    //
    fetchNovexNotesData_Wrapper(fileId) {
        var results = this.fetchNovexNoteData(fileId);
        results.then((data) => {
            switch (data.httpCode) {
                case 200:
                    console.log(data);
                    if (data.body != null && data.body.notes != null) console.log(data.body.notes);
                    if (data.body != null && data.body.notes != null) {
                        this.setState({
                            novexNoteData: data.body.notes,
                        });
                    }
                    this.setState({
                        novexNotesModalOpen: true
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchNovexNoteData(fileId) {
        var middlewareURL = "https://middleware.equilaw.uk.com"; //"http://localhost:49493"; //
        const response = await fetch(middlewareURL + "/Novex/fetchNovexCaseHistoryNotes", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: "AcQVAF3B3sXpVXxca79CZSr3Sekn8PRMsSJdLKHQUp3efapDMQCDeeYdauCKWDfSFabNbzUB9kaL24ahXHe6nmUqtbMC2D64kZZYvYG67KnCQy7qeEQZXnvwXv8RQ9AB",
                dpsFileId: fileId,
                endpointOverride: "https://api.novex.software/v1/graphql",
                endpointAuthToken: "live"
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    fetchMatterData_Wrapper() {
        var results = this.fetchMatterData(this.props.url);
        results.then((data) => {
            console.log(data.body);
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        tableData: data.body,
                        matterDataDimmerActive: false,
                        shouldRefresh: true,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchMatterData(url) {
        const response = await fetch(url + "/Matters/GetMatterData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClickShowHoldReasonButton(fileId){
        console.log("fileid = " + fileId);
        var reason = "";
        for (let i = 0; i < this.state.tableData.length; i++) {
            if (this.state.tableData[i].FileID === fileId) {
                reason = this.state.tableData[i].OnHoldReason;
            }
        }
        this.setState({
            caseHoldReason: reason != null ? reason : "",
            holdReasonModalOpen: true,
        });
    }
    //
    expanderComponent(row) {
        //
        var d = new Date(row.original.CurrentStatusDate);
        var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d_text = "";
        if (d.getFullYear() > 1970) {
            d_text = day + "/" + months[d.getMonth()] + "/" + d.getFullYear();
        }
        //
        return (
            <div>
                <Segment className="Matters-CurrentStatus-Cell Matters-Segment-Generic" secondary={true} padded>
                    <p className="Matters-Cell-SizeMed">{row.original.CurrentStatus}
                        <Button className={"ui blue Matters-Button-Book " + (((row.original.CurrentStatus === "Mortgage Report Sent") && (row.original.CurrentStatus !== "Hold")) ? this.state.bookButton_CSS : "Matters-Hidden")} value={row != null ? row.original != null ? row.original.FileID : 0 : 0} onClick={this.onClickBookApptButton} >Book Appt.</Button>
                    </p>
                    <p className="Matters-Cell-Date">
                        {d_text}
                        <Button className="Matters-Button-History">History</Button>
                    </p>
                </Segment>
            </div>
        )
        //
    }
    //
    onClickBookApptButton(event) {
        event.preventDefault();
        this.props.history.push("/bookAppts?id=" + event.target.value);
    }
    //
    makePlaceholderFilter(placeholder) {
        return ({ filter, onFilterChange }) => (
            <input type='text'
                placeholder={placeholder}
                style={{
                    width: '100%'
                }}
                value={filter ? filter.value : ''}
                onChange={(event) => onFilterChange(event.target.value)}
            />
        )
    }
    //
    prepAndOpenNotesModal(fileId) {
        var notes = "";
        for (let i = 0; i < this.state.tableData.length; i++) {
            if (this.state.tableData[i].FileID === fileId) {
                notes = this.state.tableData[i].PortalNotes;
            }
        }
        var notesLength = notes != null ? notes.length : 0;
        this.updateCharsRemaining(this.state.notesCharsMax - notesLength);
        this.setState({
            amendNotesModalOpen: true,
            casePortalNotes: notes != null ? notes : "",
        });
    }
    //
    updateCharsRemaining(CharsRemaining) {
        this.setState({
            notesCharsRemaining: CharsRemaining,
        });
    }
    //
    portalNotesOnChangeHandler(event) {
        var newNotes = event.target.value;
        var CharsRemaining = this.state.notesCharsMax - newNotes.length;
        if (CharsRemaining >= 0) {
            this.setState({
                casePortalNotes: newNotes,
            });
            this.updateCharsRemaining(CharsRemaining);
        }
        else if ((newNotes.length - this.state.casePortalNotes.length) > 1) {
            this.setState({
                casePortalNotes: newNotes.slice(0, this.state.notesCharsMax),
            });
            this.updateCharsRemaining(0);
        }
        else this.updateCharsRemaining(0);
    }
    //
    glossaryDownload() {
        this.setState({
            glossaryButtonCSS: "loading disabled",
        });
        const link = document.createElement('a');
        link.href = this.state.glossaryFileURL;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
            this.setState({
                glossaryButtonCSS: "",
            });
        }, 500);
    }
    //
    render() {
        const data = this.state.tableData.length > 0 ? this.state.tableData : [];
        const novexNoteData = this.state.novexNoteData.length > 0 ? this.state.novexNoteData : [];
        //
        const columns = [{
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Case</h2 >,
            resizable: false,
            Filter: ({ filter, onChange }) => (
                <input type='text'
                    placeholder="Type to Search..."
                    className="Matters-Search-Box"
                    value={filter ? filter.value : ''}
                    onChange={event => onChange(event.target.value)}
                />
            ),
            Cell: row => {
                return (
                    <div>
                        <Segment className="Matters-Segment-Generic" secondary={true} padded>
                            <h2 className="Matters-Header-Ref">
                                {row.original.Reference}
                                <Button className={this.state.amendNotesButtonCSS} size="mini" onClick={() => this.prepAndOpenNotesModal(row.original.FileID)}>Amend Notes</Button>
                            </h2>
                            <p className="Matters-Cell-NameIFARef">
                                {row.original.Surname} {row.original.IFARef != null ? "(" + row.original.IFARef + ")" : ""}
                                
                            </p>
                            <p className="Matters-Cell-Address">{row.original.Address1 + ", " + row.original.Postcode}
                                <font className="Matters-CaseType">Case Type:&nbsp;{row.original.MatterType}</font>
                            </p>
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Notes</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="Matters-Segment-Generic" secondary={true} padded>
                            {/* <p className="Matter-P-Notes">{row.original.PortalNotes}</p> */}
                            <div className={this.state.novexNotesDivParentCSS}>
                                <Button className={this.state.novexNotesButtonCSS} size="mini" onClick={() => this.fetchNovexNotesData_Wrapper(row.original.FileID)}>Notes</Button>
                            </div>
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Contact</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="Matters-Segment-Generic" secondary={true} padded>
                            <p className="Matters-Cell-SizeMed"><a href={"mailto:" + row.original.CaseHandlerEmail}>{row.original.CaseHandler}</a></p>
                            {/* <p className="Matters-Cell-SizeSmall">{row.original.Telephone}</p> */}
                            <p className="Matters-Cell-SizeSmall">
                                {(row.original.UsingCallBookingURL != null && row.original.UsingCallBookingURL) ? <a target="_blank" href={row.original.CallBookingURL+row.original.CaseHandler.replace(" ", "").replace("-", "")}>Click to book call back...</a> : row.original.CaseHandlerDDI}
                                <Button className={"ui Matters-Button-HoldReason mini " + ((row.original.CurrentStatus === "Hold") ? this.state.bookButton_CSS : "Matters-Hidden")} value={row != null ? row.original != null ? row.original.FileID : 0 : 0} onClick={() => this.onClickShowHoldReasonButton(row.original.FileID)} >Show Hold Reason</Button>
                            </p>
                            
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Milestone</h2>,
            resizable: false,
            expander: true,
            className: "Matters-Table-Column",
            //width: 300,
            filterable: false
        }];
        // const notesColumns = [{
        //     Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Novex Notes</h2>,
        //     resizable: false,
        //     Cell: row => {
        //         return (
        //             <div>
        //                 <Segment className="Matters-Segment-Generic" padded>
        //                     <p className="Matters-Cell-Address">
        //                         {row.original.content}
        //                     </p>
        //                 </Segment>
        //             </div>
        //         )
        //     }
        // }];
        //
        return (
            <div>
                <Segment>
                    <Dimmer active={this.state.matterDataDimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="folder open icon big" />&emsp;Your Cases</h3>
                        {/* <a className="IQ-ButtonGlossary ui button blue" role="button" href={this.state.glossaryFileURL} download>Glossary
                        <Button className="ui button" color="blue">Glossary</Button>
                    </a> */}
                        <Button className={"ui button Matters-ButtonGlossary " + this.state.glossaryButtonCSS} color="blue" onClick={this.glossaryDownload}>
                            <i className="ui download icon"></i>
                        Portal Terminology
                    </Button>
                    </Label>

                    <br /><br /><br />
                    {data != null && data.length > 0 ? <ReactTable className="Matters-Table" filterable data={data} columns={columns} defaultPageSize={10} sortable={false}
                        ExpanderComponent={this.expanderComponent}
                        FilterRender={this.makePlaceholderFilter("Custom Text")}
                        ExpanderDefaults={{ width: 30 }}
                        SubComponent={row => {
                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            var d1 = new Date(row.original.DateInstruction);
                            var d1_display = row.original.DateInstruction != null ? (d1.getDate() < 10 ? "0" + d1.getDate() : d1.getDate()) + "/" + months[d1.getMonth()] + "/" + d1.getFullYear() : "N/A";
                            var d2 = new Date(row.original.DateOffer);
                            var d2_display = row.original.DateOffer != null ? (d2.getDate() < 10 ? "0" + d2.getDate() : d2.getDate()) + "/" + months[d2.getMonth()] + "/" + d2.getFullYear() : "N/A";
                            //var d3 = new Date(row.original.DateAppointmentBooked);
                            //var d3_display = row.original.DateAppointmentBooked != null ? (d3.getDate() < 10 ? "0" + d3.getDate(): d3.getDate()) + "/" + months[d3.getMonth()] + "/" + d3.getFullYear() : "N/A";                        
                            var d3 = new Date(row.original.DateMortgageReportSent);
                            var d3_display = row.original.DateMortgageReportSent != null ? (d3.getDate() < 10 ? "0" + d3.getDate() : d3.getDate()) + "/" + months[d3.getMonth()] + "/" + d3.getFullYear() : "N/A";
                            var d4 = new Date(row.original.DateAppointment);
                            var d4_display = row.original.DateAppointment != null ? (d4.getDate() < 10 ? "0" + d4.getDate() : d4.getDate()) + "/" + months[d4.getMonth()] + "/" + d4.getFullYear() : "N/A";
                            var d5 = new Date(row.original.DateSignedDocsReceived);
                            var d5_display = row.original.DateSignedDocsReceived != null ? (d5.getDate() < 10 ? "0" + d5.getDate() : d5.getDate()) + "/" + months[d5.getMonth()] + "/" + d5.getFullYear() : "N/A";
                            var d6 = new Date(row.original.DateSchedule);
                            var d6_display = row.original.DateSchedule != null ? (d6.getDate() < 10 ? "0" + d6.getDate() : d6.getDate()) + "/" + months[d6.getMonth()] + "/" + d6.getFullYear() : "N/A";
                            var d7 = new Date(row.original.DateCompletionDateRecFromLender);
                            var d7_display = row.original.DateCompletionDateRecFromLender != null ? (d7.getDate() < 10 ? "0" + d7.getDate() : d7.getDate()) + "/" + months[d7.getMonth()] + "/" + d7.getFullYear() : "N/A";
                            var d8 = new Date(row.original.DateCompletionDateSetByLender);
                            var d8_display = row.original.DateCompletionDateSetByLender != null ? (d8.getDate() < 10 ? "0" + d8.getDate() : d8.getDate()) + "/" + months[d8.getMonth()] + "/" + d8.getFullYear() : "N/A";
                            //
                            return (
                                <div className="Matters-Sub-Grid">
                                    <Grid columns={4}>
                                        <Grid.Row className="Matters-Grid-Row">
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Instruction<br />
                                                    <b>{d1_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Mortgage Offer<br />
                                                    <b>{d2_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                {/* <Segment>
                                                Appointment Found<br/>
                                                <b>{d3_display}</b>
                                            </Segment> */}
                                                <Segment>
                                                    Mortgage Report Sent<br />
                                                    <b>{d3_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Appointment Booked For<br />
                                                    <b>{d4_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Signed Documents Received<br />
                                                    <b>{d5_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Schedule Sent<br />
                                                    <b>{d6_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Completion Date Rec<br />
                                                    <b>{d7_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column className="Matters-Sub-Column">
                                                <Segment>
                                                    Completion Date Set<br />
                                                    <b>{d8_display}</b>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            );
                        }}
                        defaultFilterMethod={(filter, row) => {
                            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            var d = new Date(row._original.CurrentStatusDate);
                            var currentStatusDate_String = row._original.CurrentStatusDate != null ? (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + "/" + months[d.getMonth()] + "/" + d.getFullYear() : "N/A";
                            //
                            var match_Reference = row._original.Reference != null ? row._original.Reference.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_Surname = row._original.Surname != null ? row._original.Surname.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_Telephone = row._original.Telephone != null ? row._original.Telephone.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_Address1 = row._original.Address1 != null ? row._original.Address1.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_Postcode = row._original.Postcode != null ? row._original.Postcode.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_IFARef = row._original.IFARef != null ? row._original.IFARef.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_CaseHandler = row._original.CaseHandler != null ? row._original.CaseHandler.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_CurrentStatus = row._original.CurrentStatus != null ? row._original.CurrentStatus.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_CurrentStatusDate = row._original.CurrentStatusDate != null ? row._original.CurrentStatusDate.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            var match_CurrentStatusDate_String = currentStatusDate_String != null ? currentStatusDate_String.toLowerCase().includes(filter.value.toLowerCase()) : false;
                            return (
                                match_Reference ||
                                match_Surname ||
                                match_Telephone ||
                                match_Address1 ||
                                match_Postcode ||
                                match_IFARef ||
                                match_CaseHandler ||
                                match_CurrentStatus ||
                                match_CurrentStatusDate ||
                                match_CurrentStatusDate_String
                            )
                        }
                        }
                    /> : <div></div>}
                </Segment>
                <Modal style={{ position: 'static' }} className="Matters-Modal-AmendNotes" open={this.state.amendNotesModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ amendNotesModalOpen: false })}>
                    <Header><Icon className='edit circle' />Amend Portal Notes</Header>
                    <Modal.Content>
                        <div>
                            <TextArea className="Matters-TextArea-AmendNotes" value={this.state.casePortalNotes} onChange={this.portalNotesOnChangeHandler}></TextArea>
                            <br />
                            <b className="Matters-Notes-CharsRemaining">Remaining Characters:&nbsp;{this.state.notesCharsRemaining}/{this.state.notesCharsMax}</b>
                            <br />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className={this.state.amendNotesCancelButtonCSS} color='red' onClick={() => this.setState({ amendNotesModalOpen: false })}><Icon name='cancel' />&nbsp;Cancel</Button>
                        <Button className={this.state.amendNotesSaveButtonCSS} color='green' onClick={() => this.saveNotes()}><Icon name='save' />&nbsp;Save</Button>
                    </Modal.Actions>
                </Modal>
                <Modal style={{ position: 'static' }} className="Matters-Modal-AmendNotes Matters-NovexNotes-TopOffset" open={this.state.novexNotesModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ novexNotesModalOpen: false })}>
                    <Header><Icon className='edit circle' />Case Notes</Header>
                    <Modal.Content>
                        <div>
                            {/* {
                                novexNoteData != null && novexNoteData.length > 0 ? <ReactTable
                                    className="Matters-Table"
                                    data={novexNoteData}
                                    columns={notesColumns}
                                    defaultPageSize={10}
                                /> : <div></div>
                            } */}
                            {
                                novexNoteData != null && novexNoteData.length > 0 ? novexNoteData.map(row =>
                                (<Segment key={row.id}>
                                    <p>{row.content}</p>
                                    <p></p>
                                    <p>{"Created by: " + row.created_by}</p>
                                    <p className="Matters-NovexNotes-Paragraph">{"Date: " +
                                        ((new Date(row.created_at).getDate() < 10 ? "0" + (new Date(row.created_at).getDate()) : new Date(row.created_at).getDate())) + "/" +
                                        ((new Date(row.created_at).getMonth() + 1) < 10 ? "0" + (new Date(row.created_at).getMonth() + 1) : (new Date(row.created_at).getMonth() + 1)) + "/" +
                                        new Date(row.created_at).getUTCFullYear() + "    (" +
                                        ((new Date(row.created_at).getHours() < 10 ? "0" + (new Date(row.created_at).getHours()) : new Date(row.created_at).getHours())) + ":" +
                                        ((new Date(row.created_at).getMinutes() < 10 ? "0" + (new Date(row.created_at).getMinutes()) : new Date(row.created_at).getMinutes())) + ")"
                                    }</p>
                                    {
                                        row.last_edited_by == row.created_by && row.created_at == row.updated_at ? "" :
                                        <div>
                                            <p>{"Last edited by: " + row.last_edited_by}</p>
                                            <p className="Matters-NovexNotes-Paragraph">{"Last edited date: " +
                                                ((new Date(row.updated_at).getDate() < 10 ? "0" + (new Date(row.updated_at).getDate()) : new Date(row.updated_at).getDate())) + "/" +
                                                ((new Date(row.updated_at).getMonth() + 1) < 10 ? "0" + (new Date(row.updated_at).getMonth() + 1) : (new Date(row.updated_at).getMonth() + 1)) + "/" +
                                                new Date(row.updated_at).getUTCFullYear() + "    (" +
                                                ((new Date(row.updated_at).getHours() < 10 ? "0" + (new Date(row.updated_at).getHours()) : new Date(row.updated_at).getHours())) + ":" +
                                                ((new Date(row.updated_at).getMinutes() < 10 ? "0" + (new Date(row.updated_at).getMinutes()) : new Date(row.updated_at).getMinutes())) + ")"
                                            }</p>
                                        </div>
                                    }
                                    
                                </Segment>))
                                    : <div>No Notes Found!</div>
                            }
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className={this.state.novexNotesCancelButtonCSS} onClick={() => this.setState({ novexNotesModalOpen: false })}><Icon name='cancel' />&nbsp;Close</Button>
                    </Modal.Actions>
                </Modal>
                <Modal style={{ position: 'static' }} className="Matters-Modal-AmendNotes" open={this.state.holdReasonModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ holdReasonModalOpen: false, caseHoldReason: "" })}>
                    <Header><Icon className='pause circle' />View On Hold Reason</Header>
                    <Modal.Content>
                        <div>
                            <div>{this.state.caseHoldReason}</div>
                            <br />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                    <Button className={this.state.novexNotesCancelButtonCSS} onClick={() => this.setState({ holdReasonModalOpen: false, caseHoldReason: "" })}><Icon name='cancel' />&nbsp;Close</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default MattersPage