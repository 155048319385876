import React, { Component } from 'react';
import './IQPage.css';
import { Segment, Label, Button, Icon, Form, Dropdown, Dimmer, Loader, Grid, Input, Modal, Header, Progress } from 'semantic-ui-react';
import moment from 'moment';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';
import 'react-table/react-table.css'
import axios from 'axios';
//import { isNull } from 'util';

class IQPage1Clients extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment.utc(),
            reference: "TEST001/1",
            fileId: "0",//"62014",
            varsList: this.props.varsList,
            iqStep: this.props.iqStep,
            clientsDimmerActive: true,
            dimmerActionText: "Loading...",
            messageModalOpen: false,
            messageModalIcon_CSS: "warning circle icon",
            messageModalTitle: "",
            messageModalContent: "",
            button_back_CSS: "red IQ-backButton",
            button_forward_CSS: "green IQ-forwardButton",
            uploaded: 0,
            uploadModalOpen: false,
            
            /////////////////////////////////////////////////////////
            ///////////////// Step 1. Client Details ////////////////
            /////////////////////////////////////////////////////////
            //
            //////////////////////////
            // Client 1 - Variables //
            //////////////////////////
            //
            input_clients_1_Surname: "",
            input_clients_1_MiddleNames: "",
            input_clients_1_FirstName: "",
            input_clients_1_DOB: moment.utc(),
            input_clients_1_NINumber: "",
            input_clients_1_Tel: "",
            input_clients_1_Tel_Mobile: "",
            input_clients_1_Tel_Work: "",
            input_clients_1_Email: "",
            button_clients_1_validID_Checked_YES: false,
            button_clients_1_validID_Checked_NO: false,
            button_clients_1_validID_YES_CSS: "ui",
            button_clients_1_validID_NO_CSS: "ui",
            segment_clients_1_validID_CSS: "IQ-Hidden",
            button_clients_1_validID_RecentExpired_Checked_YES: false,
            button_clients_1_validID_RecentExpired_Checked_NO: false,
            button_clients_1_validID_RecentExpired_YES_CSS: "ui",
            button_clients_1_validID_RecentExpired_NO_CSS: "ui",
            // POA #1
            button_clients_1_poa_Checked_YES: false,
            button_clients_1_poa_Checked_NO: false,            
            button_clients_1_poa_YES_CSS: "ui",
            button_clients_1_poa_NO_CSS: "ui",
            segment_clients_1_poa_CSS: "IQ-Hidden",
            input_clients_1_poa_value: "",
            button_clients_1_poa_visit_Checked_YES: false,
            button_clients_1_poa_visit_Checked_NO: false,
            button_clients_1_poa_visit_YES_CSS: "ui",
            button_clients_1_poa_visit_NO_CSS: "ui",
            input_clients_1_poa_Surname: "",
            input_clients_1_poa_MiddleNames: "",
            input_clients_1_poa_FirstName: "",
            input_clients_1_poa_NINumber: "",
            button_clients_1_poa_validID_Checked_YES: false,
            button_clients_1_poa_validID_Checked_NO: false,
            button_clients_1_poa_validID_YES_CSS: "ui",
            button_clients_1_poa_validID_NO_CSS: "ui",
            segment_clients_1_poa_validID_CSS: "IQ-Hidden",
            button_clients_1_poa_validID_RecentExpired_Checked_YES: false,
            button_clients_1_poa_validID_RecentExpired_Checked_NO: false,
            button_clients_1_poa_validID_RecentExpired_YES_CSS: "ui",
            button_clients_1_poa_validID_RecentExpired_NO_CSS: "ui",
            input_clients_1_poa_DOB: moment.utc(),
            input_clients_1_poa_Address_1: "",
            input_clients_1_poa_Address_2: "",
            input_clients_1_poa_Town: "",
            input_clients_1_poa_County: "",
            input_clients_1_poa_Postcode: "",
            input_clients_1_poa_Mobile: "",
            input_clients_1_poa_Email: "",
            button_clients_1_poa_correspond_Checked_YES: false,
            button_clients_1_poa_correspond_Checked_NO: false,
            button_clients_1_poa_correspond_YES_CSS: "ui",
            button_clients_1_poa_correspond_NO_CSS: "ui",
            button_clients_1_poa_opg_Checked_YES: false,
            button_clients_1_poa_opg_Checked_NO: false,
            button_clients_1_poa_opg_YES_CSS: "ui",
            button_clients_1_poa_opg_NO_CSS: "ui",
            segment_clients_1_poa_opg_CSS: "IQ-Hidden",
            button_clients_1_poa_opg_named_Checked_YES: false,
            button_clients_1_poa_opg_named_Checked_NO: false,
            button_clients_1_poa_opg_named_YES_CSS: "ui",
            button_clients_1_poa_opg_named_NO_CSS: "ui",
            button_clients_1_poa_opg_restrictions_Checked_YES: false,
            button_clients_1_poa_opg_restrictions_Checked_NO: false,
            button_clients_1_poa_opg_restrictions_YES_CSS: "ui",
            button_clients_1_poa_opg_restrictions_NO_CSS: "ui",
            segment_clients_1_poa_opg_restrictions_CSS: "IQ-Hidden",
            input_clients_1_poa_opg_restrictions_details: "",
            file_clients_1_poa_opg: "",
            file_clients_1_poa_opg_data: "",
            bool_clients_1_poa_opg_hasUploaded: false,
            button_clients_1_poa_opg_upload_CSS: "ui blue",
            button_clients_1_poa_opg_jointly_Checked_YES: false,
            button_clients_1_poa_opg_jointly_Checked_NO: false,
            button_clients_1_poa_opg_jointly_YES_CSS: "ui",
            button_clients_1_poa_opg_jointly_NO_CSS: "ui",
            //
            //////////////////////////
            // Client 2 - Variables //
            //////////////////////////
            //
            input_clients_2_Surname: "",
            input_clients_2_MiddleNames: "",
            input_clients_2_FirstName: "",
            input_clients_2_DOB: moment.utc(),
            input_clients_2_NINumber: "",
            input_clients_2_Tel: "",
            input_clients_2_Tel_Mobile: "",
            input_clients_2_Tel_Work: "",
            //input_clients_2_Email: "",     
                  
            button_clients_2_validID_Checked_YES: false,
            button_clients_2_validID_Checked_NO: false,
            button_clients_2_validID_YES_CSS: "ui",
            button_clients_2_validID_NO_CSS: "ui",
            segment_clients_2_validID_CSS: "IQ-Hidden",  

            button_clients_2_validID_RecentExpired_Checked_YES: false,
            button_clients_2_validID_RecentExpired_Checked_NO: false,
            button_clients_2_validID_RecentExpired_YES_CSS: "ui",
            button_clients_2_validID_RecentExpired_NO_CSS: "ui",                    
            // POA #2
            segment_clients_2_poa_sameAs1_CSS: "IQ-Hidden",
            button_clients_2_poa_sameAs1_Checked_YES: false,
            button_clients_2_poa_sameAs1_Checked_NO: false,            
            button_clients_2_poa_sameAs1_YES_CSS: "ui",
            button_clients_2_poa_sameAs1_NO_CSS: "ui",
            button_clients_2_poa_Checked_YES: false,
            button_clients_2_poa_Checked_NO: false,            
            button_clients_2_poa_YES_CSS: "ui",
            button_clients_2_poa_NO_CSS: "ui",
            segment_clients_2_poa_CSS: "IQ-Hidden",   
            input_clients_2_poa_value: "",
            button_clients_2_poa_visit_Checked_YES: false,
            button_clients_2_poa_visit_Checked_NO: false,
            button_clients_2_poa_visit_YES_CSS: "ui",
            button_clients_2_poa_visit_NO_CSS: "ui",
            input_clients_2_poa_Surname: "",
            input_clients_2_poa_MiddleNames: "",
            input_clients_2_poa_FirstName: "",
            input_clients_2_poa_NINumber: "",
            button_clients_2_poa_validID_Checked_YES: false,
            button_clients_2_poa_validID_Checked_NO: false,
            button_clients_2_poa_validID_YES_CSS: "ui",
            button_clients_2_poa_validID_NO_CSS: "ui",
            segment_clients_2_poa_validID_CSS: "IQ-Hidden",
            button_clients_2_poa_validID_RecentExpired_Checked_YES: false,
            button_clients_2_poa_validID_RecentExpired_Checked_NO: false,
            button_clients_2_poa_validID_RecentExpired_YES_CSS: "ui",
            button_clients_2_poa_validID_RecentExpired_NO_CSS: "ui",
            input_clients_2_poa_DOB: moment.utc(),
            input_clients_2_poa_Address_1: "",
            input_clients_2_poa_Address_2: "",
            input_clients_2_poa_Town: "",
            input_clients_2_poa_County: "",
            input_clients_2_poa_Postcode: "",
            input_clients_2_poa_Mobile: "",
            input_clients_2_poa_Email: "",
            button_clients_2_poa_correspond_Checked_YES: false,
            button_clients_2_poa_correspond_Checked_NO: false,
            button_clients_2_poa_correspond_YES_CSS: "ui",
            button_clients_2_poa_correspond_NO_CSS: "ui",
            button_clients_2_poa_opg_Checked_YES: false,
            button_clients_2_poa_opg_Checked_NO: false,
            button_clients_2_poa_opg_YES_CSS: "ui",
            button_clients_2_poa_opg_NO_CSS: "ui",
            segment_clients_2_poa_opg_CSS: "IQ-Hidden",
            button_clients_2_poa_opg_named_Checked_YES: false,
            button_clients_2_poa_opg_named_Checked_NO: false,
            button_clients_2_poa_opg_named_YES_CSS: "ui",
            button_clients_2_poa_opg_named_NO_CSS: "ui",
            button_clients_2_poa_opg_restrictions_Checked_YES: false,
            button_clients_2_poa_opg_restrictions_Checked_NO: false,
            button_clients_2_poa_opg_restrictions_YES_CSS: "ui",
            button_clients_2_poa_opg_restrictions_NO_CSS: "ui",
            segment_clients_2_poa_opg_restrictions_CSS: "IQ-Hidden",
            input_clients_2_poa_opg_restrictions_details: "",
            file_clients_2_poa_opg: "",
            file_clients_2_poa_opg_data: "",
            bool_clients_2_poa_opg_hasUploaded: false,
            button_clients_2_poa_opg_upload_CSS: "ui blue",
            button_clients_2_poa_opg_jointly_Checked_YES: false,
            button_clients_2_poa_opg_jointly_Checked_NO: false,
            button_clients_2_poa_opg_jointly_YES_CSS: "ui",
            button_clients_2_poa_opg_jointly_NO_CSS: "ui",
        }
        this.poaOptions = [            
            {
                text: 'Physical Incapacity',
                value: 'physicalincapacity',
            },
            {
                text: 'Mental Incapacity',
                value: 'mentalincapacity',
            },
        ];
        /////////////////////////////////////////////////////////
        this.valueChange = this.valueChange.bind(this);
        this.buttonChange_YES = this.buttonChange_YES.bind(this);
        this.buttonChange_NO = this.buttonChange_NO.bind(this);
        this.formatNINumber = this.formatNINumber.bind(this);
        this.handleMomentChange = this.handleMomentChange.bind(this);
        /////////////////////////////////////////////////////////
        //
        this.populateVarsData = this.populateVarsData.bind(this);
        //
        //////////////////////////
        // Client 1 - Functions //
        //////////////////////////
        //
        this.onChange_clients_DOBDate_1 = this.onChange_clients_DOBDate_1.bind(this);
        this.onChange_clients_NINumber_1 = this.onChange_clients_NINumber_1.bind(this);
        this.onChange_clients_poa_reason_1 = this.onChange_clients_poa_reason_1.bind(this);
        this.onChange_clients_poa_NINumber_1 = this.onChange_clients_poa_NINumber_1.bind(this);
        this.onChange_clients_poa_DOBDate_1 = this.onChange_clients_poa_DOBDate_1.bind(this);
        this.onChange_clients_poa_opg_file_1 = this.onChange_clients_poa_opg_file_1.bind(this);
        this.onClick_clients_poa_opg_upload_1 = this.onClick_clients_poa_opg_upload_1.bind(this);
        //
        //////////////////////////
        // Client 2 - Functions //
        //////////////////////////
        //
        this.onChange_clients_DOBDate_2 = this.onChange_clients_DOBDate_2.bind(this);
        this.onChange_clients_NINumber_2 = this.onChange_clients_NINumber_2.bind(this);
        this.onChange_clients_poa_reason_2 = this.onChange_clients_poa_reason_2.bind(this);
        this.onChange_clients_poa_NINumber_2 = this.onChange_clients_poa_NINumber_2.bind(this);
        this.onChange_clients_poa_DOBDate_2 = this.onChange_clients_poa_DOBDate_2.bind(this);
        this.onChange_clients_poa_opg_file_2 = this.onChange_clients_poa_opg_file_2.bind(this);
        this.onClick_clients_poa_opg_upload_2 = this.onClick_clients_poa_opg_upload_2.bind(this);
        //
        this.upload_File = this.upload_File.bind(this);
        //        
        this.saveData_Wrapper = this.saveData_Wrapper.bind(this);
        this.saveData = this.saveData.bind(this);
        //
        //
        setTimeout(this.populateVarsData, 200);
    }

    //
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    buttonChange_YES(key, hide, style) {
        this.setState({
            ['button_' + key + '_Checked_YES']: true,
            ['button_' + key + '_Checked_NO']: false,
            ['button_' + key + '_YES_CSS']: "ui green",
            ['button_' + key + '_NO_CSS']: "ui",            
        });
        if(hide != null){
            this.setState({
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),           
            });          
        }
    };
    /////////////////////////////////////////////////////////////////
    buttonChange_NO = (key, hide, style) => {
        this.setState({
            ['button_' + key + '_Checked_YES']: false,
            ['button_' + key + '_Checked_NO']: true,
            ['button_' + key + '_YES_CSS']: "ui",
            ['button_' + key + '_NO_CSS']: "ui red",
        });
        if(hide != null){ 
            this.setState({                
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),           
            });
        }
    };
    //
    //
    formatNINumber(input){
        //console.log("----------------------------");
        var v = input.split(" ");
        var s = "";
        for(let i = 0; i < v.length; i++){
            s += v[i];
        }
        //console.log("v: " + v);
        //console.log("s: " + s);
        var f = "";
        var count = 0;
        for(let i = 0; i < s.length && count < 9; i++){
            if(s[i] !== " " && s[i] !== ",") {
                f += s[i];
                count++;
                if(count === 2 && s.length > count) f += " ";
                else if (count === 4 && s.length > count) f += " ";
                else if (count === 6 && s.length > count) f += " ";
                else if (count === 8 && s.length > count) f += " ";
            }            
        }
        //console.log("f: " + f);
        return f;
    }
    //
    //
    handleMomentChange = moment => {
        this.setState({ moment });
    };  
    //
    /////////////////////////////////////////////////////////////////
    //
    //
    componentDidUpdate(prevProps) {
        if (this.props.varsList !== prevProps.varsList) {
            this.setState({
                //clientsDimmerActive: true,
            })
            this.populateVarsData();
        }
      }
    //
    populateVarsData(){
        this.setState({
            varsList: this.props.varsList,
        })
        var varList = this.props.varsList;
        for(var i = 0; i < varList.length; i++){
            var ovCode = varList[i].m_Item1;
            var value = varList[i].m_Item2;
            if(value != null){
            switch(ovCode){
                //"63", "3005", "64", "79", "2995", "75", "82", "81", "827", "3083", "3084", "4532",
                case "63":
                    this.setState({
                        input_clients_1_Surname: value,
                    });
                break;
                case "3005":
                    this.setState({
                        input_clients_1_MiddleNames: value,
                    });
                    break;
                case "64":
                    this.setState({
                        input_clients_1_FirstName: value,
                    });
                    break;
                case "79":
                    var d1 = moment.utc(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_clients_1_DOB: d1,
                    });
                    break;
                case "2995":
                    var event = {
                        target: { value: value }
                    };
                    this.onChange_clients_NINumber_1(event);
                    break;
                case "75":
                    this.setState({
                        input_clients_1_Tel: value,
                    });
                    break;
                case "82":
                    this.setState({
                        input_clients_1_Tel_Mobile: value,
                    });
                    break;
                case "81":
                    this.setState({
                        input_clients_1_Tel_Work: value,
                    });
                    break;
                case "827":
                    this.setState({
                        input_clients_1_Email: value,
                    });
                    break;
                case "3083":
                    if(value === "Yes") this.buttonChange_YES("clients_1_validID", true, null);                    
                    else this.buttonChange_NO('clients_1_validID', false, 'IQ-Segment-BottomNegMargin');                    
                    break;
                case "3084":
                    if(value === "Yes") this.buttonChange_YES('clients_1_validID_RecentExpired', null, null)                    
                    else this.buttonChange_NO('clients_1_validID_RecentExpired', null, null)                  
                    break;
                case "4532":
                    if(value === "Yes") this.buttonChange_YES('clients_1_poa', false, null);
                    else this.buttonChange_NO('clients_1_poa', true, null)
                    break;
                //
                // Client 1 POA
                //
                case "4541":
                    this.setState({
                        input_clients_1_poa_value: value,
                    });
                    break;
                case "4578":
                    if(value) this.buttonChange_YES("clients_1_poa_visit", true, null);                    
                    else this.buttonChange_NO('clients_1_poa_visit', false, null);                    
                    break;
                case "4533":
                    this.setState({
                        input_clients_1_poa_Surname: value,
                    });
                break;
                case "4534":
                    this.setState({
                        input_clients_1_poa_MiddleNames: value,
                    });
                    break;
                case "4535":
                    this.setState({
                        input_clients_1_poa_FirstName: value,
                    });
                    break;
                case "4539":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_validID", true, null);
                    else this.buttonChange_NO('clients_1_poa_validID', false, 'IQ-Segment-TopMargin');
                    break;
                case "4540":
                    if(value === "Yes") this.buttonChange_YES('clients_1_poa_validID_RecentExpired', null, null)
                    else this.buttonChange_NO('clients_1_poa_validID_RecentExpired', null, null)
                    break;
                case "4536":
                    var d2 = moment.utc(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_clients_1_poa_DOB: d2,
                    });
                    break;
                case "4542":
                    this.setState({
                        input_clients_1_poa_Address_1: value,
                    });
                    break;
                case "4543":
                    this.setState({
                        input_clients_1_poa_Address_2: value,
                    });
                    break;
                case "4544":
                    this.setState({
                        input_clients_1_poa_Town: value,
                    });
                    break;
                case "4545":
                    this.setState({
                        input_clients_1_poa_County: value,
                    });
                    break;
                case "4546":
                    this.setState({
                        input_clients_1_poa_Postcode: value,
                    });
                    break;
                case "4538":
                    this.setState({
                        input_clients_1_poa_Mobile: value,
                    });
                    break;
                case "4547":
                    this.setState({
                        input_clients_1_poa_Email: value,
                    });
                    break;
                case "4548":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_correspond", null, null);                    
                    else this.buttonChange_NO('clients_1_poa_correspond', null, null);                    
                    break;
                case "4549":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_opg", false, null);                    
                    else this.buttonChange_NO('clients_1_poa_opg', true, null);                    
                    break;
                case "4550":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_opg_named", null, null);                    
                    else this.buttonChange_NO('clients_1_poa_opg_named', null, null);                    
                    break;
                case "4552":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_opg_restrictions", false, null);                    
                    else this.buttonChange_NO('clients_1_poa_opg_restrictions', true, null);                    
                    break;
                case "4576":
                    this.setState({
                        input_clients_1_poa_opg_restrictions_details: value,
                    });
                    break;
                case "4551":
                    if(value === "Yes") this.buttonChange_YES("clients_1_poa_jointly", null, null);                    
                    else this.buttonChange_NO('clients_1_poa_jointly', null, null);                    
                    break;
                //
                // Client 2
                //
                case "822":
                    this.setState({
                        input_clients_2_Surname: value,
                    });
                break;
                case "3006":
                    this.setState({
                        input_clients_2_MiddleNames: value,
                    });
                    break;
                case "821":
                    this.setState({
                        input_clients_2_FirstName: value,
                    });
                    break;
                case "831":
                    var d3 = moment.utc(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_clients_2_DOB: d3,
                    });
                    break;
                case "832":
                    var event2 = {
                        target: { value: value }
                    };
                    this.onChange_clients_NINumber_2(event2);
                    break;
                case "833":
                    this.setState({
                        input_clients_2_Tel_Mobile: value,
                    });
                    break;
                case "3081":
                    if(value === "Yes") this.buttonChange_YES("clients_2_validID", true, null);                    
                    else this.buttonChange_NO('clients_2_validID', false, 'IQ-Segment-BottomNegMargin');                    
                    break;
                case "3082":
                    if(value === "Yes") this.buttonChange_YES('clients_2_validID_RecentExpired', null, null)                    
                    else this.buttonChange_NO('clients_2_validID_RecentExpired', null, null)                  
                    break;
                case "4554":
                    if(value === "Yes") this.buttonChange_YES('clients_2_poa', false, null);
                    else this.buttonChange_NO('clients_2_poa', true, null)
                    break;
                //
                // Client 2 POA
                //                
                case "4555":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_sameAs1", true, null);                    
                    else this.buttonChange_NO('clients_2_poa_sameAs1', false, null);  
                    break;
                case "4556":
                    this.setState({
                        input_clients_2_poa_value: value,
                    });
                    break;
                case "4577":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_visit", true, null);                    
                    else this.buttonChange_NO('clients_2_poa_visit', false, null);                    
                    break;
                case "4557":
                    this.setState({
                        input_clients_2_poa_Surname: value,
                    });
                break;
                case "4559":
                    this.setState({
                        input_clients_2_poa_MiddleNames: value,
                    });
                    break;
                case "4558":
                    this.setState({
                        input_clients_2_poa_FirstName: value,
                    });
                    break;
                case "4561":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_validID", true, null);
                    else this.buttonChange_NO('clients_2_poa_validID', false, 'IQ-Segment-TopMargin');
                    break;
                case "4562":
                    if(value === "Yes") this.buttonChange_YES('clients_2_poa_validID_RecentExpired', null, null)
                    else this.buttonChange_NO('clients_2_poa_validID_RecentExpired', null, null)
                    break;
                case "4563":
                    var d4 = moment.utc(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_clients_2_poa_DOB: d4,
                    });
                    break;
                case "4564":
                    this.setState({
                        input_clients_2_poa_Address_1: value,
                    });
                    break;
                case "4565":
                    this.setState({
                        input_clients_2_poa_Address_2: value,
                    });
                    break;
                case "4566":
                    this.setState({
                        input_clients_2_poa_Town: value,
                    });
                    break;
                case "4567":
                    this.setState({
                        input_clients_2_poa_County: value,
                    });
                    break;
                case "4568":
                    this.setState({
                        input_clients_2_poa_Postcode: value,
                    });
                    break;
                case "4569":
                    this.setState({
                        input_clients_2_poa_Mobile: value,
                    });
                    break;
                case "4570":
                    // this.setState({
                    //     input_clients_2_poa_Email: value,
                    // });
                    break;
                case "4571":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_correspond", null, null);
                    else this.buttonChange_NO('clients_2_poa_correspond', null, null);
                    break;
                case "4572":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_opg", false, null);
                    else this.buttonChange_NO('clients_2_poa_opg', true, null);
                    break;
                case "4573":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_opg_named", null, null);
                    else this.buttonChange_NO('clients_2_poa_opg_named', null, null);
                    break;
                case "4575":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_opg_restrictions", false, null);
                    else this.buttonChange_NO('clients_2_poa_opg_restrictions', true, null);
                    break;
                case "4553":
                    this.setState({
                        input_clients_2_poa_opg_restrictions_details: value,
                    });
                    break;
                case "4574":
                    if(value === "Yes") this.buttonChange_YES("clients_2_poa_jointly", null, null);                    
                    else this.buttonChange_NO('clients_2_poa_jointly', null, null);                    
                    break;
                //
                default:
                    break;
                }
            }
        }
        setTimeout(() => {
            this.setState({
                clientsDimmerActive: false,
            })
        }, 300);        
    }
    //
    //
    ///////////////////////////////
    // Step 1. Clients 1 Details //
    ///////////////////////////////
    //
    onChange_clients_DOBDate_1(value){
        var d = moment.utc(value, 'MM-DD-YYYY').toDate();
        if(this.state.input_clients_1_DOB !== d){
            this.setState({
                input_clients_1_DOB: d,
            });
        }
    }
    //
    onChange_clients_NINumber_1(event){
        //console.log("Event value: " + event.target.value);
        //console.log("Event value: " + this.state.input_clients_1_NINumber);
        if(event.target.value !== this.state.input_clients_1_NINumber){
            this.setState({
                input_clients_1_NINumber: this.formatNINumber(event.target.value)
            });
        }
    }
    //
    //////////////////
    // Client 1 POA //
    //////////////////
    //
    onChange_clients_poa_reason_1(event, data){
        console.log("POA Reason value 1: " + data.value);
        this.setState({
            input_clients_poa_value_1: data.value,
        });
    }
    //
    onChange_clients_poa_NINumber_1(event){
        if(event.target.value !== this.state.input_clients_1_poa_NINumber){
            this.setState({
                input_clients_1_poa_NINumber: this.formatNINumber(event.target.value)
            });
        }
    }
    //
    onChange_clients_poa_DOBDate_1(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_clients_poa_DOB_1:  d,
        });
    }      
    //
    //
    ///////////////////////////////
    // Step 1. Clients 2 Details //
    ///////////////////////////////
    //
    onChange_clients_DOBDate_2(value){
        var d = moment.utc(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_clients_2_DOB: d,
        });
    }
    //
    onChange_clients_NINumber_2(event){
        if(event.target.value !== this.state.input_clients_2_NINumber){
            this.setState({
                input_clients_2_NINumber: this.formatNINumber(event.target.value)
            });
        }
    }
    //
    //////////////////
    // Client 2 POA //
    //////////////////
    //
    //
    onChange_clients_poa_reason_2(event, data){
        console.log("POA Reason value 2: " + data.value);
        this.setState({
            input_clients_poa_value_2: data.value,
        });
    }
    //
    onChange_clients_poa_NINumber_2(event){
        if(event.target.value !== this.state.input_clients_2_poa_NINumber){
            this.setState({
                input_clients_2_poa_NINumber: this.formatNINumber(event.target.value)
            });
        }
    }
    //
    onChange_clients_poa_DOBDate_2(value){
        var d = moment.utc(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_clients_2_poa_DOB: d,
        });
    }
    //
    onChange_clients_poa_restrictions_details_2(event){
        this.setState({
            input_clients_poa_opg_restrictions_details_2: event.target.value
        });
    }
    //
    //
    onChange_clients_poa_opg_file_1(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_clients_1_poa_opg: file,
                file_clients_1_poa_opg_data: reader.result,
                uploaded: 0,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    onChange_clients_poa_opg_file_2(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_clients_2_poa_opg: file,
                file_clients_2_poa_opg_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    onClick_clients_poa_opg_upload_1(){
        this.setState({
            button_clients_poa_opg_upload_CSS_1: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_clients_1_poa_opg_data, this.state.file_clients_1_poa_opg.name, 1, "clients_1_poa_opg", () => {
            this.setState({
                button_clients_poa_opg_upload_CSS_1: "ui blue",
            });
        });
    }
    //
    //
    onClick_clients_poa_opg_upload_2(){
        this.setState({
            button_clients_poa_opg_upload_CSS_2: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_clients_2_poa_opg_data, this.state.file_clients_2_poa_opg.name, 1, "clients_2_poa_opg",   () => {
            this.setState({
                button_clients_poa_opg_upload_CSS_2: "ui blue",
            });
        });
    }
    //
    //
    upload_File(fileData, fileName, iqStep, iqUploadVar, callback){
        //
        if(fileData !== "" && fileName !== ""){
            axios.post(
                this.props.url + "/IQ/fileUpload",
                {
                    "token": localStorage.token,
                    "content": fileData,
                    "fileName": fileName,
                    "fileId": this.props.fileId,
                    "iqStep": iqStep,
                    "iqUploadVar": iqUploadVar,
                    "copyToDPS": true,
                },
                { 
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            uploaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                        })
                    },
                },
            )
            .then((response) => {
                console.log(response);
                this.setState({
                    messageModalIcon_CSS: "checkmark circle icon",
                    messageModalTitle: "Upload Success",
                    messageModalContent: "Your document has been successfully uploaded!",
                    messageModalOpen: true,
                    uploadModalOpen: false,
                });
                callback();
            })
            .catch((error) => {    
                //https://github.com/axios/axios#handling-errors
                console.log(error);
                switch(error.response.status){
                    case 401:
                        this.props.history.push("/");
                        break;
                    case 500:
                        this.setState({                            
                            messageModalIcon_CSS: "warning circle icon",
                            messageModalTitle: "Error",
                            messageModalContent: "A server error occured during your file upload.\r\n\r\nPlease try again, but if the error persists then please contact Equilaw",
                            messageModalOpen: true,
                            uploadModalOpen: false,
                        });
                        callback();
                        break;
                    default:
                        break;
                }
            });
        }
        else callback();
    }
    //
    //
    saveData_Wrapper(){
        this.setState({
            dimmerActionText: "Saving Data...",
            clientsDimmerActive: true,
            button_forward_CSS: "green IQ-forwardButton loading disabled",
        });
        var results = this.saveData(this.props.url);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        clientsDimmerActive: false,
                        button_forward_CSS: "green IQ-forwardButton",
                    });
                    this.props.forward();
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async saveData(url){
        const response = await fetch(url + "/IQ/SaveData_Step1", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.fileId,
                backward: false,
                //
                client1_Surname: this.state.input_clients_1_Surname,
                client1_MiddleNames: this.state.input_clients_1_MiddleNames,
                client1_FirstName: this.state.input_clients_1_FirstName,
                client1_DOB: this.state.input_clients_1_DOB,
                client1_NINumber: this.state.input_clients_1_NINumber.replace(" ", ""),
                client1_Tel_Home: this.state.input_clients_1_Tel,
                client1_Tel_Mobile: this.state.input_clients_1_Tel_Mobile,
                client1_Tel_Work: this.state.input_clients_1_Tel_Work,
                client1_Email: this.state.input_clients_1_Email,
                client1_HasValidID: this.state.button_clients_1_validID_Checked_YES ? true : (this.state.button_clients_1_validID_Checked_NO ? false: null),
                client1_HasRecentExpiredID: this.state.button_clients_1_validID_RecentExpired_Checked_YES ? true : (this.state.button_clients_1_validID_RecentExpired_Checked_NO ? false: null),
                client1UsingPOA: this.state.button_clients_1_poa_Checked_YES ? true : (this.state.button_clients_1_poa_Checked_NO ? false: null),
                //
                client1_poa_reason: this.state.input_clients_1_poa_value,
                client1_poa_homeVisit: this.state.button_clients_poa_visit_Checked_YES_1 ? true : (this.state.button_clients_poa_visit_Checked_NO_1 ? false: null),
                client1_poa_Surname: this.state.input_clients_1_poa_Surname,
                client1_poa_MiddleNames: this.state.input_clients_1_poa_MiddleNames,
                client1_poa_FirstName: this.state.input_clients_1_poa_FirstName,
                client1_poa_NINumber: this.state.input_clients_1_poa_NINumber.replace(" ", ""),
                client1_poa_HasValidID: this.state.button_clients_poa_validID_Checked_YES_1 ? true : (this.state.button_clients_poa_validID_Checked_NO_1 ? false: null),
                client1_poa_HasRecentExpiredID: this.state.button_clients_poa_Checked_YES_1 ? true : (this.state.button_clients_poa_Checked_NO_1 ? false: null),
                client1_poa_DOB: this.state.input_clients_1_poa_DOB,
                client1_poa_Address1: this.state.input_clients_1_poa_Address_1,
                client1_poa_Address2: this.state.input_clients_1_poa_Address_2,
                client1_poa_Town: this.state.input_clients_1_poa_Town,
                client1_poa_County: this.state.input_clients_1_poa_County,
                client1_poa_Postcode: this.state.input_clients_1_poa_Postcode,
                client1_poa_Tel_Mobile: this.state.input_clients_1_poa_Mobile,
                client1_poa_Email: this.state.input_clients_1_poa_Email,
                client1_poa_SendCorrespondance: this.state.button_clients_poa_correspond_Checked_YES_1 ? true : (this.state.button_clients_poa_correspond_Checked_NO_1 ? false: null),
                client1_poa_opg_Obtained: this.state.button_clients_poa_opg_Checked_YES_1 ? true : (this.state.button_clients_poa_opg_Checked_NO_1 ? false: null),
                client1_poa_CanActJointlyOrSeverally: this.state.button_clients_poa_opg_jointly_Checked_YES_1 ? true : (this.state.button_clients_poa_opg_jointly_Checked_NO_1 ? false: null),
                //
                client1_poa_opg_NamedOnOPG: this.state.button_clients_poa_opg_named_Checked_YES_1 ? true : (this.state.button_clients_poa_opg_named_Checked_NO_1 ? false: null),
                client1_poa_opg_HasOPGRestrictions: this.state.button_clients_poa_opg_restrictions_Checked_YES_1 ? true : (this.state.button_clients_poa_opg_restrictions_Checked_NO_1 ? false: null),
                client1_poa_opg_OPGRestrictionDetails: this.state.input_clients_1_poa_opg_restrictions_details,
                client1_poa_opg_UploadedOPGDoc: this.state.bool_clients_1_poa_opg_hasUploaded,
                //
                //////////////
                // CLIENT 2 //
                //////////////
                client2_Surname: this.state.input_clients_2_Surname,
                client2_MiddleNames: this.state.input_clients_2_MiddleNames,
                client2_FirstName: this.state.input_clients_2_FirstName,
                client2_DOB: this.state.input_clients_2_DOB,
                client2_NINumber: this.state.input_clients_2_NINumber.replace(" ", ""),
                //client2_Tel_Home: this.state.input_clients_Tel_2,
                client2_Tel_Mobile: this.state.input_clients_2_Tel_Mobile,
                //client2_Tel_Work: this.state.input_clients_Tel_Work_2,
                client2_Email: "",//this.state.input_clients_2_Email,
                client2_HasValidID: this.state.button_clients_2_validID_Checked_YES ? true : (this.state.button_clients_2_validID_Checked_NO ? false: null),
                client2_HasRecentExpiredID: this.state.button_clients_2_validID_RecentExpired_Checked_YES ? true : (this.state.button_clients_2_validID_RecentExpired_Checked_NO ? false: null),
                client2UsingPOA: this.state.button_clients_2_poa_Checked_YES ? true : (this.state.button_clients_2_poa_Checked_NO ? false: null),
                //
                client2_poa_SameAs1: this.state.button_clients_2_poa_sameAs1_Checked_YES ? true : (this.state.button_clients_2_poa_sameAs1_Checked_NO ? false: null),
                client2_poa_reason: this.state.input_clients_2_poa_value,
                client2_poa_homeVisit: this.state.button_clients_2_poa_visit_Checked_YES ? true : (this.state.button_clients_2_poa_visit_Checked_NO ? false: null),
                client2_poa_Surname: this.state.input_clients_2_poa_Surname,
                client2_poa_MiddleNames: this.state.input_clients_2_poa_MiddleNames,
                client2_poa_FirstName: this.state.input_clients_2_poa_FirstName,
                client2_poa_NINumber: this.state.input_clients_2_poa_NINumber.replace(" ", ""),
                client2_poa_HasValidID: this.state.button_clients_2_poa_validID_Checked_YES ? true : (this.state.button_clients_2_poa_validID_Checked_NO ? false: null),
                client2_poa_HasRecentExpiredID: this.state.button_clients_2_poa_Checked_YES ? true : (this.state.button_clients_2_poa_Checked_NO ? false: null),
                client2_poa_DOB: this.state.input_clients_2_poa_DOB,
                client2_poa_Address1: this.state.input_clients_2_poa_Address_1,
                client2_poa_Address2: this.state.input_clients_2_poa_Address_2,
                client2_poa_Town: this.state.input_clients_2_poa_Town,
                client2_poa_County: this.state.input_clients_2_poa_County,
                client2_poa_Postcode: this.state.input_clients_2_poa_Postcode,
                client2_poa_Tel_Mobile: this.state.input_clients_2_poa_Mobile,
                client2_poa_Email: this.state.input_clients_2_poa_Email,
                client2_poa_SendCorrespondance: this.state.button_clients_2_poa_correspond_Checked_YES ? true : (this.state.button_clients_2_poa_correspond_Checked_NO ? false: null),
                client2_poa_opg_Obtained: this.state.button_clients_2_poa_opg_Checked_YES ? true : (this.state.button_clients_2_poa_opg_Checked_NO ? false: null),
                client2_poa_CanActJointlyOrSeverally: this.state.button_clients_2_poa_opg_jointly_Checked_YES ? true : (this.state.button_clients_2_poa_opg_jointly_Checked_NO ? false: null),
                //
                client2_poa_opg_NamedOnOPG: this.state.button_clients_2_poa_opg_named_Checked_YES ? true : (this.state.button_clients_2_poa_opg_named_Checked_NO ? false: null),
                client2_poa_opg_HasOPGRestrictions: this.state.button_clients_2_poa_opg_restrictions_Checked_YES ? true : (this.state.button_clients_2_poa_opg_restrictions_Checked_NO ? false: null),
                client2_poa_opg_OPGRestrictionDetails: this.state.input_clients_2_poa_opg_restrictions_details,
                client2_poa_opg_UploadedOPGDoc: this.state.bool_clients_2_poa_opg_hasUploaded,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>                
                {/* Step 1. Client Details */}
                <Segment>
                    <Dimmer active={this.state.clientsDimmerActive}>
                        <Loader>{this.state.dimmerActionText}</Loader>
                    </Dimmer>
                    <Grid columns={2} className="IQ-Grid">
                        <Grid.Row>
                            <Grid.Column>
                                <Segment className="IQ-Segment"> 
                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="user icon large"/>&emsp;Client 1 Details</h4></Label>                                   
                                    <br/><br/>                                    
                                    <div>                                        
                                    <Form>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_clients_1_Surname} onChange={this.valueChange('input_clients_Surname_1')} />
                                            </div>                                                                                    
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_clients_1_MiddleNames} onChange={this.valueChange('input_clients_MiddleNames_1')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_clients_1_FirstName} onChange={this.valueChange('input_clients_FirstName_1')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <div>
                                                <div className="IQ-DivInline">
                                                    <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                                            
                                                </div>                                                                            
                                                <Calendar
                                                    id= "input_clients_1_DOB"
                                                    format='ddd DD/MMM/YYYY'
                                                    //date={this.state.moment.format('DD/MM/YYYY')}
                                                    displayYrWithMonth={true}
                                                    closeOnSelect={true}
                                                    openOnInputFocus={true}
                                                    readOnly={true}
                                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                                    date={this.state.input_clients_1_DOB}
                                                    onChange={this.onChange_clients_DOBDate_1}
                                                    locale="en-gb"
                                                    //customIcon="calendar outline icon"
                                                />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="NI Number:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className="input_client_1_ninumber" placeholder="NI Number" type="text" value={this.state.input_clients_1_NINumber} onChange={this.onChange_clients_NINumber_1} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Home:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Home" type="text" value={this.state.input_clients_1_Tel} onChange={this.valueChange('input_clients_1_Tel')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Mobile:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Mobile" type="text" value={this.state.input_clients_1_Tel_Mobile} onChange={this.valueChange('input_clients_1_Tel_Mobile')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Work:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Work" type="text" value={this.state.input_clients_1_Tel_Work} onChange={this.valueChange('input_clients_1_Tel_Work')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Email:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Email" type="text" value={this.state.input_clients_1_Email} onChange={this.valueChange('input_clients_1_Email')} />
                                            </div>
                                        </Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you have current valid Photo I.D?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_clients_1_validID_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_validID', true, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_clients_1_validID_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_validID', false, 'IQ-Segment-BottomNegMargin')}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_clients_1_validID_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Photo I.D.</h4></Label>
                                            <div>
                                                <br/>
                                                <div>
                                                    <Label className="IQ-Label" pointing={"right"} content="Do you have recently expired photo I.D?"/>&emsp;
                                                    <div className='ui buttons'>
                                                        <Button className={this.state.button_clients_1_validID_RecentExpired_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_validID_RecentExpired', null, null)}}>Yes</Button>
                                                        <div className='or' />
                                                        <Button className={this.state.button_clients_1_validID_RecentExpired_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_validID_RecentExpired', null, null)}}>No</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Segment>
                                        <br/><br/>
                                        <Label className="IQ-Label" pointing={"right"} content="Is the First Client being represented by someone with Power of Attorney (POA)?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_clients_1_poa_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_clients_1_poa_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_clients_1_poa_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Power of Attorney</h4></Label>
                                            <div>
                                                <br/>
                                                <span>
                                                    <Label className="IQ-Label" pointing={"right"} content="Reason for using Power of Attorney:"/>&emsp;
                                                    <Dropdown placeholder="Select POA Reason..." inline selection options={this.poaOptions} onChange={this.onChange_clients_poa_reason_1}/>
                                                </span> 
                                            </div>
                                            <br/>
                                            <Label className="IQ-Label" pointing={"right"} content="Will the home visit be at the POA's home address?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_clients_1_poa_visit_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_visit', null, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_clients_1_poa_visit_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_visit', null, null)}}>No</Button>
                                            </div>
                                            <br/><br/>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Surname:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Surname" type="text" value={this.state.input_clients_1_poa_Surname} onChange={this.valueChange('input_clients_1_poa_Surname')} />
                                                </div>                                                                                    
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Middle Name(s):"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Middle Name(s)" type="text" value={this.state.input_clients_1_poa_MiddleNames} onChange={this.valueChange('input_clients_1_poa_MiddleNames')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA First Name:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA First Name" type="text" value={this.state.input_clients_1_poa_FirstName} onChange={this.valueChange('input_clients_1_poa_FirstName')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA NI Number:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA NI Number" type="text" value={this.state.input_clients_1_poa_NINumber} onChange={this.onChange_clients_poa_NINumber_1} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="Does the POA have current valid Photo I.D?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_1_poa_validID_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_validID', true, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_1_poa_validID_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_validID', false, 'IQ-Segment-TopMargin')}}>No</Button>
                                                </div>
                                                <br/>
                                                <div>                                             
                                                    <Segment className={this.state.segment_clients_1_poa_validID_CSS}>                                                    
                                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA Photo I.D.</h4></Label>
                                                        <div>
                                                            <br/>
                                                            <div>
                                                                <Label className="IQ-Label" pointing={"right"} content="Does the POA have recently expired photo I.D?"/>&emsp;
                                                                <div className='ui buttons'>
                                                                    <Button className={this.state.button_clients_1_poa_validID_RecentExpired_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_validID_RecentExpired', null, null)}}>Yes</Button>
                                                                    <div className='or' />
                                                                    <Button className={this.state.button_clients_1_poa_validID_RecentExpired_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_validID_RecentExpired', null, null)}}>No</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Segment>
                                                </div>
                                            </Form.Field>                                            
                                            <Form.Field>
                                                <div>
                                                    <div className="IQ-DivInline">
                                                        <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="POA Date of Birth:"/>&emsp;                                                                            
                                                    </div>                                                                            
                                                    <Calendar
                                                        id= "input_clients_1_poa_DOB"
                                                        format='ddd DD/MMM/YYYY'
                                                        //date={this.state.moment.format('DD/MM/YYYY')}
                                                        displayYrWithMonth={true}
                                                        closeOnSelect={true}
                                                        openOnInputFocus={true}
                                                        readOnly={true}
                                                        inputFieldClass="input-calendar-value IQ-Calendar"
                                                        date={this.state.input_clients_1_poa_DOB}
                                                        onChange={this.onChange_clients_poa_DOBDate_1}
                                                        locale="en-gb"
                                                        //customIcon="calendar outline icon"
                                                    />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Address 1:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Address 1" type="text" value={this.state.input_clients_1_poa_Address_1} onChange={this.valueChange('input_clients_1_poa_Address_1')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Address 2:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Address 2" type="text" value={this.state.input_clients_1_poa_Address_2} onChange={this.valueChange('input_clients_1_poa_Address_2')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Town/City:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Town/City" type="text" value={this.state.input_clients_1_poa_Town} onChange={this.valueChange('input_clients_1_poa_Town')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA County:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA County" type="text" value={this.state.input_clients_1_poa_County} onChange={this.valueChange('input_clients_1_poa_County')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Postcode:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Postcode" type="text" value={this.state.input_clients_1_poa_Postcode} onChange={this.valueChange('input_clients_1_poa_Postcode')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Mobile Number:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Mobile Number" type="text" value={this.state.input_clients_1_poa_Mobile} onChange={this.valueChange('input_clients_1_poa_Mobile')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Email:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Email" type="text" value={this.state.input_clients_1_poa_Email} onChange={this.valueChange('input_clients_1_poa_Email')} />
                                                </div>
                                            </Form.Field>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Should we send correspondance to the POA?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_1_poa_correspond_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_correspond', null, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_1_poa_correspond_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_correspond', null, null)}}>No</Button>
                                                </div>
                                            </div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Has 'Office of the Public Guardian' (OPG) been obtained?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_1_poa_opg_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_opg', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_1_poa_opg_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_opg', true, null)}}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_clients_1_poa_opg_CSS}>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA OPG Details</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div>
                                                            <Label className="IQ-Label" pointing={"right"} content="Is the attorney named on the OPG?"/>&emsp;
                                                            <div className='ui buttons'>
                                                                <Button className={this.state.button_clients_1_poa_opg_named_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_opg_named', null, null)}}>Yes</Button>
                                                                <div className='or' />
                                                                <Button className={this.state.button_clients_1_poa_opg_named_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_opg_named', null, null)}}>No</Button>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            <Label className="IQ-Label" pointing={"right"} content="Are there any restrictions?"/>&emsp;
                                                            <div className='ui buttons'>
                                                                <Button className={this.state.button_clients_1_poa_opg_restrictions_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_opg_restrictions', false, null)}}>Yes</Button>
                                                                <div className='or' />
                                                                <Button className={this.state.button_clients_1_poa_opg_restrictions_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_opg_restrictions', true, null)}}>No</Button>
                                                            </div>
                                                        </div>
                                                        <Segment className={this.state.segment_clients_1_poa_opg_restrictions_CSS}>
                                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA OPG Restrictions Details</h4></Label>
                                                            <div>
                                                                <br/>
                                                                <div>
                                                                    <Label className="IQ-Label" pointing={"right"} content="What are the restrictions?"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="OPG Restrictions" type="text" value={this.state.input_clients_1_poa_opg_restrictions_details} onChange={this.valueChange('input_clients_1_poa_opg_restrictions_details')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Segment>
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        <Label className="IQ-Label" pointing={"right"} content="If possible, please upload a copy of the OPG document."/>&emsp;
                                                        <Input id="fileUpload1" className="ui" type="file" onChange={this.onChange_clients_poa_opg_file_1}/>&emsp;
                                                        <Button className="ui blue" content="Upload" onClick={this.onClick_clients_poa_opg_upload_1}/>
                                                    </div>
                                                    <br/>
                                                    <Label className="IQ-Label red">
                                                    PLEASE NOTE: In all cases, you must send us the original OPG document regardless of whether you have uploaded a copy or not.
                                                    <br/><br/> 
                                                    However, by uploading a copy, you will be decreasing the time it takes us to process your application.
                                                    </Label>
                                                </Segment>
                                            </div>
                                            <br/>
                                            <Label className="IQ-Label" pointing={"right"} content="Is the POA able to act 'Jointly or Severally'?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_clients_1_poa_opg_jointly_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_1_poa_opg_jointly', null, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_clients_1_poa_opg_jointly_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_1_poa_opg_jointly', null, null)}}>No</Button>
                                            </div>
                                        </Segment>                                        
                                    </Form>
                                    </div>
                                </Segment>
                            </Grid.Column>
                            {/* //////////////// */}
                            {/* //////////////// */}
                            {/* //// Client 2 // */}
                            {/* //////////////// */}
                            {/* //////////////// */}
                            <Grid.Column>                                
                                <Segment className="IQ-Segment"> 
                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="user icon large"/>&emsp;Client 2 Details</h4></Label>                                   
                                    <br/><br/>                                    
                                    <div>                                        
                                    <Form>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_clients_2_Surname} onChange={this.valueChange('input_clients_2_Surname')} />
                                            </div>                                                                                    
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_clients_2_MiddleNames} onChange={this.valueChange('input_clients_2_MiddleNames')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_clients_2_FirstName} onChange={this.valueChange('input_clients_2_FirstName')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <div>
                                                <div className="IQ-DivInline">
                                                    <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                  
                                                </div>
                                                <Calendar
                                                    id= "input_clients_2_DOB"
                                                    format='ddd DD/MMM/YYYY'
                                                    //date={this.state.moment.format('DD/MM/YYYY')}
                                                    displayYrWithMonth={true}
                                                    closeOnSelect={true}
                                                    openOnInputFocus={true}
                                                    readOnly={true}
                                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                                    date={this.state.input_clients_2_DOB}
                                                    onChange={this.onChange_clients_DOBDate_2}
                                                    locale="en-gb"
                                                    //customIcon="calendar outline icon"
                                                />
                                            </div>                                    
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="NI Number:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="NI Number" type="text" value={this.state.input_clients_2_NINumber} onChange={this.onChange_clients_NINumber_2} />
                                            </div>
                                        </Form.Field>
                                        {/* <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Home:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Home" type="text" value={this.state.input_clients_Tel_2} onChange={this.onChange_clients_Tel_2} />
                                            </div>
                                        </Form.Field> */}
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Mobile:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Mobile" type="text" value={this.state.input_clients_2_Tel_Mobile} onChange={this.valueChange('input_clients_2_Tel_Mobile')} />
                                            </div>
                                        </Form.Field>
                                        {/* <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Telephone - Work:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Telephone - Work" type="text" value={this.state.input_clients_Tel_Work_2} onChange={this.onChange_clients_Tel_Work_2} />
                                            </div>
                                        </Form.Field> */}
                                        {/* <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Email:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Email" type="text" value={this.state.input_clients_2_Email} onChange={this.valueChange('input_clients_2_Email')} />
                                            </div>
                                        </Form.Field> */}
                                        <Label className="IQ-Label" pointing={"right"} content="Do you have current valid Photo I.D?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_clients_2_validID_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_validID', true, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_clients_2_validID_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_validID', false, 'IQ-Segment-BottomNegMargin')}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_clients_2_validID_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Photo I.D.</h4></Label>
                                            <div>
                                                <br/>
                                                <div>
                                                    <Label className="IQ-Label" pointing={"right"} content="Do you have recently expired photo I.D?"/>&emsp;
                                                    <div className='ui buttons'>
                                                        <Button className={this.state.button_clients_2_validID_RecentExpired_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_validID_RecentExpired', null, null)}}>Yes</Button>
                                                        <div className='or' />
                                                        <Button className={this.state.button_clients_2_validID_RecentExpired_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_validID_RecentExpired', null, null)}}>No</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Segment>
                                        <br/><br/>
                                        <Label className="IQ-Label" pointing={"right"} content="Is the Second Client being represented by someone with Power of Attorney (POA)?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_clients_2_poa_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_clients_2_poa_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_clients_2_poa_CSS}>
                                            <Label className="IQ-Label" pointing={"right"} content="Is this the same POA as Client 1?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_clients_2_poa_sameAs1_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_sameAs1', true, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_clients_2_poa_sameAs1_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_sameAs1', false, null)}}>No</Button>
                                            </div>
                                        </Segment>
                                        <Segment className={this.state.segment_clients_2_poa_sameAs1_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Power of Attorney</h4></Label>
                                            <div>
                                                <br/>
                                                <span>
                                                    <Label className="IQ-Label" pointing={"right"} content="Reason for using Power of Attorney:"/>&emsp;
                                                    <Dropdown placeholder="Select POA Reason..." inline selection options={this.poaOptions} onChange={this.onChange_clients_poa_reason_2}/>
                                                </span> 
                                            </div>
                                            <br/>
                                            <Label className="IQ-Label" pointing={"right"} content="Will the home visit be at the POA's home address?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_clients_2_poa_visit_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_visit', null, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_clients_2_poa_visit_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_visit', null, null)}}>No</Button>
                                            </div>
                                            <br/><br/>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Surname:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Surname" type="text" value={this.state.input_clients_2_poa_Surname} onChange={this.valueChange('input_clients_2_poa_Surname')} />
                                                </div>                                                                                    
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Middle Name(s):"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Middle Name(s)" type="text" value={this.state.input_clients_2_poa_MiddleNames} onChange={this.valueChange('input_clients_2_poa_MiddleNames')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA First Name:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA First Name" type="text" value={this.state.input_clients_2_poa_FirstName} onChange={this.valueChange('input_clients_2_poa_FirstName')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA NI Number:"/>&emsp; 
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA NI Number" type="text" value={this.state.input_clients_2_poa_NINumber} onChange={this.onChange_clients_poa_NINumber_2} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="Does the POA have current valid Photo I.D?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_2_poa_validID_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_validID', true, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_2_poa_validID_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_validID', false, 'IQ-Segment-TopMargin')}}>No</Button>
                                                </div>
                                                <br/>
                                                <div>                                             
                                                    <Segment className={this.state.segment_clients_2_poa_validID_CSS}>                                                    
                                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA Photo I.D.</h4></Label>
                                                        <div>
                                                            <br/>
                                                            <div>
                                                                <Label className="IQ-Label" pointing={"right"} content="Does the POA have recently expired photo I.D?"/>&emsp;
                                                                <div className='ui buttons'>
                                                                    <Button className={this.state.button_clients_2_poa_validID_RecentExpired_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_validID_RecentExpired', null, null)}}>Yes</Button>
                                                                    <div className='or' />
                                                                    <Button className={this.state.button_clients_2_poa_validID_RecentExpired_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_validID_RecentExpired', null, null)}}>No</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Segment>
                                                </div>
                                            </Form.Field>                                            
                                            <Form.Field>
                                                <div>
                                                    <div className="IQ-DivInline">
                                                        <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="POA Date of Birth:"/>&emsp;
                                                    </div>                                                                            
                                                    <Calendar
                                                        id= "input_clients_2_poa_DOB"
                                                        format='ddd DD/MMM/YYYY'
                                                        //date={this.state.moment.format('DD/MM/YYYY')}
                                                        displayYrWithMonth={true}
                                                        closeOnSelect={true}
                                                        openOnInputFocus={true}
                                                        readOnly={true}
                                                        inputFieldClass="input-calendar-value IQ-Calendar"
                                                        date={this.state.input_clients_2_poa_DOB}
                                                        onChange={this.onChange_clients_poa_DOBDate_2}
                                                        locale="en-gb"
                                                        //customIcon="calendar outline icon"
                                                    />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Address 1:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Address 1" type="text" value={this.state.input_clients_2_poa_Address_1} onChange={this.valueChange('input_clients_2_poa_Address_1')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Address 2:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Address 2" type="text" value={this.state.input_clients_2_poa_Address_2} onChange={this.valueChange('input_clients_2_poa_Address_2')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Town/City:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Town/City" type="text" value={this.state.input_clients_2_poa_Town} onChange={this.valueChange('input_clients_2_poa_Town')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA County:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA County" type="text" value={this.state.input_clients_2_poa_County} onChange={this.valueChange('input_clients_2_poa_County')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Postcode:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Postcode" type="text" value={this.state.input_clients_2_poa_Postcode} onChange={this.valueChange('input_clients_2_poa_Postcode')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Mobile Number:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Mobile Number" type="text" value={this.state.input_clients_2_poa_Mobile} onChange={this.valueChange('input_clients_2_poa_Mobile')} />
                                                </div>
                                            </Form.Field>
                                            <Form.Field>
                                                <Label className="IQ-Label" pointing={"right"} content="POA Email:"/>&emsp;
                                                <div className="IQ-Form-Field-Sm">
                                                    <input  className={this.state.passMatch} placeholder="POA Email" type="text" value={this.state.input_clients_2_poa_Email} onChange={this.valueChange('input_clients_2_poa_Email')} />
                                                </div>
                                            </Form.Field>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Should we send correspondance to the POA?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_2_poa_correspond_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_correspond', null, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_2_poa_correspond_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_correspond', null, null)}}>No</Button>
                                                </div>
                                            </div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Has 'Office of the Public Guardian' (OPG) been obtained?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_clients_2_poa_opg_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_opg', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_clients_2_poa_opg_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_opg', true, null)}}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_clients_2_poa_opg_CSS}>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA OPG Details</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div>
                                                            <Label className="IQ-Label" pointing={"right"} content="Is the attorney named on the OPG?"/>&emsp;
                                                            <div className='ui buttons'>
                                                                <Button className={this.state.button_clients_2_poa_opg_named_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_opg_named', null, null)}}>Yes</Button>
                                                                <div className='or' />
                                                                <Button className={this.state.button_clients_2_poa_opg_named_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_opg_named', null, null)}}>No</Button>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div>
                                                            <Label className="IQ-Label" pointing={"right"} content="Are there any restrictions?"/>&emsp;
                                                            <div className='ui buttons'>
                                                                <Button className={this.state.button_clients_2_poa_opg_restrictions_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_opg_restrictions', false, null)}}>Yes</Button>
                                                                <div className='or' />
                                                                <Button className={this.state.button_clients_2_poa_opg_restrictions_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_opg_restrictions', true, null)}}>No</Button>
                                                            </div>
                                                        </div>
                                                        <Segment className={this.state.segment_clients_2_poa_opg_restrictions_CSS}>
                                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: POA OPG Restrictions Details</h4></Label>
                                                            <div>
                                                                <br/>
                                                                <div>
                                                                    <Label className="IQ-Label" pointing={"right"} content="What are the restrictions?"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="OPG Restrictions" type="text" value={this.state.input_clients_2_poa_opg_restrictions_details} onChange={this.valueChange('input_clients_2_poa_opg_restrictions_details')} />
                                                                    </div>
                                                                </div>
                                                            </div>                                                            
                                                        </Segment>
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        <Label className="IQ-Label" pointing={"right"} content="If possible, please upload a copy of the OPG document."/>&emsp;
                                                        <Input id="fileUpload2" className="ui" type="file" onChange={this.onChange_clients_poa_opg_file_2}/>&emsp;
                                                        <Button className={this.state.button_clients_2_poa_opg_upload_CSS} content="Upload" onClick={this.onClick_clients_poa_opg_upload_2}/>
                                                    </div>
                                                    <br/>
                                                    <Label className="IQ-Label red">
                                                        PLEASE NOTE: In all cases, you must send us the original OPG document regardless of whether you have uploaded a copy or not.
                                                        <br/><br/>
                                                        By uploading a copy, you will be decreasing the time it takes us to process your application.
                                                    </Label>
                                                </Segment>
                                            </div>
                                            <br/>
                                            <Label className="IQ-Label" pointing={"right"} content="Is the POA able to act 'Jointly or Severally'?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_clients_2_poa_opg_jointly_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('clients_2_poa_opg_jointly', null, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_clients_2_poa_opg_jointly_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('clients_2_poa_opg_jointly', null, null)}}>No</Button>
                                            </div>
                                        </Segment>
                                    </Form>
                                    </div>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br/>
                    <Button className={this.state.button_forward_CSS} onClick={this.saveData_Wrapper}>Next Step</Button>
                    <br/><br/>
                </Segment>
                <Modal className="IQ-Modal-Message" open={this.state.messageModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className={this.state.messageModalIcon_CSS}/>&emsp;{this.state.messageModalTitle}</Header>
                    <Modal.Content>
                        <div>
                            {this.state.messageModalContent}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>
                </Modal>
                <Modal className="IQ-Modal-Message" open={this.state.uploadModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className="ui icon upload"/>&emsp;File Uploading...</Header>
                    <Modal.Content>
                        <div>
                            <Progress percent={Math.round(this.state.uploaded)} progress active color="green"  max="100" />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button> */}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default IQPage1Clients