import React, { Component } from 'react';
import './ClientCasePage.css';
import { Segment, Label, Button, Icon, Dimmer, Loader, Grid } from 'semantic-ui-react';
//import BigCalendarWrapper from './BigCalendarWrapper';
import ClientCaseAutoAllocate from './ClientCaseAutoAllocate';

class ClientCasePage extends Component{
    constructor(props){
        super(props);
        this.state = {
            tableData: [],
            matterDataDimmerActive: true,
            matterDocListDimmerActive: true,
            months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            fileNames: [],
            filesModalOpen: false,
            matterApptBookDimmerActive: false,
            matterFileId: 0,
            clientSegmentCSS_preapt: "",
            clientSegmentCSS: "Matters-Client-Segment-Hidden",
            clientSegmentCSS_postapt: "Matters-Client-Segment-Hidden-postapt",
            clientSegmentCSS_mobile: "",

            milestoneCSS_1: "Matters-Grid-Segment-Outstanding",
            milestoneCSS_2: "Matters-Grid-Segment-Outstanding",
            milestoneCSS_3: "Matters-Grid-Segment-Outstanding",
            milestoneCSS_4: "Matters-Grid-Segment-Outstanding",
            milestoneCSS_5: "Matters-Grid-Segment-Outstanding",
            milestoneCSS_6: "Matters-Grid-Segment-Outstanding",

            noDocsClass: "",
            //
            IQButton_CSS: "",
            //
            docChecklistData: "",
            docChecklistDataDimmerActive: false,
            docChecklistEmpty: "Matters-Client-Segment-Hidden",
            docStep1_needed_poa_opg_1: false,
            docStep1_needed_poa_opg_1_CSS: "Matters-Client-Segment-Hidden",
            docStep1_needed_poa_opg_1_Icon: "red remove icon",
            docStep1_uploaded_poa_opg_1: false,
            docStep1_uploaded_poa_opg_1_Icon: "red remove icon",
            docStep1_needed_poa_opg_2: false,
            docStep1_needed_poa_opg_2_CSS: "Matters-Client-Segment-Hidden",
            docStep1_needed_poa_opg_2_Icon: "red remove icon",
            docStep1_uploaded_poa_opg_2: false,
            docStep1_uploaded_poa_opg_2_Icon: "red remove icon",
            //
            docStep2_needed_property_CSS: "Matters-Client-Segment-Hidden",
            docStep2_needed_solar_CSS: "Matters-Client-Segment-Hidden",
            docStep2_uploaded_solar_mcs_Icon: "red remove icon",
            docStep2_needed_solar_lease_CSS: "Matters-Client-Segment-Hidden",
            docStep2_needed_solar_lease_Icon: "red remove icon",
            docStep2_uploaded_solar_lease_Icon: "red remove icon",
            docStep2_needed_solar_receipt_CSS: "Matters-Client-Segment-Hidden",
            docStep2_needed_solar_receipt_Icon: "red remove icon",
            docStep2_uploaded_solar_receipt_Icon: "red remove icon",
            docStep2_needed_tenure_share_CSS: "Matters-Client-Segment-Hidden",
            docStep2_needed_tenure_share_Icon: "red remove icon",
            docStep2_uploaded_tenure_share_Icon: "red remove icon",
            //
            docStep3_showStep: "Matters-Client-Segment-Hidden",
            //
            docStep3_needed_occupier_1_capacity_evidence_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_1_capacity_evidence_Icon: "red remove icon",
            docStep3_uploaded_occupier_1_capacity_evidence_Icon: "red remove icon",
            docStep3_needed_occupier_1_capacity_carePlan_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_1_capacity_carePlan_Icon: "red remove icon",
            docStep3_uploaded_occupier_1_capacity_carePlan_Icon: "red remove icon",
            //
            docStep3_needed_occupier_2_capacity_evidence_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_2_capacity_evidence_Icon: "red remove icon",
            docStep3_uploaded_occupier_2_capacity_evidence_Icon: "red remove icon",
            docStep3_needed_occupier_2_capacity_carePlan_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_2_capacity_carePlan_Icon: "red remove icon",
            docStep3_uploaded_occupier_2_capacity_carePlan_Icon: "red remove icon",
            //
            docStep3_needed_occupier_3_capacity_evidence_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_3_capacity_evidence_Icon: "red remove icon",
            docStep3_uploaded_occupier_3_capacity_evidence_Icon: "red remove icon",
            docStep3_needed_occupier_3_capacity_carePlan_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_3_capacity_carePlan_Icon: "red remove icon",
            docStep3_uploaded_occupier_3_capacity_carePlan_Icon: "red remove icon",
            //
            docStep3_needed_occupier_4_capacity_evidence_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_4_capacity_evidence_Icon: "red remove icon",
            docStep3_uploaded_occupier_4_capacity_evidence_Icon: "red remove icon",
            docStep3_needed_occupier_4_capacity_carePlan_CSS: "Matters-Client-Segment-Hidden",
            docStep3_needed_occupier_4_capacity_carePlan_Icon: "red remove icon",
            docStep3_uploaded_occupier_4_capacity_carePlan_Icon: "red remove icon",
        }
        this.fetchMatterData = this.fetchMatterData.bind(this);
        this.fetchMatterData_Wrapper = this.fetchMatterData_Wrapper.bind(this);
        this.makePlaceholderFilter = this.makePlaceholderFilter.bind(this);
        this.fetchDocList_Wrapper = this.fetchDocList_Wrapper.bind(this);
        this.fetchDocList = this.fetchDocList.bind(this);
        //
        this.fetchDocData_Wrapper = this.fetchDocData_Wrapper.bind(this);
        this.fetchDocData = this.fetchDocData.bind(this);
        this.base64ToArrayBuffer = this.base64ToArrayBuffer.bind(this);
        //
        this.navigateToIQ = this.navigateToIQ.bind(this);
        //
        this.fetchDocChecklistData_Wrapper = this.fetchDocChecklistData_Wrapper.bind(this);
        //
        this.fetchMatterData_Wrapper();         
        //setTimeout(() => {this.fetchDocList_Wrapper(this.props.url); }, 400);
    }
    //
    fetchMatterData_Wrapper(){
        var results = this.fetchMatterData(this.props.url);
        results.then((data) => {
            //console.log(data.body[0]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        matterFileId: data.body[0].FileID,
                        matterDataDimmerActive: false                        
                    });
                    if(data.body[0].DateAppointment == null && data.body[0].DateOffer != null) {                        
                        this.setState({
                            clientSegmentCSS: "",
                            clientSegmentCSS_preapt: "Matters-Client-Segment-Hidden"
                        });  
                    }
                    if(data.body[0].DateAppointment != null && data.body[0].DateOffer != null){
                        this.setState({
                            clientSegmentCSS_preapt: "Matters-Client-Segment-Hidden",
                            clientSegmentCSS_postapt: "",
                            clientSegmentCSS_mobile: "Matters-Client-Segment-Hidden-mobile"
                        });
                    }
                    this.setState({
                        milestoneCSS_1: data.body[0].DateOffer != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",
                        milestoneCSS_2: data.body[0].DateMortgageReportSent != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",
                        milestoneCSS_3: data.body[0].DateAppointment != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",

                        milestoneCSS_4: data.body[0].DateSignedDocsReceived != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",
                        milestoneCSS_5: data.body[0].DateSchedule != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",
                        milestoneCSS_6: data.body[0].DateCompletionDateSetByLender != null ? "Matters-Grid-Segment-Completed" : "Matters-Grid-Segment-Outstanding",
                    });
                    //
                    this.fetchDocList_Wrapper(this.props.url);
                    //
                    this.fetchDocChecklistData_Wrapper();
                    //
                    console.log("matter file id: " + this.state.matterFileId);
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchMatterData(url){
        const response = await fetch(url + "/Matters/GetMatterData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    expanderComponent(row) {
        //
        var d = new Date(row.original.CurrentStatusDate);
        var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d_text = "";
        if(d.getFullYear() > 1970){
            d_text = day + "/" + months[d.getMonth()] + "/" + d.getFullYear();
        }
        //
        return(
            <div>
                <Segment className="Matters-CurrentStatus-Cell Matters-Segment-Generic" secondary={true} padded>
                    <p className="Matters-Cell-SizeMed">{row.original.CurrentStatus}</p>
                    <p className="Matters-Cell-Date">
                        {d_text}
                        <Button className="Matters-Button-History">History</Button>
                    </p>
                </Segment>
            </div>
        )
    }
    //
    makePlaceholderFilter(placeholder) {
        return ({filter, onFilterChange}) => (
            <input type='text'
              placeholder={placeholder}
              style={{
                width: '100%'
              }}
              value={filter ? filter.value : ''}
              onChange={(event) => onFilterChange(event.target.value)}
            />
        )
    }
    //
    navigateToIQ(){
        this.setState({
            IQButton_CSS: "disabled loading",
        });
        setTimeout(() => {
            this.props.history.push("/iq?id=" + this.state.matterFileId);
        }, 200);
    }
    //
    fetchDocList_Wrapper(url){
        //if(this.state.tableData != null && this.state.tableData.length > 0){
            var results = this.fetchDocList(this.props.url);
            results.then((data) => {
                this.setState({
                    matterDocListDimmerActive: false,
                });     
                //console.log(data.body[1]);
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            //matterDocListDimmerActive: false, 
                            fileNames: data.body,                                                      
                        });        
                        if(this.state.fileNames.length > 0) {
                            this.setState({
                                noDocsClass: "noDocsHide",                                
                            });
                        }         
                        break;
                    case 400:
                        console.log(data.body.Message);
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        //}
    }
    //
    async fetchDocList(url){
        const response = await fetch(url + "/Matters/GetAllMatterDocs", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.tableData[0].FileID,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    fetchDocData_Wrapper(fileName){
        var results = this.fetchDocData(this.props.url, fileName);
        results.then((data) => {
            //console.log(data.body);
            switch(data.httpCode){
                case 200:                    
                    var blob = new Blob([this.base64ToArrayBuffer(data.body)], {type: "application/pdf"});
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;//"document.pdf";
                    link.click();
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchDocData(url, fileName){
        const response = await fetch(url + "/Matters/GetMatterDocData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.tableData[0].FileID,
                fileName: fileName,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    fetchDocChecklistData_Wrapper(){
        var results = this.fetchDocChecklistData(this.props.url);
        results.then((data) => {
            console.log(data.body);
            switch(data.httpCode){
                case 200:
                    console.log(data.body.step1.need_POA_OPGCert_1);
                    
                    this.setState({
                        docChecklistData: data.body,
                        //
                        docStep1_needed_poa_opg_1: data.body.step1.need_POA_OPGCert_1,
                        docStep1_needed_poa_opg_1_CSS: data.body.step1.need_POA_OPGCert_1 ? "" : "Matters-Client-Segment-Hidden",
                        docStep1_needed_poa_opg_1_Icon: data.body.step1.need_POA_OPGCert_1 ? "green checkmark icon" : "red remove icon",
                        docStep1_uploaded_poa_opg_1: data.body.step1.uploaded_POA_OPGCert_1,
                        docStep1_uploaded_poa_opg_1_Icon: data.body.step1.uploaded_POA_OPGCert_1 ? "green checkmark icon" : "red remove icon",
                        //
                        docStep1_needed_poa_opg_2: data.body.step1.need_POA_OPGCert_2,
                        docStep1_needed_poa_opg_2_CSS: data.body.step1.need_POA_OPGCert_2 ? "" : "Matters-Client-Segment-Hidden",
                        docStep1_needed_poa_opg_2_Icon: data.body.step1.need_POA_OPGCert_2 ? "green checkmark icon" : "red remove icon",
                        docStep1_uploaded_poa_opg_2: data.body.step1.uploaded_POA_OPGCert_2,
                        docStep1_uploaded_poa_opg_2_Icon: data.body.step1.uploaded_POA_OPGCert_2 ? "green checkmark icon" : "red remove icon",
                        //
                        docStep2_needed_property_CSS: data.body.step2.need_solar || data.body.step2.need_tenure_shareCert ? "" : "Matters-Client-Segment-Hidden",
                        docStep2_needed_solar_CSS: data.body.step2.need_solar ? "" : "Matters-Client-Segment-Hidden",
                        docStep2_uploaded_solar_mcs_Icon: data.body.step2.uploaded_solar_mcs ? "green checkmark icon" : "red remove icon",
                        docStep2_needed_solar_lease_CSS: data.body.step2.need_solar_lease ? "" : "Matters-Client-Segment-Hidden",
                        docStep2_uploaded_solar_lease_Icon: data.body.step2.uploaded_solar_lease ? "green checkmark icon" : "red remove icon",
                        docStep2_needed_solar_receipt_CSS: data.body.step2.need_solar_receipt ? "" : "Matters-Client-Segment-Hidden",
                        docStep2_needed_solar_receipt_Icon: data.body.step2.need_solar_receipt ? "green checkmark icon" : "red remove icon",
                        docStep2_uploaded_solar_receipt_Icon: data.body.step2.uploaded_solar_receipt ? "green checkmark icon" : "red remove icon",
                        //
                        docStep2_needed_tenure_share_CSS: data.body.step2.need_tenure_shareCert ? "" : "Matters-Client-Segment-Hidden", 
                        docStep2_needed_tenure_share_Icon: data.body.step2.need_tenure_shareCert ? "green checkmark icon" : "red remove icon",
                        docStep2_uploaded_tenure_share_Icon: data.body.step2.uploaded_tenure_shareCert ? "green checkmark icon" : "red remove icon",
                        //
                        docStep3_showStep : (data.body.step3.need_occupier_1_capacity_evidence || data.body.step3.need_occupier_2_capacity_evidence || data.body.step3.need_occupier_3_capacity_evidence || data.body.step3.need_occupier_4_capacity_evidence),
                        //
                        docStep3_needed_occupier_1_capacity_evidence_CSS: data.body.step3.need_occupier_1_capacity_evidence ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_1_capacity_evidence_Icon: data.body.step3.need_occupier_1_capacity_evidence ? "green checkmark icon" : "red remove icon",                        
                        docStep3_uploaded_occupier_1_capacity_evidence_Icon: data.body.step3.uploaded_occupier_1_capacity_evidence ? "green checkmark icon" : "red remove icon",
                        docStep3_needed_occupier_1_capacity_carePlan_CSS: data.body.step3.need_occupier_1_capacity_carePlan ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_1_capacity_carePlan_Icon: data.body.step3.need_occupier_1_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        docStep3_uploaded_occupier_1_capacity_carePlan_Icon: data.body.step3.uploaded_occupier_1_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        //
                        docStep3_needed_occupier_2_capacity_evidence_CSS: data.body.step3.need_occupier_2_capacity_evidence ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_2_capacity_evidence_Icon: data.body.step3.need_occupier_2_capacity_evidence ? "green checkmark icon" : "red remove icon",                        
                        docStep3_uploaded_occupier_2_capacity_evidence_Icon: data.body.step3.uploaded_occupier_2_capacity_evidence ? "green checkmark icon" : "red remove icon",
                        docStep3_needed_occupier_2_capacity_carePlan_CSS: data.body.step3.need_occupier_2_capacity_carePlan ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_2_capacity_carePlan_Icon: data.body.step3.need_occupier_2_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        docStep3_uploaded_occupier_2_capacity_carePlan_Icon: data.body.step3.uploaded_occupier_2_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        //
                        docStep3_needed_occupier_3_capacity_evidence_CSS: data.body.step3.need_occupier_3_capacity_evidence ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_3_capacity_evidence_Icon: data.body.step3.need_occupier_3_capacity_evidence ? "green checkmark icon" : "red remove icon",                        
                        docStep3_uploaded_occupier_3_capacity_evidence_Icon: data.body.step3.uploaded_occupier_3_capacity_evidence ? "green checkmark icon" : "red remove icon",
                        docStep3_needed_occupier_3_capacity_carePlan_CSS: data.body.step3.need_occupier_3_capacity_carePlan ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_3_capacity_carePlan_Icon: data.body.step3.need_occupier_3_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        docStep3_uploaded_occupier_3_capacity_carePlan_Icon: data.body.step3.uploaded_occupier_3_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        //
                        docStep3_needed_occupier_4_capacity_evidence_CSS: data.body.step3.need_occupier_4_capacity_evidence ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_4_capacity_evidence_Icon: data.body.step3.need_occupier_4_capacity_evidence ? "green checkmark icon" : "red remove icon",                        
                        docStep3_uploaded_occupier_4_capacity_evidence_Icon: data.body.step3.uploaded_occupier_4_capacity_evidence ? "green checkmark icon" : "red remove icon",
                        docStep3_needed_occupier_4_capacity_carePlan_CSS: data.body.step3.need_occupier_4_capacity_carePlan ? "" : "Matters-Client-Segment-Hidden",
                        docStep3_needed_occupier_4_capacity_carePlan_Icon: data.body.step3.need_occupier_4_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                        docStep3_uploaded_occupier_4_capacity_carePlan_Icon: data.body.step3.uploaded_occupier_4_capacity_carePlan ? "green checkmark icon" : "red remove icon",
                    });
                    if(
                        !data.body.step1.need_POA_OPGCert_1 &&
                        !data.body.step1.need_POA_OPGCert_2 &&
                        !data.body.step2.need_solar &&
                        !data.body.step2.need_solar_mcs &&
                        !data.body.step2.need_solar_lease &&
                        !data.body.step2.need_solar_reciept &&
                        !data.body.step2.need_tenure_shareCert &&
                        !data.body.step3.need_occupier_1_capacity_evidence &&
                        !data.body.step3.need_occupier_2_capacity_evidence &&
                        !data.body.step3.need_occupier_2_capacity_evidence &&
                        !data.body.step3.need_occupier_2_capacity_evidence){
                        this.setState({
                            docChecklistEmpty: "",
                        });
                    }
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchDocChecklistData(url){
        const response = await fetch(url + "/Matters/GetDocumentChecklistData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.tableData[0].FileID,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
           var ascii = binaryString.charCodeAt(i);
           bytes[i] = ascii;
        }
        return bytes;
     }
    //
    render(){
        var data = null;
        if(this.state.tableData.length > 0){
            data = this.state.tableData[0];
            //console.log(data);
            //
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // var d1 = new Date(data.DateInstruction);
            // var d1_display = data.DateInstruction != null ? (d1.getDate() < 10 ? "0" + d1.getDate(): d1.getDate()) + "/" + months[d1.getMonth()] + "/" + d1.getFullYear() : "N/A";
            var d2 = new Date(data.DateOffer);
            var d2_display = data.DateOffer != null ? (d2.getDate() < 10 ? "0" + d2.getDate(): d2.getDate()) + "/" + months[d2.getMonth()] + "/" + d2.getFullYear() : "N/A";
            //var d3 = new Date(data.DateAppointmentBooked);
            //var d3_display = data.DateAppointmentBooked != null ? (d3.getDate() < 10 ? "0" + d3.getDate(): d3.getDate()) + "/" + months[d3.getMonth()] + "/" + d3.getFullYear() : "N/A";                        
            var d3 = new Date(data.DateMortgageReportSent);
            var d3_display = data.DateMortgageReportSent != null ? (d3.getDate() < 10 ? "0" + d3.getDate(): d3.getDate()) + "/" + months[d3.getMonth()] + "/" + d3.getFullYear() : "N/A";                        
            var d4 = new Date(data.DateAppointment);
            var d4_display = data.DateAppointment != null ? (d4.getDate() < 10 ? "0" + d4.getDate(): d4.getDate()) + "/" + months[d4.getMonth()] + "/" + d4.getFullYear() : "N/A";
            var d5 = new Date(data.DateSignedDocsReceived);
            var d5_display = data.DateSignedDocsReceived != null ? (d5.getDate() < 10 ? "0" + d5.getDate(): d5.getDate()) + "/" + months[d5.getMonth()] + "/" + d5.getFullYear() : "N/A";
            var d6 = new Date(data.DateSchedule);
            var d6_display = data.DateSchedule != null ? (d6.getDate() < 10 ? "0" + d6.getDate(): d6.getDate()) + "/" + months[d6.getMonth()] + "/" + d6.getFullYear() : "N/A";
            // var d7 = new Date(data.DateCompletionDateRecFromLender);
            // var d7_display = data.DateCompletionDateRecFromLender != null ? (d7.getDate() < 10 ? "0" + d7.getDate(): d7.getDate()) + "/" + months[d7.getMonth()] + "/" + d7.getFullYear() : "N/A";
            var d8 = new Date(data.DateCompletionDateSetByLender);
            var d8_display = data.DateCompletionDateSetByLender != null ? (d8.getDate() < 10 ? "0" + d8.getDate(): d8.getDate()) + "/" + months[d8.getMonth()] + "/" + d8.getFullYear() : "N/A";
            //
            var d = new Date(data.CurrentStatusDate);
            var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
            var d_text = "";
            if(d.getFullYear() > 1970){
                d_text = day + "/" + months[d.getMonth()] + "/" + d.getFullYear();
            }
            //
            return(
                <div>
                    <Segment>
                        <Dimmer active={this.state.matterDataDimmerActive}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="folder open icon big"/>&emsp;Your Cases</h3></Label>
                        <br/><br/><br/>
                        {data != null ? 
                        <Grid>
                            <Grid.Row columns={2} className="Matters-Grid-Row">
                                <Grid.Column>
                                <Segment className="Matters-Segment-Generic" secondary={true} padded>
                                    <h2 className="Matters-Header-Ref  Matters-TitleText-Blue">{"Case Reference: "}&emsp;{data.Reference}</h2>
                                    <p className="Matters-Cell-SizeMed">{"Property Address: "}&emsp;{data.Address1 + ", " + data.Postcode}</p>                            
                                </Segment>
                                </Grid.Column>
                                <Grid.Column>
                                <Segment className="Matters-CurrentStatus-Cell Matters-Segment-Generic" secondary={true} padded>
                                    <h2 className="Matters-Header-Ref Matters-TitleText-Blue">{"Current Case Status:"}</h2>
                                    <p className="Matters-Cell-SizeMed">
                                        <span>{data.CurrentStatus}</span>&emsp;
                                        {d_text}
                                    </p>
                                </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row  columns={3} className="Matters-Grid-Row">
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_1}>
                                    <Label className="Matters-Grid-Label" as='a' ribbon>Step 1.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                            Mortgage Offer received from lender:<br/>
                                            <b>{d2_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_2}>
                                    <Label className="Matters-Grid-Label" as='a' ribbon>Step 2.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                            We have sent out your Mortgage Report:<br/>
                                            <b>{d3_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_3}>
                                    <Label className="Matters-Grid-Label" as='a' ribbon>Step 3.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                            Signing Appointment booked.<br/>
                                            You are due to meet with a solicitor on:<br/>
                                            <b>{d4_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_4}>
                                        <Label className="Matters-Grid-Label" as='a' ribbon>Step 4.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                        Your Signed Documents received back to us:<br/>
                                            <b>{d5_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_5}>
                                        <Label className="Matters-Grid-Label" as='a' ribbon>Step 5.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                            Relevant documents have been returned to lender:<br/>
                                            <b>{d6_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column>                                  
                                    <Segment className={this.state.milestoneCSS_6}>
                                        <Label className="Matters-Grid-Label" as='a' ribbon>Step 6.</Label>                     
                                        <div className="Matters-Div-Milestone">                                        
                                        Completion of your Lifetime Mortgage has been set for:<br/>
                                            <b>{d8_display}</b>
                                        </div>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        : <div></div>}
                    </Segment>
                    <Segment>
                        <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="pencil alternate icon big"/>&emsp;Initial Questionnaire</h3></Label>
                        <br/><br/><br/>
                        <Button className={this.state.IQButton_CSS} color="blue" onClick={this.navigateToIQ}>Go to Questionnaire</Button>
                    </Segment>
                    <Segment>
                        <Dimmer active={this.state.matterApptBookDimmerActive}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="calendar alternate icon big"/>&emsp;Arrange Signing Appointment</h3></Label>
                        <br/><br/><br/>
                        <div className={this.state.clientSegmentCSS_preapt}>
                            Once you have received a mortgage offer, you will then be able to book an appointment to either visit us in Gloucester and talk to one of our solicitors, or to have one of our mobile agents meet with you at the property.
                            <br/>
                        </div>
                        <div className={this.state.clientSegmentCSS_postapt}>
                            Your appointment booking request has been received, and you should receive a confirmation email shortly.
                            <br/>
                        </div>
                        <div className={this.state.clientSegmentCSS}>
                            {/* <BigCalendarWrapper fileId={this.state.matterFileId} calendarType={localStorage.roleId === "50" ? "none" : "calendar"} url={this.props.url}/> */}
                            <ClientCaseAutoAllocate url={this.props.url} matterFileId={this.state.matterFileId}></ClientCaseAutoAllocate>
                        </div>
                        <br/>
                        <div className={this.state.clientSegmentCSS_mobile}>
                            Please note, if you have any questions or issues relating to your appointment booking, then please call our scheduling team on: 01452 376702
                        </div>
                    </Segment>
                    <Segment>
                        <Dimmer active={this.state.docChecklistDataDimmerActive}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="check square icon big"/>&emsp;Required Document Checklist</h3></Label>
                        <br/><br/><br/>
                        <div id="div_nodocs" className={this.state.docChecklistEmpty}>There are no documents required to be uploaded for this case.</div>
                        <div>
                            <div className={this.state.docStep1_needed_poa_opg_1_CSS}>
                                <h4><u>Step 1 - Client Details</u></h4>
                                <font>Client 1 - Power of attorney - OPG Certificate Needed?&emsp;<i className={this.state.docStep1_needed_poa_opg_1_Icon}></i></font>
                                <br/>
                                <font>Client 1 - Power of attorney - OPG Certificate Uploaded?&emsp;<i className={this.state.docStep1_uploaded_poa_opg_1_Icon}></i></font>
                            </div>
                            <div className={this.state.docStep1_needed_poa_opg_2_CSS}>
                                <font>Client 2 - Power of attorney - OPG Certificate Needed?&emsp;<i className={this.state.docStep1_needed_poa_opg_2_Icon}></i></font>
                                <br/>
                                <font>Client 2 - Power of attorney - OPG Certificate Uploaded?&emsp;<i className={this.state.docStep1_uploaded_poa_opg_2_Icon}></i></font>
                            </div>
                            <br/>
                            <div className={this.state.docStep2_needed_property_CSS}>
                                <h4><u>Step 2 - Property Details</u></h4>
                                <font>Solar - MCS Certificate Needed?&emsp;<i className="green checkmark icon"></i></font>
                                <br/>
                                <font>Solar - MCS Certificate Uploaded?&emsp;<i className={this.state.docStep2_uploaded_solar_mcs_Icon}></i></font>
                                <div className={this.state.docStep2_needed_solar_lease_CSS}>
                                    <br/>
                                    <font>Solar - Solar Lease Needed?&emsp;<i className={this.state.docStep2_needed_solar_lease_Icon}></i></font>
                                    <br/>
                                    <font>Solar - Solar Lease Uploaded?&emsp;<i className={this.state.docStep2_uploaded_solar_lease_Icon}></i></font>
                                </div>
                                <div className={this.state.docStep2_needed_solar_receipt_CSS}>
                                    <br/>
                                    <font>Solar - Solar Receipt Needed?&emsp;<i className={this.state.docStep2_needed_solar_receipt_Icon}></i></font>
                                    <br/>
                                    <font>Solar - Solar Receipt Uploaded?&emsp;<i className={this.state.docStep2_uploaded_solar_receipt_Icon}></i></font>
                                </div>
                                <div className={this.state.docStep2_needed_tenure_share_CSS}>
                                    <br/>
                                    <font>Tenure - Management Comapnay Share Certificate Needed?&emsp;<i className={this.state.docStep2_needed_tenure_share_Icon}></i></font>
                                    <br/>
                                    <font>Tenure - Management Comapnay Share Certificate Uploaded?&emsp;<i className={this.state.docStep2_uploaded_tenure_share_Icon}></i></font>
                                </div>
                            </div>
                            <br/>
                            <div className={this.state.docStep3_showStep ? "" : "IQ-Hidden"}>
                                <h4><u>Step 3 - Occupier Details</u></h4>
                                <div className={this.state.docStep3_needed_occupier_1_capacity_evidence_CSS}>
                                    <font>Occupier 1 - Capacity Evidence Needed?&emsp;<i className={this.state.docStep3_needed_occupier_1_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 1 - Capacity Evidence Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_1_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 1 - Care Plan Needed?&emsp;<i className={this.state.docStep3_needed_occupier_1_capacity_carePlan_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 1 - Care Plan Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_1_capacity_carePlan_Icon}></i></font>
                                </div>
                                <div className={this.state.docStep3_needed_occupier_2_capacity_evidence_CSS}>
                                    <br/>
                                    <font>Occupier 2 - Capacity Evidence Needed?&emsp;<i className={this.state.docStep3_needed_occupier_2_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 2 - Capacity Evidence Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_2_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 2 - Care Plan Needed?&emsp;<i className={this.state.docStep3_needed_occupier_2_capacity_carePlan_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 2 - Care Plan Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_2_capacity_carePlan_Icon}></i></font>
                                </div>
                                <div className={this.state.docStep3_needed_occupier_3_capacity_evidence_CSS}>
                                    <br/>
                                    <font>Occupier 3 - Capacity Evidence Needed?&emsp;<i className={this.state.docStep3_needed_occupier_3_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 3 - Capacity Evidence Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_3_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 3 - Care Plan Needed?&emsp;<i className={this.state.docStep3_needed_occupier_3_capacity_carePlan_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 3 - Care Plan Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_3_capacity_carePlan_Icon}></i></font>
                                </div>
                                <div className={this.state.docStep3_needed_occupier_4_capacity_evidence_CSS}>
                                    <br/>
                                    <font>Occupier 4 - Capacity Evidence Needed?&emsp;<i className={this.state.docStep3_needed_occupier_4_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 4 - Capacity Evidence Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_4_capacity_evidence_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 4 - Care Plan Needed?&emsp;<i className={this.state.docStep3_needed_occupier_4_capacity_carePlan_Icon}></i></font>
                                    <br/>
                                    <font>Occupier 4 - Care Plan Uploaded?&emsp;<i className={this.state.docStep3_uploaded_occupier_4_capacity_carePlan_Icon}></i></font>
                                </div>
                            </div>
                        </div>
                    </Segment>
                    <Segment>
                        <Dimmer active={this.state.matterDocListDimmerActive}>
                            <Loader>Loading</Loader>
                        </Dimmer>
                        <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="file alternate icon big"/>&emsp;Your Documents</h3></Label>
                        <br/><br/><br/>
                        <div id="div_nodocs" className={this.state.noDocsClass}>There are no documents for this case.</div>
                        <div content={this.state.fileNames}>
                            {
                                this.state.fileNames.map(item =>
                                <div key={item}>
                                    <a className="Matters-Doc-Link" href="/" onClick={(event) => {event.preventDefault(); this.fetchDocData_Wrapper(item);}}>{item}</a>
                                    <br/><br/>
                                </div>                                
                            )}
                            </div>
                    </Segment>
                </div>
            );
        }
        else {
            return(
                <div></div>
            );
        }
        
    }
}

export default ClientCasePage