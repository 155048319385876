import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import './AdminPage.css';

class AgentCoveragePage extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadedAgents: false,
            loadedPostcodes: false,
            selectedAgent: "",

            segment_editUser_agent: "IQ-Hidden",

            existingAgentValue: "",
            agentOptions: [],   
            selectedPostcodeValues: [],
            postcodeOptions: [],         
            formCSS: "Admin-Edit-Hide",
            dimmerActive: true,
            editPostcodesLoading: "",

            loadedRotas: false,
            loadedMappedPostcodes: false,
            checkingMappedData: false,

            editResultModalOpen: false,
            editResultModalIcon: "warning circle red",
            editResultModalHeader: "Agent Coverage Update Fail!",
            editResultModalText: "Default error text",

            button_monday_selected: false,
            button_tuesday_selected: false,
            button_wednesday_selected: false,
            button_thursday_selected: false,
            button_friday_selected: false,
            button_saturday_selected: false,
            button_sunday_selected: false,

            button_monday_css: "ui button",
            button_tuesday_css: "ui button",
            button_wednesday_css: "ui button",
            button_thursday_css: "ui button",
            button_friday_css: "ui button",
            button_saturday_css: "ui button",
            button_sunday_css: "ui button",
        }
        //
        this.valueChange = this.valueChange.bind(this);
        this.dataLoadedChecker = this.dataLoadedChecker.bind(this);
        //
        this.getAgentRotas_Wrapper = this.getAgentRotas_Wrapper.bind(this);
        this.getAgentRotas = this.getAgentRotas.bind(this);
        //
        this.onClick_buttonMonday = this.onClick_buttonMonday.bind(this);
        this.onClick_buttonTuesday = this.onClick_buttonTuesday.bind(this);
        this.onClick_buttonWednesday = this.onClick_buttonWednesday.bind(this);
        this.onClick_buttonThursday = this.onClick_buttonThursday.bind(this);
        this.onClick_buttonFriday = this.onClick_buttonFriday.bind(this);
        this.onClick_buttonSaturday = this.onClick_buttonSaturday.bind(this);
        this.onClick_buttonSunday = this.onClick_buttonSunday.bind(this);
        //
        this.onClick_submitValidate = this.onClick_submitValidate.bind(this);
        //        
        this.loadExistingAgentList_Wrapper = this.loadExistingAgentList_Wrapper.bind(this);
        this.loadExistingAgentList = this.loadExistingAgentList.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
        //
        this.editAgentCoverageWrapper = this.editAgentCoverageWrapper.bind(this);
        this.editAgentCoverage = this.editAgentCoverage.bind(this);
        //
        this.loadExistingAgentList_Wrapper(this.props.url);
        this.loadPostcodeOutwards_Wrapper(this.props.url);
        setTimeout(() => {
            this.dataLoadedChecker();
        }, 150)
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    //
    dataLoadedChecker(){
        if(this.state.loadedAgents && this.state.loadedPostcodes){
            this.setState({
                dimmerActive: false,
            });
        }
        else {
            setTimeout(() => {
                this.dataLoadedChecker();
            }, 100)
        }
    }

    onClick_buttonMonday(){
        if(this.state.button_monday_css === "ui button"){
            this.setState({
                button_monday_css: "ui button green",
                button_monday_selected: true,
            });
        }
        else {
            this.setState({
                button_monday_css: "ui button",
                button_monday_selected: false,
            });
        }
    }
    onClick_buttonTuesday(){
        if(this.state.button_tuesday_css === "ui button"){
            this.setState({
                button_tuesday_css: "ui button green",
                button_tuesday_selected: true,
            });
        }
        else {
            this.setState({
                button_tuesday_css: "ui button",
                button_tuesday_selected: false,
            });
        }
    }
    onClick_buttonWednesday(){
        if(this.state.button_wednesday_css === "ui button"){
            this.setState({
                button_wednesday_css: "ui button green",
                button_wednesday_selected: true,
            });
        }
        else {
            this.setState({
                button_wednesday_css: "ui button",
                button_wednesday_selected: false,
            });
        }
    }
    onClick_buttonThursday(){
        if(this.state.button_thursday_css === "ui button"){
            this.setState({
                button_thursday_css: "ui button green",
                button_thursday_selected: true,
            });
        }
        else {
            this.setState({
                button_thursday_css: "ui button",
                button_thursday_selected: false,
            });
        }
    }
    onClick_buttonFriday(){
        if(this.state.button_friday_css === "ui button"){
            this.setState({
                button_friday_css: "ui button green",
                button_friday_selected: true,
            });
        }
        else {
            this.setState({
                button_friday_css: "ui button",
                button_friday_selected: false,
            });
        }
    }
    onClick_buttonSaturday(){
        if(this.state.button_saturday_css === "ui button"){
            this.setState({
                button_saturday_css: "ui button green",
                button_saturday_selected: true,
            });
        }
        else {
            this.setState({
                button_saturday_css: "ui button",
                button_saturday_selected: false,
            });
        }
    }
    onClick_buttonSunday(){
        if(this.state.button_sunday_css === "ui button"){
            this.setState({
                button_sunday_css: "ui button green",
                button_sunday_selected: true,
            });
        }
        else {
            this.setState({
                button_sunday_css: "ui button",
                button_sunday_selected: false,
            });
        }
    }

    editAgentCoverageWrapper(url){
        this.setState({
            editPostcodesLoading: "loading disabled"
        });
        if(this.onClick_submitValidate()){
            var result = this.editAgentCoverage(url);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            editResultModalIcon: "checkmark circle green",
                            editResultModalHeader: "Success",
                            editResultModalText: "Agent Postcode coverage succeessfully updated!",
                            editResultModalOpen: true,
                            editPostcodesLoading: ""
                        });  
                        //setTimeout(() => { window.location.reload(); }, 1000);
                        break;
                    case 400:
                        this.setState({
                            editResultModalIcon: "warning circle red",
                            editResultModalHeader: "Error",
                            editResultModalText: "Coverage update Failed!\n\n" + data.body.Message,
                            editResultModalOpen: true,
                            editPostcodesLoading: ""
                        });     
                        break;
                    default:
                        break;
                } ;
                setTimeout(() => {
                    this.setState({
                        editPostcodesLoading: ""
                    });
                }, 200);
            });
        }
        else {
            this.setState({
                editPostcodesLoading: ""
            });
        }
    }
    //
    async editAgentCoverage(url){
        const response = await fetch(url + "/Schedule/UpdateMappedAgentPostcodes", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.existingAgentValue,
                postcodeIdList: this.state.selectedPostcodeValues,
                selected_Monday: this.state.button_monday_selected,
                selected_Tuesday: this.state.button_tuesday_selected,
                selected_Wednesday: this.state.button_wednesday_selected,
                selected_Thursday: this.state.button_thursday_selected,
                selected_Friday: this.state.button_friday_selected,
                selected_Saturday: this.state.button_saturday_selected,
                selected_Sunday: this.state.button_sunday_selected,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getAgentRotas_Wrapper(url){
        this.setState({
            button_sunday_selected: false,
            button_sunday_css: "ui button",
            button_monday_selected: false,
            button_monday_css: "ui button",
            button_tuesday_selected: false,
            button_tuesday_css: "ui button",
            button_wednesday_selected: false,
            button_wednesday_css: "ui button",
            button_thursday_selected: false,
            button_thursday_css: "ui button",
            button_friday_selected: false,
            button_friday_css: "ui button",
            button_saturday_selected: false,
            button_saturday_css: "ui button",
        });
        var result = this.getAgentRotas(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        editPostcodesLoading: "",
                    });
                    for(var i = 0; i < data.body.length; i++){
                        switch(data.body[i].DayOfWeek_Text){
                            case "Sunday":
                                this.setState({
                                    button_sunday_selected: data.body[i].Active,
                                    button_sunday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Monday":
                                this.setState({
                                    button_monday_selected: data.body[i].Active,
                                    button_monday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Tuesday":
                                this.setState({
                                    button_tuesday_selected: data.body[i].Active,
                                    button_tuesday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Wednesday":
                                this.setState({
                                    button_wednesday_selected: data.body[i].Active,
                                    button_wednesday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Thursday":
                                this.setState({
                                    button_thursday_selected: data.body[i].Active,
                                    button_thursday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Friday":
                                this.setState({
                                    button_friday_selected: data.body[i].Active,
                                    button_friday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            case "Saturday":
                                this.setState({
                                    button_saturday_selected: data.body[i].Active,
                                    button_saturday_css: "ui button" + (data.body[i].Active ? " green" : ""),
                                });
                                break;
                            default:
                                break;
                        }
                    }
                    this.setState({
                        loadedRotas: true,
                    });
                    if(!this.state.checkingMappedData) this.checkLoadedAgentMappedData();
                    break;
                case 400:
                    this.setState({
                        editResultModalIcon: "warning circle red",
                        editResultModalHeader: "Error",
                        editResultModalText: "Get agent rotas Failed!\n\n" + data.body.Message,
                        editResultModalOpen: true,
                        editPostcodesLoading: ""
                    });
                    break;
                default:
                    break;
            } ;
            setTimeout(() => {
                this.setState({
                    editPostcodesLoading: ""
                });
            }, 200);
        });
    }
    //
    async getAgentRotas(url){
        const response = await fetch(url + "/Schedule/GetAgentRotas", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.existingAgentValue,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handlePostcodeChange(event, data){
        this.setState({
            selectedPostcodeValues: data.value,
        });     
    }
    //
    handleAgentChange(event, data){
        this.setState({
            existingAgentValue: data.value,
            formCSS: "",
            dimmerActive: true
        });
        console.log("agent id? " + data.value);
        //
        setTimeout(() => {
            this.loadMappedPostcodeOutwards_Wrapper(this.props.url);
            this.getAgentRotas_Wrapper(this.props.url);
        }, 50)
    }
    //
    loadPostcodeOutwards_Wrapper(url){
        var result = this.loadPostcodeOutwards(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        postcodeOptions: data.body,
                        loadedPostcodes: true,
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadPostcodeOutwards(url){
        const response = await fetch(url + "/Schedule/GetListOfAllPostcodeOutwards", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentId: this.state.existingAgentValue,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadMappedPostcodeOutwards_Wrapper(url){
        var result = this.loadMappedPostcodeOutwards(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    console.log(data.body);
                    this.setState({
                        selectedPostcodeValues: data.body,
                    });
                    this.setState({
                        loadedMappedPostcodes: true,
                    });
                    if(!this.state.checkingMappedData) this.checkLoadedAgentMappedData();
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    checkLoadedAgentMappedData(){
        this.setState({
            checkingMappedData: true,
        });
        if(this.state.loadedRotas && this.state.loadedMappedPostcodes){
            this.setState({
                dimmerActive: false,
                loadedRotas: false,
                loadedMappedPostcodes: false,
                checkingMappedData: false,
            });
        }
        else if (this.state.loadedRotas) {
            //console.log("waiting for mapped postcodes..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
        else if (this.state.loadedMappedPostcodes) {
            //console.log("waiting for rotas..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
        else {
            //console.log("waiting for everything..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
    }
    //
    async loadMappedPostcodeOutwards(url){
        const response = await fetch(url + "/Schedule/GetAllMappedAgentPostcodes_ForDropdown", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                userId: this.state.existingAgentValue
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingAgentList_Wrapper(url){
        var result = this.loadExistingAgentList(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        agentOptions: data.body,
                        loadedAgents: true,
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingAgentList(url){
        const response = await fetch(url + "/Account/GetListOfAllAgents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_clearAllSelectedPostcodes(){
        this.setState({
            selectedPostcodeValues: []
        });
    }
    //
    onClick_submitValidate(){
        var atLeast1DaySelected = false;
        if(this.state.button_monday_selected || 
            this.state.button_tuesday_selected || 
            this.state.button_wednesday_selected || 
            this.state.button_thursday_selected || 
            this.state.button_friday_selected || 
            this.state.button_saturday_selected || 
            this.state.button_sunday_selected) {
            atLeast1DaySelected = true;
        }
        if(!atLeast1DaySelected){
            this.setState({
                buttonGroup_CSS: "ui buttons Admin-ButtonGroup-Validate-Fail"
            });
            return false;
        }
        else {
            this.setState({
                buttonGroup_CSS: "ui buttons"
            });
            return true;
        }
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="map large"/>&emsp;Edit Agent Coverage</h5></Label>
                    <br/><br/><br/>
                    <div>
                    <Form>
                        <Form.Field>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select Agent</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown fluid={true} selection={true} search={true} placeholder="Select Agent..." value={this.state.existingAgentValue} options={this.state.agentOptions} onChange={this.handleAgentChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                    </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/>
                    <div className={this.state.formCSS}>
                    <Form>
                        <Form.Field>
                            <div className="Admin-Div-Company-Region">
                                <label>Select Postcode</label>
                                <div className="Admin-Div-Company-Outer">
                                    <div className="Admin-Div-Postcodes">
                                        <Dropdown fluid={true} selection={true} search={true} multiple={true} placeholder="Select Postcode..." value={this.state.selectedPostcodeValues} options={this.state.postcodeOptions} onChange={this.handlePostcodeChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                        <br/>
                        <br/>
                        <label>Please select which days this coverage applies to?</label>
                        <br/>
                        <br/>
                        <div className={this.state.buttonGroup_CSS}>
                            <Button className={this.state.button_monday_css} onClick={() => this.onClick_buttonMonday()}>Monday</Button>
                            <Button className={this.state.button_tuesday_css} onClick={() => this.onClick_buttonTuesday()}>Tuesday</Button>
                            <Button className={this.state.button_wednesday_css} onClick={() => this.onClick_buttonWednesday()}>Wednesday</Button>
                            <Button className={this.state.button_thursday_css} onClick={() => this.onClick_buttonThursday()}>Thursday</Button>
                            <Button className={this.state.button_friday_css} onClick={() => this.onClick_buttonFriday()}>Friday</Button>
                            <Button className={this.state.button_saturday_css} onClick={() => this.onClick_buttonSaturday()}>Saturday</Button>
                            <Button className={this.state.button_sunday_css} onClick={() => this.onClick_buttonSunday()}>Sunday</Button>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Button className={"ui button red Admin-Button-Clear"} content="Clear all" onClick={() => this.onClick_clearAllSelectedPostcodes()}/>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <Button className={"ui button blue " + this.state.editPostcodesLoading} content="Save Changes" onClick={() => this.editAgentCoverageWrapper(this.props.url)}/>
                    </Form>
                    </div>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ editResultModalOpen: false, editPostcodesLoading: ""})}>
                        <Header><Icon className={this.state.editResultModalIcon} />{this.state.editResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.editResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={() => this.setState({ editResultModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default AgentCoveragePage;