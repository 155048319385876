import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Dropdown, Dimmer, Loader, Menu } from 'semantic-ui-react';
import './AdminPage.css';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';

var moment = require('moment');

class AgentCoveragePageV2 extends Component {
    constructor(props) {
        super(props);
        this.editSegment = React.createRef();
        this.state = {
            loadedAgents: false,
            loadedPostcodes: false,

            segment_editUser_agent: "IQ-Hidden",

            existingAgentValue: "",
            agentOptions: [],
            selectedPostcodeValues: [],
            postcodeOptions: [],
            formCSS: "Admin-Edit-Hide",
            dimmerActive: true,
            editPostcodesLoading: "",

            changeStartOrEnd: "",
            changeTimeButton_CSS: "",
            editTimeModalOpen: false,
            timeOptions_Hour: [
                { key: 0, text: "00", value: 0 },
                { key: 1, text: "01", value: 1 },
                { key: 2, text: "02", value: 2 },
                { key: 3, text: "03", value: 3 },
                { key: 4, text: "04", value: 4 },
                { key: 5, text: "05", value: 5 },
                { key: 6, text: "06", value: 6 },
                { key: 7, text: "07", value: 7 },
                { key: 8, text: "08", value: 8 },
                { key: 9, text: "09", value: 9 },
                { key: 10, text: "10", value: 10 },
                { key: 11, text: "11", value: 11 },
                { key: 12, text: "12", value: 12 },
                { key: 13, text: "13", value: 13 },
                { key: 14, text: "14", value: 14 },
                { key: 15, text: "15", value: 15 },
                { key: 16, text: "16", value: 16 },
                { key: 17, text: "17", value: 17 },
                { key: 18, text: "18", value: 18 },
                { key: 19, text: "19", value: 19 },
                { key: 20, text: "20", value: 20 },
                { key: 21, text: "21", value: 21 },
                { key: 22, text: "22", value: 22 },
                { key: 23, text: "23", value: 23 },
            ],
            timeOptions_Minute: [
                { key: 0, text: "00", value: 0 },
                { key: 1, text: "15", value: 15 },
                { key: 2, text: "30", value: 30 },
                { key: 3, text: "45", value: 45 },
            ],

            daySelected: "Monday",
            dayOfWeek: 1,
            existingStartTime: "00:00",
            existingFinishTime: "00:00",
            changeTimeModalSubmitButton_CSS: "",
            newTime_Hour: null,
            newTime_Minute: null,

            activeTabMon: true,
            activeTabTues: false,
            activeTabWeds: false,
            activeTabThurs: false,
            activeTabFri: false,
            activeTabSat: false,
            activeTabSun: false,

            copyMenu_Monday_CSS: "disabled",
            copyMenu_Tuesday_CSS: "",
            copyMenu_Wednesday_CSS: "",
            copyMenu_Thursday_CSS: "",
            copyMenu_Friday_CSS: "",
            copyMenu_Saturday_CSS: "",
            copyMenu_Sunday_CSS: "",

            tabDimmer_Active: false,

            httpEditTimeResultModalOpen: false,
            httpEditTimeResultModalIcon: "",
            httpEditTimeResultModalHeader: "",
            httpEditTimeResultModalText: "",

            loadedRotas: true,
            loadedMappedPostcodes: false,
            checkingMappedData: false,

            exceptionDimmerActive: true,
            exceptionDate: moment(new Date()).add(1, 'day'),
            exceptionIsWorking: false,
            selectedExceptionPostcodeValues: [],
            changeExceptionStartOrEnd: "",
            changeExceptionTimeButton_CSS: "",
            editExceptionTimeModalOpen: false,
            exceptionStartTime_Hour: 8,
            exceptionStartTime_Minutes: 0,
            exceptionEndTime_Hour: 18,
            exceptionEndTime_Minutes: 0,
            exceptionStartTime: null,
            exceptionEndTime: null,
            createExceptionLoading: false,
            segmentExceptionCoverage_CSS: "IQ-Hidden",
            existingRotaExceptions: [],
            exceptionCreatedSuccess: false,
            confirmExceptionDelete: false,
            exceptionDelete_CSS: "",
            exceptionIdToDelete: 0,
            exceptionDeleteSuccess: false,
            editExceptionModalPostcodeIdList: [],
            exceptionPostcodeModalOpen: false,
            button_viewExceptionPostcodes_CSS: "",
            selectedExistingExceptionPostcodeValues: [],
            selectedExistingExceptionPostcodeOptions: [],
            button_exception_edit_CSS: "",

            editResultModalOpen: false,
            editResultModalIcon: "warning circle red",
            editResultModalHeader: "Agent Coverage Update Fail!",
            editResultModalText: "Default error text",

            button_monday_selected: false,
            button_tuesday_selected: false,
            button_wednesday_selected: false,
            button_thursday_selected: false,
            button_friday_selected: false,
            button_saturday_selected: false,
            button_sunday_selected: false,

            button_monday_css: "ui button",
            button_tuesday_css: "ui button",
            button_wednesday_css: "ui button",
            button_thursday_css: "ui button",
            button_friday_css: "ui button",
            button_saturday_css: "ui button",
            button_sunday_css: "ui button",
            //
            //
            exceptionDiv_CSS: "IQ-Hidden",
        }
        //
        this.valueChange = this.valueChange.bind(this);
        this.dataLoadedChecker = this.dataLoadedChecker.bind(this);
        //
        this.onClick_submitValidate = this.onClick_submitValidate.bind(this);
        //        
        this.loadExistingAgentList_Wrapper = this.loadExistingAgentList_Wrapper.bind(this);
        this.loadExistingAgentList = this.loadExistingAgentList.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
        //
        this.editAgentCoverageWrapper = this.editAgentCoverageWrapper.bind(this);
        this.editAgentCoverage = this.editAgentCoverage.bind(this);
        //
        this.onClick_handleTabChange = this.onClick_handleTabChange.bind(this);
        //
        this.onChange_exceptionDate = this.onChange_exceptionDate.bind(this);
        this.handleExceptionIsWorkingCheckboxChange = this.handleExceptionIsWorkingCheckboxChange.bind(this);
        this.handleExceptionPostcodeChange = this.handleExceptionPostcodeChange.bind(this);
        this.onChangeExceptionStartTimeHours = this.onChangeExceptionStartTimeHours.bind(this);
        this.onChangeExceptionStartTimeMinutes = this.onChangeExceptionStartTimeMinutes.bind(this);
        this.onChangeExceptionEndTimeHours = this.onChangeExceptionEndTimeHours.bind(this);
        this.onChangeExceptionEndTimeMinutes = this.onChangeExceptionEndTimeMinutes.bind(this);
        this.upsertRotaExceptionWrapper = this.upsertRotaExceptionWrapper.bind(this);
        this.upsertRotaException = this.upsertRotaException.bind(this);
        this.getAllRotaExceptionsWrapper = this.getAllRotaExceptionsWrapper.bind(this);
        this.getAllRotaExceptions = this.getAllRotaExceptions.bind(this);
        this.onClick_handleExceptionDelete = this.onClick_handleExceptionDelete.bind(this);
        this.deactivateRotaExceptionWrapper = this.deactivateRotaExceptionWrapper.bind(this);
        this.deactivateRotaException = this.deactivateRotaException.bind(this);
        this.onClick_ViewExceptionPostcodes = this.onClick_ViewExceptionPostcodes.bind(this);
        this.onClick_EditException = this.onClick_EditException.bind(this);
        this.onClick_CopyFromDayToException = this.onClick_CopyFromDayToException.bind(this);
        //
        this.updateRotaTime_Wrapper = this.updateRotaTime_Wrapper.bind(this);
        this.updateRotaTime = this.updateRotaTime.bind(this);
        this.onChangeNewTimeHours = this.onChangeNewTimeHours.bind(this);
        this.onChangeNewTimeMinutes = this.onChangeNewTimeMinutes.bind(this);
        //
        this.loadExistingAgentList_Wrapper(this.props.url);
        this.loadPostcodeOutwards_Wrapper(this.props.url);
        setTimeout(() => {
            this.dataLoadedChecker();
        }, 150)
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({ [key]: e.target.value });
    /////////////////////////////////////////////////////////////////
    //
    //
    dataLoadedChecker() {
        if (this.state.loadedAgents && this.state.loadedPostcodes) {
            this.setState({
                dimmerActive: false,
            });
        }
        else {
            setTimeout(() => {
                this.dataLoadedChecker();
            }, 100)
        }
    }

    editAgentCoverageWrapper(url) {
        this.setState({
            editPostcodesLoading: "loading disabled"
        });
        console.log(this.state.selectedPostcodeValues);
        //
        var result = this.editAgentCoverage(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editResultModalIcon: "checkmark circle green",
                        editResultModalHeader: "Success",
                        editResultModalText: "Agent Postcode coverage succeessfully updated!",
                        editResultModalOpen: true,
                        editPostcodesLoading: ""
                    });
                    break;
                case 400:
                    this.setState({
                        editResultModalIcon: "warning circle red",
                        editResultModalHeader: "Error",
                        editResultModalText: "Coverage update Failed!\n\n" + data.body.Message,
                        editResultModalOpen: true,
                        editPostcodesLoading: ""
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            };
            setTimeout(() => {
                this.setState({
                    editPostcodesLoading: ""
                });
            }, 200);
        });
    }
    //
    async editAgentCoverage(url) {
        const response = await fetch(url + "/Schedule/UpdateMappedAgentPostcodesPerDay", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.existingAgentValue,
                postcodeIdList: this.state.selectedPostcodeValues,
                selected_Monday: this.state.activeTabMon,
                selected_Tuesday: this.state.activeTabTues,
                selected_Wednesday: this.state.activeTabWeds,
                selected_Thursday: this.state.activeTabThurs,
                selected_Friday: this.state.activeTabFri,
                selected_Saturday: this.state.activeTabSat,
                selected_Sunday: this.state.activeTabSun,
                selectedDay: this.state.dayOfWeek,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handlePostcodeChange(event, data) {
        this.setState({
            selectedPostcodeValues: data.value,
        });
    }
    //
    handleAgentChange(event, data) {
        this.setState({
            existingAgentValue: data.value,
            formCSS: "",
            dimmerActive: true
        });
        console.log("agent user id? " + data.value);
        //
        setTimeout(() => {
            this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { this.getAllRotaExceptionsWrapper(this.props.url); });
        }, 50)
    }
    //
    loadPostcodeOutwards_Wrapper(url) {
        var result = this.loadPostcodeOutwards(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        postcodeOptions: data.body,
                        loadedPostcodes: true,
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadPostcodeOutwards(url) {
        const response = await fetch(url + "/Schedule/GetListOfAllPostcodeOutwards", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                //agentId: this.state.existingAgentValue,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadMappedPostcodeOutwards_Wrapper(url, copyFromDay, callback) {
        var result = this.loadMappedPostcodeOutwards(url, copyFromDay);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    var startDateTime = new Date(data.body.startTime);
                    var startTime_Hours = startDateTime.getHours() < 10 ? "0" + startDateTime.getHours() : startDateTime.getHours();
                    var startTime_Minutes = startDateTime.getMinutes() < 10 ? "0" + startDateTime.getMinutes() : startDateTime.getMinutes();
                    var finishDateTime = new Date(data.body.finishTime);
                    var finishTime_Hours = finishDateTime.getHours() < 10 ? "0" + finishDateTime.getHours() : finishDateTime.getHours();
                    var finishTime_Minutes = finishDateTime.getMinutes() < 10 ? "0" + finishDateTime.getMinutes() : finishDateTime.getMinutes();
                    this.setState({
                        selectedPostcodeValues: data.body.mappedOutCodes,
                        existingStartTime: startTime_Hours + ":" + startTime_Minutes,
                        existingFinishTime: finishTime_Hours + ":" + finishTime_Minutes,
                        loadedMappedPostcodes: true,
                        tabDimmer_Active: false,
                    });
                    //
                    callback();
                    if (!this.state.checkingMappedData) this.checkLoadedAgentMappedData();
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    checkLoadedAgentMappedData() {
        this.setState({
            checkingMappedData: true,
        });
        if (this.state.loadedRotas && this.state.loadedMappedPostcodes) {
            this.setState({
                dimmerActive: false,
                //loadedRotas: false,
                loadedMappedPostcodes: false,
                checkingMappedData: false,
            });
        }
        else if (this.state.loadedRotas) {
            //console.log("waiting for mapped postcodes..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
        else if (this.state.loadedMappedPostcodes) {
            //console.log("waiting for rotas..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
        else {
            //console.log("waiting for everything..");
            setTimeout(() => {
                this.checkLoadedAgentMappedData();
            }, 50)
        }
    }
    //
    async loadMappedPostcodeOutwards(url, copyFromDay) {
        var dayOfWeek = -1;
        switch (this.state.daySelected) {
            case "Monday":
                dayOfWeek = 1
                break;
            case "Tuesday":
                dayOfWeek = 2
                break;
            case "Wednesday":
                dayOfWeek = 3
                break;
            case "Thursday":
                dayOfWeek = 4
                break;
            case "Friday":
                dayOfWeek = 5
                break;
            case "Saturday":
                dayOfWeek = 6
                break;
            case "Sunday":
                dayOfWeek = 0
                break;
            default:
                dayOfWeek = -1
                break;
        }
        this.setState({
            dayOfWeek: dayOfWeek
        });
        const response = await fetch(url + "/Schedule/GetAllMappedAgentPostcodesForDayOfWeek_ForDropdown", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                userId: this.state.existingAgentValue,
                dayOfWeek: copyFromDay >= 0 ? copyFromDay : dayOfWeek
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingAgentList_Wrapper(url) {
        var result = this.loadExistingAgentList(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        agentOptions: data.body,
                        loadedAgents: true,
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingAgentList(url) {
        const response = await fetch(url + "/Account/GetListOfAllAgents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_clearAllSelectedPostcodes() {
        this.setState({
            selectedPostcodeValues: []
        });
    }
    //
    onClick_submitValidate() {
        var atLeast1DaySelected = false;
        if (this.state.button_monday_selected ||
            this.state.button_tuesday_selected ||
            this.state.button_wednesday_selected ||
            this.state.button_thursday_selected ||
            this.state.button_friday_selected ||
            this.state.button_saturday_selected ||
            this.state.button_sunday_selected) {
            atLeast1DaySelected = true;
        }
        if (!atLeast1DaySelected) {
            this.setState({
                buttonGroup_CSS: "ui buttons Admin-ButtonGroup-Validate-Fail"
            });
            return false;
        }
        else {
            this.setState({
                buttonGroup_CSS: "ui buttons"
            });
            return true;
        }
    }
    //
    onClick_handleTabChange(tab) {
        this.setState({
            tabDimmer_Active: true,
        });
        switch (tab) {
            case "mon":
                if (this.state.daySelected !== "Monday") {
                    this.setState({
                        activeTabMon: true,
                        activeTabTues: false,
                        activeTabWeds: false,
                        activeTabThurs: false,
                        activeTabFri: false,
                        activeTabSat: false,
                        activeTabSun: false,
                        daySelected: "Monday",
                        copyMenu_Monday_CSS: "disabled",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "tues":
                if (this.state.daySelected !== "Tuesday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: true,
                        activeTabWeds: false,
                        activeTabThurs: false,
                        activeTabFri: false,
                        activeTabSat: false,
                        activeTabSun: false,
                        daySelected: "Tuesday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "disabled",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "weds":
                if (this.state.daySelected !== "Wednesday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: false,
                        activeTabWeds: true,
                        activeTabThurs: false,
                        activeTabFri: false,
                        activeTabSat: false,
                        activeTabSun: false,
                        daySelected: "Wednesday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "disabled",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "thurs":
                if (this.state.daySelected !== "Thursday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: false,
                        activeTabWeds: false,
                        activeTabThurs: true,
                        activeTabFri: false,
                        activeTabSat: false,
                        activeTabSun: false,
                        daySelected: "Thursday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "disabled",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "fri":
                if (this.state.daySelected !== "Friday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: false,
                        activeTabWeds: false,
                        activeTabThurs: false,
                        activeTabFri: true,
                        activeTabSat: false,
                        activeTabSun: false,
                        daySelected: "Friday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "disabled",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "sat":
                if (this.state.daySelected !== "Saturday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: false,
                        activeTabWeds: false,
                        activeTabThurs: false,
                        activeTabFri: false,
                        activeTabSat: true,
                        activeTabSun: false,
                        daySelected: "Saturday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "disabled",
                        copyMenu_Sunday_CSS: "",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            case "sun":
                if (this.state.daySelected !== "Sunday") {
                    this.setState({
                        activeTabMon: false,
                        activeTabTues: false,
                        activeTabWeds: false,
                        activeTabThurs: false,
                        activeTabFri: false,
                        activeTabSat: false,
                        activeTabSun: true,
                        daySelected: "Sunday",
                        copyMenu_Monday_CSS: "",
                        copyMenu_Tuesday_CSS: "",
                        copyMenu_Wednesday_CSS: "",
                        copyMenu_Thursday_CSS: "",
                        copyMenu_Friday_CSS: "",
                        copyMenu_Saturday_CSS: "",
                        copyMenu_Sunday_CSS: "disabled",
                    });
                    setTimeout(() => {
                        console.log("Selected Day: " + this.state.daySelected);
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }, 100);
                }
                break;
            default:
                break;
        }
    }
    //
    onClick_CopyFromDay(day) {
        this.setState({
            tabDimmer_Active: true,
        });
        switch (day) {
            case "Monday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 1, () => { });
                break;
            case "Tuesday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 2, () => { });
                break;
            case "Wednesday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 3, () => { });
                break;
            case "Thursday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 4, () => { });
                break;
            case "Friday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 5, () => { });
                break;
            case "Saturday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 6, () => { });
                break;
            case "Sunday":
                this.loadMappedPostcodeOutwards_Wrapper(this.props.url, 0, () => { });
                break;
            default:
                break;
        }
    }
    //
    onClick_CopyFromDayToException() {
        this.setState({
            selectedExceptionPostcodeValues: this.state.selectedPostcodeValues,
        });
    }
    //
    onChangeNewTimeHours(event, data, startOrEnd) {
        this.setState({
            newTime_Hour: data.value
        });
    }
    //
    onChangeNewTimeMinutes(event, data, startOrEnd) {
        this.setState({
            newTime_Minutes: data.value
        });
    }
    //
    updateRotaTime_Wrapper(url) {
        var result = this.updateRotaTime(url);
        result.then((data) => {
            var errorMsg = data.body.finishBeforeStart ? "Finish time set before Start time!" : data.body.startAfterFinish ? "Start time set after Finish time!" : "";
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editTimeModalOpen: false,
                        changeTimeButton_CSS: "",
                        changeTimeModalSubmitButton_CSS: "",
                    });
                    if (!data.body.result) {
                        this.setState({
                            httpEditTimeResultModalOpen: true,
                            httpEditTimeResultModalIcon: "warning circle red",
                            httpEditTimeResultModalHeader: "Error",
                            httpEditTimeResultModalText: "Rota Time update Failed!\n\n" + errorMsg,
                        });
                    }
                    else {
                        this.setState({
                            dimmerActive: true,
                        });
                        this.loadMappedPostcodeOutwards_Wrapper(this.props.url, -1, () => { });
                    }
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async updateRotaTime(url) {
        var tempDate = new Date();
        tempDate.setHours(this.state.newTime_Hour);
        tempDate.setMinutes(this.state.newTime_Minutes + Math.abs(tempDate.getTimezoneOffset()));
        const response = await fetch(url + "/Schedule/UpdateRotaTime", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentId: this.state.existingAgentValue,
                dayOfWeek: this.state.dayOfWeek,
                rotaTime: tempDate,
                startOrFinish: this.state.changeStartOrEnd
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_ShowExceptionDiv() {
        this.setState({
            exceptionDiv_CSS: "",
            exceptionIsWorking: false,
            selectedExceptionPostcodeValues: [],
            exceptionStartTime_Hour: 8,
            exceptionStartTime_Minutes: 0,
            exceptionEndTime_Hour: 18,
            exceptionEndTime_Minutes: 0,
        });
    }
    //
    onChange_exceptionDate(value) {
        var d = moment(value, 'MM-DD-YYYY').add(2, "h");
        //console.log("new sched date = " + d);
        if (this.state.exceptionDate !== d) {
            this.setState({
                exceptionDate: d,
            });
        }
    }
    //
    handleExceptionIsWorkingCheckboxChange(event) {
        console.log("exception is working? " + event.target.checked);
        this.setState({
            exceptionIsWorking: event.target.checked,
            segmentExceptionCoverage_CSS: event.target.checked ? "" : "IQ-Hidden",
        });
    }
    //
    handleExceptionPostcodeChange(event, data) {
        this.setState({
            selectedExceptionPostcodeValues: data.value,
        });
    }
    //
    onChangeExceptionStartTimeHours(event, data, startOrEnd) {
        this.setState({
            exceptionStartTime_Hour: data.value
        });
    }
    //
    onChangeExceptionStartTimeMinutes(event, data, startOrEnd) {
        this.setState({
            exceptionStartTime_Minutes: data.value
        });
    }
    //
    onChangeExceptionEndTimeHours(event, data, startOrEnd) {
        this.setState({
            exceptionEndTime_Hour: data.value
        });
    }
    //
    onChangeExceptionEndTimeMinutes(event, data, startOrEnd) {
        this.setState({
            exceptionEndTime_Minutes: data.value
        });
    }
    //
    upsertRotaExceptionWrapper(url) {
        this.setState({
            createExceptionLoading: "loading disabled"
        });
        console.log(this.state.selectedPostcodeValues);
        //
        var result = this.upsertRotaException(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editResultModalIcon: "checkmark circle green",
                        editResultModalHeader: "Success",
                        editResultModalText: "Agent Rota Exception succeessfully updated!",
                        editResultModalOpen: true,
                        createExceptionLoading: "",
                        exceptionCreatedSuccess: true,
                    });
                    break;
                case 400:
                    this.setState({
                        editResultModalIcon: "warning circle red",
                        editResultModalHeader: "Error",
                        editResultModalText: "Agent Rota Exception creation Failed!\n\n" + data.body.Message,
                        editResultModalOpen: true,
                        createExceptionLoading: ""
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            };
            setTimeout(() => {
                this.setState({
                    editPostcodesLoading: ""
                });
            }, 200);
        });
    }
    //
    async upsertRotaException(url) {
        var tempDate_Start = new Date();
        tempDate_Start.setHours(this.state.exceptionStartTime_Hour);
        tempDate_Start.setMinutes(this.state.exceptionStartTime_Minutes + Math.abs(tempDate_Start.getTimezoneOffset()));
        var tempDate_End = new Date();
        tempDate_End.setHours(this.state.exceptionEndTime_Hour);
        tempDate_End.setMinutes(this.state.exceptionEndTime_Minutes + Math.abs(tempDate_End.getTimezoneOffset()));
        const response = await fetch(url + "/Schedule/UpsertRotaException", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.existingAgentValue,
                postcodeIdList: this.state.selectedExceptionPostcodeValues,
                exceptionDate: this.state.exceptionDate,
                isWorkingSelectedDate: this.state.exceptionIsWorking,
                exceptionStartTime: tempDate_Start,
                exceptionFinishTime: tempDate_End,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getAllRotaExceptionsWrapper(url) {
        //
        this.setState({
            exceptionDimmerActive: true,
        });
        var result = this.getAllRotaExceptions(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    // var testData = data.body;
                    // var pcList = [];
                    // for(let i = 0; i < 90; i++){
                    //     pcList.push((i+1));
                    // }
                    // testData[0].exceptionPostcodeList = pcList;
                    this.setState({
                        existingRotaExceptions: data.body,
                        //existingRotaExceptions: testData,
                        exceptionDimmerActive: false,
                    });
                    console.log(data.body);
                    break;
                case 400:
                    this.setState({
                        editResultModalIcon: "warning circle red",
                        editResultModalHeader: "Error",
                        editResultModalText: "Failed to fetch rota exceptions!\n\n" + data.body.Message,
                        editResultModalOpen: true,
                        editPostcodesLoading: ""
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            };
            setTimeout(() => {
                this.setState({
                    editPostcodesLoading: ""
                });
            }, 200);
        });
    }
    //
    async getAllRotaExceptions(url) {
        const response = await fetch(url + "/Schedule/GetAllRotaExceptionsForAgent", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.existingAgentValue,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_handleExceptionDelete(exceptionId) {
        this.setState({
            editResultModalIcon: "warning circle red",
            editResultModalHeader: "Confirm Action",
            editResultModalText: "Are you sure you want to delete this exception?",
            editResultModalOpen: true,
            confirmExceptionDelete: true,
            exceptionDelete_CSS: "disabled loading",
            exceptionIdToDelete: exceptionId,
        });
    }
    deactivateRotaExceptionWrapper(url) {
        //
        this.setState({
            exceptionDimmerActive: true,
        });
        var result = this.deactivateRotaException(url, this.state.exceptionIdToDelete);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        editResultModalIcon: "checkmark circle green",
                        editResultModalHeader: "Success",
                        editResultModalText: "Agent Rota Exception succeessfully deleted!",
                        editResultModalOpen: true,
                        exceptionDelete_CSS: "",
                        exceptionDeleteSuccess: true,
                    });
                    break;
                case 400:
                    this.setState({
                        editResultModalIcon: "warning circle red",
                        editResultModalHeader: "Error",
                        editResultModalText: "Failed to delete rota exception!\n\n" + data.body.Message,
                        editResultModalOpen: true,
                        exceptionDelete_CSS: "",
                        exceptionDimmerActive: false,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            };
        });
    }
    //
    async deactivateRotaException(url, exceptionId) {
        const response = await fetch(url + "/Schedule/DeactivateRotaException", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentRotaExceptionId: exceptionId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClick_ViewExceptionPostcodes(postcodeIdArray) {
        var options = [];
        for (let i = 0; i < postcodeIdArray.length; i++) {
            for (let j = 0; j < this.state.postcodeOptions.length; j++) {
                if (this.state.postcodeOptions[j].key === postcodeIdArray[i]) options.push(this.state.postcodeOptions[j]);
            }
        }
        this.setState({
            selectedExistingExceptionPostcodeValues: postcodeIdArray,
            selectedExistingExceptionPostcodeOptions: options,
            button_viewExceptionPostcodes_CSS: "disabled loading",
            exceptionPostcodeModalOpen: true,
        });
    }
    //
    onClick_EditException(ex, postcodeArray) {
        //this.editSegment.current.focus();//.scrollIntoView({behaviour: "smooth"});
        var exDate = moment(ex.ExceptionDate.split("T")[0]).add(2, "h"); //pad time component to allow for summertime locale fluctuations (and european timezones)
        this.setState({
            selectedExceptionPostcodeValues: postcodeArray,
            exceptionDiv_CSS: "",
            segmentExceptionCoverage_CSS: ex.IsWorking === true ? "" : "IQ-Hidden",
            exceptionIsWorking: ex.IsWorking,
            exceptionDate: moment(exDate),
            button_exception_edit_CSS: "disabled loading",
            createExceptionLoading: "",
            exceptionStartTime_Hour: moment(ex.StartTime).hour(),
            exceptionStartTime_Minutes: moment(ex.StartTime).minute(),
            exceptionEndTime_Hour: moment(ex.EndTime).hour(),
            exceptionEndTime_Minutes: moment(ex.EndTime).minute(),
        });
        setTimeout(() => {
            this.setState({
                button_exception_edit_CSS: "",
            });
        }, 300);
    }
    //
    render() {
        return (
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="map large" />&emsp;Edit Agent Coverage v2</h5></Label>
                    <br /><br /><br />
                    <div>
                        <Form>
                            <Form.Field>
                                <div className="Admin-Div-Company-Region">
                                    <div className="Admin-Div-Company-Outer">
                                        <label>Select Agent</label>
                                        <div className="Admin-Div-Company">
                                            <Dropdown fluid={true} selection={true} search={true} placeholder="Select Agent..." value={this.state.existingAgentValue} options={this.state.agentOptions} onChange={this.handleAgentChange} />
                                        </div>
                                    </div>
                                </div>
                            </Form.Field>
                        </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br /><br />
                    <div className={this.state.formCSS}>
                        <Segment attached="top">
                            <Dimmer active={this.state.tabDimmer_Active}>
                                <Loader>Loading</Loader>
                            </Dimmer>
                            <Form>
                                <Form.Field>
                                    <div className="Admin-Div-Company-Region div-postcode-wrapper">
                                        <label>Select Postcode</label>
                                        <div className="Admin-div-postcode-outer">
                                            <div className="Admin-div-postcode-inner">
                                                <Dropdown fluid={true} selection={true} search={true} multiple={true} placeholder="Select Postcode..." value={this.state.selectedPostcodeValues} options={this.state.postcodeOptions} onChange={this.handlePostcodeChange} />
                                            </div>
                                        </div>
                                        <br />
                                        <p className="Admin-Div-Float-Left">{"Rota Start Time: " + this.state.existingStartTime}</p>
                                        <p className="Admin-Div-Float-Right">{"Rota Finish Time: " + this.state.existingFinishTime}</p>
                                        <br /><br />
                                        <Button className={"teal Admin-Div-Float-Left " + this.state.changeTimeButton_CSS} onClick={() => { this.setState({ editTimeModalOpen: true, changeStartOrEnd: "start", changeTimeButton_CSS: "disabled loading" }); }} ><Icon className="clock" />Set Start Time</Button>

                                        <Button className={"orange Admin-Div-Float-Right " + this.state.changeTimeButton_CSS} onClick={() => { this.setState({ editTimeModalOpen: true, changeStartOrEnd: "end", changeTimeButton_CSS: "disabled loading" }); }}><Icon className="clock" />Set Finish Time</Button>
                                    </div>
                                </Form.Field>
                                <br /><br />
                                <br /><br />
                                <Button className={"ui button red Admin-Button-Clear Admin-Div-Float-Left "} content={"Clear " + this.state.daySelected} onClick={() => this.onClick_clearAllSelectedPostcodes()} />
                                <div className="Admin-Div-Float-Right">
                                    <Dropdown className="button" text='Copy From...'>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className={this.state.copyMenu_Monday_CSS} text='Monday' onClick={() => { this.onClick_CopyFromDay("Monday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Tuesday_CSS} text='Tuesday' onClick={() => { this.onClick_CopyFromDay("Tuesday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Wednesday_CSS} text='Wednesday' onClick={() => { this.onClick_CopyFromDay("Wednesday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Thursday_CSS} text='Thursday' onClick={() => { this.onClick_CopyFromDay("Thursday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Friday_CSS} text='Friday' onClick={() => { this.onClick_CopyFromDay("Friday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Saturday_CSS} text='Saturday' onClick={() => { this.onClick_CopyFromDay("Saturday") }} />
                                            <Dropdown.Item className={this.state.copyMenu_Sunday_CSS} text='Sunday' onClick={() => { this.onClick_CopyFromDay("Sunday") }} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    &emsp;&emsp;&emsp;
                                    <Button className={"ui button green " + this.state.editPostcodesLoading} content={"Save " + this.state.daySelected} onClick={() => this.editAgentCoverageWrapper(this.props.url)} />
                                </div>
                                <br /><br />
                            </Form>
                        </Segment>
                        <Menu attached="bottom" tabular>
                            <Menu.Item
                                name="mon"
                                active={this.state.activeTabMon}
                                disabled={this.state.activeTabMon}
                                onClick={() => { this.onClick_handleTabChange("mon") }}>
                                Monday
                            </Menu.Item>
                            <Menu.Item
                                name="tues"
                                active={this.state.activeTabTues}
                                disabled={this.state.activeTabTues}
                                onClick={() => { this.onClick_handleTabChange("tues") }}>
                                Tuesday
                            </Menu.Item>
                            <Menu.Item
                                name="weds"
                                active={this.state.activeTabWeds}
                                disabled={this.state.activeTabWeds}
                                onClick={() => { this.onClick_handleTabChange("weds") }}>
                                Wednesday
                            </Menu.Item>
                            <Menu.Item
                                name="thurs"
                                active={this.state.activeTabThurs}
                                disabled={this.state.activeTabThurs}
                                onClick={() => { this.onClick_handleTabChange("thurs") }}>
                                Thursday
                            </Menu.Item>
                            <Menu.Item
                                name="fri"
                                active={this.state.activeTabFri}
                                disabled={this.state.activeTabFri}
                                onClick={() => { this.onClick_handleTabChange("fri") }}>
                                Friday
                            </Menu.Item>
                            <Menu.Item
                                name="sat"
                                active={this.state.activeTabSat}
                                disabled={this.state.activeTabSat}
                                onClick={() => { this.onClick_handleTabChange("sat") }}>
                                Saturday
                            </Menu.Item>
                            <Menu.Item
                                name="sun"
                                active={this.state.activeTabSun}
                                disabled={this.state.activeTabSun}
                                onClick={() => { this.onClick_handleTabChange("sun") }}>
                                Sunday
                            </Menu.Item>
                        </Menu>
                        <br /><br />
                        <Segment>
                            <Dimmer active={this.state.exceptionDimmerActive}>
                                <Loader>Loading</Loader>
                            </Dimmer>
                            <Label attached="top"><h5 className="Settings-Header"><Icon className="random large" />&emsp;Existing Rota Exceptions</h5></Label>
                            <br /><br />
                            <div className="Admin-Div-existing-exceptions">
                                {this.state.existingRotaExceptions.map(
                                    (item, i) => {
                                        return (
                                            <div className={i} key={i}>
                                                <font>Exception Date:&emsp;{item.ex.ExceptionDate.split("T")[0]}</font>
                                                <font>&emsp;<b>&bull;</b>&emsp;Agent Is Working?&emsp;{item.ex.IsWorking ? "Yes" : "No"}</font>
                                                {item.ex.IsWorking ? <font>&emsp;<b>&bull;</b>&emsp;Exception Start Time:&emsp;{item.ex.StartTime.split("T")[1].slice(0, 5)}</font> : ""}
                                                {item.ex.IsWorking ? <font>&emsp;<b>&bull;</b>&emsp;Exception Finish Time:&emsp;{item.ex.EndTime.split("T")[1].slice(0, 5)}</font> : ""}
                                                {item.exceptionPostcodeList != null && item.exceptionPostcodeList.length > 0 ? <font>&emsp;<b>&bull;</b>&emsp;<Button className={"mini green " + this.state.button_viewExceptionPostcodes_CSS} onClick={() => { this.onClick_ViewExceptionPostcodes(item.exceptionPostcodeList) }}>View Postcodes</Button></font> : ""}
                                                <font>&emsp;<b>&bull;</b>&emsp;<Button className={"mini blue " + this.state.button_exception_edit_CSS} onClick={() => { this.onClick_EditException(item.ex, item.exceptionPostcodeList) }}>Edit</Button></font>
                                                <font>&emsp;<b>&bull;</b>&emsp;<Button className={"mini red " + this.state.exceptionDelete_CSS} onClick={() => { this.onClick_handleExceptionDelete(item.ex.AgentRotaExceptionId) }}>Delete</Button></font>
                                                {i !== this.state.existingRotaExceptions.length - 1 ? <hr /> : ""}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            {this.state.existingRotaExceptions.length === 0 ? <div>No existing exceptions!</div> : ""}
                        </Segment>
                        <br /><br />
                        <div>
                            <Button className={"ui button blue Admin-Button-Clear"} content={"Create New Rota Exception"} onClick={() => { this.onClick_ShowExceptionDiv() }} />
                            <br /><br />
                            <div className={this.state.exceptionDiv_CSS} ref={this.editSegment}>
                                <Segment>
                                    <Form>
                                        <Form.Field className="Admin-Div-CreateException">
                                            <br /><br /><br /><br />
                                            <label className="Admin-ExceptionDateLabel">Select Exception Date</label>
                                            <div className="Admin-DivCalendar">
                                                <Calendar
                                                    id="input_clients_1_DOB"
                                                    format='ddd DD/MMM/YYYY'
                                                    displayYrWithMonth={true}
                                                    closeOnSelect={true}
                                                    openOnInputFocus={true}
                                                    minDate={(new Date()).getDate() + "/" + (new Date()).getMonth() + "/" + (new Date()).getFullYear()}
                                                    readOnly={true}
                                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                                    date={this.state.exceptionDate}
                                                    onChange={this.onChange_exceptionDate}
                                                    locale="en-gb"
                                                //customIcon="calendar outline icon"
                                                />
                                            </div>
                                            <br />
                                            <div className="Admin-Div-UserIsStaff">
                                                <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Agent is working this date?" />&emsp;
                                                <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.exceptionIsWorking} onChange={this.handleExceptionIsWorkingCheckboxChange} />
                                            </div>
                                            <Segment className={this.state.segmentExceptionCoverage_CSS}>
                                                <Label attached="top"><h5 className="Settings-Header"><Icon className="map large" />&emsp;Exception postcode coverage</h5></Label>
                                                <div className="Admin-Div-Company-Region div-postcode-wrapper">
                                                    <label>Select Exception Postcodes</label>
                                                    <div className="Admin-div-postcode-outer">
                                                        <div className="Admin-div-postcode-inner">
                                                            <Dropdown fluid={true} selection={true} search={true} multiple={true} placeholder="Select Postcode..." value={this.state.selectedExceptionPostcodeValues} options={this.state.postcodeOptions} onChange={this.handleExceptionPostcodeChange} />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="Admin-Div-Float-Left">
                                                        Select exception Start Time:&emsp;
                                                        <Dropdown placeholder="Select Hour" scrolling options={this.state.timeOptions_Hour} onChange={this.onChangeExceptionStartTimeHours} value={this.state.exceptionStartTime_Hour}></Dropdown>
                                                        &emsp;:&emsp;
                                                        <Dropdown placeholder="Select Minutes" scrolling options={this.state.timeOptions_Minute} onChange={this.onChangeExceptionStartTimeMinutes} value={this.state.exceptionStartTime_Minutes}></Dropdown>
                                                    </div>
                                                    <div className="Admin-Div-Float-Right">
                                                        Select exception Finish Time:&emsp;
                                                        <Dropdown placeholder="Select Hour" scrolling options={this.state.timeOptions_Hour} onChange={this.onChangeExceptionEndTimeHours} value={this.state.exceptionEndTime_Hour}></Dropdown>
                                                        &emsp;:&emsp;
                                                        <Dropdown placeholder="Select Minutes" scrolling options={this.state.timeOptions_Minute} onChange={this.onChangeExceptionEndTimeMinutes} value={this.state.exceptionEndTime_Minutes}></Dropdown>
                                                    </div>
                                                    <br /><br />
                                                </div>
                                                <div className="Admin-Div-Float-Left">
                                                    <Button className={"ui button red "} content={"Clear Exception Postcodes"} onClick={() => this.setState({ selectedExceptionPostcodeValues: [] })} />
                                                </div>
                                                <div className="Admin-Div-Float-Right">
                                                    <Button content={"Copy from active rota tab..."} onClick={() => { this.onClick_CopyFromDayToException() }} />
                                                </div>
                                                <br /><br />
                                            </Segment>
                                            <div className="Admin-Div-Float-Right">
                                                {/* <Button content={"Copy from active rota..."} onClick={() => {this.onClick_CopyFromDayToException()}}/>
                                                &emsp;&emsp;&emsp; */}
                                                <Button className={"ui button green " + this.state.createExceptionLoading} content={"Save New Rota Exception"} onClick={() => this.upsertRotaExceptionWrapper(this.props.url)} />
                                            </div>
                                            <br /><br />
                                        </Form.Field>
                                    </Form>
                                </Segment>
                            </div>
                        </div>
                    </div>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ editResultModalOpen: false, editPostcodesLoading: "" })}>
                        <Header><Icon className={this.state.editResultModalIcon} />{this.state.editResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.editResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className={"red Admin-Div-Float-Left " + (this.state.confirmExceptionDelete === true ? "" : "Admin-Hidden")} onClick={() => {
                                this.setState({
                                    editResultModalOpen: false,
                                });
                                if (this.state.confirmExceptionDelete) {
                                    this.setState({
                                        confirmExceptionDelete: false,
                                        exceptionDelete_CSS: ""
                                    });
                                }
                            }}>
                                <Icon className="cancel icon" />
                                    Cancel
                            </Button>
                            <Button color='green' onClick={() => {
                                if (this.state.exceptionCreatedSuccess) {
                                    this.getAllRotaExceptionsWrapper(this.props.url);
                                    this.setState({
                                        exceptionCreatedSuccess: false
                                    });
                                }
                                if (this.state.confirmExceptionDelete) {
                                    this.deactivateRotaExceptionWrapper(this.props.url);
                                    this.setState({
                                        confirmExceptionDelete: false
                                    });
                                }
                                if (this.state.exceptionDeleteSuccess) {
                                    this.getAllRotaExceptionsWrapper(this.props.url);
                                    this.setState({
                                        exceptionDeleteSuccess: false
                                    });
                                }
                                this.setState({ editResultModalOpen: false })
                            }}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editTimeModalOpen} closeOnDimmerClick={false} >
                        <Header><Icon className={"ui icon clock"} />Edit Rota {this.state.changeStartOrEnd === "start" ? "Start" : "Finish Cutoff"} Time</Header>
                        <Modal.Content>
                            <div>
                                Select new {this.state.changeStartOrEnd === "start" ? "Start" : "Finish Cutoff"} Time:&emsp;
                                <Dropdown placeholder="Select Hour" scrolling options={this.state.timeOptions_Hour} onChange={this.onChangeNewTimeHours} value={this.state.newTime_Hour}></Dropdown>
                                &emsp;:&emsp;
                                <Dropdown placeholder="Select Minutes" scrolling options={this.state.timeOptions_Minute} onChange={this.onChangeNewTimeMinutes} value={this.state.newTime_Minutes}></Dropdown>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => { this.setState({ editTimeModalOpen: false, editTimeLoading: "", changeTimeButton_CSS: "" }); }}>
                                <Icon className="cancel icon" />
                                Cancel
                            </Button>
                            <Button color='green' className={this.state.changeTimeModalSubmitButton_CSS} onClick={
                                () => {
                                    this.setState({ changeTimeModalSubmitButton_CSS: "disabled loading" });
                                    this.updateRotaTime_Wrapper(this.props.url);
                                }}><Icon name='checkmark' />&nbsp;Update</Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Admin-Modal-EditTimeResult" open={this.state.httpEditTimeResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ httpEditTimeResultModalOpen: false })}>
                        <Header><Icon className={this.state.httpEditTimeResultModalIcon} />{this.state.httpEditTimeResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.httpEditTimeResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={
                                () => {
                                    this.setState({ httpEditTimeResultModalOpen: false });
                                }}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal className="Admin-Modal-EditTimeResult" open={this.state.exceptionPostcodeModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ exceptionPostcodeModalOpen: false })}>
                        <Header><Icon className="map marker alternate" />View exception Postcodes</Header>
                        <Modal.Content>
                            <div className="Admin-Div-Company-Region div-postcode-wrapper">
                                <Segment>
                                    {this.state.selectedExistingExceptionPostcodeOptions.map(
                                        (item, i) => {
                                            return (
                                                <Label className="label-postcode-margin" key={i}>{item.text}</Label>
                                            );
                                        }
                                    )}
                                </Segment>
                                {/* <label>View exception Postcodes</label> */}
                                {/* <div className="Admin-div-postcode-outer">
                                    <div className="Admin-div-postcode-inner">
                                        <Dropdown disabled fluid={true} selection={true} search={false} multiple={true} placeholder="Select Postcode..." value={this.state.selectedExistingExceptionPostcodeValues} options={this.state.selectedExistingExceptionPostcodeOptions} onChange={(e) => { e.preventDefault(); }}/>
                                    </div>
                                </div> */}
                                <br />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className='Admin-Div-Float-Left' onClick={
                                () => {
                                    this.setState({
                                        exceptionPostcodeModalOpen: false,
                                        button_viewExceptionPostcodes_CSS: "",
                                    });
                                }}><Icon name='reply' />&nbsp;Back</Button>
                            <br /><br /><br />
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default AgentCoveragePageV2;