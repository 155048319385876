import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Message, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import './AdminPage.css';

class NovexUserMigration extends Component{
    constructor(props){
        super(props);
        this.state = {

            segment_editUser_agent: "IQ-Hidden",
            dimmerActive: false,
            selectedUser: -1,

            completeModalOpen: false,

            submitButton_CSS: "IQ-Hidden"
        }
        //
        this.handleUserChange = this.handleUserChange.bind(this);
        this.loadExistingUserList_Wrapper = this.loadExistingUserList_Wrapper.bind(this);
        this.loadExistingUserList = this.loadExistingUserList.bind(this);
        this.migrateUser_Wrapper = this.migrateUser_Wrapper.bind(this);
        this.migrateUser = this.migrateUser.bind(this);
        //
        this.loadExistingUserList_Wrapper(this.props.url);
        //
    }
    //
    //
    handleUserChange(event, data){
        this.setState({
            selectedUser: data.value,
        });
        //
        setTimeout(() => {
            console.log("selected userId: " + this.state.selectedUser);
            this.setState({
                submitButton_CSS: ""
            });
        }, 150)
    }
    //
    migrateUser_Wrapper(){
        let url = "https://middleware.equilaw.uk.com/Middleware/ImportActiveDPSFileHistoryToNovex_ForUserId";
        //let url = "http://localhost:49493/Middleware/ImportActiveDPSFileHistoryToNovex_ForUserId";
        this.setState({
            dimmerActive: true
        });
        var result = this.migrateUser(url);
        result.then((data) => {
            this.setState({
                dimmerActive: false
            });
            switch(data.httpCode){
                case 200:
                    this.setState({
                        completeModalOpen: true
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async migrateUser(url){
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                portalUserId: this.state.selectedUser
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingUserList_Wrapper(url){
        var result = this.loadExistingUserList(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        userOptions: data.body
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingUserList(url){
        const response = await fetch(url + "/Account/GetListOfAllUsers", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="edit large"/>&emsp;Migrate DPS File History to Novex</h5></Label>
                    <br/><br/><br/>
                    <p>
                        Note: This operation will, for a given user, migrate all DPS file history documents to the corresponding cases in Novex. Only currently active, or on-hold cases will be processed.
                        <br/><br/>
                        THIS SHOULD ONLY BE RUN <b><u>ONCE</u></b> PER USER!
                    </p>
                    <div>
                    <Form>
                        <Form.Field>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select User</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown fluid={true} selection={true} search={true} placeholder="Select User..." value={this.state.existingUserValue} options={this.state.userOptions} onChange={this.handleUserChange}/>
                                    </div>
                                    <br/><br/><br/>
                                    <Button className={"blue " + this.state.submitButton_CSS} size="small" onClick={() => {
                                        this.setState({
                                            alterModalButton_CSS: "disabled loading"
                                        });
                                        this.migrateUser_Wrapper();
                                    }}>
                                        <Icon className="upload" />&nbsp;Migrate User File History
                                    </Button>
                                </div>
                            </div>
                        </Form.Field>
                        </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Processing...</Loader>
                    </Dimmer>
                    <br/><br/>
                </Segment>
                <div>
                <Modal className="Appt-Modal-Complete" open={this.state.completeModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ completeModalOpen: false })}>
                        <Header ><Icon className="ui icon share square outline"></Icon>Migrate User Request</Header>
                        <Modal.Content>
                            <p>User successfully migrated</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="Admin-Div-Float-Left" onClick={() => { this.setState({ completeModalOpen: false }); }}>
                                <Icon className="reply icon" />
                                Close
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>            
        );
    }
}

export default NovexUserMigration;