import React from 'react';
import ReactDOM from 'react-dom';
import './PolyFill_ArrayFind';
import './PolyFill_FindIndex';
import './PolyFill_IsNaN';
import './PolyFill_Includes';
import './PolyFill_String_Includes';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
