import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Message, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import './AdminPage.css';

class EditUser extends Component{
    constructor(props){
        super(props);
        this.state = {
            companyOptions: this.props.companyOptions,
            companyValue: "",
            companyId: 0,
            companyError: false,
            userIsStaff: false,

            roleOptions: this.props.roleOptions,
            roleValue: "",
            roleId: 0,
            roleError: false,

            firstName: "",
            firstNameError: false,
            lastName: "",
            lastNameError: false,
            username: "",
            usernameLoading: "",
            badEmail: false,
            badEmailMsg: "",
            goodEmail: false,
            usernameError: false,
            editUserLoading: "",
            existingUserChosen: false,
            phone1: "",
            phone1Error: false,
            phone2: "",
            phone2Error: false, 

            segment_editUser_agent: "IQ-Hidden",
            input_editUser_apptDuration: "30",
            input_editUser_postcode: "",
            input_editUser_windowpaddingMinutes: "30",

            segment_editUser_ifaAdmin: "IQ-Hidden",
            usingAreaMap: false,
            input_editUser_advisorEmail: "",
            existingMappedEmailsList: [],
            buttonAddEmail_CSS: "",
            removeEmail_CSS: "",

            userIsActive: true,
            userHasPassReset: false,

            existingUserValue: "",
            userOptions: [],            
            formCSS: "Admin-Edit-Hide",
            dimmerActive: false,

            editResultModalOpen: false,
            editResultModalIcon: "warning circle red",
            editResultModalHeader: "User Edit Fail!",
            editResultModalText: "Default error text"
        }
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.emailCheckWrapper = this.emailCheckWrapper.bind(this);
        this.emailCheck = this.emailCheck.bind(this);        
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleIsStaffCheckboxChange = this.handleIsStaffCheckboxChange.bind(this);        
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handlePhone1Change = this.handlePhone1Change.bind(this);
        this.handlePhone2Change = this.handlePhone2Change.bind(this);
        this.handleFormValidation = this.handleFormValidation.bind(this);
        this.editUserWrapper = this.editUserWrapper.bind(this);
        this.editUser = this.editUser.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.loadExistingUserList_Wrapper = this.loadExistingUserList_Wrapper.bind(this);
        this.loadExistingUserList = this.loadExistingUserList.bind(this);
        this.loadExistingUserData_Wrapper = this.loadExistingUserData_Wrapper.bind(this);
        this.loadExistingUserData = this.loadExistingUserData.bind(this);
        this.handleActiveCheckboxChange = this.handleActiveCheckboxChange.bind(this);
        this.handlePassResetCheckboxChange = this.handlePassResetCheckboxChange.bind(this);
        this.handleActiveOnChange = this.handleActiveOnChange.bind(this);
        this.handleUsingAreaMapCheckboxChange = this.handleUsingAreaMapCheckboxChange.bind(this);
        //
        this.loadExistingUserList_Wrapper(this.props.url);
        //
        this.mapEmailWrapper = this.mapEmailWrapper.bind(this);
        this.mapAdvisorEmail = this.mapAdvisorEmail.bind(this);
        //
        this.removeEmailMap_Wrapper = this.removeEmailMap_Wrapper.bind(this);
        this.removeEmailMap = this.removeEmailMap.bind(this);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    handleFirstNameChange(event){
        this.setState({
            firstName: event.target.value
        });
        if(this.state.firstNameError && event.target.value !== ""){
            this.setState({
                firstNameError: false
            });
        }
    }
    //
    handleLastNameChange(event){
        this.setState({
            lastName: event.target.value            
        });
        if(this.state.lastNameError && event.target.value !== ""){
            this.setState({
                lastNameError: false
            });
        }
    }
    //
    handleUsernameChange(event){
        this.setState({
            username: event.target.value,
            badEmail: false,
            goodEmail: false
        });
        if(this.state.usernameError && this.goodEmail){
            this.setState({
                usernameError: false
            });
        }
    }
    //
    emailCheckWrapper(url){
        var uiDelay = 200;
        this.setState({
            usernameLoading: "loading disabled"
        });
        var result = this.emailCheck(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    if(!data.body){
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Email in use! Please use another mail address.",
                                goodEmail: false,
                            });
                        }, uiDelay);
                    }
                    else{
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: false,
                                goodEmail: true,
                                usernameError: false
                            });
                        }, uiDelay);
                    }
                    break;
                case 400:
                    if(this.state.username === ""){
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Email missing!. Please enter a valid email address!",
                                goodEmail: false,
                                usernameError: true,
                            });
                        }, uiDelay);
                    }
                    else{
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Invalid session token. Please login again!",
                                goodEmail: false,
                                editResultModalOpen: true
                            });
                        }, uiDelay);
                    }
                    break;
                case 401:
                    setTimeout(() => {
                        // this.setState({
                        //     usernameLoading: "",
                        //     badEmail: true,
                        //     badEmailMsg: "You do not have permission to use this function.",
                        //     goodEmail: false
                        // });
                        this.props.history.push("/");
                    }, uiDelay);
                    break;
                case 404:
                    setTimeout(() => {
                        this.setState({
                            usernameLoading: "",
                            badEmail: true,
                            badEmailMsg: "Email is invalid! Please check the address.",
                            goodEmail: false,
                            usernameError: true,
                        });
                        
                    }, uiDelay);
                    break;
                default:
                    break;
            }
        });     
    }
    //
    async emailCheck(url){
        const response = await fetch(url + "/Account/EmailCheck", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                email: this.state.username
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    editUserWrapper(url){
        this.setState({
            editUserLoading: "loading disabled"
        });
        if(this.handleFormValidation()){
            console.log("validation passed");
            //
            var result = this.editUser(url);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            editResultModalIcon: "checkmark circle green",
                            editResultModalHeader: "Success",
                            editResultModalText: "User Edit Succeeded!",
                            editResultModalOpen: true
                        });  
                        setTimeout(() => { window.location.reload(); }, 1000);
                        break;
                    case 400:
                        this.setState({
                            editResultModalIcon: "warning circle red",
                            editResultModalHeader: "Error",
                            editResultModalText: "User Edit Failed!\n\n" + data.body.Message,
                            editResultModalOpen: true
                        });     
                        break;
                    default:
                        break;
                } ;
                setTimeout(() => {
                    this.setState({
                        editUserLoading: ""
                    });
                }, 200);
            });
        }
        else {            
            console.log("validation failed!");
            setTimeout(() => {
                this.setState({
                    editUserLoading: ""
                });
            }, 200)
        }
        
    }
    //
    async editUser(url){
        const response = await fetch(url + "/Account/EditUserDetails", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                username: this.state.username,
                email: this.state.username,
                phone1: this.state.phone1,
                phone2: this.state.phone2,
                companyId: this.state.userIsStaff ? -99 : this.state.companyId,
                userRole: this.state.roleId,
                userId: this.state.existingUserValue,
                active: this.state.userIsActive,
                passwordReset: this.state.userHasPassReset,
                //
                apptDuration: this.state.input_editUser_apptDuration,
                postcode: this.state.input_editUser_postcode,
                paddingMinutes: this.state.input_editUser_windowpaddingMinutes,
                //
                usingAdvisorMap: this.state.usingAreaMap
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleCompanyChange(event, data){
        this.setState({
            companyValue: data.value,
            companyId: data.value
        })
    }
    //
    handleRoleChange(event, data ){
        this.setState({
            roleValue: data.value,
            roleId: data.value,
            segment_editUser_agent: data.value === 6 ? "" : "IQ-Hidden",
        })
        if(this.state.roleError && data.value > 0){
            this.setState({
                roleError: false
            });
        }
    }
    //
    handleIsStaffCheckboxChange(event){
        this.setState({
            userIsStaff: event.target.checked,
            companyValue: ""
        });
        if(event.target.checked){
            this.setState({
                companyError: false
            });
        }
        else if(!(this.state.companyId > 0)){
            this.setState({
                companyError: true
            });
        }
    }
    //
    handlePhone1Change(event){
        this.setState({
            phone1: event.target.value
        });
        if(this.state.phone1Error && event.target.value !== ""){
            this.setState({
                phone1Error: false
            });
        }
    }
    handlePhone2Change(event){
        this.setState({
            phone2: event.target.value
        });
        if(this.state.phone2Error && event.target.value !== ""){
            this.setState({
                phone2Error: false
            });
        }
    }
    //
    handleFormValidation(){
        var firstNameError = (this.state.firstName === "" || this.state.firstName === "undefined" );
        var lastNameError = (this.state.lastName === "" || this.state.lastName === "undefined" );
        var usernameError = (this.state.username === "" || this.state.username === "undefined" );
        //var phone1Error = (this.state.phone1 === "" || this.state.phone1 === "undefined" );
        //var phone2Error = (this.state.phone2 === "" || this.state.phone2 === "undefined" );
        var companyError = (!(this.state.companyId > 0) && !this.state.userIsStaff);
        var roleError = (!(this.state.roleId > 0));
        //
        var agentPostcodeError = (this.state.roleValue === 6 && (this.state.input_editUser_postcode === "" || this.state.input_editUser_postcode === "undefined"));
        //
        this.setState({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            usernameError: usernameError,
            //phone1Error: phone1Error,
            //phone2Error: phone2Error,
            companyError: companyError,
            roleError: roleError,
            agentPostcodeError: agentPostcodeError
        })
        //
        if(!firstNameError && !lastNameError && !usernameError && !companyError && !roleError && !agentPostcodeError) return true;
        else return false;
    }
    //
    handleUserChange(event, data){
        this.setState({
            existingUserValue: data.value,
            formCSS: "",
            dimmerActive: true
        });
        //
        setTimeout(() => {
            this.loadExistingUserData_Wrapper(this.props.url);
        }, 150)
        
    }
    //
    loadExistingUserList_Wrapper(url){
        var result = this.loadExistingUserList(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        userOptions: data.body
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingUserList(url){
        const response = await fetch(url + "/Account/GetListOfAllUsers", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingUserData_Wrapper(url){
        var result = this.loadExistingUserData(url);
        result.then((data) => {
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        firstName: data.body.FirstName,
                        lastName: data.body.LastName,
                        username: data.body.Username,
                        phone1: data.body.Phone1,
                        phone2: data.body.Phone2,
                        roleValue: data.body.RoleId,
                        roleId: data.body.RoleId,
                        usernameError: false,
                        badEmail: false,
                        userIsActive: data.body.Active,
                        userHasPassReset: data.body.PasswordResetRequested,
                        segment_editUser_agent: data.body.RoleId === 6 ? "" : "IQ-Hidden",
                        segment_editUser_ifaAdmin: data.body.RoleId === 4 ? "" : "IQ-Hidden",
                        usingAreaMap: data.body.UsingAdvisorMap,
                        segment_editUser_ifaAdmin_advisorMap: data.body.UsingAdvisorMap ? "" : "IQ-Hidden",
                        existingMappedEmailsList: data.body.UsingAdvisorMap ? data.body.IFAAdmin_AdvisorEmailMapList : [],
                        input_editUser_apptDuration: data.body.Agent_ApptDuration,
                        input_editUser_postcode: data.body.Agent_HomeLocation_Postcode != null ? data.body.Agent_HomeLocation_Postcode : "",
                        input_editUser_windowpaddingMinutes: data.body.Agent_WindowPaddingMinutes,
                    });
                    if(data.body.CompanyId === -99){
                        this.setState({
                            userIsStaff: true
                        });                        
                    }
                    else {
                        this.setState({
                            companyValue: data.body.CompanyId,
                            companyId: data.body.CompanyId,
                        });
                    }
                    setTimeout(() => {
                        this.setState({
                            dimmerActive: false
                        });
                    }, 200)
                    break;
                case 400:
                    setTimeout(() => {
                        this.setState({
                            dimmerActive: false
                        });
                    }, 200)
                    break;
                default:
                    break;
            }                
        });        
    }
    //
    async loadExistingUserData(url){
        const response = await fetch(url + "/Account/GetExistingUserDetails", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                userId: this.state.existingUserValue
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleActiveCheckboxChange(event){
        this.setState({
            userIsActive: event.target.checked
        });
    }
    handleActiveOnChange(){}
    //
    handlePassResetCheckboxChange(event){
        this.setState({
            userHasPassReset: event.target.checked
        });
    }
    handleUsingAreaMapCheckboxChange(event){
        this.setState({
            usingAreaMap: event.target.checked,
            segment_editUser_ifaAdmin_advisorMap: event.target.checked ? "" : "IQ-Hidden"
        });
    }
    mapEmailWrapper(url){
        this.setState({
            buttonAddEmail_CSS: "disabled loading",
        });
        if(this.state.input_editUser_advisorEmail !== null && this.state.input_editUser_advisorEmail !== ""){
            var result = this.mapAdvisorEmail(url);
            result.then((data) => {
                console.log(data);
                var emailArray = this.state.existingMappedEmailsList;
                console.log(emailArray);
                console.log("newEmail: " + this.state.input_editUser_advisorEmail);
                emailArray.push(this.state.input_editUser_advisorEmail);
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            existingMappedEmailsList: emailArray, 
                            newAdvisorEmail: "",
                            buttonAddEmail_CSS: ""
                        });
                        break;
                    case 400:
                        setTimeout(() => {
                            this.setState({
                                dimmerActive: false
                            });
                        }, 200)
                        break;
                    default:
                        break;
                }                
            }); 
        }
        else{
            this.setState({
                buttonAddEmail_CSS: "",
            })
        }
    }
    async mapAdvisorEmail(url){
        const response = await fetch(url + "/Account/MapAdvisorEmail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                managerUserId: this.state.existingUserValue,
                advisorEmail: this.state.input_editUser_advisorEmail
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    removeEmailMap_Wrapper(url, email){
        this.setState({
            removeEmail_CSS: "disabled loading",
        });
        var result = this.removeEmailMap(url, email);
        result.then((data) => {
            console.log(data);
            var emailArray = this.state.existingMappedEmailsList.filter((value) => {
                return value !== email;
            });
            switch(data.httpCode){
                case 200:
                    this.setState({
                        existingMappedEmailsList: emailArray,
                        newAdvisorEmail: "",
                        removeEmail_CSS: ""
                    });
                    break;
                case 400:
                    setTimeout(() => {
                        this.setState({
                            dimmerActive: false
                        });
                    }, 200)
                    break;
                default:
                    break;
            }                
        });
    }
    async removeEmailMap(url, email){
        const response = await fetch(url + "/Account/RemoveAdvisorEmailMap", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                managerUserId: this.state.existingUserValue,
                advisorEmail: email
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="edit large"/>&emsp;Edit User</h5></Label>
                    <br/><br/><br/>
                    <div>
                    <Form>
                        <Form.Field>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select Existing User</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown fluid={true} selection={true} search={true} placeholder="Select Existing User..." value={this.state.existingUserValue} options={this.state.userOptions} onChange={this.handleUserChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                        </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/>
                    <div className={this.state.formCSS}>
                        <Form>                                
                            <div className="Admin-Div-Name-Region">
                                <Form.Field error={this.state.firstNameError}>
                                <div className="Admin-Div-FirstName-Outer">
                                    <label>First Name</label>
                                    <div className="Admin-Div-FirstName-Input">
                                        <input placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange}/>
                                    </div>
                                </div>
                                </Form.Field>
                                &emsp;
                                <Form.Field error={this.state.lastNameError}>
                                <div className="Admin-Div-LastName-Outer">
                                    <label>Last Name</label>
                                    <div className="Admin-Div-LastName-Input">
                                        <input placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange}/>
                                    </div>
                                </div>
                                </Form.Field>
                            </div>
                        </Form>
                        <br/>
                        <Form error={this.state.badEmail} success={this.state.goodEmail}>
                            <Form.Field error={this.state.usernameError}>
                                <label>Username (email)</label>
                                <div className="Admin-Div-Username">
                                    <input placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />&emsp;
                                    <button className={"ui button blue " + this.state.usernameLoading} onClick={() => {this.emailCheckWrapper(this.props.url);}}>Check</button>
                                </div>
                            </Form.Field>
                            <Message
                                error
                                header='Error'
                                content={this.state.badEmailMsg}
                            />
                            <Message
                                success
                                header='Success'
                                content="This Email is available and not currently in use."
                            />
                            
                        </Form>
                        <br/>
                        <Form>
                            <Form.Field error={this.state.phone1Error}>
                                <label>Contact Phone 1</label>
                                <div className="Admin-Div-Phone1">
                                    <input placeholder="Phone 1" value={this.state.phone1} onChange={this.handlePhone1Change} />&emsp;
                                </div>
                            </Form.Field>
                            <Form.Field error={this.state.phone2Error}>
                                <label>Contact Phone 2</label>
                                <div className="Admin-Div-Phone2">
                                    <input placeholder="Phone 2" value={this.state.phone2} onChange={this.handlePhone2Change} />&emsp;
                                </div>
                            </Form.Field>
                        </Form>
                        <Form>
                            <Form.Field error={this.state.companyError}>
                                <div className="Admin-Div-Company-Region">
                                    <div className="Admin-Div-Company-Outer">
                                        <label>User Company</label>
                                        <div className="Admin-Div-Company">
                                            <Dropdown disabled={this.state.userIsStaff} fluid={true} selection={true} search={true} placeholder="Select User Company..." value={this.state.companyValue} options={this.state.companyOptions} onChange={this.handleCompanyChange}/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="User is Equilaw staff?"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.userIsStaff} onChange={this.handleIsStaffCheckboxChange}/>
                                    </div>
                                </div>
                            </Form.Field>
                            <Form.Field error={this.state.roleError}>
                                <div className="Admin-Div-Company-Region">
                                    <div className="Admin-Div-Role-Outer">
                                        <label>User Role</label>
                                        <div className="Admin-Div-Role">
                                            <Dropdown fluid={true} selection={true} placeholder="Select User Role..." options={this.state.roleOptions} value={this.state.roleValue} onChange={this.handleRoleChange}/>
                                        </div>
                                    </div>
                                </div>
                            </Form.Field>
                        </Form>
                        <Segment className={this.state.segment_editUser_agent}>
                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Agent Details</h4></Label>
                            <div>
                                <br/>
                                <Form>
                                    <Form.Field>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"} content="Agent Appointment Duration (in minutes)"/>&emsp;
                                            <div className="Admin-Form-Field-Sm">
                                                <input className={this.state.passMatch} placeholder="Appointment Duration" type="text" value={this.state.input_editUser_apptDuration} onChange={this.valueChange('input_editUser_apptDuration')} />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    <Form.Field error={this.state.agentPostcodeError}>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"} content="Agent Home Postcode"/>&emsp;
                                            <div className="Admin-Form-Field-Sm">
                                                <input className={this.state.passMatch} placeholder="Postcode" type="text" value={this.state.input_editUser_postcode} onChange={this.valueChange('input_editUser_postcode')} />
                                            </div>
                                        </div>
                                    </Form.Field>
                                    {/* <Form.Field>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"} content="Appointment Time-Window Padding (in minutes)"/>&emsp;
                                            <div className="Admin-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Appointment Padding" type="text" value={this.state.input_editUser_windowpaddingMinutes} onChange={this.valueChange('input_editUser_windowpaddingMinutes')} />
                                            </div>
                                        </div>
                                    </Form.Field> */}
                                </Form>                                                       
                            </div>                                                            
                        </Segment>
                        <Segment className={this.state.segment_editUser_ifaAdmin}>
                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: IFA Admin Details</h4></Label>
                            <div>
                                <br/>
                                <Form>
                                    <Form.Field>
                                        <div className="Admin-Div-UserIsStaff">
                                            <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Using Advisor email area mapping?"/>&emsp;
                                            <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.usingAreaMap} onChange={this.handleUsingAreaMapCheckboxChange}/>
                                        </div>
                                        <Segment className={this.state.segment_editUser_ifaAdmin_advisorMap}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header">Edit Mapped Advisors</h4></Label>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Advisor Email To Add:"/>&emsp;
                                                <div className="Admin-Form-Field-Sm">
                                                    <input className={this.state.passMatch} placeholder="Advisor Email" type="text" value={this.state.input_editUser_advisorEmail} onChange={this.valueChange('input_editUser_advisorEmail')} />
                                                    &emsp;<button className={"ui button green " + this.state.buttonAddEmail_CSS} onClick={() => {this.mapEmailWrapper(this.props.url)}}>Add</button>
                                                </div>
                                                <Segment>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header">Existing Mapped Advisors</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div >
                                                            {this.state.existingMappedEmailsList.map((item, i) => {
                                                                return <p key={i}>{item}&emsp;<i className={"icon remove circle red large Admin-EditUser-Remove-Email " + this.state.removeEmail_CSS} onClick={() => {this.removeEmailMap_Wrapper(this.props.url, item)}} ></i></p>;
                                                            })}
                                                        </div>
                                                        <br/>
                                                    </div>
                                                </Segment>
                                            </div>
                                        </Segment>
                                    </Form.Field>
                                </Form>                                                       
                            </div>                                                            
                        </Segment>
                        <Form>
                            <br/>
                            <Form.Field>
                                <div className="Admin-Div-UserIsStaff">
                                    <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="User is Active?"/>&emsp;
                                    <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.userIsActive} onClick={this.handleActiveCheckboxChange} onChange={this.handleActiveOnChange}/>
                                </div>
                                <br/><br/>
                                <div className="Admin-Div-UserIsStaff">
                                    <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="User Has outstanding Password reset?"/>&emsp;
                                    <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" disabled={!this.state.userHasPassReset} checked={this.state.userHasPassReset} onChange={this.handlePassResetCheckboxChange}/>
                                </div>
                            </Form.Field>
                        </Form>
                        <br/><br/>
                        <Button className={"ui button green " + this.state.editUserLoading} content="Save Changes" onClick={() => this.editUserWrapper(this.props.url)}/>
                    </div>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ editResultModalOpen: false, editUserLoading: ""})}>
                        <Header><Icon className={this.state.editResultModalIcon} />{this.state.editResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.editResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={() => this.setState({ editResultModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>            
        );
    }
}

export default EditUser;