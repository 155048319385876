import React, { Component } from 'react';
import MattersPage from './MattersPage';
import ClientCasePage from './ClientCasePage';

class MattersWrapper extends Component{
    //
    render(){
        return(
        <div>
            {
                localStorage.roleId === "5" ? <ClientCasePage history={this.props.history} url={this.props.url}/> : <MattersPage history={this.props.history} url={this.props.url}/>
            }
        </div>
        );
    };
}

export default MattersWrapper;