import React, { Component } from 'react';
import './MattersPage.css';
import './AdminIQPage.css';
import { Segment, Label, Button, Input } from 'semantic-ui-react';
import 'react-table/react-table.css'
import ReactTable  from 'react-table';

class AdminIQPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            tableData: [],
            input_matter_ref: "",
            input_matter_ref_error: false,
            button_load_CSS: "ui green",
        }
        this.valueChange = this.valueChange.bind(this);
        this.handleEnterPressed = this.handleEnterPressed.bind(this);
        this.onClick_loadIQ = this.onClick_loadIQ.bind(this);
        this.onClick_gotoIQ = this.onClick_gotoIQ.bind(this);
        this.fetchFileId_Wrapper = this.fetchFileId_Wrapper.bind(this);
        this.fetchFileId = this.fetchFileId.bind(this);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    onClick_loadIQ(){
        this.setState({
            button_load_CSS: "ui green loading disabled",
        });
        if(this.state.input_matter_ref !== "") this.fetchFileId_Wrapper();
        else {
            this.setState({
                input_matter_ref_error: true,
            });
        }
        this.setState({
            button_load_CSS: "ui green",
        });
    }
    //
    handleEnterPressed(event){
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the 'Enter' keycode
            this.fetchFileId_Wrapper();
        }
    }
    //
    onClick_gotoIQ(fileId){
        this.props.history.push("/IQ?id=" + fileId);
    }
    //
    fetchFileId_Wrapper(){        
        var results = this.fetchFileId(this.props.url);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    //console.log(data);
                    this.setState({
                        tableData: data.body,
                        matterDataDimmerActive: false,
                    });
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fetchFileId(url){
        const response = await fetch(url + "/Matters/GetFileIdFromMatterRef", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                matRef: this.state.input_matter_ref,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }

    render(){
        const data = this.state.tableData.length > 0 ? this.state.tableData : [{MAT_Ref: "No Results!"}];
        //
        const columns = [{            
            Header: <b className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;File Id</b>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return(
                    <div>
                        <Segment className="AdminIQ-Segment-Generic" secondary={true} padded>
                            <p className="AdminIQ-Cell">{row.original.MAT_FileID}</p>
                        </Segment>
                    </div>
                )
            }},{
            Header: () => (<b className="AdminIQ-Table-Heading">&nbsp;&nbsp;&nbsp;Reference</b>),
            resizable: false,
            filterable: false,
            Cell: row => {
                return(
                    <div>
                        <Segment className="AdminIQ-Segment-Generic" secondary={true} padded>
                            <p className="AdminIQ-Cell">{row.original.MAT_Ref}</p>
                        </Segment>
                    </div>
                )
            }},{
            Header: <b className="AdminIQ-Table-Heading">&nbsp;&nbsp;&nbsp;Address</b>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return(
                    <div>
                        <Segment className="AdminIQ-Segment-Generic" secondary={true} padded>
                            <p className="AdminIQ-Cell">{row.original.MAT_Details}</p>
                        </Segment>
                    </div>
                )
            }},{
            Header: <b className="AdminIQ-Table-Heading">&nbsp;&nbsp;&nbsp;Closed?</b>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return(
                    <div>
                        <Segment className="AdminIQ-Segment-Generic" secondary={true} padded>
                            <p className="AdminIQ-Cell">{row.original.MAT_Closed != null ? row.original.MAT_Closed = 1 ? "Yes" : "No" : ""}</p>
                        </Segment>
                    </div>
                )
            }},{
            Header: <b className="AdminIQ-Table-Heading">&nbsp;&nbsp;&nbsp;Actions</b>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return(
                    <div>
                        <Segment className="AdminIQ-Segment-Generic" secondary={true} padded>
                            <p className="AdminIQ-Cell">{row.original.MAT_FileID != null ? <Button className="ui blue AdminIQ-Button-Goto" onClick={() => this.onClick_gotoIQ(row.original.MAT_FileID)}>Goto IQ for ID: {row.original.MAT_FileID}</Button> : ""}</p>
                        </Segment>
                    </div>
                )
            }},
        ]
        //
        return(
            <div>
                <div>
                    <Label className="IQ-Label" pointing={"right"} content="Input matter reference to fetch Initial Questionnaire"/>&emsp;
                    <Input id="" className="ui" error={this.state.input_matter_ref_error} type="text" value={this.state.input_matter_ref} onChange={this.valueChange('input_matter_ref')} onKeyPress={this.handleEnterPressed.bind(this)} />&emsp;
                    <Button className={this.state.button_load_CSS} content="Load Matters" onClick={this.onClick_loadIQ}/>
                </div>
                <br/><br/>
                { data != null && data.length > 0 ? <ReactTable className="AdminIQ-Table" data={data} columns={columns} defaultPageSize={10} sortable={false} /> : <div></div>}
            </div>
        )
    }
}

export default AdminIQPage