import React, { Component } from 'react';
import './IQPage.css';
import { Segment, Label, Icon, } from 'semantic-ui-react';
import moment from 'moment';
import '../node_modules/react-input-calendar/style/index.css';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css'
import IQPage1Clients from './IQPage1Clients';
import IQPage2Property from './IQPage2Property';
import IQPage3Occupiers from './IQPage3Occupiers';
import IQPage4Financial from './IQPage4Financial';
import IQPage5Misc from './IQPage5Misc';

class IQPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment(),
            reference: "",//"TEST001/1",
            //
            segment_step_CSS: ["", "IQ-Hidden", "IQ-Hidden", "IQ-Hidden", "IQ-Hidden"],
            title_step_CSS: ["step IQ-Step active", "step IQ-Step", "step IQ-Step", "step IQ-Step", "step IQ-Step"],
            //
            button_back_CSS: "red IQ-backButton disabled",
            button_forward_CSS: "green IQ-forwardButton",
            //
            IQStep: 1,
            //
            varsList: [],
            //
            fileId: (this.props.history.location.search.split("id=")[1] > 0) ? this.props.history.location.search.split("id=")[1] : 0,
        }
        this.onClick_step_forward = this.onClick_step_forward.bind(this);
        this.onClick_step_back = this.onClick_step_back.bind(this);
        //
        this.getIQData_Wrapper = this.getIQData_Wrapper.bind(this);
        //
        setTimeout(this.getIQData_Wrapper, 50);
    }
    //
    //
    onClick_step_forward(){        
        //console.log("Step: " + this.state.IQStep);
        var titleArray = this.state.title_step_CSS;
        titleArray[parseInt(this.state.IQStep)] = "step IQ-Step";
        titleArray[parseInt(this.state.IQStep)+1] = "step IQ-Step active";
        //
        var stepArray = this.state.segment_step_CSS;
        stepArray[parseInt(this.state.IQStep)] = "IQ-Hidden";
        stepArray[parseInt(this.state.IQStep)+1] = "";
        this.setState({
            title_step_CSS: titleArray,
            segment_step_CSS: stepArray,
        });
        this.setState({
            IQStep: parseInt(this.state.IQStep)+1,
        });
        //console.log("Step After: " + this.state.IQStep);
        //window.location.reload();
        this.getIQData_Wrapper();
    }
    //
    onClick_step_back(){        
        //console.log("Step: " + this.state.IQStep);
        var titleArray = this.state.title_step_CSS;
        titleArray[parseInt(this.state.IQStep)] = "step IQ-Step";
        titleArray[parseInt(this.state.IQStep)-1] = "step IQ-Step active";
        //
        var stepArray = this.state.segment_step_CSS;
        stepArray[parseInt(this.state.IQStep)] = "IQ-Hidden";
        stepArray[parseInt(this.state.IQStep)-1] = "";
        this.setState({
            title_step_CSS: titleArray,
            segment_step_CSS: stepArray,
        });
        this.setState({
            IQStep: parseInt(this.state.IQStep)-1,
        });
        //console.log("Step After: " + this.state.IQStep);
        this.getIQData_Wrapper();
        //window.location.reload();
    }
    //
    getIQData_Wrapper(){
        console.log("fetching IQ data...");
        var results = this.getIQData(this.props.url);
        results.then((data) => {
            //console.log(data.body.varsList);
            switch(data.httpCode){
                case 200:
                    console.log("IQStep from server: " + data.body.iqStep);
                    var temp_segment_step_CSS = ["IQ-Hidden", "IQ-Hidden", "IQ-Hidden", "IQ-Hidden", "IQ-Hidden"];
                    var temp_title_step_CSS = ["step IQ-Step", "step IQ-Step", "step IQ-Step", "step IQ-Step", "step IQ-Step"];
                    if(parseInt(data.body.iqStep) === 5) {
                        temp_segment_step_CSS[0] = "";
                    }
                    else temp_segment_step_CSS[data.body.iqStep] = "";
                    if(parseInt(data.body.iqStep) === 5) {
                        temp_title_step_CSS[0] = "step IQ-Step active";
                    }
                    else temp_title_step_CSS[data.body.iqStep] = "step IQ-Step active";                    
                    //
                    console.log("Old varsList count: " + this.state.varsList.length);
                    this.setState({       
                        reference: data.body.reference,
                        IQStep: data.body.iqStep,
                        varsList: data.body.varsList,
                        segment_step_CSS: temp_segment_step_CSS,
                        title_step_CSS: temp_title_step_CSS,
                        fileId: data.body.fileId,
                    });    
                    //console.log("iq step: " + data.body.iqStep);
                    console.log("New varsList count: " + this.state.varsList.length);       
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async getIQData(url){
        const response = await fetch(url + "/IQ/getIQData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.fileId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <br/>
                    <br/>
                    <br/>
                    {/* <Dimmer active={this.state.matterDataDimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer> */}
                    <Label className="Matters-Header-Segment" attached="top"><h3 className="Matters-Header"><Icon className="edit icon big"/>&emsp;Initial Questionnaire</h3></Label>
                    <div>
                        <div className='ui top attached steps IQ-Steps'>
                            <div className={this.state.title_step_CSS[0]}>
                                <i className='user icon IQ-Step-Icon' aria-hidden='true' />
                                <div className='content'>
                                    <div className='title IQ-Step-Title'>Step 1. Client Information</div>
                                    {/* <div className='description'>Sub title</div> */}
                                </div>
                            </div>
                            <div className={this.state.title_step_CSS[1]}>
                                <i className='home icon IQ-Step-Icon' aria-hidden='true' />
                                <div className='content'>
                                    <div className='title IQ-Step-Title'>Step 2. Property Details</div>
                                    {/* <div className='description'>Sub title</div> */}
                                </div>
                            </div>
                            <div className={this.state.title_step_CSS[2]}> 
                                <i className='users icon IQ-Step-Icon' aria-hidden='true'  />
                                <div className='content'>
                                    <div className='title IQ-Step-Title'>Step 3. Occupiers Details</div>
                                    {/* <div className='description'>Sub title</div> */}
                                </div>
                            </div>
                            <div className={this.state.title_step_CSS[3]}>
                                <i className='money bill icon IQ-Step-Icon' aria-hidden='true' />
                                <div className='content'>
                                    <div className='title IQ-Step-Title'>Step 4. Financial Details</div>
                                    {/* <div className='description'>Sub title</div> */}
                                </div>
                            </div>
                            <div className={this.state.title_step_CSS[4]}>
                                <i className='address card icon IQ-Step-Icon' aria-hidden='true' />
                                <div className='content'>
                                    <div className='title IQ-Step-Title'>Step 5. Misc. Details</div>
                                    {/* <div className='description'>Sub title</div> */}
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            {/* Step 1. Client Information */}
                            <Segment className={this.state.segment_step_CSS[0]}>
                                <div>
                                    <IQPage1Clients ref={(child_Step1) => { this._child_Step1 = child_Step1; }} url={this.props.url} forward={this.onClick_step_forward} varsList={this.state.varsList} fileId={this.state.fileId} iqStep={this.state.IQStep}/>
                                </div>
                            </Segment>
                            {/* Step 2. Property Details */}
                            <Segment className={this.state.segment_step_CSS[1]}>
                                <div>
                                    <IQPage2Property ref={(child_Step2) => { this._child_Step2 = child_Step2; }} url={this.props.url} forward={this.onClick_step_forward} back={this.onClick_step_back} varsList={this.state.varsList} fileId={this.state.fileId} reference={this.state.reference}/>
                                </div>
                            </Segment>
                            {/* Step 3. Occupiers Details */}
                            <Segment className={this.state.segment_step_CSS[2]}>
                                <div>
                                    <IQPage3Occupiers ref={(child_Step3) => { this._child_Step3 = child_Step3; }} url={this.props.url} forward={this.onClick_step_forward} back={this.onClick_step_back} varsList={this.state.varsList} fileId={this.state.fileId}/>
                                </div>
                            </Segment>
                            {/* Step 4. Financial Details */}
                            <Segment className={this.state.segment_step_CSS[3]}>
                                <div>
                                    <IQPage4Financial ref={(child_Step4) => { this._child_Step4 = child_Step4; }} url={this.props.url} forward={this.onClick_step_forward} back={this.onClick_step_back} varsList={this.state.varsList} fileId={this.state.fileId}/>
                                </div>
                            </Segment>
                            {/* Step 5. Misc. Details */}
                            <Segment className={this.state.segment_step_CSS[4]}>
                                <div>
                                    <IQPage5Misc ref={(child_Step5) => { this._child_Step5 = child_Step5; }} url={this.props.url} forward={this.onClick_step_forward} back={this.onClick_step_back} varsList={this.state.varsList} fileId={this.state.fileId}/>
                                </div>
                            </Segment>                            
                        </div>
                        {/* <br/>
                        <Button className={this.state.button_back_CSS}>Go Back</Button>
                        <Button className={this.state.button_forward_CSS}>Next Step</Button>
                        <br/>
                        <br/> */}
                    </div>
                </Segment>
            </div>
        );
    }
}

export default IQPage