import React, { Component } from "react";
import { Segment, Label, Button, Modal, Header, Icon, Form, Dropdown, Dimmer, Loader, Grid, Item, Checkbox, Input } from 'semantic-ui-react';
import './AdminPage.css';

class SchedulingSettings extends Component{
    constructor(props){
        super(props);
        this.state = { 
            numDays: -1,
            updateSettingsLoading: "",
            updateSuccessCSS: "IQ-Hidden",
            updateFailureCSS: "IQ-Hidden", //
            dimmerActive: true,
            inputNumDaysCSS: "",
        }        
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.getSettingsData_Wrapper = this.getSettingsData_Wrapper.bind(this);
        this.getSettingsData = this.getSettingsData.bind(this);
        this.handleNumDaysChange = this.handleNumDaysChange.bind(this);
        this.updateSettings_Wrapper = this.updateSettings_Wrapper.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        //
        this.getSettingsData_Wrapper(this.props.url);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////

    getSettingsData_Wrapper(url){
        var result = this.getSettingsData(url);
        var options = [];
        result.then((data) => {
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        numDays: data.body.autoAppt_FutureWindow_Days,
                        dimmerActive: false
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                case 403:
                    this.props.history.push("/matters");
                default:
                    break;
            }
        });
        return options;
    }

    async getSettingsData(url){
        const response = await fetch(url + "/Schedule/GetSchedulingSettings", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }

    handleNumDaysChange(event){
        this.setState({
            numDays: event.target.value
        });
    }
    //
    updateSettings_Wrapper(url){
        this.setState({
            updateSettingsLoading: "loading disabled",
            inputNumDaysCSS: "disabled",
        });
        //
        var result = this.updateSettings(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        updateSuccessCSS: "",
                    });
                    break;
                case 400:
                    this.setState({
                        updateFailureCSS: "",
                    });
                    break;
                default:
                    break;
            } ;
            setTimeout(() => {
                this.setState({
                    updateSettingsLoading: "",
                    inputNumDaysCSS: ""
                });
            }, 200);
        });
        
        
    }
    //
    async updateSettings(url){
        const response = await fetch(url + "/Schedule/UpdateSchedulingSettings", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                numDays: this.state.numDays,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="settings large" />&emsp;Scheduling Settings</h5></Label>
                    <br/><br/>
                    <div className={this.state.formCSS}>
                        <Form>                                
                            <div className="Admin-Div-Name-Region">
                                <Form.Field error={this.state.firstNameError}>
                                <div className="Admin-Div-FirstName-Outer">
                                    <label>Auto allocate Future Window Size (Days)</label>
                                    <div className="Admin-Div-FirstName-Input">
                                        <input className={this.state.inputNumDaysCSS} placeholder="Days" type="text" value={this.state.numDays} onChange={this.valueChange('numDays')}/>
                                    </div>
                                </div>
                                </Form.Field>
                            </div>
                        </Form>
                    </div>
                    <br/>
                    <Button className={"ui button blue " + this.state.updateSettingsLoading} onClick={() => {this.updateSettings_Wrapper(this.props.url);}}>Save</Button>
                    <br/><br/>
                    <div className={this.state.updateSuccessCSS}><Label><Icon className="ui icon checkmark green"></Icon>Save succeeded</Label></div>
                    <div className={this.state.updateFailureCSS}><Label><Icon className="ui icon close red"></Icon>&nbsp;Save failed!</Label></div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </Segment>
            </div>
        );
    }
}

export default SchedulingSettings