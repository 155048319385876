import React, { Component } from 'react';
import './SettingsPage.css';
import { Segment, Label, Icon, Form, Message, Button, Grid } from 'semantic-ui-react';

class SettingsPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: "test@equilaw.uk.com",
            emailValFail: false,
            emailLoading: "",
            emailUpdateSuccess: false,
            pass1: "",
            pass2: "",
            passNoMatch: false,
            passMatch: "",
            passwordLoading: "",
            passSubmitSuccess: false,
            gdprChecked: true,
            gdprRevokeSaveLoading: "",
            //
            email_instructionChecked: false,
            email_firstContactChecked: false,
            email_mortgageChecked: false,
            email_apptBookedChecked: false,
            email_docsSignedChecked: false,
            email_scheduleChecked: false,
            email_compDateSetChecked: false,
            //
            push_instructionChecked: false,
            push_firstContactChecked: false,
            push_mortgageChecked: false,
            push_apptBookedChecked: false,
            push_docsSignedChecked: false,
            push_scheduleChecked: false,
            push_compDateSetChecked: false,
            //
        }
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handlePass1Change = this.handlePass1Change.bind(this);
        this.handlePass2Change = this.handlePass2Change.bind(this);
        this.submitPasswordChange_Wrapper = this.submitPasswordChange_Wrapper.bind(this);
        this.submitPasswordChange = this.submitPasswordChange.bind(this);
        this.handleGDPRChange = this.handleGDPRChange.bind(this);
        this.UpdateGDPRStatus = this.UpdateGDPRStatus.bind(this);
        this.handleGDPRRevokeSave = this.handleGDPRRevokeSave.bind(this);
        this.handleGDPRChange_Dummy = this.handleGDPRChange_Dummy.bind(this);
        this.handleEmailChange_Dummy = this.handleEmailChange_Dummy.bind(this);
        this.handlePushChange_Dummy = this.handlePushChange_Dummy.bind(this);
        //
        this.handleEmail_Change_Instruction = this.handleEmail_Change_Instruction.bind(this);
        this.handleEmail_Change_FirstContact = this.handleEmail_Change_FirstContact.bind(this);
        this.handleEmail_Change_Mortgage = this.handleEmail_Change_Mortgage.bind(this);
        this.handleEmail_Change_ApptBooked = this.handleEmail_Change_ApptBooked.bind(this);
        this.handleEmail_Change_DocsSigned = this.handleEmail_Change_DocsSigned.bind(this);
        this.handleEmail_Change_ScheduleSent = this.handleEmail_Change_ScheduleSent.bind(this);
        this.handleEmail_Change_CompDateSet = this.handleEmail_Change_CompDateSet.bind(this);
        //
        this.handlePush_Change_Instruction = this.handlePush_Change_Instruction.bind(this);
        this.handlePush_Change_FirstContact = this.handlePush_Change_FirstContact.bind(this);
        this.handlePush_Change_Mortgage = this.handlePush_Change_Mortgage.bind(this);
        this.handlePush_Change_ApptBooked = this.handlePush_Change_ApptBooked.bind(this);
        this.handlePush_Change_DocsSigned = this.handlePush_Change_DocsSigned.bind(this);
        this.handlePush_Change_ScheduleSent = this.handlePush_Change_ScheduleSent.bind(this);
        this.handlePush_Change_CompDateSet = this.handlePush_Change_CompDateSet.bind(this);
        //
        this.fetchEmailPrefs_Wrapper = this.fetchEmailPrefs_Wrapper.bind(this);
        this.fetchEmailPrefs = this.fetchEmailPrefs.bind(this);
        this.emailPrefUpdate_Wrapper = this.emailPrefUpdate_Wrapper.bind(this);
        this.emailPrefUpdate = this.emailPrefUpdate.bind(this);
        //
        this.fetchPushPrefs_Wrapper = this.fetchPushPrefs_Wrapper.bind(this);
        this.fetchPushPrefs = this.fetchPushPrefs.bind(this);
        this.pushPrefUpdate_Wrapper = this.pushPrefUpdate_Wrapper.bind(this);
        this.pushPrefUpdate = this.pushPrefUpdate.bind(this);
        //
        this.sleep = this.sleep.bind(this);
        //
        this.fetchEmailPrefs_Wrapper(this.props.url);
        this.fetchPushPrefs_Wrapper(this.props.url);
    }
    //
    handleEmailChange(){
        //TODO
    }
    //
    handleEmailSubmit(event){
        this.setState({
            emailLoading: "loading disabled"
        });
        //
        // DO SOME STUFF HERE!
        //
        setTimeout(() => {
            this.setState({
                emailLoading: "",
                emailUpdateSuccess: true
            });
        }, 1000);
    }
    //
    handlePass1Change(event){
        this.setState({
            pass1: event.target.value
        })
        if(event.target.value !== "" && event.target.value === this.state.pass2) {
            this.setState({
                passNoMatch: false,
                passMatch: "Settings-Input-Success"
            })
        }
        else {
            this.setState({
                passNoMatch: true,
                passMatch: ""
            })
        }
    }
    //
    handlePass2Change(event){
        this.setState({
            pass2: event.target.value
        })
        if(event.target.value !== "" && event.target.value === this.state.pass1) {
            this.setState({
                passNoMatch: false,
                passMatch: "Settings-Input-Success"
            })
        }
        else {
            this.setState({
                passNoMatch: true,
                passMatch: ""
            })
        }
    }
    //
    submitPasswordChange_Wrapper(url){
        var result = this.submitPasswordChange(url)
        result.then((data) => {
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        passSubmitSuccess: true,
                    });    
                    this.props.history.push("/");                    
                    break;
                case 400:
                    this.setState({
                        creationResultModalIcon: "warning circle red",
                        creationResultModalHeader: "Error",
                        creationResultModalText: "User Creation Failed!\n\n" + data.body.Message,
                        creationResultModalOpen: true
                    });     
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }            
        });
        setTimeout(() => {
            this.setState({
                createUserLoading: ""
            });
        }, 200)
    }
    //
    async submitPasswordChange(url){
        this.setState({
            passwordLoading: "loading disabled"
        });
        //
        const response = await fetch(url + "/Account/ResetPassword", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                password: this.state.pass2
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleGDPRChange(event){
        this.setState({
            gdprChecked: !this.state.gdprChecked
        });
    }
    //
    handleGDPRRevokeSave(event){
        this.setState({
            gdprRevokeSaveLoading: "disabled loading",
        });
        this.UpdateGDPRStatus_Wrapper(this.props.url);
    }
    //
    UpdateGDPRStatus_Wrapper(url){
        var result = this.UpdateGDPRStatus(url)
        result.then((data) => {
            switch(data.httpCode){
                case 200:   
                    this.props.history.push("/");                    
                    break;
                case 400:    
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }            
        });
    }
    //
    async UpdateGDPRStatus(url){
        //
        const response = await fetch(url + "/Account/UpdateGDPRStatus", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                GDPRStatus: this.state.gdprChecked
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleGDPRChange_Dummy(){}
    //
    handleEmailChange_Dummy(){}
    //
    handlePushChange_Dummy(){}
    //
    handleEmail_Change_Instruction(){
        this.setState({
            email_instructionChecked: !this.state.email_instructionChecked
        });
    }
    handleEmail_Change_FirstContact(){
        this.setState({
            email_firstContactChecked: !this.state.email_firstContactChecked
        });
    }
    handleEmail_Change_Mortgage(){
        this.setState({
            email_mortgageChecked: !this.state.email_mortgageChecked
        });
    }
    handleEmail_Change_ApptBooked(){
        this.setState({
            email_apptBookedChecked: !this.state.email_apptBookedChecked
        });
    }
    handleEmail_Change_DocsSigned(){
        this.setState({
            email_docsSignedChecked: !this.state.email_docsSignedChecked
        });
    }
    handleEmail_Change_ScheduleSent(){
        this.setState({
            email_scheduleChecked: !this.state.email_scheduleChecked
        });
    }
    handleEmail_Change_CompDateSet(){
        this.setState({
            email_compDateSetChecked: !this.state.email_compDateSetChecked
        });
    }
    //
    fetchEmailPrefs_Wrapper(url){
        var result = this.fetchEmailPrefs(url)
        result.then((data) => {
            switch(data.httpCode){
                case 200:   
                    for(let i = 0; i < data.body.length; i++){
                        switch(i){
                            case 0:
                                this.setState({
                                    email_instructionChecked: data.body[i],
                                });
                                break;
                            case 1:
                                this.setState({
                                    email_firstContactChecked: data.body[i],
                                });
                                break;
                            case 2:
                                this.setState({
                                    email_mortgageChecked: data.body[i],
                                });
                                break;
                            case 3:
                                this.setState({
                                    email_apptBookedChecked: data.body[i],
                                });
                                break;
                            case 4:
                                this.setState({
                                    email_docsSignedChecked: data.body[i],
                                });
                                break;
                            case 5:
                                this.setState({
                                    email_scheduleChecked: data.body[i],
                                });
                                break;
                            case 6:
                                this.setState({
                                    email_compDateSetChecked: data.body[i],
                                });
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }            
        });
    }
    //
    async fetchEmailPrefs(url){
        const response = await fetch(url + "/Account/FetchEmailPrefs", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    emailPrefUpdate_Wrapper(){
        this.setState({
            updateEmailPrefsLoading: "disabled loading",
        });
        var emailPrefList = [];
        //
        if(this.state.email_instructionChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_firstContactChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_mortgageChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_apptBookedChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_docsSignedChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_scheduleChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        if(this.state.email_compDateSetChecked) emailPrefList.push(true);
        else emailPrefList.push(false);
        //
        var result = this.emailPrefUpdate(this.props.url, emailPrefList)
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    window.location.reload();                   
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }            
        });
    }
    async emailPrefUpdate(url, emailPrefList){        
        const response = await fetch(url + "/Account/UpdateEmailPrefs", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                prefList: emailPrefList,
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handlePush_Change_Instruction(){
        this.setState({
            push_instructionChecked: !this.state.push_instructionChecked
        });
    }
    handlePush_Change_FirstContact(){
        this.setState({
            push_firstContactChecked: !this.state.push_firstContactChecked
        });
    }
    handlePush_Change_Mortgage(){
        this.setState({
            push_mortgageChecked: !this.state.push_mortgageChecked
        });
    }
    handlePush_Change_ApptBooked(){
        this.setState({
            push_apptBookedChecked: !this.state.push_apptBookedChecked
        });
    }
    handlePush_Change_DocsSigned(){
        this.setState({
            push_docsSignedChecked: !this.state.push_docsSignedChecked
        });
    }
    handlePush_Change_ScheduleSent(){
        this.setState({
            push_scheduleChecked: !this.state.push_scheduleChecked
        });
    }
    handlePush_Change_CompDateSet(){
        this.setState({
            push_compDateSetChecked: !this.state.push_compDateSetChecked
        });
    }
    //
    fetchPushPrefs_Wrapper(url){
        var result = this.fetchPushPrefs(url)
        result.then((data) => {
            switch(data.httpCode){
                case 200:   
                    for(let i = 0; i < data.body.length; i++){
                        switch(i){
                            case 0:
                                this.setState({
                                    push_instructionChecked: data.body[i],
                                });
                                break;
                            case 1:
                                this.setState({
                                    push_firstContactChecked: data.body[i],
                                });
                                break;
                            case 2:
                                this.setState({
                                    push_mortgageChecked: data.body[i],
                                });
                                break;
                            case 3:
                                this.setState({
                                    push_apptBookedChecked: data.body[i],
                                });
                                break;
                            case 4:
                                this.setState({
                                    push_docsSignedChecked: data.body[i],
                                });
                                break;
                            case 5:
                                this.setState({
                                    push_scheduleChecked: data.body[i],
                                });
                                break;
                            case 6:
                                this.setState({
                                    push_compDateSetChecked: data.body[i],
                                });
                                break;
                            default:
                                break;
                        }
                    }
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }            
        });
    }
    //
    async fetchPushPrefs(url){
        const response = await fetch(url + "/Account/FetchPushPrefs", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    pushPrefUpdate_Wrapper(){
        this.setState({
            updatePushPrefsLoading: "disabled loading",
        });
        var pushPrefList = [];
        //
        if(this.state.push_instructionChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_firstContactChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_mortgageChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_apptBookedChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_docsSignedChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_scheduleChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        if(this.state.push_compDateSetChecked) pushPrefList.push(true);
        else pushPrefList.push(false);
        //
        var result = this.pushPrefUpdate(this.props.url, pushPrefList)
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    window.location.reload();                   
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    async pushPrefUpdate(url, pushPrefList){        
        const response = await fetch(url + "/Account/UpdatePushPrefs", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                prefList: pushPrefList,
            }),            
        })
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    sleep(ms){
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    //
    render(){
        return(
            <div>
                <div className="Settings-Segment-Wrapper">
                    <Segment className="Settings-Style">
                        <Label className="Settings-Header-Segment" attached="top"><h3 className="Settings-Header"><Icon className="settings big"/>&emsp;User Settings</h3></Label>
                        <br/><br/><br/>
                        {/* <Form error={this.state.emailValFail} success={this.state.emailUpdateSuccess}>
                            <Form.Field>
                                <label>Change your email</label>
                                <div className="Settings-Div-Email">
                                    <input className="Settings-Input-Email" placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} />&emsp;
                                    <button className={"ui button blue " + this.state.emailLoading} onClick={this.handleEmailSubmit}>Update</button>
                                </div>
                            </Form.Field>
                            <Message
                                error
                                header='Error - Email validation failure!'
                                content='The provided email does not look like a valid email. Please re-check the entered address and try again.'
                            />
                            <Message
                                success
                                header='Success'
                                content="Email successfully changed!"
                            />
                        </Form>
                        <br/> */}
                        <Segment>
                            <Label attached="top"><h5 className="Settings-Header"><Icon className="key large"/>&emsp;Change password</h5></Label>
                            <br/><br/><br/>
                            <Form error={this.state.passNoMatch} success={this.state.passSubmitSuccess}>
                                <Form.Field success={this.state.passMatch}>
                                    <label>New Password</label>
                                    <div className="Settings-Div-Password">
                                        <input  className={this.state.passMatch} placeholder="New Password" type="password" value={this.state.pass1} onChange={this.handlePass1Change} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <label>Confirm Password</label>
                                    <div className="Settings-Div-Password">
                                        <input  className={this.state.passMatch} placeholder="Confirm Password" type="password" value={this.state.pass2} onChange={this.handlePass2Change} />&emsp;
                                        <button className={"ui button blue " + this.state.passwordLoading} onClick={() => this.submitPasswordChange_Wrapper(this.props.url)}>Update</button>
                                    </div>                                    
                                </Form.Field>
                                <Message
                                        error
                                        header='Error'
                                        content='Password Mis-match!'
                                    />
                                <Message
                                    success
                                    header='Success'
                                    content="Password changed!"
                                />
                            </Form>                                                        
                        </Segment>
                        <Segment>
                            <Label attached="top"><h5 className="Settings-Header"><Icon className="exclamation large"/>&emsp;Revoke GDPR consent</h5></Label>
                            <br/><br/><br/>
                            <Form>
                                <Form.Field>
                                <div className="Admin-Div-UserIsStaff">
                                    <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Un-tick the box to revoke your GDPR Consent"/>&emsp;
                                    <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.gdprChecked} onClick={this.handleGDPRChange} onChange={this.handleGDPRChange_Dummy} />
                                </div>
                                </Form.Field>
                                <br/>
                                <Button color="blue" className={this.state.gdprRevokeSaveLoading} content="Save" onClick={this.handleGDPRRevokeSave} />
                            </Form>
                        </Segment>
                        <Segment>
                            <Label attached="top"><h5 className="Settings-Header"><Icon className="mail large"/>&emsp;Milestone Email Preferences</h5></Label>
                            <br/>
                            <h3>Email:</h3>
                            <br/>
                            <Grid columns={4} className="Settings-Grid">
                                <Grid.Row>
                                <Grid.Column>
                                    {/* <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Instruction"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_instructionChecked} onClick={this.handleEmail_Change_Instruction} onChange={this.handleEmailChange_Dummy} />
                                    </div> */}
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="First Client Contact"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_firstContactChecked} onClick={this.handleEmail_Change_FirstContact} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Mortgage Offer"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_mortgageChecked} onClick={this.handleEmail_Change_Mortgage} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>       
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Appointment Booked"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_apptBookedChecked} onClick={this.handleEmail_Change_ApptBooked} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>                                                       
                                <Grid.Column></Grid.Column>
                                </Grid.Row>

                                <Grid.Row>                                
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Signed Docs Received"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_docsSignedChecked} onClick={this.handleEmail_Change_DocsSigned} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Schedule Sent"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_scheduleChecked} onClick={this.handleEmail_Change_ScheduleSent} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Completion Date Set"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.email_compDateSetChecked} onClick={this.handleEmail_Change_CompDateSet} onChange={this.handleEmailChange_Dummy} />
                                    </div>
                                </Grid.Column>                                
                                <Grid.Column></Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br/><br/>
                            <button className={"ui button blue " + this.state.updateEmailPrefsLoading} onClick={this.emailPrefUpdate_Wrapper}>Update</button>                                                     
                        </Segment>
                        <Segment>
                            <Label attached="top"><h5 className="Settings-Header"><Icon className="mobile alternate large"/>&emsp;Milestone Push Notification (Mobile App) Preferences</h5></Label>
                            <br/>
                            <h3>Push Notifications:</h3>
                            <br/>
                            <Grid columns={4} className="Settings-Grid">
                                <Grid.Row>
                                <Grid.Column>
                                    {/* <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Instruction"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_instructionChecked} onClick={this.handlePush_Change_Instruction} onChange={this.handlePushChange_Dummy} />
                                    </div> */}
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="First Client Contact"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_firstContactChecked} onClick={this.handlePush_Change_FirstContact} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Mortgage Offer"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_mortgageChecked} onClick={this.handlePush_Change_Mortgage} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Appointment Booked"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_apptBookedChecked} onClick={this.handlePush_Change_ApptBooked} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>                                
                                <Grid.Column></Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Signed Docs Received"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_docsSignedChecked} onClick={this.handlePush_Change_DocsSigned} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Schedule Sent"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_scheduleChecked} onClick={this.handlePush_Change_ScheduleSent} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div className="Admin-Div-UserIsStaff">
                                        <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="Completion Date Set"/>&emsp;
                                        <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" checked={this.state.push_compDateSetChecked} onClick={this.handlePush_Change_CompDateSet} onChange={this.handlePushChange_Dummy} />
                                    </div>
                                </Grid.Column>                                
                                <Grid.Column></Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br/><br/>
                            <button className={"ui button blue " + this.state.updatePushPrefsLoading} onClick={this.pushPrefUpdate_Wrapper}>Update</button>                                                     
                        </Segment>
                    </Segment>
                </div>
            </div>
        );
    }
}

export default SettingsPage;