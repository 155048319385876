import React, { Component } from 'react';
import './IQPage.css';
import { Input, Segment, Label, Button, Icon, Form, Dropdown, Modal, Header, Dimmer, Loader, Grid, Progress } from 'semantic-ui-react';
import moment from 'moment';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css'
import axios from 'axios';

class IQPage2Property extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment(),
            reference: "TEST001/1",
            fileId: "",
            varsList: this.props.varsList,
            dimmerActionText: "Loading...",
            propertyDimmerActive: false,
            button_back_CSS: "red IQ-backButton",
            button_forward_CSS: "green IQ-forwardButton",
            messageModalOpen: false,
            messageModalIcon_CSS: "warning circle icon",
            messageModalTitle: "",
            messageModalContent: "",
            uploaded: 0,
            uploadModalOpen: false,
            
            //////////////////////////////
            // Step 2. Property Details //
            //////////////////////////////
            input_Address_1: "",
            input_Address_2: "",
            input_Town: "",
            input_County: "",
            input_Postcode: "",
            
            button_property_changeOfOwner_Checked_YES: false,
            button_property_changeOfOwner_Checked_NO: false,
            button_property_changeOfOwner_YES_CSS: "ui",
            button_property_changeOfOwner_NO_CSS: "ui",
            segment_property_changeOfOwner_CSS: "IQ-Hidden",            
            button_property_changeOfOwner_marketValue_Checked_YES: false,
            button_property_changeOfOwner_marketValue_Checked_NO: false,
            button_property_changeOfOwner_marketValue_YES_CSS: "ui",
            button_property_changeOfOwner_marketValue_NO_CSS: "ui",

            button_property_additionalLand_Checked_YES: false,
            button_property_additionalLand_Checked_NO: false,            
            button_property_additionalLand_YES_CSS: "ui",
            button_property_additionalLand_NO_CSS: "ui",
            segment_property_additionalLand_CSS: "IQ-Hidden",
            input_property_additionalLand_titleNumber_value: "",

            button_property_notices_Checked_YES: false,
            button_property_notices_Checked_NO: false,
            button_property_notices_YES_CSS: "ui",
            button_property_notices_NO_CSS: "ui,",

            button_property_business_Checked_YES: false,
            button_property_business_Checked_NO: false,
            button_property_business_YES_CSS: "ui",
            button_property_business_NO_CSS: "ui,",
            segment_property_business_CSS: "IQ-Hidden",
            input_property_business_details_value: "",
            
            button_property_buyToLet_Checked_YES: false,
            button_property_buyToLet_Checked_NO: false,
            button_property_buyToLet_YES_CSS: "ui",
            button_property_buyToLet_NO_CSS: "ui",
            segment_property_buyToLet_CSS: "IQ-Hidden",
            button_property_buyToLet_tenancy_upload_CSS: "ui blue",
            file_property_buyToLet_tenancy: "",
            file_property_buyToLet_tenancy_data: "",
            button_property_buyToLet_rightToRent_upload_CSS: "ui blue",
            file_property_buyToLet_rightToRent: "",
            file_property_buyToLet_rightToRent_data: "",
            button_property_buyToLet_gas_upload_CSS: "ui blue",
            file_property_buyToLet_gas: "",
            file_property_buyToLet_gas_data: "",
            button_property_buyToLet_epc_upload_CSS: "ui blue",
            file_property_buyToLet_epc: "",
            file_property_buyToLet_epc_data: "",

            input_property_septic_value: "",

            button_property_2ndHome_Checked_YES: false,
            button_property_2ndHome_Checked_NO: false,
            button_property_2ndHome_YES_CSS: "ui",
            button_property_2ndHome_NO_CSS: "ui",

            button_property_mortgage_Checked_YES: false,
            button_property_mortgage_Checked_NO: false,
            button_property_mortgage_YES_CSS: "ui",
            button_property_mortgage_NO_CSS: "ui",
            segment_property_mortgage_CSS: "IQ-Hidden",
            input_property_mortgage_lender_value: "",
            input_property_mortgage_amount_value: "",
            input_property_mortgage_accountNumber_value: "",

            button_property_solar_have_Checked_YES: false,
            button_property_solar_have_Checked_NO: false,
            button_property_solar_have_YES_CSS: "ui",
            button_property_solar_have_NO_CSS: "ui",
            button_property_solar_own_Checked_YES: false,
            button_property_solar_own_Checked_NO: false,
            button_property_solar_own_YES_CSS: "ui",
            button_property_solar_own_NO_CSS: "ui",
            segment_property_solar_have_CSS: "IQ-Hidden",
            button_property_solar_ASG_Checked_YES: false,
            button_property_solar_ASG_Checked_NO: false,
            button_property_solar_ASG_YES_CSS: "ui",
            button_property_solar_ASG_NO_CSS: "ui",
            segment_property_solar_ownership_CSS: "IQ-Hidden",
            segment_property_solar_ownership_outright_CSS: "IQ-Hidden",
            segment_property_solar_ownership_lease_CSS: "IQ-Hidden",
            button_property_solar_receipt_upload_CSS: "ui blue",
            file_property_solar_receipt: "",
            file_property_solar_receipt_data: "",
            button_property_solar_lease_upload_CSS: "ui blue",
            file_property_solar_lease: "",
            file_property_solar_lease_data: "",
            button_property_solar_mcs_upload_CSS: "ui blue",
            file_property_solar_mcs: "",
            file_property_solar_mcs_data: "",

            button_property_restrictions_Checked_YES: false,
            button_property_restrictions_Checked_NO: false,
            button_property_restrictions_YES_CSS: "ui",
            button_property_restrictions_NO_CSS: "ui",
            restrictionsModalOpen: false,
            segment_property_restrictions_CSS: "IQ-Hidden",
            input_property_restrictions_name_value: "",
            input_property_restrictions_details_value: "",

            input_property_restrictions_lender1_value: "",
            input_property_restrictions_lender1_accnum_value: "",
            input_property_restrictions_lender1_amount_value: "",
            input_property_restrictions_lender2_value: "",
            input_property_restrictions_lender2_accnum_value: "",
            input_property_restrictions_lender2_amount_value: "",
            input_property_restrictions_lender3_value: "",
            input_property_restrictions_lender3_accnum_value: "",
            input_property_restrictions_lender3_amount_value: "",
            input_property_restrictions_lender4_value: "",
            input_property_restrictions_lender4_accnum_value: "",
            input_property_restrictions_lender4_amount_value: "",
            input_property_restrictions_lender5_value: "",
            input_property_restrictions_lender5_accnum_value: "",
            input_property_restrictions_lender5_amount_value: "",
            input_property_restrictions_lender6_value: "",
            input_property_restrictions_lender6_accnum_value: "",
            input_property_restrictions_lender6_amount_value: "",
            
            button_property_registered_modal_moreLenders_Checked_YES: false,
            button_property_registered_modal_moreLenders_Checked_NO: false,
            segment_property_restrictions_modal_moreLenders_CSS: "IQ-Hidden",
            button_property_restrictions_modal_moreLenders_YES_CSS: "ui",
            button_property_restrictions_modal_moreLenders_NO_CSS: "ui",

            button_property_insurance_Checked_YES: false,
            button_property_insurance_Checked_NO: false,
            button_property_insurance_YES_CSS: "ui",
            button_property_insurance_NO_CSS: "ui",
            segment_property_insurance_CSS: "IQ-Hidden",
            input_property_insurance_company_value: "",
            input_property_insurance_expiryDate_value: moment(),
            input_property_insurance_coveramount_value: "",
            button_property_insurance_cert_upload_CSS: "ui blue",
            file_property_insurance_cert: "",
            file_property_insurance_cert_data: "",

            button_property_registered_Checked_YES: false,
            button_property_registered_Checked_NO: false,
            button_property_registered_YES_CSS: "ui",
            button_property_registered_NO_CSS: "ui",
            segment_property_registered_CSS: "IQ-Hidden",
            button_property_registered_deeds_Checked_YES: false,
            button_property_registered_deeds_Checked_NO: false,
            button_property_registered_deeds_YES_CSS: "ui",
            button_property_registered_deeds_NO_CSS: "ui",
            segment_property_registered_deeds_CSS: "IQ-Hidden",
            segment_property_registered_deeds_note_CSS: "IQ-Hidden",
            button_property_registered_mortgage_Checked_YES: false,
            button_property_registered_mortgage_Checked_NO: false,
            button_property_registered_mortgage_YES_CSS: "ui",
            button_property_registered_mortgage_NO_CSS: "ui",
            segment_property_registered_mortgage_CSS: "IQ-Hidden",
            segment_property_registered_thirdparty_CSS: "IQ-Hidden",
            input_property_registered_mortgage_lender_value: "",
            input_property_registered_mortgage_lender_ref: "",
            input_property_registered_deeds_value: "",
            input_property_registered_3pnumber_value: "",

            input_property_tenure_value: "",
            segment_property_tenure_CSS: "IQ-Hidden",
            input_property_tenure_agents_value: "",
            input_property_tenure_company_value: "",
            button_property_tenure_share_Checked_YES: false,
            button_property_tenure_share_Checked_NO: false,
            button_property_tenure_share_YES_CSS: "ui",
            button_property_tenure_share_NO_CSS: "ui",
            segment_property_tenure_share_CSS: "IQ-Hidden",
            button_property_tenure_share_upload_CSS: "ui blue",
            file_property_tenure_share: "",
            file_property_tenure_share_data: "",

            button_property_tenure_groundrent_Checked_YES: false,
            button_property_tenure_groundrent_Checked_NO: false,
            button_property_tenure_groundrent_YES_CSS: "ui",
            button_property_tenure_groundrent_NO_CSS: "ui",
            segment_property_tenure_groundrent_CSS: "IQ-Hidden",
            input_property_tenure_groundrent_value: "",
            input_property_tenure_groundrent_yearly_value: "",
            input_property_tenure_groundrent_landlord_value: "",
            input_property_tenure_lease_years: "",
            input_property_tenure_estate_value: "",
        }
        this.septicOptions = [
            {
                text: 'Mains Drains',
                value: 'mains',
            },
            {
                text: 'Septic Tank',
                value: 'septic',
            }
        ];
        this.tenureOptions = [            
            {
                text: 'Freehold',
                value: 'freehold',
            },
            {
                text: 'Leasehold',
                value: 'leasehold',
            },
            {
                text: 'Unregistered',
                value: 'unregistered',
            },
        ];
        this.estateOptions = [
            {
                text: "Registered Freehold",
                value: "Registered Freehold",
            },
            {
                text: "UnRegistered Freehold",
                value: "UnRegistered Freehold",
            },
            {
                text: "Registered Leasehold",
                value: "Registered Leasehold",
            },
            {
                text: "UnRegistered Leasehold",
                value: "UnRegistered Leasehold",
            },
            {
                text: "New Lease",
                value: "New Lease",
            },
        ];
        //////////////////////////////
        // Step 2. Property Details //
        //////////////////////////////
        //
        this.valueChange = this.valueChange.bind(this);
        this.buttonChange_YES = this.buttonChange_YES.bind(this);
        this.buttonChange_NO = this.buttonChange_NO.bind(this);
        ///////////////////////////////////////////////////////
        //
        this.populateVarsData = this.populateVarsData.bind(this);
        this.upload_File = this.upload_File.bind(this);
        //
        this.onClick_property_buyToLet_tenancy_upload = this.onClick_property_buyToLet_tenancy_upload.bind(this);
        this.onChange_property_buyToLet_tenancy_file = this.onChange_property_buyToLet_tenancy_file.bind(this);
        this.onClick_property_buyToLet_rightToRent_upload = this.onClick_property_buyToLet_rightToRent_upload.bind(this);
        this.onChange_property_buyToLet_rightToRent_file = this.onChange_property_buyToLet_rightToRent_file.bind(this);
        this.onClick_property_buyToLet_gas_upload = this.onClick_property_buyToLet_gas_upload.bind(this);
        this.onChange_property_buyToLet_gas_file = this.onChange_property_buyToLet_gas_file.bind(this);
        //
        this.onChange_property_septic = this.onChange_property_septic.bind(this);
        //
        this.onClick_property_SolarOwn_YES = this.onClick_property_SolarOwn_YES.bind(this);
        this.onClick_property_SolarOwn_NO = this.onClick_property_SolarOwn_NO.bind(this);
        this.onClick_property_solar_mcs_upload = this.onClick_property_solar_mcs_upload.bind(this);
        this.onChange_property_solar_mcs_file = this.onChange_property_solar_mcs_file.bind(this);
        this.onClick_property_solar_receipt_upload = this.onClick_property_solar_receipt_upload.bind(this);
        this.onChange_property_solar_receipt_file = this.onChange_property_solar_receipt_file.bind(this);
        this.onClick_property_solar_lease_upload = this.onClick_property_solar_lease_upload.bind(this);
        this.onChange_property_solar_lease_file = this.onChange_property_solar_lease_file.bind(this);
        //
        this.onClick_property_restrictions_YES = this.onClick_property_restrictions_YES.bind(this);
        this.onClick_button_modal_lender_details = this.onClick_button_modal_lender_details.bind(this);
        //
        this.onChange_property_insurance_expiryDate = this.onChange_property_insurance_expiryDate.bind(this);
        this.onClick_property_insurance_cert_upload = this.onClick_property_insurance_cert_upload.bind(this);
        this.onChange_property_insurance_cert_file = this.onChange_property_insurance_cert_file.bind(this);
        this.onClick_property_registered_deeds_YES = this.onClick_property_registered_deeds_YES.bind(this);
        this.onClick_property_registered_deeds_NO = this.onClick_property_registered_deeds_NO.bind(this);
        this.onClick_property_registered_mortgage_YES = this.onClick_property_registered_mortgage_YES.bind(this);
        this.onClick_property_registered_mortgage_NO = this.onClick_property_registered_mortgage_NO.bind(this);
        //
        this.onChange_property_tenure = this.onChange_property_tenure.bind(this);
        this.onChange_property_tenure_share_file = this.onChange_property_tenure_share_file.bind(this);
        //
        this.onChange_property_estate = this.onChange_property_estate.bind(this);
        //
        this.saveData_Wrapper_Forward = this.saveData_Wrapper_Forward.bind(this);
        this.saveData_Wrapper_Back = this.saveData_Wrapper_Back.bind(this);
        //
        //
        setTimeout(this.populateVarsData, 150);
    }

    //////////////////////////////
    // Step 2. Property Details //
    //////////////////////////////
    //
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    buttonChange_YES(key, hide, style) {
        this.setState({
            ['button_' + key + '_Checked_YES']: true,
            ['button_' + key + '_Checked_NO']: false,
            ['button_' + key + '_YES_CSS']: "ui green",
            ['button_' + key + '_NO_CSS']: "ui",
        });
        if(hide != null){
            this.setState({
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),
            });
        }
    };
    /////////////////////////////////////////////////////////////////
    buttonChange_NO = (key, hide, style) => {
        this.setState({
            ['button_' + key + '_Checked_YES']: false,
            ['button_' + key + '_Checked_NO']: true,
            ['button_' + key + '_YES_CSS']: "ui",
            ['button_' + key + '_NO_CSS']: "ui red",
        });
        if(hide != null){
            this.setState({
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),
            });
        }
    };
    /////////////////////////////////////////////////////////////////
    //
    componentDidUpdate(prevProps) {
        if (this.props.varsList !== prevProps.varsList) {
            this.setState({
                //propertyDimmerActive: true,
            })
            this.populateVarsData();
        }
      }
    //
    populateVarsData(){
        var varList = this.props.varsList;
        for(var i = 0; i < varList.length; i++){
            var ovCode = varList[i].m_Item1;
            var value = varList[i].m_Item2;
            if(value != null){
            switch(ovCode){
                case "68":
                    this.setState({
                        input_Address_1: value,
                    });
                break;
                case "69":
                    this.setState({
                        input_Address_2: value,
                    });
                break;
                case "70":
                    this.setState({
                        input_Town: value,
                    });
                break;
                case "3649":
                    this.setState({
                        input_County: value,
                    });
                break;
                case "71":
                    this.setState({
                        input_Postcode: value,
                    });
                break;
                //
                case "4073":
                    if(value === "Yes") this.buttonChange_YES("property_changeOfOwner", false, null);                    
                    else this.buttonChange_NO('property_changeOfOwner', true, null);
                break;
                case "4074":
                    if(value === "Yes") this.buttonChange_YES("property_changeOfOwner_marketValue", null, null);                    
                    else this.buttonChange_NO('property_changeOfOwner_marketValue', null, null);
                break;
                case "4076":
                    if(value === "Yes") this.buttonChange_YES("property_additionalLand", false, null);                    
                    else this.buttonChange_NO('property_additionalLand', true, null);
                break;
                case "3941":
                    this.setState({
                        input_property_additionalLand_titleNumber_value: value,
                    });
                break;
                //
                case "4079":
                    if(value === "Yes") this.buttonChange_YES("property_notices", null, null);
                    else this.buttonChange_NO('property_notices', null, null);
                break;
                //
                case "4075":
                    if(value === "Yes") this.buttonChange_YES("property_business", false, null);                    
                    else this.buttonChange_NO('property_business', true, null);
                break;
                case "4078":
                    this.setState({
                        input_property_business_details_value: value,
                    });
                break;
                //
                case "4202":
                    if(value === "Yes") this.buttonChange_YES("property_buyToLet", false, null);
                    else this.buttonChange_NO('property_buyToLet', true, null);
                break;
                //
                case "4741":
                    this.setState({
                        input_property_septic_value: value,
                    });
                break;
                //
                case "4642":
                if(value === "Yes") this.buttonChange_YES("property_2ndHome", null, null);
                else this.buttonChange_NO('property_2ndHome', null, null);
                break;
                //
                case "4083":
                if(value === "Yes") this.buttonChange_YES("property_mortgage", false, null);
                else this.buttonChange_NO('property_mortgage', true, null);
                break;
                //
                case "1841":
                    this.setState({
                        input_property_mortgage_accountNumber_value: value,
                        input_property_restrictions_lender1_accnum: value,
                    });
                break;
                //
                case "1815":
                    this.setState({
                        input_property_mortgage_amount_value: value,
                        input_property_restrictions_lender1_amount: value,
                    });
                break;
                //
                case "3504":
                    if(value === "Yes") this.buttonChange_YES("property_solar_have", false, null);
                    else this.buttonChange_NO('property_solar_have', true, null);
                break;
                case "3507":
                    if(value === "Yes") this.buttonChange_YES("property_solar_ASG", null, null);
                    else this.buttonChange_NO('property_solar_ASG', null, null);
                break;
                case "3505":
                    if(value === "Yes") this.onClick_property_SolarOwn_YES();
                    else this.onClick_property_SolarOwn_NO();
                break;
                //
                case "4599":
                    if(value === "Yes") this.buttonChange_YES("property_floodDamage", null, null);
                    else this.buttonChange_NO('property_floodDamage', null, null);
                break;
                //
                case "1608":
                    if(value === "Yes") {
                        //this.onClick_property_restrictions_YES();
                        this.setState({
                            button_property_restrictions_Checked_YES: true,
                            button_property_restrictions_Checked_NO: false,
                            button_property_restrictions_YES_CSS: "ui green",
                            button_property_restrictions_NO_CSS: "ui",
                            segment_property_restrictions_CSS: "IQ-Segment",
                            //restrictionsModalOpen: true,
                        });
                    }
                    else this.buttonChange_NO('property_restrictions', true, null);
                break;
                case "3049":
                    this.setState({
                        input_property_restrictions_name: value,
                    });
                break;
                case "4094":
                    this.setState({
                        input_property_restrictions_details: value,
                    });
                break;
                //
                case "1814":
                    this.setState({
                        input_property_restrictions_lender1: value,
                        input_property_mortgage_lender_value: value,
                    });
                break;
                //
                case "1816":
                    this.setState({
                        input_property_restrictions_lender2: value,
                    });
                break;
                case "1842":
                    this.setState({
                        input_property_restrictions_lender2_accnum: value,
                    });
                break;
                case "1817":
                    this.setState({
                        input_property_restrictions_lender2_amount: value,
                    });
                break;
                //
                case "1818":
                    this.setState({
                        input_property_restrictions_lender3: value,
                    });
                break;
                case "1843":
                    this.setState({
                        input_property_restrictions_lender3_accnum: value,
                    });
                break;
                case "1819":
                    this.setState({
                        input_property_restrictions_lender3_amount: value,
                    });
                break;
                //
                case "1820":
                    this.setState({
                        input_property_restrictions_lender4: value,
                    });
                break;
                case "1844":
                    this.setState({
                        input_property_restrictions_lender4_accnum: value,
                    });
                break;
                case "1821":
                    this.setState({
                        input_property_restrictions_lender4_amount: value,
                    });
                break;
                //
                case "838":
                    this.setState({
                        input_property_restrictions_lender5: value,
                    });
                break;
                case "840":
                    this.setState({
                        input_property_restrictions_lender5_accnum: value,
                    });
                break;
                case "842":
                    this.setState({
                        input_property_restrictions_lender5_amount: value,
                    });
                break;
                //
                case "839":
                    this.setState({
                        input_property_restrictions_lender6: value,
                    });
                break;
                case "841":
                    this.setState({
                        input_property_restrictions_lender6_accnum: value,
                    });
                break;
                case "843":
                    this.setState({
                        input_property_restrictions_lender6_amount: value,
                    });
                break;
                //
                case "4531":
                    this.setState({
                        input_property_insurance_company: value,
                    });
                break;
                case "2401":
                    var d1 = moment(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_property_insurance_expiryDate_value: d1,
                    });
                break;
                case "2619":
                    this.setState({
                        input_property_insurance_coveramount: value,
                    });
                break;
                case "3058":
                    this.setState({
                        input_property_registered_deeds_value: value,
                    });
                break;
                case "4072":
                    this.setState({
                        input_property_registered_3pnumber_value: value,
                    });
                break;
                //
                case "1241":
                    this.setState({
                        input_property_tenure_value: value,
                    });
                break;
                case "600":
                    var v = value.split("-");
                    if(v.length > 1){
                        this.setState({
                            input_property_tenure_company_value: value[1],
                        });
                    }
                    else {
                        this.setState({
                            input_property_tenure_agents_value: value[0],
                        });
                    }                    
                break;
                case "599":
                    this.setState({
                        input_property_tenure_company_value: value,
                    });
                break;
                case "6":
                    if(value === "Yes") this.buttonChange_YES("property_tenure_share", false, null);
                    else this.buttonChange_NO('property_tenure_share', true, null);
                break;
                //
                case "4307":
                    if(value === "Yes") this.buttonChange_YES("property_tenure_groundrent", false, null);
                    else this.buttonChange_NO('property_tenure_groundrent', true, null);
                break;
                case "2002":
                    this.setState({
                        input_property_tenure_groundrent_value: value,
                    });
                break;
                case "18":
                    this.setState({
                        input_property_tenure_groundrent_yearly_value: value,
                    });
                break;
                case "1603":
                    this.setState({
                        input_property_tenure_groundrent_landlord_value: value,
                    });
                break;
                case "4396":
                    this.setState({
                        input_property_tenure_lease_years: value,
                    });
                break;
                case "1248":
                    this.setState({
                        input_property_tenure_estate_value: value,
                    });
                break;
                //
                //
                default:
                    break;
                }
            }
        }
        setTimeout(() => {
            this.setState({
                propertyDimmerActive: false,
            })
        }, 300);        
    }
    //    
    //
    onClick_property_SolarOwn_YES(){
        this.setState({
            button_property_solar_own_Checked_YES: true,
            button_property_solar_own_Checked_NO: false,
            button_property_solar_own_YES_CSS: "ui green",
            button_property_solar_own_NO_CSS: "ui",
            segment_property_solar_ownership_CSS: "IQ-Segment",
            segment_property_solar_ownership_outright_CSS: "",
            segment_property_solar_ownership_lease_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_property_SolarOwn_NO(){
        console.log("clicking the NO solar button...");
        this.setState({
            button_property_solar_own_Checked_YES: false,
            button_property_solar_own_Checked_NO: true,
            button_property_solar_own_YES_CSS: "ui",
            button_property_solar_own_NO_CSS: "ui red",
            segment_property_solar_ownership_CSS: "",
            segment_property_solar_ownership_outright_CSS: "IQ-Hidden",
            segment_property_solar_ownership_lease_CSS: "",
        });
    }
    //
    onChange_property_buyToLet_tenancy_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_buyToLet_tenancy: file,
                file_property_buyToLet_tenancy_data: reader.result,
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    onChange_property_buyToLet_rightToRent_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_buyToLet_rightToRent: file,
                file_property_buyToLet_rightToRent_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    onChange_property_buyToLet_gas_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_buyToLet_gas: file,
                file_property_buyToLet_gas_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    onChange_property_buyToLet_epc_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_buyToLet_epc: file,
                file_property_buyToLet_epc_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    onChange_property_solar_receipt_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_solar_receipt: file,
                file_property_solar_receipt_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    onChange_property_solar_lease_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_solar_lease: file,
                file_property_solar_lease_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    onChange_property_solar_mcs_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_solar_mcs: file,
                file_property_solar_mcs_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    onChange_property_insurance_cert_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_insurance_cert: file,
                file_property_insurance_cert_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    //
    onChange_property_tenure_share_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_property_tenure_share: file,
                file_property_tenure_share_data: reader.result,                
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    onClick_property_restrictions_YES(){
        this.setState({
            button_property_restrictions_Checked_YES: true,
            button_property_restrictions_Checked_NO: false,
            button_property_restrictions_YES_CSS: "ui green",
            button_property_restrictions_NO_CSS: "ui",
            segment_property_restrictions_CSS: "IQ-Segment",
            restrictionsModalOpen: true,
        });
    }
    onClick_button_modal_lender_details(){
        this.setState({
            restrictionsModalOpen: true,
        });
    }
    //
    onChange_property_insurance_expiryDate(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_property_insurance_expiryDate_value:  d,
        });
    }
    handleMomentChange = moment => {
        this.setState({ moment });
    };
    onClick_property_registered_deeds_YES(){
        this.setState({
            button_property_registered_deeds_Checked_YES: true,
            button_property_registered_deeds_Checked_NO: false,
            button_property_registered_deeds_YES_CSS: "ui green",
            button_property_registered_deeds_NO_CSS: "ui",
            segment_property_registered_deeds_CSS: "IQ-Hidden",
            segment_property_registered_deeds_note_CSS: "",
        });
    }
    //
    onClick_property_registered_deeds_NO(){
        this.setState({
            button_property_registered_deeds_Checked_YES: false,
            button_property_registered_deeds_Checked_NO: true,
            button_property_registered_deeds_YES_CSS: "ui",
            button_property_registered_deeds_NO_CSS: "ui red",
            segment_property_registered_deeds_CSS: "",
            segment_property_registered_deeds_note_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_property_registered_mortgage_YES(){
        this.setState({
            button_property_registered_mortgage_Checked_YES: true,
            button_property_registered_mortgage_Checked_NO: false,
            button_property_registered_mortgage_YES_CSS: "ui green",
            button_property_registered_mortgage_NO_CSS: "ui",
            segment_property_registered_mortgage_CSS: "",
            segment_property_registered_thirdparty_CSS: "IQ-Hidden",
            restrictionsModalOpen: true,
        });
    }
    //
    onClick_property_registered_mortgage_NO(){
        this.setState({
            button_property_registered_mortgage_Checked_YES: false,
            button_property_registered_mortgage_Checked_NO: true,
            button_property_registered_mortgage_YES_CSS: "ui",
            button_property_registered_mortgage_NO_CSS: "ui red",
            segment_property_registered_mortgage_CSS: "IQ-Hidden",
            segment_property_registered_thirdparty_CSS: "",
        });
    }
    //
    onChange_property_septic(event, data){
        //console.log("Septic value: " + data.value);
        this.setState({
            input_property_septic_value: data.value,
        });
    }
    //
    onChange_property_tenure(event, data){
        //console.log("Tenure value: " + data.value);
        if(data.value === "leasehold"){
            this.setState({
                input_property_tenure_value: data.value,
                segment_property_tenure_CSS: "IQ-Segment",
            });
        }
        else {
            this.setState({
                input_property_tenure_value: data.value,
                segment_property_tenure_CSS: "IQ-Hidden",
            });
        }
    }
    //
    onChange_property_estate(event, data){
        //console.log("Estate value: " + data.value);
        this.setState({
            input_property_tenure_estate_value: data.value,
        });        
    }
    //
    onClick_property_buyToLet_tenancy_upload(){
        this.setState({
            button_property_buyToLet_tenancy_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_buyToLet_tenancy_data, this.state.file_property_buyToLet_tenancy.name, 2, "property_buyToLet_tenancy", () => {
            this.setState({
                button_property_buyToLet_tenancy_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_buyToLet_rightToRent_upload(){
        this.setState({
            button_property_buyToLet_rightToRent_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_buyToLet_rightToRent_data, this.state.file_property_buyToLet_rightToRent.name, 2, "property_buyToLet_rightToRent", () => {
            this.setState({
                button_property_buyToLet_rightToRent_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_buyToLet_gas_upload(){
        this.setState({
            button_property_buyToLet_gas_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_buyToLet_gas_data, this.state.file_property_buyToLet_gas.name, 2, "property_buyToLet_gas", () => {
            this.setState({
                button_property_buyToLet_gas_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_buyToLet_epc_upload(){
        this.setState({
            button_property_buyToLet_epc_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_buyToLet_epc_data, this.state.file_property_buyToLet_epc.name, 2, "property_buyToLet_epc", () => {
            this.setState({
                button_property_buyToLet_epc_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_solar_receipt_upload(){
        this.setState({
            button_property_solar_receipt_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_solar_receipt_data, this.state.file_property_solar_receipt.name, 2, "property_solar_receipt", () => {
            this.setState({
                button_property_solar_receipt_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_solar_lease_upload(){
        this.setState({
            button_property_solar_lease_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_solar_lease_data, this.state.file_property_solar_lease.name, 2, "property_solar_lease", () => {
            this.setState({
                button_property_solar_lease_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_solar_mcs_upload(){
        this.setState({
            button_property_solar_mcs_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_solar_mcs_data, this.state.file_property_solar_mcs.name, 2, "property_solar_mcs", () => {
            this.setState({
                button_property_solar_mcs_upload_CSS: "ui blue",
            });
        });
    }
    //
    //
    //
    onClick_property_tenure_share_upload(){
        this.setState({
            button_property_tenure_share_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_tenure_share_data, this.state.file_property_tenure_share.name, 2, "property_tenure_share", () => {
            this.setState({
                button_property_tenure_share_upload_CSS: "ui blue",
            });
        });
    }
    //
    onClick_property_insurance_cert_upload(){
        this.setState({
            button_property_insurance_cert_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_property_insurance_cert_data, this.state.file_property_insurance_cert.name, 2, "property_insurance_cert", () => {
            this.setState({
                button_property_insurance_cert_upload_CSS: "ui blue",
            });
        });
    }
    //
    //
    upload_File(fileData, fileName, iqStep, iqUploadVar, callback){
        //
        if(fileData !== "" && fileName !== ""){
            axios.post(
                this.props.url + "/IQ/fileUpload",
                {
                    "token": localStorage.token,
                    "content": fileData,
                    "fileName": fileName,
                    "fileId": this.props.fileId,
                    "iqStep": iqStep,
                    "iqUploadVar": iqUploadVar,
                    "copyToDPS": true,
                },
                { 
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            uploaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                        })
                    },
                },
            )
            .then((response) => {
                console.log(response);
                this.setState({
                    messageModalIcon_CSS: "checkmark circle icon",
                    messageModalTitle: "Upload Success",
                    messageModalContent: "Your document has been successfully uploaded!",
                    messageModalOpen: true,
                    uploadModalOpen: false,
                });
                callback();
            })
            .catch((error) => {    
                //https://github.com/axios/axios#handling-errors
                console.log(error);
                switch(error.response.status){
                    case 401:
                        this.props.history.push("/");
                        break;
                    case 500:
                        this.setState({                            
                            messageModalIcon_CSS: "warning circle icon",
                            messageModalTitle: "Error",
                            messageModalContent: "A server error occured during your file upload.\r\n\r\nPlease try again, but if the error persists then please contact Equilaw",
                            messageModalOpen: true,
                            uploadModalOpen: false,
                        });
                        callback();
                        break;
                    default:
                        break;
                }
            });
        }
        else callback();
    }
    //
    //
    saveData_Wrapper_Forward(){
        this.setState({
            dimmerActionText: "Saving Data...",
            propertyDimmerActive: true,
            button_forward_CSS: "green IQ-forwardButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, false);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        propertyDimmerActive: false,
                        button_forward_CSS: "green IQ-forwardButton",
                    });        
                    this.props.forward();
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    saveData_Wrapper_Back(){
        this.setState({
            dimmerActionText: "Saving Data...",
            propertyDimmerActive: true,
            button_back_CSS: "red IQ-backButton loading disabled",
            backward: true,
        }); 
        var results = this.saveData(this.props.url, true);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        propertyDimmerActive: false,
                        button_back_CSS: "red IQ-backButton",
                    }); 
                    this.props.back();
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async saveData(url, backward){
        console.log("mortgage lender: " + this.state.input_property_registered_mortgage_lender_value);
        const response = await fetch(url + "/IQ/SaveData_Step2", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.fileId,
                backward: backward,
                //
                Address_1: this.state.input_Address_1,
                Address_2: this.state.input_Address_2,
                Town: this.state.input_Town,
                County: this.state.input_County,
                Postcode: this.state.input_Postcode,
                //            
                property_changeOfOwner_Checked: this.state.button_property_changeOfOwner_Checked_YES ? true : (this.state.button_property_changeOfOwner_Checked_NO ? false: null),
                property_changeOfOwner_marketValue_Checked: this.state.button_property_changeOfOwner_marketValue_Checked_YES ? true : (this.state.button_property_changeOfOwner_marketValue_Checked_NO ? false: null),
                //
                property_additionalLand_Checked: this.state.button_property_additionalLand_Checked_YES ? true : (this.state.button_property_additionalLand_Checked_NO ? false: null),
                property_additionalLand_titleNumber: this.state.input_property_additionalLand_titleNumber_value,
                //
                property_notices_Checked: this.state.button_property_notices_Checked_YES ? true : (this.state.button_property_notices_Checked_NO ? false: null),
                //
                property_business_Checked: this.state.button_property_business_Checked_YES ? true : (this.state.button_property_business_Checked_NO ? false: null),
                property_business_details: this.state.input_property_business_details_value,
                //
                property_buyToLet_Checked: this.state.button_property_buyToLet_Checked_YES ? true : (this.state.button_property_buyToLet_Checked_NO ? false: null),
                //
                property_mainsOrSeptic: this.state.input_property_septic_value,
                //
                property_mortgage_Checked: this.state.button_property_mortgage_Checked_YES ? true : (this.state.button_property_mortgage_Checked_NO ? false: null),
                property_mortgage_accountNumber: this.state.input_property_mortgage_accountNumber_value,
                property_mortgage_amount: this.state.input_property_mortgage_amount_value,
                //
                property_solar_have_Checked: this.state.button_property_solar_have_Checked_YES ? true : (this.state.button_property_solar_have_Checked_NO ? false: null),
                property_solar_ASG_Checked: this.state.button_property_solar_ASG_Checked_YES ? true : (this.state.button_property_solar_ASG_Checked_NO ? false: null),
                property_solar_own_Checked: this.state.button_property_solar_own_Checked_YES ? true : (this.state.button_property_solar_own_Checked_NO ? false: null),
                //
                property_floodDamage_Checked: this.state.button_property_floodDamage_Checked_YES ? true : (this.state.button_property_floodDamage_Checked_NO ? false: null),
                //
                //
                property_restrictions_Checked: this.state.button_property_restrictions_Checked_YES ? true : (this.state.button_property_restrictions_Checked_NO ? false: null),
                property_restrictions_name: this.state.input_property_restrictions_name_value,
                property_restrictions_details: this.state.input_property_restrictions_details_value,

                property_restrictions_lender1: this.state.input_property_restrictions_lender1_value,
                property_restrictions_lender1_accnum: this.state.input_property_restrictions_lender1_accnum,
                property_restrictions_lender1_amount: this.state.input_property_restrictions_lender1_amount,

                property_restrictions_lender2: this.state.input_property_restrictions_lender2_value,
                property_restrictions_lender2_accnum: this.state.input_property_restrictions_lender2_accnum,
                property_restrictions_lender2_amount: this.state.input_property_restrictions_lender2_amount,

                property_restrictions_lender3: this.state.input_property_restrictions_lender3_value,
                property_restrictions_lender3_accnum: this.state.input_property_restrictions_lender3_accnum,
                property_restrictions_lender3_amount: this.state.input_property_restrictions_lender3_amount,

                property_restrictions_lender4: this.state.input_property_restrictions_lender4_value,
                property_restrictions_lender4_accnum: this.state.input_property_restrictions_lender4_accnum,
                property_restrictions_lender4_amount: this.state.input_property_restrictions_lender4_amount,

                property_restrictions_lender5: this.state.input_property_restrictions_lender5_value,
                property_restrictions_lender5_accnum: this.state.input_property_restrictions_lender5_accnum,
                property_restrictions_lender5_amount: this.state.input_property_restrictions_lender5_amount,

                property_restrictions_lender6: this.state.input_property_restrictions_lender6_value,
                property_restrictions_lender6_accnum: this.state.input_property_restrictions_lender6_accnum,
                property_restrictions_lender6_amount: this.state.input_property_restrictions_lender6_amount,
                //
                property_insurance_Checked: this.state.button_property_insurance_Checked_YES ? true : (this.state.button_property_insurance_Checked_NO ? false: null),
                property_insurance_company: this.state.input_property_insurance_company_value,
                property_insurance_expiryDate_value: this.state.input_property_insurance_expiryDate_value,
                property_insurance_coveramount: this.state.input_property_insurance_coveramount_value,
                //
                property_registered_Checked: this.state.button_property_registered_Checked_YES ? true : (this.state.button_property_registered_Checked_NO ? false: null),
                property_registered_deeds_Checked: this.state.button_property_registered_deeds_Checked_YES ? true : (this.state.button_property_registered_deeds_Checked_NO ? false: null),
                property_registered_mortgage_Checked: this.state.button_property_registered_mortgage_Checked_YES ? true : (this.state.button_property_registered_mortgage_Checked_NO ? false: null),
                property_registered_mortgage_lender_value: this.state.input_property_mortgage_lender_value,
                property_registered_mortgage_lender_ref: this.state.input_property_mortgage_accountNumber_value,
                property_registered_deeds_value: this.state.input_property_registered_deeds_value,
                property_registered_3pnumber_value: this.state.input_property_registered_3pnumber_value,
                //
                property_tenure_value: this.state.input_property_tenure_value,
                property_tenure_agents_value: this.state.input_property_tenure_agents_value,
                property_tenure_company_value: this.state.input_property_tenure_company_value,
                property_tenure_share_Checked: this.state.button_property_tenure_share_Checked_YES ? true : (this.state.button_property_tenure_share_Checked_NO ? false: null),
                property_tenure_groundrent_Checked: this.state.button_property_tenure_groundrent_Checked_YES ? true : (this.state.button_property_tenure_groundrent_Checked_NO ? false: null),
                property_tenure_groundrent_value: this.state.input_property_tenure_groundrent_value,
                property_tenure_groundrent_yearly_value: this.state.input_property_tenure_groundrent_yearly_value,
                property_tenure_groundrent_landlord_value: this.state.input_property_tenure_groundrent_landlord_value,
                property_tenure_lease_years: this.state.input_property_tenure_lease_years,
                property_tenure_estate_value: this.state.input_property_tenure_estate_value,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>                
                {/* Step 2. Property Details */}                            
                <Segment>
                    <Dimmer active={this.state.propertyDimmerActive}>
                        <Loader>{this.state.dimmerActionText}</Loader>
                    </Dimmer>
                    <Grid columns={2} className="IQ-Grid">
                        <Grid.Row>
                            <Grid.Column>
                                <div>
                                <div>
                                    <Form>
                                        <Form.Field>
                                            <label>Address 1</label>
                                            <div className="IQ-Form-Field-Lg">
                                                <input  className={this.state.passMatch} placeholder="Address 1" type="text" value={this.state.input_Address_1} onChange={this.valueChange('input_Address_1')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Address 2</label>
                                            <div className="IQ-Form-Field-Lg">
                                                <input  className={this.state.passMatch} placeholder="Address 2" type="text" value={this.state.input_Address_2} onChange={this.valueChange('input_Address_2')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Town/City</label>
                                            <div className="IQ-Form-Field-Lg">
                                                <input  className={this.state.passMatch} placeholder="Town/City" type="text" value={this.state.input_Town} onChange={this.valueChange('input_Town')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>County</label>
                                            <div className="IQ-Form-Field-Lg">
                                                <input  className={this.state.passMatch} placeholder="County" type="text" value={this.state.input_County} onChange={this.valueChange('input_County')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Postcode</label>
                                            <div className="IQ-Form-Field-Md">
                                                <input  className={this.state.passMatch} placeholder="Postcode" type="text" value={this.state.input_Postcode} onChange={this.valueChange('input_Postcode')} />
                                            </div>
                                        </Form.Field>
                                    </Form>
                                    </div>
                                    {/* <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Has the property changed hands in the last 6 months?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_changeOfOwner_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_changeOfOwner', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_changeOfOwner_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_changeOfOwner', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_changeOfOwner_CSS}>
                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Property change of ownership</h4></Label>
                                        <div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Was it at market value?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_changeOfOwner_marketValue_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_changeOfOwner_marketValue', null, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_changeOfOwner_marketValue_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_changeOfOwner_marketValue', null, null)}}>No</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Segment>
                                    </div> */}
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you own any additional land that is to be included in Equity Release?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_additionalLand_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_additionalLand', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_additionalLand_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_additionalLand', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_additionalLand_CSS}>
                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Additional Land</h4></Label>
                                        <div>
                                            <br/>
                                            <div>
                                                <Form>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="Additional Land Title Number:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input  className={this.state.passMatch} placeholder="Additional Land Title Number" type="text" value={this.state.input_property_additionalLand_titleNumber_value} onChange={this.valueChange('input_property_additionalLand_titleNumber_value')} />
                                                        </div>
                                                    </Form.Field>
                                                </Form>
                                            </div>        
                                        </div>
                                    </Segment>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Have you made or received any notices in relation to the property?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_notices_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_notices', null, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_notices_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_notices', null, null)}}>No</Button>
                                        </div>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you operate any business from the property or any part of it?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_business_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_business', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_business_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_business', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_business_CSS}>
                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Business details</h4></Label>
                                        <div>
                                            <br/>
                                            <div>
                                                <Form>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="Please give details of the business:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input  className={this.state.passMatch} placeholder="Please give details of the business" type="text" value={this.state.input_property_business_details_value} onChange={this.valueChange('input_property_business_details_value')} />
                                                        </div>
                                                    </Form.Field>
                                                </Form>
                                            </div>        
                                        </div>
                                    </Segment>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Is the property a Buy-To-Let?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_buyToLet_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_buyToLet', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_buyToLet_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_buyToLet', true, null)}}>No</Button>
                                        </div>
                                    </div>
                                    <Segment className={this.state.segment_property_buyToLet_CSS}>
                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Buy-To-Let</h4></Label>
                                        <div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Please upload a copy of your tenancy agreement."/>&emsp;
                                                <Input id="" className="ui" type="file" onChange={this.onChange_property_buyToLet_tenancy_file}/>&emsp;
                                                <Button className={this.state.button_property_buyToLet_tenancy_upload_CSS} content="Upload" onClick={this.onClick_property_buyToLet_tenancy_upload}/>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Please upload a copy of the tenant's 'Right to Rent' evidence."/>&emsp;
                                                <Input id="" className="ui" type="file" onChange={this.onChange_property_buyToLet_rightToRent_file}/>&emsp;
                                                <Button className={this.state.button_property_buyToLet_rightToRent_upload_CSS} content="Upload" onClick={this.onClick_property_buyToLet_rightToRent_upload}/>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Please upload a copy of the Gas Safety Certificate."/>&emsp;
                                                <Input id="" className="ui" type="file" onChange={this.onChange_property_buyToLet_gas_file}/>&emsp;
                                                <Button className={this.state.button_property_buyToLet_gas_upload_CSS} content="Upload" onClick={this.onClick_property_buyToLet_gas_upload}/>
                                            </div>
                                        </div>
                                        <div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Please upload a copy of the EPC Certificate."/>&emsp;
                                                <Input id="" className="ui" type="file" onChange={this.onChange_property_buyToLet_epc_file}/>&emsp;
                                                <Button className={this.state.button_property_buyToLet_epc_upload_CSS} content="Upload" onClick={this.onClick_property_buyToLet_epc_upload}/>
                                            </div>
                                        </div>
                                    </Segment>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <span>
                                            <Label className="IQ-Label" pointing={"right"} content="Does the property have mains drainage or a septic tank?:"/>&emsp;
                                            <Dropdown placeholder="Mains or Septic?" inline selection options={this.septicOptions} onChange={this.onChange_property_septic}/>
                                        </span>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <span>
                                            <Label className="IQ-Label" pointing={"right"} content="Is the property your second/holiday home?:"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_property_2ndHome_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_2ndHome', null, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_property_2ndHome_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_2ndHome', null, null)}}>No</Button>
                                            </div>
                                        </span>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <span>
                                            <Label className="IQ-Label" pointing={"right"} content="Is there an existing mortgage on the property?"/>&emsp;
                                            <div className='ui buttons'>
                                                <Button className={this.state.button_property_mortgage_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_mortgage', false, null)}}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_property_mortgage_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_mortgage', true, null)}}>No</Button>
                                            </div>
                                        </span>                                        
                                        <Segment className={this.state.segment_property_mortgage_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Existing Mortgage</h4></Label>
                                            <div>
                                                <br/>
                                                <Form>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="Lender:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input  className={this.state.passMatch} placeholder="Lender" type="text" value={this.state.input_property_mortgage_lender_value} onChange={this.valueChange('input_property_mortgage_lender_value')} />
                                                        </div>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_mortgage_accountNumber_value} onChange={this.valueChange('input_property_mortgage_accountNumber_value')} />
                                                        </div>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="Mortgage Amount:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input  className={this.state.passMatch} placeholder="Mortgage Amount" type="text" value={this.state.input_property_mortgage_amount_value} onChange={this.valueChange('input_property_mortgage_amount_value')} />
                                                        </div>
                                                    </Form.Field>
                                                </Form>
                                            </div>
                                        </Segment>
                                    </div>
                                    <br/>
                                    <hr/>
                                </div> 
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Does the property have Solar Panels?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_solar_have_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_solar_have', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_solar_have_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_solar_have', true, null)}}>No</Button>
                                        </div>
                                    </div>
                                    <Segment className={this.state.segment_property_solar_have_CSS}>
                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Solar Panels</h4></Label>
                                        <div>
                                            <br/>
                                            {/* <div className="IQ-checkboxDiv">
                                                <Label className="IQ-Label" pointing={"right"} content="Do you own your Solar Panels?"/>&emsp;
                                                <input type="checkbox" className="ui checkbox IQ-checkbox" checked={this.state.checkbox_solar_checked} onChange={this.onChange_SolarCheckbox} />
                                            </div>
                                            <br/>
                                            <br/> */}
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Is the Solar Panel provider 'A Shade Greener'?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_solar_ASG_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_solar_ASG', null, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_solar_ASG_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_solar_ASG', null, null)}}>No</Button>
                                                </div>
                                            </div>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Are the Solar Panels owned outright?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_solar_own_YES_CSS} role='button' onClick={this.onClick_property_SolarOwn_YES}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_solar_own_NO_CSS} role='button' onClick={this.onClick_property_SolarOwn_NO}>No</Button>
                                                </div>
                                            </div>
                                            <Segment className={this.state.segment_property_solar_ownership_outright_CSS}>
                                                <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Solar Panel Ownership</h4></Label>
                                                <div>
                                                    <br/>
                                                    <div>
                                                        <Label className="IQ-Label" pointing={"right"} content="If owned outright, we require a copy of the payment receipt."/>&emsp;
                                                        <Input id="" className="ui" type="file" onChange={this.onChange_property_solar_receipt_file}/>&emsp;
                                                        <Button className={this.state.button_property_solar_receipt_upload_CSS} content="Upload" onClick={this.onClick_property_solar_receipt_upload}/>
                                                    </div>
                                                </div>
                                            </Segment>
                                            <Segment className={this.state.segment_property_solar_ownership_lease_CSS}>
                                                <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Solar Panel Ownership</h4></Label>
                                                <div>
                                                    <br/>
                                                    <div>
                                                        <Label className="IQ-Label" pointing={"right"} content="If leased, we require a copy of the lease."/>&emsp;
                                                        <Input id="" className="ui" type="file" onChange={this.onChange_property_solar_lease_file}/>&emsp;
                                                        <Button className={this.state.button_property_solar_lease_upload_CSS} content="Upload" onClick={this.onClick_property_solar_lease_upload}/>
                                                    </div>
                                                </div>
                                            </Segment>
                                            <br/>
                                            <div>
                                                <div>
                                                    <Label className="IQ-Label" pointing={"right"} content="Please upload the Microgeneration certificate (MCS) for the property."/>&emsp;
                                                    <Input id="" className="ui" type="file" onChange={this.onChange_property_solar_mcs_file}/>&emsp;
                                                    <Button className={this.state.button_property_solar_mcs_upload_CSS} content="Upload" onClick={this.onClick_property_solar_mcs_upload}/>
                                                </div>                                                
                                            </div>                                                                                       
                                        </div>
                                    </Segment>
                                    <br/>
                                    <hr/>                                 
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Has the property had flood or coastal errosion in the past 15 years?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_floodDamage_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_floodDamage', null, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_floodDamage_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_floodDamage', null, null)}}>No</Button>
                                        </div>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Are there any known Restrictions/Cautions/Inhibitions/Notices etc.?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_restrictions_YES_CSS} role='button' onClick={this.onClick_property_restrictions_YES}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_restrictions_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_restrictions', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_restrictions_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Restrictions details</h4></Label>
                                            <div>
                                                <br/>
                                                <Button className="ui button blue" onClick={this.onClick_button_modal_lender_details}>Enter Lender(s) Details</Button>
                                                <br/><br/>
                                                <div>
                                                    <Form>
                                                        <Form.Field>
                                                            <Label className="IQ-Label" pointing={"right"} content="In favour of (name):"/>&emsp;
                                                            <div className="IQ-Form-Field-Sm">
                                                                <input  className={this.state.passMatch} placeholder="In favour of (name)" type="text" value={this.state.input_property_restrictions_name_value} onChange={this.valueChange('input_property_restrictions_name_value')} />
                                                            </div>
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Label className="IQ-Label" pointing={"right"} content="Reference/Details of Restriction/Caution/etc.:"/>&emsp;
                                                            <div className="IQ-Form-Field-Sm">
                                                                <input  className={this.state.passMatch} placeholder="Restriction Details" type="text" value={this.state.input_property_restrictions_details_value} onChange={this.valueChange('input_property_restrictions_details_value')} />
                                                            </div>
                                                        </Form.Field>
                                                    </Form>
                                                </div>
                                            </div>
                                        </Segment>
                                        <Modal className="IQ-Modal" open={this.state.restrictionsModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ restrictionsModalOpen: false})}>
                                            <Header className="Matters-Header"><Icon className="question circle icon"/>&emsp;More information required:&emsp;Restrictions details</Header>
                                            <Modal.Content>
                                                <div>
                                                    <Grid className="IQ-Grid IQ-Modal-Grid" columns={3}>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">                                                            
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #1:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #1 Name" type="text" value={this.state.input_property_restrictions_lender1_value} onChange={this.valueChange('input_property_restrictions_lender1_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender1_accnum_value} onChange={this.valueChange('input_property_restrictions_lender1_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender1_amount_value} onChange={this.valueChange('input_property_restrictions_lender1_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                {/* <Form.Field>
                                                                    <div>
                                                                        <div className="IQ-DivInline">
                                                                            <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Charge/Mortgage on Title:"/>&emsp;                                                                            
                                                                        </div>                                                                            
                                                                        <Calendar
                                                                            format='ddd DD/MMM/YYYY'
                                                                            //date={this.state.moment.format('DD/MM/YYYY')}
                                                                            displayYrWithMonth={true}
                                                                            closeOnSelect={true}
                                                                            openOnInputFocus={true}
                                                                            readOnly={true}
                                                                            inputFieldClass="input-calendar-value IQ-Calendar"
                                                                            //customIcon="calendar outline icon"
                                                                        />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <div>
                                                                        <div className="IQ-DivInline">
                                                                            <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Expiry of Redemption Statement:"/>&emsp;                                                                            
                                                                        </div>                                                                            
                                                                        <Calendar
                                                                            format='ddd DD/MMM/YYYY'
                                                                            //date={this.state.moment.format('DD/MM/YYYY')}
                                                                            displayYrWithMonth={true}
                                                                            closeOnSelect={true}
                                                                            openOnInputFocus={true}
                                                                            readOnly={true}
                                                                            inputFieldClass="input-calendar-value IQ-Calendar"
                                                                            //customIcon="calendar outline icon"
                                                                        />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Equilaw to pay?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Undertaking given?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Redeem exist. ER?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Commercial Loan?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Commercial Overdraft?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Has the charge/mortgage been repaid?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_YES_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_YES}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_eqltoPay_NO_CSS} role='button' onClick={this.onClick_property_restrictions_modal_eqltoPay_NO}>No</Button>
                                                                    </div>
                                                                </Form.Field> */}
                                                            </Form>
                                                        </Grid.Column>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #2:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #2 Name" type="text" value={this.state.input_property_restrictions_lender2_value} onChange={this.valueChange('input_property_restrictions_lender2_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender2_accnum_value} onChange={this.valueChange('input_property_restrictions_lender2_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender2_amount_value} onChange={this.valueChange('input_property_restrictions_lender2_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </Grid.Column>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #3:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #3 Name" type="text" value={this.state.input_property_restrictions_lender3_value} onChange={this.valueChange('input_property_restrictions_lender3_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender3_accnum_value} onChange={this.valueChange('input_property_restrictions_lender3_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender3_amount_value} onChange={this.valueChange('input_property_restrictions_lender3_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </Grid.Column>
                                                    </Grid>
                                                    <br/><br/>
                                                    <div className="IQ-Modal-Div-Lenders">
                                                        <Form>
                                                        <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="More lenders?"/>&emsp;
                                                                    <div className='ui buttons'>
                                                                        <Button className={this.state.button_property_restrictions_modal_moreLenders_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_restrictions_modal_moreLenders', false, null)}}>Yes</Button>
                                                                        <div className='or' />
                                                                        <Button className={this.state.button_property_restrictions_modal_moreLenders_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_restrictions_modal_moreLenders', true, null)}}>No</Button>
                                                                    </div>
                                                                </Form.Field>
                                                        </Form>
                                                    </div>  
                                                    <br/><br/><br/><br/>
                                                    <div className={this.state.segment_property_restrictions_modal_moreLenders_CSS}>
                                                    <Grid className="IQ-Grid IQ-Modal-Grid" columns={3}>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">                                                            
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #4:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #4 Name" type="text" value={this.state.input_property_restrictions_lender4_value} onChange={this.valueChange('input_property_restrictions_lender4_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender4_accnum_value} onChange={this.valueChange('input_property_restrictions_lender4_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender4_amount_value} onChange={this.valueChange('input_property_restrictions_lender4_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </Grid.Column>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #5:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #5 Name" type="text" value={this.state.input_property_restrictions_lender5_value} onChange={this.valueChange('input_property_restrictions_lender5_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender5_accnum_value} onChange={this.valueChange('input_property_restrictions_lender5_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender5_amount_value} onChange={this.valueChange('input_property_restrictions_lender5_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </Grid.Column>
                                                        <Grid.Column className="IQ-Modal-Grid-Column">
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Lender #6:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Lender #6 Name" type="text" value={this.state.input_property_restrictions_lender6_value} onChange={this.valueChange('input_property_restrictions_lender6_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account Number:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Account Number" type="text" value={this.state.input_property_restrictions_lender6_accnum_value} onChange={this.valueChange('input_property_restrictions_lender6_accnum_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Amount:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Amount" type="text" value={this.state.input_property_restrictions_lender6_amount_value} onChange={this.valueChange('input_property_restrictions_lender6_amount_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </Grid.Column>
                                                    </Grid>
                                                    </div>
                                                    {/* <div>
                                                        <Form>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="Lenders Redeeming:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input className={this.state.passMatch} placeholder="Lenders Redeeming" type="text" value={this.state.input_property_tenure_groundrent_value} onChange={this.onChange_property_tenure_groundrent} />
                                                                </div>
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="EQL Redeeming:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="EQL Redeeming" type="text" value={this.state.input_property_tenure_groundrent_yearly_value} onChange={this.onChange_property_tenure_groundrent_yearly} />
                                                                </div>
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="TOTAL REDEMPTIONS:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="Total Redemptions" type="text" value={this.state.input_property_tenure_groundrent_yearly_value} onChange={this.onChange_property_tenure_groundrent_yearly} />
                                                                </div>
                                                            </Form.Field>
                                                        </Form>
                                                    </div>                                                     */}
                                                </div>                                                
                                            </Modal.Content> 
                                            <Modal.Actions>
                                                <Button color='green' onClick={() => this.setState({ restrictionsModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                                            </Modal.Actions>
                                        </Modal>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you have buildings insurance for the property?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_insurance_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_insurance', false, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_insurance_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_insurance', true, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_insurance_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Buildings Insurance details</h4></Label>
                                            <div>
                                                <br/>
                                                <div>
                                                    <Form>
                                                        <Form.Field>
                                                            <Label className="IQ-Label" pointing={"right"} content="Insurance Company Name:"/>&emsp;
                                                            <div className="IQ-Form-Field-Sm">
                                                                <input  className={this.state.passMatch} placeholder="Insurance Company Name" type="text" value={this.state.input_property_insurance_company_value} onChange={this.valueChange('input_property_insurance_company_value')} />
                                                            </div>
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <div>
                                                                <div className="IQ-DivInline">
                                                                    <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date Insurance Expires:"/>&emsp;                                                                            
                                                                </div>                                                                            
                                                                <Calendar
                                                                    id= "input_property_insurance_expiryDate"
                                                                    format='ddd DD/MMM/YYYY'
                                                                    //date={this.state.moment.format('DD/MM/YYYY')}
                                                                    displayYrWithMonth={true}
                                                                    closeOnSelect={true}
                                                                    openOnInputFocus={true}
                                                                    readOnly={true}
                                                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                                                    date={this.state.input_property_insurance_expiryDate_value}
                                                                    onChange={this.onChange_property_insurance_expiryDate}
                                                                    locale="en-gb"
                                                                    //customIcon="calendar outline icon"
                                                                />
                                                            </div>
                                                        </Form.Field>
                                                        <Form.Field>
                                                            <Label className="IQ-Label" pointing={"right"} content="Current Insurance cover amount:"/>&emsp;
                                                            <div className="IQ-Form-Field-Sm">
                                                                <input  className={this.state.passMatch} placeholder="Insurance cover amount" type="text" value={this.state.input_property_insurance_coveramount_value} onChange={this.valueChange('input_property_insurance_coveramount_value')} />
                                                            </div>
                                                        </Form.Field>
                                                        <div>
                                                            <div>
                                                                <Label className="IQ-Label" pointing={"right"} content="Please upload the business insurance certificate for the property."/>&emsp;
                                                                <Input id="" className="ui" type="file" onChange={this.onChange_property_insurance_cert_file}/>&emsp;
                                                                <Button className={this.state.button_property_insurance_cert_upload_CSS} content="Upload" onClick={this.onClick_property_insurance_cert_upload}/>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </div>        
                                            </div>
                                        </Segment>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Is the property registered with HM Land Registry?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_property_registered_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_registered', true, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_property_registered_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_registered', false, null)}}>No</Button>
                                        </div>
                                        <Segment className={this.state.segment_property_registered_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Unregistered Property</h4></Label>
                                            <div>
                                                <br/>
                                                <Label className="IQ-Label" pointing={"right"} content="Do you hold the title deeds for the property?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_registered_deeds_YES_CSS} role='button' onClick={this.onClick_property_registered_deeds_YES}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_registered_deeds_NO_CSS} role='button' onClick={this.onClick_property_registered_deeds_NO}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_property_registered_deeds_CSS}>
                                                    <Label className="IQ-Label" pointing={"right"} content="Is there a mortgage registered against the property?"/>&emsp;
                                                    <div className='ui buttons'>
                                                        <Button className={this.state.button_property_registered_mortgage_YES_CSS} role='button' onClick={this.onClick_property_registered_mortgage_YES}>Yes</Button>
                                                        <div className='or' />
                                                        <Button className={this.state.button_property_registered_mortgage_NO_CSS} role='button' onClick={this.onClick_property_registered_mortgage_NO}>No</Button>
                                                    </div>
                                                    {/* <Segment className={this.state.segment_property_registered_mortgage_CSS}>
                                                        <div>
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Who is the mortgage lender?:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Who is the mortgage lender?" type="text" value={this.state.input_property_registered_mortgage_lender_value} onChange={this.valueChange('input_property_registered_mortgage_lender_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="What is the Account/Reference number for the mortgage?:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="What is the Account/Reference number for the mortgage?" type="text" value={this.state.input_property_registered_mortgage_ref} onChange={this.valueChange('input_property_registered_mortgage_ref')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </div>
                                                    </Segment> */}
                                                    <Segment className={this.state.segment_property_registered_thirdparty_CSS}>
                                                        <div>
                                                            <Form>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="If no mortgage, where are the title deeds/lease held?:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Where are the title deeds/lease held?" type="text" value={this.state.input_property_registered_deeds_value} onChange={this.valueChange('input_property_registered_deeds_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                                <Form.Field>
                                                                    <Label className="IQ-Label" pointing={"right"} content="Account/Reference number for deeds if held with third party?:"/>&emsp;
                                                                    <div className="IQ-Form-Field-Sm">
                                                                        <input  className={this.state.passMatch} placeholder="Third party Account/Reference number" type="text" value={this.state.input_property_registered_3pnumber_value} onChange={this.valueChange('input_property_registered_3pnumber_value')} />
                                                                    </div>
                                                                </Form.Field>
                                                            </Form>
                                                        </div>
                                                    </Segment>
                                                </Segment>
                                                <Segment className={this.state.segment_property_registered_deeds_note_CSS}>
                                                    <h4 className="IQ-Warning">PLEASE NOTE: You must send us the deeds for the property via post to the following address:</h4>
                                                    <span><b>{this.props.reference}</b><br/>Equilaw<br/>1330 Montpellier Court<br/>Gloucester Business Park<br/>Gloucester<br/>GL3 4AH</span>
                                                </Segment>
                                            </div>
                                        </Segment>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <span>
                                            <Label className="IQ-Label" pointing={"right"} content="Property Tenure type:"/>&emsp;
                                            <Dropdown placeholder="Select Tenure type..." inline selection options={this.tenureOptions} onChange={this.onChange_property_tenure}/>
                                        </span>                                                    
                                        <Segment className={this.state.segment_property_tenure_CSS}>
                                            <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Leasehold details</h4></Label>
                                            <div>
                                                <br/>
                                                <div>
                                                    <Segment>
                                                        <Form>
                                                            <Form.Field>
                                                                <label>Managing Agents:</label>
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="Managing Agents" type="text" value={this.state.input_property_tenure_agents_value} onChange={this.valueChange('input_property_tenure_agents_value')} />
                                                                </div>
                                                            </Form.Field>
                                                            <h4 className="IQ-BoldBlueHeader"><u>AND / OR</u></h4>
                                                            <Form.Field>
                                                                <label>Management Company: </label>
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="Management Company" type="text" value={this.state.input_property_tenure_company_value} onChange={this.valueChange('input_property_tenure_company_value')} />
                                                                </div>
                                                            </Form.Field>
                                                        </Form>
                                                    </Segment>
                                                </div>        
                                            </div>
                                            <br/>
                                            <hr/>
                                            <br/>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Do you own a share in the management company?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_tenure_share_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_tenure_share', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_tenure_share_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_tenure_share', true, null)}}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_property_tenure_share_CSS}>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Management Company Share details</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div>
                                                            <Label className="IQ-Label" pointing={"right"} content="Please upload your share certificate for the Management Company."/>&emsp;
                                                            <Input id="" className="ui" type="file" onChange={this.onChange_property_tenure_share_file}/>&emsp;
                                                            <Button className={this.state.button_property_tenure_share_upload_CSS} content="Upload" onClick={this.onClick_property_tenure_share_upload}/>
                                                        </div>
                                                    </div>
                                                </Segment>
                                            </div>
                                            <br/>
                                            <hr/>
                                            <br/>
                                            <div>                                                
                                                <Label className="IQ-Label" pointing={"right"} content="Do you pay ground rent?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_property_tenure_groundrent_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('property_tenure_groundrent', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_property_tenure_groundrent_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('property_tenure_groundrent', true, null)}}>No</Button>
                                                </div>                                                
                                                <Segment className={this.state.segment_property_tenure_groundrent_CSS}>
                                                    <br/><br/>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Ground Rent</h4></Label>
                                                    <div>
                                                        <Form>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="Amount of Yearly Ground Rent:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input className={this.state.passMatch} placeholder="Amount of yearly Ground Rent" type="text" value={this.state.input_property_tenure_groundrent_value} onChange={this.valueChange('input_property_tenure_groundrent_value')} />
                                                                </div>
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="Amount of yearly Rent Charge:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="Amount of yearly Rent Charge" type="text" value={this.state.input_property_tenure_groundrent_yearly_value} onChange={this.valueChange('input_property_tenure_groundrent_yearly_value')} />
                                                                </div>
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Label className="IQ-Label" pointing={"right"} content="Name of Landlord:"/>&emsp;
                                                                <div className="IQ-Form-Field-Sm">
                                                                    <input  className={this.state.passMatch} placeholder="Name of Landlord" type="text" value={this.state.input_property_tenure_groundrent_landlord_value} onChange={this.valueChange('input_property_tenure_groundrent_landlord_value')} />
                                                                </div>
                                                            </Form.Field>
                                                        </Form>
                                                    </div>
                                                </Segment>                                                
                                            </div>
                                            <br/>
                                            <hr/>
                                            <br/>
                                            <div>
                                                <Form>
                                                    <Form.Field>
                                                        <Label className="IQ-Label" pointing={"right"} content="How many year are left on the lease?:"/>&emsp;
                                                        <div className="IQ-Form-Field-Sm">
                                                            <input className={this.state.passMatch} placeholder="Years left on lease" type="text" value={this.state.input_property_tenure_lease_years} onChange={this.valueChange('input_property_tenure_lease_years')} />
                                                        </div>
                                                    </Form.Field>
                                                </Form>
                                            </div>
                                        </Segment>                                                    
                                    </div>
                                    {/* <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <span>
                                            <Label className="IQ-Label" pointing={"right"} content="Registered Estate type:"/>&emsp;
                                            <Dropdown placeholder="Registered Estate type..." inline selection options={this.estateOptions} onChange={this.onChange_property_estate}/>
                                        </span>
                                    </div> */}
                                    <br/>
                                    <hr/>
                                    <br/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br/>
                    <Button className={this.state.button_back_CSS} onClick={this.saveData_Wrapper_Back}>Previous Step</Button>                    
                    <Button className={this.state.button_forward_CSS} onClick={this.saveData_Wrapper_Forward}>Next Step</Button>
                    <br/><br/>
                </Segment>
                <Modal className="IQ-Modal-Message" open={this.state.messageModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className={this.state.messageModalIcon_CSS}/>&emsp;{this.state.messageModalTitle}</Header>
                    <Modal.Content>
                        <div>
                            {this.state.messageModalContent}
                        </div>                                                
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>
                </Modal>
                <Modal className="IQ-Modal-Message" open={this.state.uploadModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className="ui icon upload"/>&emsp;File Uploading...</Header>
                    <Modal.Content>
                        <div>
                            <Progress percent={Math.round(this.state.uploaded)} progress active color="green"  max="100" />
                        </div>                                                
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button> */}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default IQPage2Property