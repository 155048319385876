import React, { Component } from 'react';
import { Segment, Modal, Header, Button, Icon, Grid, Checkbox, Label, Input, Dimmer, Loader, } from 'semantic-ui-react';
import moment from 'moment';

class ClientCaseAutoAllocate extends Component{
    constructor(props) {
        super(props);
        this.state = {
            //
            sendConfirmations: false,
            dimmerActive: false,
            //
            optionGridCSS: "IQ-Hidden",
            option_MobileActive: false,
            option_EquilawActive: false,
            findApptButtonCss: "disabled",
            bookingLocationPref: "",
            locMobile_CSS: "",
            locEquilaw_CSS: "",
            buttonOption1CSS: "",
            buttonOption2CSS: "",
            buttonOption3CSS: "",
            option1SchedId: 0,
            option2SchedId: 0,
            option3SchedId: 0,
            option1AgentName: "",
            option2AgentName: "",
            option3AgentName: "",
            chosenOption: 0,
            //
            roleId: localStorage.roleId,
            //
            bookApptModalOpen: false,
            apptAtPoa: false,
            input_appt_custom_postcode: "",
            fileInfo: "",
            divFileInfo_CSS: "",
            bookingButton_CSS: "",
            //
            option1_scheduleEstEndTime: "",
            option1_scheduleEstDistance: "",
            option2_scheduleEstEndTime: "",
            option2_scheduleEstDistance: "",
            option3_scheduleEstEndTime: "",
            option3_scheduleEstDistance: "",
            //
            showOption1: "IQ-Hidden",
            showOption2: "IQ-Hidden",
            showOption3: "IQ-Hidden",
        };
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.fillOptionsOutlook_Wrapper = this.fillOptionsOutlook_Wrapper.bind(this);
        this.fillOptionsOutlook = this.fillOptionsOutlook.bind(this);
        this.fillOptions_Wrapper = this.fillOptions_Wrapper.bind(this);
        this.fillOptions = this.fillOptions.bind(this);
        //
        this.bookAppt_Wrapper = this.bookAppt_Wrapper.bind(this);
        this.bookAppt = this.bookAppt.bind(this);
        this.bookAppt_Outlook = this.bookAppt_Outlook.bind(this);
        //
        this.fetchCustomPostcodeData = this.fetchCustomPostcodeData.bind(this);
        //
        this.onClickBookButton = this.onClickBookButton.bind(this);
    }
    //
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    fillOptions_Wrapper(){
        this.setState({
            dimmerActive: true,
        });
        var results = this.fillOptions(this.props.url);
        results.then((data) => {
            switch(data.httpCode){
                case 200:
                    console.log(data.body.schedList);
                    var tempDate1 = data.body.schedList.length > 0 ? new Date(data.body.schedList[0].apptDate) : null;
                    var tempDate2 = data.body.schedList.length > 1 ? new Date(data.body.schedList[1].apptDate) : null;
                    var tempDate3 = data.body.schedList.length > 2 ? new Date(data.body.schedList[2].apptDate) : null;
                    if(tempDate1 != null) tempDate1.setHours(tempDate1.getHours() + 1);
                    if(tempDate2 != null) tempDate2.setHours(tempDate2.getHours() + 1);
                    if(tempDate3 != null) tempDate3.setHours(tempDate3.getHours() + 1);
                    //console.log("totalTimeMinutes: " + data.body.schedList[1].scheduleTotalTime/60);
                    var endTime_Option_1 = data.body.schedList.length > 0 ? moment(new Date(data.body.schedList[0].routingStartTime)).add(Math.round(data.body.schedList[0].scheduleTotalTime/60), 'm').format("HH:mm a") : null;
                    var endTime_Option_2 = data.body.schedList.length > 1 ? moment(new Date(data.body.schedList[1].routingStartTime)).add(Math.round(data.body.schedList[1].scheduleTotalTime/60), 'm').format("HH:mm a") : null;
                    var endTime_Option_3 = data.body.schedList.length > 2 ? moment(new Date(data.body.schedList[2].routingStartTime)).add(Math.round(data.body.schedList[2].scheduleTotalTime/60), 'm').format("HH:mm a") : null;
                    //
                    this.setState({
                        findApptButtonCss: "",
                        optionGridCSS: "",
                        option1: data.body.schedList.length > 0 ? moment(tempDate1).format("ddd DD/MMM/YYYY") : "",
                        option2: data.body.schedList.length > 1 ? moment(tempDate2).format("ddd DD/MMM/YYYY") : "",
                        option3: data.body.schedList.length > 2 ? moment(tempDate3).format("ddd DD/MMM/YYYY") : "",
                        option1_date: tempDate1,
                        option2_date: tempDate2,
                        option3_date: tempDate3,
                        buttonOption1CSS: data.body.schedList.length < 1 ? "disabled" : "",
                        buttonOption2CSS: data.body.schedList.length < 2 ? "disabled" : "",
                        buttonOption3CSS: data.body.schedList.length < 3 ? "disabled" : "",
                        option1SchedId: data.body.schedList.length > 0 ? data.body.schedList[0].scheduleId : 0,
                        option2SchedId: data.body.schedList.length > 1 ? data.body.schedList[1].scheduleId : 0,
                        option3SchedId: data.body.schedList.length > 2 ? data.body.schedList[2].scheduleId : 0,
                        option1AgentName: data.body.schedList.length > 0 ? data.body.schedList[0].agentName : "",
                        option2AgentName: data.body.schedList.length > 1 ? data.body.schedList[1].agentName : "",
                        option3AgentName: data.body.schedList.length > 2 ? data.body.schedList[2].agentName : "",
                        option1_scheduleEstDistance: data.body.schedList.length > 0 ? data.body.schedList[0].scheduleTotalDist : 0,
                        option2_scheduleEstDistance: data.body.schedList.length > 1 ? data.body.schedList[1].scheduleTotalDist : 0,
                        option3_scheduleEstDistance: data.body.schedList.length > 2 ? data.body.schedList[2].scheduleTotalDist : 0,
                        option1_scheduleEstEndTime: data.body.schedList.length > 0 ?  endTime_Option_1 : "",
                        option2_scheduleEstEndTime: data.body.schedList.length > 1 ?  endTime_Option_2 : "",
                        option3_scheduleEstEndTime: data.body.schedList.length > 2 ?  endTime_Option_3 : "",
                        dimmerActive: false,
                        showOption1: data.body.schedList.length > 0 ? "" : "IQ-Hidden",
                        showOption2: data.body.schedList.length > 1 ? "" : "IQ-Hidden",
                        showOption3: data.body.schedList.length > 2 ? "" : "IQ-Hidden",
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Find Appointments Error!",
                        bookingResultText: data.body.Message,
                        bookedModalOpen: true,
                        findApptButtonCss: "",
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                case 409: 
                    this.setState({
                        findApptButtonCss: "",
                        optionGridCSS: "IQ-Hidden",
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Find Appointments Error!",
                        bookingResultText: "Please try again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                    });
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fillOptions(url){
        const response = await fetch(url + "/Schedule/GetApptChoicesForClient", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.matterFileId,
                numChoices: 3,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    fillOptionsOutlook_Wrapper(){
        this.setState({
            dimmerActive: true,
        });
        var results = this.fillOptionsOutlook(this.props.url);
        results.then((data) => {
            switch(data.httpCode){
                case 200:
                    console.log(data.body);
                    var tempDate1 = data.body.length > 0 ? new Date(data.body[0].startDate) : null;
                    var tempDate2 = data.body.length > 1 ? new Date(data.body[1].startDate) : null;
                    var tempDate3 = data.body.length > 2 ? new Date(data.body[2].startDate) : null;
                    if(tempDate1 != null) tempDate1.setHours(tempDate1.getHours() + 1);
                    if(tempDate2 != null) tempDate2.setHours(tempDate2.getHours() + 1);
                    if(tempDate3 != null) tempDate3.setHours(tempDate3.getHours() + 1);
                    this.setState({
                        findApptButtonCss: "",
                        optionGridCSS: "",
                        option1: data.body.length > 0 ? data.body[0].startDate_string : "",
                        option2: data.body.length > 1 ? data.body[1].startDate_string : "",
                        option3: data.body.length > 2 ? data.body[2].startDate_string : "",
                        option1_date: tempDate1,
                        option2_date: tempDate2,
                        option3_date: tempDate3,
                        buttonOption1CSS: data.body.length < 1 ? "disabled" : "",
                        buttonOption2CSS: data.body.length < 2 ? "disabled" : "",
                        buttonOption3CSS: data.body.length < 3 ? "disabled" : "",
                        option1AgentName: data.body[0].agentName,
                        option2AgentName: data.body[1].agentName,
                        option3AgentName: data.body[2].agentName,
                        dimmerActive: false,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Find Appointments Error!",
                        bookingResultText: data.body.Message + "Please try again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                        findApptButtonCss: "",
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async fillOptionsOutlook(url){
        const response = await fetch(url + "/Matters/GetApptsForDate", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.matterFileId,
                fetchDate: new Date(),
                fetchReach: 5,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClickBookButton(optionValue) {
        this.setState({
            //selectedOption: optionValue.toString(),
            buttonOptionCSS: "loading disabled",
            chosenOption: optionValue,
        });
        var result = this.fetchCustomPostcodeData(this.props.url, this.props.matterFileId);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    console.log(data.body);  
                    this.setState({
                        apptAtPoa: data.body.apptAtPOAPostcode,
                        input_appt_custom_postcode: data.body.poaPostcode,
                    });
                    if(this.state.roleId !== 5){
                        this.setState({
                            bookApptModalOpen: true,
                        });
                    }
                    else {
                        this.bookAppt_Wrapper(optionValue);
                    }
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    // onClickBookButton_2(){
    //     if(this.state.roleId !== 5){
    //         this.setState({
    //             bookApptModalOpen: true,
    //             selectedOption: "2",
    //             buttonOption2CSS: "loading disabled",
    //             chosenOption: 2,
    //         });
    //     }
    //     else {
    //         this.setState({
    //             selectedOption: "2",
    //             buttonOption2CSS: "loading disabled",
    //             chosenOption: 2,
    //         });
    //         this.bookAppt_Wrapper(this.state.option2_date, 2);
    //     }
    // }
    // onClickBookButton_3(){
    //     if(this.state.roleId !== 5){
    //         this.setState({
    //             bookApptModalOpen: true,
    //             selectedOption: "3",
    //             buttonOption3CSS: "loading disabled",
    //             chosenOption: 3,
    //         });
    //     }
    //     else {
    //         this.setState({
    //             selectedOption: "3",
    //             buttonOption3CSS: "loading disabled",
    //             chosenOption: 3,
    //         });
    //         this.bookAppt_Wrapper(this.state.option3_date, 3);
    //     }
    // }
    //
    bookAppt_Wrapper(){
        var optionValue = this.state.chosenOption;
        var results;
        var chosenDate = optionValue === 1 ? this.state.option1_date : optionValue === 2 ? this.state.option2_date : this.state.option3_date;
        if(this.state.option_EquilawActive) results = this.bookAppt_Outlook(this.props.url, chosenDate);
        else if(this.state.option_MobileActive) results = this.bookAppt(this.props.url, chosenDate, optionValue);
        results.then((data) => {
            switch(data.httpCode){
                case 200:
                    //console.log("appt booking result: " + data.body);  
                    this.setState({
                        bookingResultIcon: "checkmark green icon",
                        bookingResultHeader: "Booking Success!",
                        bookingResultText: "Your chosen appointment has been successfully booked. You will receive confirmation of your booking via email.",
                        bookedModalOpen: true,
                        bookApptModalOpen: false,
                        bookingResult: true,
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: data.body.Message + " Please attempt your booking again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                        bookApptModalOpen: false,
                        bookingResult: false,
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                case 409: //conflict == zero results - possible postcode error
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: "An error occured with your booking! Please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                        bookApptModalOpen: false,
                        bookingResult: false,
                    });
                    break;
                default:
                    break;
            }
        });
    }
    //
    async bookAppt_Outlook(url, chosenDate){
        if(chosenDate.getFullYear() > 2000){
            const response = await fetch(url + "/Matters/CreateApptForDate", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: localStorage.token,
                    fetchDate: chosenDate.toISOString(),
                    fileId: this.props.matterFileId,
                    sendConfirmations: this.state.sendConfirmations,
                })
            });
            const responseObject = {
                httpCode: response.status,
                body: response.status === 200 || response.status === 400 ? await response.json() : ""
            }
            return responseObject;
        }
        else {
            const responseObject = {
                httpCode: "FAIL",
                body: "FAIL"
            }
            return responseObject;
        }
    }
    //
    async bookAppt(url, chosenDate, option){
        let newApptDate = new Date(chosenDate);
        console.log("chosen option: " + option);
        let schedId = 0;        
        switch(option){
            case 1:
                schedId = this.state.option1SchedId;
                break;
            case 2:
                schedId = this.state.option2SchedId;
                break;
            case 3:
                schedId = this.state.option3SchedId;
                break;
            default:
                break;
        }
        console.log("appt date hour = " + newApptDate.getHours());
        //
        if(newApptDate.getFullYear() > 2000){
            const response = await fetch(url + "/Schedule/BookApptForPortal_NoOutlook", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: localStorage.token,
                    scheduleId: schedId,
                    fileId: this.props.matterFileId,
                    //sendConfirmations: this.state.sendConfirmations,
                    customPostcode: this.state.input_appt_custom_postcode,
                    usingCustomPostcode: (this.state.input_appt_custom_postcode !== null && this.state.input_appt_custom_postcode !== ""),
                    usingAutoAllocate: true,
                })
            });
            const responseObject = {
                httpCode: response.status,
                body: response.status === 200 || response.status === 400 ? await response.json() : ""
            }
            return responseObject;
        }
        else {
            const responseObject = {
                httpCode: "FAIL",
                body: "FAIL"
            }
            return responseObject;
        }
    }
    //
    //
    async fetchCustomPostcodeData(url, fileId){
        const response = await fetch(url + "/Schedule/GetMatterPOAPostcodeData", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: fileId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Dimmer active={this.state.dimmerActive}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <h4 className="ClientApptLocationChoiceLabel">Would you rather have your signing appointment at home or at Equilaw (Gloucester)?</h4>
                <Button.Group>
                    
                    <Button className={this.state.locMobile_CSS} active={this.state.option_MobileActive} onClick={() => {
                        this.setState({option_MobileActive: true, option_EquilawActive: false, findApptButtonCss: "", bookingLocationPref: "mobile", locMobile_CSS: "ClientLocationChoiceActive", locEquilaw_CSS: ""});
                    }}>Home</Button>
                    <Button.Or />
                    <Button className={this.state.locEquilaw_CSS + (this.props.noOutlookAppts ? " disabled button-equilaw" : "")} active={this.state.option_EquilawActive}  onClick={() => {
                        this.setState({option_EquilawActive: true, option_MobileActive: false, findApptButtonCss: "", bookingLocationPref: "equilaw", locEquilaw_CSS: "ClientLocationChoiceActive", locMobile_CSS: ""});
                    }}>Equilaw</Button>
                </Button.Group>&emsp;
                <Button className={"blue " + this.state.findApptButtonCss} onClick={() => {
                    this.setState({
                        findApptButtonCss: "loading disabled",
                    }); 
                    if(this.state.option_EquilawActive) { this.fillOptionsOutlook_Wrapper(); }
                    else if(this.state.option_MobileActive) { this.fillOptions_Wrapper(); }
                }}>
                <Icon className="search icon" />&nbsp;Find Appointments</Button>
                <br/><br/>
                <div className={this.state.optionGridCSS}>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Segment className={this.state.showOption1}>
                                <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3 className="Appts-Header-Option">Option 1:&emsp;</h3>
                                                <Button className={"green " + this.state.buttonOptionCSS} size="small" onClick={() => {
                                                    this.onClickBookButton(1);
                                                }}><Icon className="checkmark"/>&nbsp;Select</Button>
                                            </Grid.Column>
                                            <Grid.Column></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h3>{this.state.option1AgentName}</h3> : ""}
                                            </Grid.Column>
                                            <Grid.Column>
                                            {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">End Time: {this.state.option1_scheduleEstEndTime}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3>{this.state.option1}&emsp;&emsp;</h3>
                                            </Grid.Column>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">Total Distance (Miles): {this.state.option1_scheduleEstDistance}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className={this.state.showOption2}>
                                <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3 className="Appts-Header-Option">Option 2:&emsp;</h3>
                                                <Button className={"green " + this.state.buttonOptionCSS} size="small" onClick={() => {
                                                    this.onClickBookButton(2);
                                                }}><Icon className="checkmark"/>&nbsp;Select</Button>
                                            </Grid.Column>
                                            <Grid.Column></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h3>{this.state.option2AgentName}</h3> : ""}
                                            </Grid.Column>
                                            <Grid.Column>
                                            {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">End Time: {this.state.option2_scheduleEstEndTime}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3>{this.state.option2}&emsp;&emsp;</h3>
                                            </Grid.Column>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">Total Distance (Miles): {this.state.option2_scheduleEstDistance}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment className={this.state.showOption3}>
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3 className="Appts-Header-Option">Option 3:&emsp;</h3>
                                                <Button className={"green " + this.state.buttonOptionCSS} size="small" onClick={() => {
                                                    this.onClickBookButton(3);
                                                }}><Icon className="checkmark"/>&nbsp;Select</Button>
                                            </Grid.Column>
                                            <Grid.Column></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h3>{this.state.option3AgentName}</h3> : ""}
                                            </Grid.Column>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">End Time: {this.state.option3_scheduleEstEndTime}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <h3>{this.state.option3}&emsp;&emsp;</h3>
                                            </Grid.Column>
                                            <Grid.Column>
                                                {this.state.roleId !== 5 ? <h4 className="Admin-Div-Float-Right">Total Distance (Miles): {this.state.option3_scheduleEstDistance}</h4> : ""}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <Modal className="Admin-Modal-CreateResult" open={this.state.bookApptModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ bookApptModalOpen: false })}>
                        <Header><Icon className={"icon address card"} />Book new Appointment</Header>
                        <Modal.Content>
                            <div>
                                <div className="has-text-centered has-text-weight-semibold">
                                <br/><br/>
                                    <div className={this.state.divFileInfo_CSS}>
                                        <h2>{"Matter selected: " + this.state.fileInfo}</h2>
                                    </div>
                                    <h2>{moment(this.state.chosenOption === 1 ? this.state.option1_date : this.state.chosenOption === 2 ? this.state.option2_date : this.state.chosenOption === 3 ? this.state.option3_date : new Date("01-01-2000")).format('ddd Do MMMM YYYY')}</h2>
                                </div>
                                <div className="has-text-centered">
                                    <br/><br/>
                                    <Checkbox label="Appt. at POA address?" disabled checked={(this.state.apptAtPoa === true)} />
                                    <br/><br/>
                                    <Label pointing="right">POA/Custom Postcode:</Label>
                                    <Input className="ui" type="text" value={this.state.input_appt_custom_postcode} onChange={this.valueChange('input_appt_custom_postcode')} />&emsp;
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className="red Admin-Div-Float-Left" onClick={() => {this.setState({ bookApptModalOpen: false, buttonOptionCSS: ""});}}>
                                <Icon className="reply icon" />
                                Back
                            </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            <Button className={"green " + this.state.bookingButton_CSS} size="small" onClick={() => {
                                    this.setState({
                                        dimmerActive: true,
                                        bookingButton_CSS: "disabled loading",
                                    });
                                    this.bookAppt_Wrapper();
                                }}>
                                <Icon className="checkmark"/>&nbsp;Book
                            </Button>
                        </Modal.Actions>
                    </Modal>
                <Modal className="Appt-Modal-Booked" open={this.state.bookedModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ bookedModalOpen: false})}>
                    <Header ><Icon className={this.state.bookingResultIcon}/>{this.state.bookingResultHeader}</Header>
                    <Modal.Content>
                        {this.state.bookingResultText}
                        <br/><br/>
                        Please try again. If this error persists, then please contact our scheduling team <a href="mailto:Scheduling@equilaw.uk.com">Scheduling@equilaw.uk.com</a>
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button onClick={() => {window.location.reload()}}>Close</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default ClientCaseAutoAllocate