
import React, { Component } from 'react';
import { Segment, Label, Icon } from 'semantic-ui-react';

class PrivacyPolicy extends Component{
    //
    render(){
        return(
            <div>
                <Segment className="Admin-Style">
                    <Label className="Admin-Header-Segment" attached="top"><h3 className="Admin-Header"><Icon className="eye big"/>&emsp;Privacy Policy</h3></Label>
                        <br/><br/>
                        <h2>PRIVACY STATEMENT</h2>
                        <p>
                        Equilaw Limited is committed to protecting your privacy when you use our services. In this policy we explain how and why we collect your personal data and what we do with it.
                        <br/><br/>
                        This policy reflects our obligations under both the Data Protection Act 1998 and the General Data Protection Regulations (2018) as adopted by the UK Government.
                        <br/><br/>
                        Please contact us if you have any questions regarding our Privacy Policy.
                        </p>
                        <h3>Confidentiality and Security</h3>
                        <p>
                        Equilaw Limited places great importance on the security of all information associated with our clients. We have security measures in place to protect against the loss, misuse and alteration of client data under our control. Our security and privacy policies are periodically reviewed and enhanced as necessary, and only authorised personnel have access to our clients’ personal data. In addition, personnel who have access to your personal data are trained with regards to ensuring the security of this data. While we cannot guarantee that loss, misuse or alteration of data will not occur, we use our best efforts to prevent this and we back up our computerised records on a daily basis so that we can restore any data that is lost or altered as a result of any human error or system malfunction. 
                        <br/><br/>
                        To prevent unauthorised access, maintain data accuracy and ensure the appropriate use of personal data, we have put in place appropriate physical, electronic and managerial procedures designed to safeguard and secure the personal data we collect and process.
                        </p>
                        <h3>Where is the personal data stored?</h3>
                        <p>
                        Equilaw Limited maintains a remotely managed server array in a secure UK based Tier 1 data centre. We also have arrangements in place to back up and if necessary restore all our data securely.  All systems are controlled, run and maintained from a secure location.
                        </p>
                        <h3>What personal data do we collect about you?</h3>
                        <span>
                        Equilaw Limited collects and processes personal data about you in order to:
                        <ol>
                            <li>identify you each time you contact us;</li>
                            <li>improve our services to you;</li>
                            <li>undertake the work you have instructed us to carry out;</li>
                            <li>comply with any legal obligations associated with your instructions to us.</li>
                        </ol>
                        We collect personally identifiable Information. This may include your name and contact details, gender, date of birth, occupation, and any other required information for the purpose of the work you have instructed us to carry out.
                        You are under no obligation to provide this information, but if you do not than we may not be able to provide you with the services requested.
                        </span>
                        <h3>How will we use the personal data collected about you?</h3>
                        <span>
                        We use the personal data you provide about yourself or others to administer the service for which the information is intended. This may include registering a property or submitting a Stamp Duty Land Tax return, for example, and we will also use it to contact you when necessary. We do not share this personal data with third parties without your permission except to the extent that is necessary in order to undertake the work you have instructed us to carry out or to comply in responding to writs, courts orders or other legal proceedings. Where it is necessary for us to formally establish your identity in order to comply with anti-money laundering or other government regulations we will pass your data to one or more specialist search providers who may in turn check these details against any database to which they have access.
                        The third parties we may share personal data with include:
                        <ul>
                            <li>The Land Registry</li>
                            <li>Companies House</li>
                            <li>HMRC</li>
                            <li>Creditsafe</li>
                            <li>Experian</li>
                        </ul>
                        Please note this is not a comprehensive list; for further information please contact us using any published telephone number, postal address or email address and ask to be directed to our Compliance Manager.
                        <br/><br/>
                        We never use or share the Personally Identifiable Information provided to us in ways or for reasons unrelated to the ones described above. If we wish to use your personal data for a new purpose, we will not do so without first obtaining your consent.
                        </span>
                        <h3>Data Retention</h3>
                        <p>
                        The normal retention period for client data is six years. We will retain your data only in accordance with this standard unless you have asked us to keep your file for a longer period of time. All client data is either deleted or destroyed or (for financial record keeping purposes) anonymised after that time.
                        </p>
                        <h3>Access to the Personal Data we may hold about you</h3>
                        <p>
                        You have the right to request a copy of the personal data that we hold about you. To obtain a copy of this personal data, please contact us using any published telephone number, postal address or email address and ask to be directed to our Compliance Manager. Please note it will not be possible to provide this information once the relevant retention period has expired. 
                        <br/><br/>
                        We want to make sure the personal data we may hold about you is current and accurate. You may ask us to correct or remove any personal data you believe is inaccurate. 
                        <br/><br/>
                        To protect your privacy and the privacy of others, we are obliged to seek evidence of your identity before we give you access to the personal data we may hold about you or make any changes to it.
                        <br/><br/>
                        If you have any questions about your right to access any personal data we hold, or if you feel your data has not been handled according to the principles of the relevant data protection regulations, you should contact the Information Commissioner’s Office for guidance on their published helpline number: 0303 123 1113.
                        </p>
                        <h3>The Services and Links from our Web Site</h3>
                        <p>
                        Our Web Site contains links to other Web Sites to which this Privacy Policy does not apply. When you visit other Web Sites through any link please ensure you read their Privacy Policies.
                        <br/><br/>
                        You can access our Web Site without disclosing your personal data.
                        </p>
                        <h3>Automatic Collection of non-personal Information (Cookies, Log files and IP Addresses)</h3>
                        <p>
                        “Cookies” are text files placed on your computer to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the website and to compile statistical reports on website activity. The Interactive Advertising Bureau (IAB) is an industry body which provides in-depth information about cookies. For further information, you may wish to visit www.aboutcookies.org. You can set your browser not to accept cookies and the IAB website provides instructions on how to do so.
                        <br/><br/>
                        When you visit our Web Site, we automatically log your IP address (the unique address which identifies your computer on the internet) which is automatically recognised by our web server. We use IP addresses to help us administer our Web Site and to collect broad demographic information for statistical use. We do not link IP addresses to personally identifiable information. We may automatically collect non-personal information about you such as the type of internet browsers you use or the site from which you linked to our Web Site. You cannot be identified from this information and it is only used to assist us in providing an effective service on our Web Site.  Session based information about your visit may also be stored automatically within your own browsing cache using HTML-5 local storage.
                        </p>
                        <h3>Changes to our Privacy Policy</h3>
                        <p>
                        We regularly review our Privacy Policy and will clearly mark any updates on our website. This Policy was last reviewed on May 2nd 2018.
                        </p>
                </Segment>
                <br/><br/>                
            </div>
        );
    }
}

export default PrivacyPolicy