import React, { Component } from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { NavLink } from "react-router-dom";
import './navbar.css';

class Navbar extends Component{
    constructor (props){
        super(props);        
        this.state = {
            activeItem: "",
            isAdmin: localStorage.roleId === "1" || localStorage.roleId === "2",
            isAdminCSS: (localStorage.roleId === "1" || localStorage.roleId === "2") ? "" : "Navbar-Hidden",
            isNewMatterCSS: localStorage.companyId === "-99" || localStorage.companyId === "4" || localStorage.companyId === "1048" ? "item" : "Navbar-Hidden", //Equilaw, Responsible, Viva
            userIsClient: false,
            isSuperAdmin: localStorage.roleId === "1" || localStorage.currentUser === "systemagent"
        };
        //
        this.determineCssClass = this.determineCssClass.bind(this);
    }
    //
    determineCssClass(itemName){
        console.log("isNewMatterCSS: " + this.state.isNewMatterCSS);
        switch(itemName){
            case "admin":
                return this.state.isAdminCSS;
            case "responsible":
                return this.state.isNewMatterCSS;
            default:
                return "";
        }
    }
    //
    render() {
        const NavBarItem = props => (
            <NavLink exact {...props} to={props.to} className={props.cssclass} activeClassName="active"/>
        );
        //
        return (
            <div>
                <Menu className={"inverted " + this.props.colour}>
                    {
                        this.props.items.map(item =>
                        <Menu.Item
                            cssclass={ item.name === "admin" ? this.state.isAdminCSS : item.name === "newMatter" ? this.state.isNewMatterCSS : item.name === "logout" ? "right item" : "item" }
                            as={item.isDropdown ? NavBarDropdownItem : NavBarItem }
                            to={"/" + (item.name === "logout" ? "" : item.name)}
                            key={item.id}
                            position={item.position}
                            name={item.text}
                            routename={item.name}
                            text={item.text} 
                            issuperadmincss={this.state.isSuperAdmin ? "Navbar-Dropdown-Link" : "Navbar-Hidden"} />
                    )}
                </Menu>
                <LoggedInUser className="Navbar-LoggedInUser" content={localStorage.currentUser} />
            </div>
        )
    }
}

export class NavBarItem extends Component{
    render(){
        return(
            <Menu.Item key={this.props.item.id} id={this.props.item.name} className={this.props.cssclass}>
                <NavLink exact to={"/" + this.props.item.name}>
                    { this.props.item.text }
                </NavLink>
            </Menu.Item>
        );
    }
}

export class NavBarDropdownItem extends Component{
    render(){
        return(
            <Dropdown item text={this.props.text} className={this.props.cssclass}>
                <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/" + this.props.routename} content="Create User" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/editUser"} content="Edit User" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/createCompany"} content="Create Company" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/mapSources"} content="Map Sources" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/adminIQ"} content="Fetch IQ" />
                    {/* <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/agentCoverage"} content="Manage Agent Coverage" /> */}
                    <Dropdown.Item 
                        as={NavLink} 
                        className={"Navbar-Dropdown-Link" }
                        exact to={"/agentCoveragev2"} 
                        content="Manage Agent Coverage" />
                    {/* <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/agentAppts"} content="Manage Agent Appoinments" /> */}
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/agentOverview"} content="Agent Schedule Overview" />
                    <Dropdown.Item as={NavLink} className={"Navbar-Dropdown-Link " + this.props.issuperadmincss} exact to={"/bookAppts"} content="Book/Manage Agent Appoinments" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/apptAutoAllocate"} content="Auto Allocate Appoinments" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/schedulingSettings"} content="Scheduling Settings" />
                    <Dropdown.Item as={NavLink} className="Navbar-Dropdown-Link" exact to={"/novexMigration"} content="Novex Migration" />
                </Dropdown.Menu>
            </Dropdown>
        );
    }    
}

class LoggedInUser extends Component{
    constructor (props){
        super(props);        
        this.state = {
            currentTime: this.dateToString(new Date().toString())
          };
        this.dateToString = this.dateToString.bind(this);
        //
        // setInterval(() => {
        //     var now = this.dateToString(new Date().toString());
        //     //console.log(now);
        //     if(this.state.currentTime !== now){
        //         this.setState({
        //             currentTime: now
        //         });
        //     }
        // }, 29000);
    }
    //
    dateToString(date){
        var s = "";
        var d = new Date(date);
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        s += (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) + "/";
        s += months[d.getMonth()] + "/";
        s += d.getFullYear() + " (";
        s += (d.getHours() < 10 ? "0" + d.getHours() : d.getHours()) + ":";
        s += (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()) + ")";
        //s += (d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()) + ")";
        return s;
    }
    //
    render(){
        return(
            <div>
                {/* <p className={this.props.className}>{this.state.currentTime} | Currently Logged in as: <b>{this.props.content}</b></p> */}
                <p className={this.props.className}>Currently Logged in as: <b>{this.props.content}</b></p>
            </div>
        );
    }
}

export default Navbar;