import React, { Component } from 'react';
import './FactFindUpload.css';
import { Button, Label, Input, Modal, Header, Icon } from 'semantic-ui-react';
import axios from 'axios';

class FactFindUpload extends Component{
    constructor(props){
        super(props);
        this.state = {
            messageModalOpen: false,
            messageModalIcon_CSS: "warning circle icon",
            messageModalTitle: "",
            messageModalContent: "",
            button_factFindUpload_file_upload_CSS: "ui blue",
            file_factFindUpload_file: "",
            file_factFindUpload_file_data: "",
            newMatterRef: "",
            newFileId: "",
            //
            mailLink_CSS: "FactFind_Hidden",
        }

        this.onClick_factFindUpload_file_upload = this.onClick_factFindUpload_file_upload.bind(this);
        this.onChange_factFindUpload_file = this.onChange_factFindUpload_file.bind(this);

        //
        console.log("Api End Point: '" + this.props.apiEndPoint + "'");
    }
    //
    onClick_factFindUpload_file_upload(){
        this.setState({
            button_factFindUpload_file_upload_CSS: "ui blue loading",
        });
        this.upload_File(this.state.file_factFindUpload_file_data, this.state.file_factFindUpload_file.name, () => {
            this.setState({
                button_factFindUpload_file_upload_CSS: "ui blue",
            });
        });
    }    
    //
    onChange_factFindUpload_file(event){
        event.preventDefault();
        let reader = new FileReader();
        let file = event.target.files[0];
        //console.log(file);
        //
        reader.onloadend = () => {
            this.setState({
                file_factFindUpload_file: file,
                file_factFindUpload_file_data: reader.result,
            });
        }
        //
        reader.readAsDataURL(file);
    }
    //
    //
    upload_File(fileData, fileName, callback){
        //
        if(fileData !== "" && fileName !== ""){
            axios({
                method: "post",
                url: this.props.url + "/Matters/factFindfileUpload",
                data: {
                    "token": localStorage.token,
                    "content": fileData,
                    "fileName": fileName,
                    "fileId": this.props.fileId,
                    "feeEarnerRef": this.props.apiEndPoint !== "" ? "EQR" : "HXL",
                },
            })
            .then((response) => {
                console.log(response.data);
                this.setState({
                    messageModalIcon_CSS: "checkmark circle icon",
                    messageModalTitle: "Upload Success",
                    messageModalContent: "Your document has been successfully uploaded and a new Matter has been generated!\r\n\r\nThe new Matter reference is: " + response.data.matterRef,
                    mailLink_CSS: "",
                    messageModalOpen: true,
                    newMatterRef: response.data.matterRef,
                    newFileId: response.data.fileId,
                });
                callback();
            })
            .catch((error) => {    
                //https://github.com/axios/axios#handling-errors
                console.log(error);
                switch(error.response.status){
                    case 400:
                        this.setState({                            
                            messageModalIcon_CSS: "copy outline icon",
                            messageModalTitle: "Duplicate OV Error",
                            messageModalContent: "Duplicate OV codes were found in your file upload.\r\n\r\nPlease remove these duplicates from the file and try again.\r\n\r\n" + error.response.data.Message,
                            mailLink_CSS: "FactFind_Hidden",
                            messageModalOpen: true,
                        });
                        callback();
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    case 409:
                        callback();
                        break;
                    case 500:
                        this.setState({                            
                            messageModalIcon_CSS: "warning circle icon",
                            messageModalTitle: "Error",
                            messageModalContent: "A server error occured during your file upload.\r\n\r\nPlease try again, but if the error persists then please contact Equilaw",
                            mailLink_CSS: "FactFind_Hidden",
                            messageModalOpen: true,
                        });
                        callback();
                        break;
                    default:
                        break;
                }
            });
        }
        else callback();
    }
    //
    render(){
        return(
            <div>
                <div>
                    <Label className="IQ-Label" pointing={"right"} content="Please locate and upload your Initial Fact Find csv file..."/>&emsp;
                    <Input id="" className="ui" type="file" onChange={this.onChange_factFindUpload_file}/>&emsp;
                    <Button className={this.state.button_factFindUpload_file_upload_CSS} content="Upload" onClick={this.onClick_factFindUpload_file_upload}/>
                </div>
                <Modal className="IQ-Modal-Message" open={this.state.messageModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className={this.state.messageModalIcon_CSS}/>&emsp;{this.state.messageModalTitle}</Header>
                    <Modal.Content>
                        <div>
                            {this.state.messageModalContent}
                        </div>
                        <br/>
                        <div className={this.state.mailLink_CSS}>
                            To send us any documents via email please click <a href={'mailto:team1@equilaw.uk.com?subject=Ref: ' + this.state.newMatterRef}>here</a>
                        </div>
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default FactFindUpload