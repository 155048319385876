import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Message, Dropdown } from 'semantic-ui-react';
import './AdminPage.css';

class CreateUser extends Component{
    constructor(props){
        super(props);
        this.state = {
            companyOptions: this.props.companyOptions,
            companyValue: "",
            companyId: 0,
            companyError: false,
            userIsStaff: false,

            roleOptions: this.props.roleOptions,
            roleValue: "",
            roleId: 0,
            roleError: false,

            firstName: "",
            firstNameError: false,
            lastName: "",
            lastNameError: false,
            username: "",
            usernameLoading: "",
            badEmail: false,
            badEmailMsg: "",
            goodEmail: false,
            usernameError: false,
            createUserLoading: "",
            existingUserChosen: false,
            phone1: "",
            phone1Error: false,
            phone2: "",
            phone2Error: false, 

            segment_createUser_agent: "IQ-Hidden",
            input_createUser_apptDuration: "30",
            input_createUser_postcode: "",
            postcodeError: false,

            creationResultModalOpen: false,
            creationResultModalIcon: "warning circle red",
            creationResultModalHeader: "User Creation Fail!",
            creationResultModalText: "Default error text"
        }
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.emailCheckWrapper = this.emailCheckWrapper.bind(this);
        this.emailCheck = this.emailCheck.bind(this);        
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleIsStaffCheckboxChange = this.handleIsStaffCheckboxChange.bind(this);        
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handlePhone1Change = this.handlePhone1Change.bind(this);
        this.handlePhone2Change = this.handlePhone2Change.bind(this);
        this.handleFormValidation = this.handleFormValidation.bind(this);
        this.createNewUserWrapper = this.createNewUserWrapper.bind(this);
        this.registerUser = this.registerUser.bind(this);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    handleFirstNameChange(event){
        this.setState({
            firstName: event.target.value
        });
        if(this.state.firstNameError && event.target.value !== ""){
            this.setState({
                firstNameError: false
            });
        }
    }
    //
    handleLastNameChange(event){
        this.setState({
            lastName: event.target.value            
        });
        if(this.state.lastNameError && event.target.value !== ""){
            this.setState({
                lastNameError: false
            });
        }
    }
    //
    handleUsernameChange(event){
        this.setState({
            username: event.target.value,
            badEmail: false,
            goodEmail: false
        });
        if(this.state.usernameError && this.goodEmail){
            this.setState({
                usernameError: false
            });
        }
    }
    //
    emailCheckWrapper(url){
        var uiDelay = 200;
        this.setState({
            usernameLoading: "loading disabled"
        });
        var result = this.emailCheck(url);
        result.then((data) => {
            console.log(data);
            switch(data.httpCode){
                case 200:
                    if(!data.body){
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Email in use! Please use another mail address.",
                                goodEmail: false,
                                //creationResultModalOpen: true
                            });
                        }, uiDelay);
                    }
                    else{
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: false,
                                goodEmail: true,
                                usernameError: false,
                                //creationResultModalOpen: true
                            });
                        }, uiDelay);
                    }
                    break;
                case 400:
                    if(this.state.username === ""){
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Email missing!. Please enter a valid email address!",
                                goodEmail: false,
                                usernameError: true,
                                //creationResultModalOpen: true
                            });
                        }, uiDelay);
                    }
                    else{
                        setTimeout(() => {
                            this.setState({
                                usernameLoading: "",
                                badEmail: true,
                                badEmailMsg: "Invalid session token. Please login again!",
                                goodEmail: false,
                                //creationResultModalOpen: true
                            });
                        }, uiDelay);
                    }
                    break;
                case 401:
                    setTimeout(() => {
                        // this.setState({
                        //     usernameLoading: "",
                        //     badEmail: true,
                        //     badEmailMsg: "You do not have permission to use this function.",
                        //     goodEmail: false
                        // });
                        this.props.history.push("/");
                    }, uiDelay);
                    break;
                case 404:
                    setTimeout(() => {
                        this.setState({
                            usernameLoading: "",
                            badEmail: true,
                            badEmailMsg: "Email is invalid! Please check the address.",
                            goodEmail: false,
                            usernameError: true,
                            //creationResultModalOpen: true
                        });
                        
                    }, uiDelay);
                    break;
                default:
                    break;
            }
        });     
    }
    //
    async emailCheck(url){
        const response = await fetch(url + "/Account/EmailCheck", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                email: this.state.username
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    createNewUserWrapper(url){
        this.setState({
            createUserLoading: "loading disabled"
        });
        if(this.handleFormValidation()){
            console.log("validation passed");
            //
            var result = this.registerUser(url);
            result.then((data) => {
                console.log(data);
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            creationResultModalIcon: "checkmark circle green",
                            creationResultModalHeader: "Success",
                            creationResultModalText: "User Creation Succeeded!",
                            creationResultModalOpen: true
                        });   
                        setTimeout(() => { window.location.reload(); }, 1000);                     
                        break;
                    case 400:
                        this.setState({
                            creationResultModalIcon: "warning circle red",
                            creationResultModalHeader: "Error",
                            creationResultModalText: "User Creation Failed!\n\n" + data.body.Message,
                            creationResultModalOpen: true
                        });     
                        break;
                    default:
                        break;
                }                
            });
        }
        else {            
            console.log("validation failed!");
            setTimeout(() => {
                this.setState({
                    createUserLoading: ""
                });
            }, 200)
        }
        
    }
    //
    async registerUser(url){
        const response = await fetch(url + "/Account/RegisterUser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                username: this.state.username,
                email: this.state.username,
                phone1: this.state.phone1,
                phone2: this.state.phone2,
                companyId: this.state.userIsStaff ? -99 : this.state.companyId,
                userRole: this.state.roleId,
                apptDuration: this.state.apptDuration,
                postcode: !this.state.postcodeError ? this.state.input_createUser_postcode : null
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleCompanyChange(event, data){
        this.setState({
            companyValue: data.value,
            companyId: data.value
        })
    }
    //
    handleRoleChange(event, data ){
        this.setState({
            roleValue: data.value,
            roleId: data.value
        })
        if(this.state.roleError && data.value > 0){
            this.setState({
                roleError: false
            });
        }
        if(data.value === 6){
            this.setState({
                segment_createUser_agent: "",
            });
        }
        else {
            this.setState({
                segment_createUser_agent: "IQ-Hidden",
            });
        }
    }
    //
    handleIsStaffCheckboxChange(event){
        this.setState({
            userIsStaff: event.target.checked,
            companyValue: ""
        });
        if(event.target.checked){
            this.setState({
                companyError: false
            });
        }
        else if(!(this.state.companyId > 0)){
            this.setState({
                companyError: true
            });
        }
    }
    //
    handlePhone1Change(event){
        this.setState({
            phone1: event.target.value
        });
        if(this.state.phone1Error && event.target.value !== ""){
            this.setState({
                phone1Error: false
            });
        }
    }
    handlePhone2Change(event){
        this.setState({
            phone2: event.target.value
        });
        if(this.state.phone2Error && event.target.value !== ""){
            this.setState({
                phone2Error: false
            });
        }
    }
    //
    handleFormValidation(){
        var firstNameError = (this.state.firstName === "" || this.state.firstName === "undefined");
        var lastNameError = (this.state.lastName === "" || this.state.lastName === "undefined");
        var usernameError = (this.state.username === "" || this.state.username === "undefined");
        //var phone1Error = (this.state.phone1 === "" || this.state.phone1 === "undefined");
        //var phone2Error = (this.state.phone2 === "" || this.state.phone2 === "undefined");
        var companyError = (!(this.state.companyId > 0) && !this.state.userIsStaff);
        var roleError = (!(this.state.roleId > 0));
        var postcodeError = (this.state.roleId === 6 && (this.state.input_createUser_postcode === "" || this.state.input_createUser_postcode === "undefined"));
        //
        this.setState({
            firstNameError: firstNameError,
            lastNameError: lastNameError,
            usernameError: usernameError,
            //phone1Error: phone1Error,
            //phone2Error: phone2Error,
            companyError: companyError,
            roleError: roleError,
            postcodeError: postcodeError,
        })
        //
        if(!firstNameError && !lastNameError && !usernameError && !companyError && !roleError && !postcodeError) return true;
        else return false;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="add user large"/>&emsp;Create New User</h5></Label>
                    <br/><br/><br/>
                    <Form>                                
                        <div className="Admin-Div-Name-Region">
                            <Form.Field error={this.state.firstNameError}>
                            <div className="Admin-Div-FirstName-Outer">
                                <label>First Name</label>
                                <div className="Admin-Div-FirstName-Input">
                                    <input placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange}/>
                                </div>
                            </div>
                            </Form.Field>
                            &emsp;
                            <Form.Field error={this.state.lastNameError}>
                            <div className="Admin-Div-LastName-Outer">
                                <label>Last Name</label>
                                <div className="Admin-Div-LastName-Input">
                                    <input placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange}/>
                                </div>
                            </div>
                            </Form.Field>
                        </div>                                
                    </Form>
                    <br/>
                    <Form error={this.state.badEmail} success={this.state.goodEmail}>
                        <Form.Field error={this.state.usernameError}>
                            <label>Username (email)</label>
                            <div className="Admin-Div-Username">
                                <input placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />&emsp;
                                <button className={"ui button blue " + this.state.usernameLoading} onClick={() => {this.emailCheckWrapper(this.props.url);}}>Check</button>
                            </div>
                        </Form.Field>
                        <Message
                            error
                            header='Error'
                            content={this.state.badEmailMsg}
                        />
                        <Message
                            success
                            header='Success'
                            content="This Email is available and not currently in use."
                        />
                        
                    </Form>
                    <br/>
                    <Form>
                        <Form.Field error={this.state.phone1Error}>
                            <label>Contact Phone 1</label>
                            <div className="Admin-Div-Phone1">
                                <input placeholder="Phone 1" value={this.state.phone1} onChange={this.handlePhone1Change} />&emsp;
                            </div>
                        </Form.Field>
                        <Form.Field error={this.state.phone2Error}>
                            <label>Contact Phone 2</label>
                            <div className="Admin-Div-Phone2">
                                <input placeholder="Phone 2" value={this.state.phone2} onChange={this.handlePhone2Change} />&emsp;
                            </div>
                        </Form.Field>
                    </Form>
                    <Form>
                        <Form.Field error={this.state.companyError}>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>User Company</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown disabled={this.state.userIsStaff} fluid={true} selection={true} search={true} placeholder="Select User Company..." value={this.state.companyValue} options={this.state.companyOptions} onChange={this.handleCompanyChange}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="Admin-Div-UserIsStaff">
                                    <Label className="Admin-Label-UserIsStaff" pointing={"right"} content="User is Equilaw staff?"/>&emsp;
                                    <input type="checkbox" className="ui checkbox Admin-Checkbox-UserIsStaff" onClick={this.handleIsStaffCheckboxChange}/>
                                </div>
                            </div>
                        </Form.Field>
                        <Form.Field error={this.state.roleError}>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Role-Outer">
                                    <label>User Role</label>
                                    <div className="Admin-Div-Role">
                                        <Dropdown fluid={true} selection={true} placeholder="Select User Role..." options={this.state.roleOptions} value={this.state.roleValue} onChange={this.handleRoleChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                    </Form>
                    <br/>
                    <Segment className={this.state.segment_createUser_agent}>
                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Agent Details</h4></Label>
                        <div>
                            <br/>
                            <Form>
                                <Form.Field>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Agent Appointment Duration (in minutes)"/>&emsp;
                                        <div className="Admin-Form-Field-Sm">
                                            <input  className={this.state.passMatch} placeholder="Appointment Duration" type="text" value={this.state.input_createUser_apptDuration} onChange={this.valueChange('input_createUser_apptDuration')} />
                                        </div>
                                    </div>
                                </Form.Field>
                                <Form.Field error={this.state.postcodeError}>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Agent Home Postcode"/>&emsp;
                                        <div className="Admin-Form-Field-Sm">
                                            <input  className={this.state.passMatch} placeholder="Postcode" type="text" value={this.state.input_createUser_postcode} onChange={this.valueChange('input_createUser_postcode')} />
                                        </div>
                                    </div>
                                </Form.Field>
                            </Form>                                                       
                        </div>                                                            
                    </Segment>
                    <br/>
                    <Button className={"ui button green " + this.state.createUserLoading} content="Create User" onClick={() => this.createNewUserWrapper(this.props.url)}/>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.creationResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ creationResultModalOpen: false, createUserLoading: ""})}>
                        <Header><Icon className={this.state.creationResultModalIcon} />{this.state.creationResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.creationResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={() => this.setState({ creationResultModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>            
        );
    }
}

export default CreateUser;