import React, { Component } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Icon, Modal, Header, Form, Dropdown } from 'semantic-ui-react';
import './BigCalendarWrapper.css';
import '../node_modules/react-big-calendar/lib/addons/dragAndDrop/styles.css';
//import { DndContext } from "react-dnd";

//const Calendar = DndContext(HTML5Backend)(Dnd);
const CalendarLocalizer =  momentLocalizer(moment);

export class BigCalendarWrapperNoOutlook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minSearchLength: 3,
            value: this.props.calendarType,
            rawApptList: this.props.apptList,
            events_lunch: [],
            //events_rawAppts: [],
            events_total: [],
            calendarDefaultDate: new Date(),
            findApptButtonCss: "disabled",
            bookedModalOpen: false,
            bookingResultIcon: "",
            bookingResultHeader: "",
            bookingResultText: "",
            bookingButton_CSS: "",
            freeSlotText: "Available",
            overrideSlotText: "Override",
            fileId: this.props.fileId,
            fileInfo: "",
            divFileInfo_CSS: "IQ-Hidden",
            divMatterSearch_CSS: "IQ-Hidden",
            matterOptions: [],
            searchQuery: "",
            searching: false,
            bookingAptDate: "",
            bookingAptTime: "",
            apptBookedOK: false,
            apptSlot_Num: -1,
            isAM: null,
            eventDescription: "",
            cancelApptId: 0,
            cancelLoading: "",
        };
        //
        this.populateEvents = this.populateEvents.bind(this);
        //
        this.getMatterInfoFromFileId_Wrapper = this.getMatterInfoFromFileId_Wrapper.bind(this);
        this.getMatterInfoFromFileId = this.getMatterInfoFromFileId.bind(this);
        this.handleMatterChange = this.handleMatterChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        //
        this.bookAppt_Wrapper = this.bookAppt_Wrapper.bind(this);
        this.bookAppt = this.bookAppt.bind(this);
        //
        this.cancelBooking_Wrapper = this.cancelBooking_Wrapper.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
        //
        this.onEventDrop = this.onEventDrop.bind(this);
        //
    }    
    //
    componentDidUpdate(prevProps){
        if(this.props.apptList !== prevProps.apptList){
            this.setState({
                rawApptList: this.props.apptList,
                value: "calendar",
            });
            setTimeout(() => {
                this.populateEvents();
                this.getMatterInfoFromFileId_Wrapper(this.props.url);
            }, 100);
        }
    }
    //
    populateEvents(){
        console.log(this.props.apptList);
        let events = [];
        for(let i = 0; i < this.state.rawApptList.length; i++) {
            var sched = this.state.rawApptList[i];
            var startDate = new Date(sched.ScheduleDate);
            startDate.setHours(8);
            startDate.setMinutes(0);
            var endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            //
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 1,
                title: sched.AM_1_Appt !== null ? sched.AM_1_Appt.apptReference + " - " + sched.AM_1_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.AM_1_Appt !== null,
                isAvail: sched.AM_1_Appt === null,
                isAM: true,
                apptId: sched.AM_1_Appt !== null ? sched.AM_1_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 6,
                title: sched.AM_6_Appt !== null ? sched.AM_6_Appt.apptReference + " - " + sched.AM_6_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.AM_6_Appt !== null,
                isAvail: sched.AM_6_Appt === null,
                isAM: true,
                apptId: sched.AM_6_Appt !== null ? sched.AM_6_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 2,
                title: sched.AM_2_Appt !== null ? sched.AM_2_Appt.apptReference + " - " + sched.AM_2_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.AM_2_Appt !== null,
                isAvail: sched.AM_2_Appt === null,
                isAM: true,
                apptId: sched.AM_2_Appt !== null ? sched.AM_2_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 7,
                title: sched.AM_7_Appt !== null ? sched.AM_7_Appt.apptReference + " - " + sched.AM_7_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.AM_7_Appt !== null,
                isAvail: sched.AM_7_Appt === null,
                isAM: true,
                apptId: sched.AM_7_Appt !== null ? sched.AM_7_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 3,
                title: sched.AM_3_Appt !== null ? sched.AM_3_Appt.apptReference + " - " + sched.AM_3_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.AM_3_Appt !== null,
                isAvail: sched.AM_3_Appt === null,
                isAM: true,
                apptId: sched.AM_3_Appt !== null ? sched.AM_3_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 8,
                title: sched.AM_8_Appt !== null ? sched.AM_8_Appt.apptReference + " - " + sched.AM_8_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.AM_8_Appt !== null,
                isAvail: sched.AM_8_Appt === null,
                isAM: true,
                apptId: sched.AM_8_Appt !== null ? sched.AM_8_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 4,
                title: sched.AM_4_Appt !== null ? sched.AM_4_Appt.apptReference + " - " + sched.AM_4_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.AM_4_Appt !== null,
                isAvail: sched.AM_4_Appt === null,
                isAM: true,
                apptId: sched.AM_4_Appt !== null ? sched.AM_4_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 9,
                title: sched.AM_9_Appt !== null ? sched.AM_9_Appt.apptReference + " - " + sched.AM_9_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.AM_9_Appt !== null,
                isAvail: sched.AM_9_Appt === null,
                isAM: true,
                apptId: sched.AM_9_Appt !== null ? sched.AM_9_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 5,
                title: sched.AM_5_Appt !== null ? sched.AM_5_Appt.apptReference + " - " + sched.AM_5_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.AM_5_Appt !== null,
                isAvail: sched.AM_5_Appt === null,
                isAM: true,
                apptId: sched.AM_5_Appt !== null ? sched.AM_5_Appt.apptId : 0,
            });
            //LUNCH APPTS
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (30*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 10,
                title: sched.AM_10_Appt !== null ? sched.AM_10_Appt.apptReference + " - " + sched.AM_10_Appt.apptDescription : "Lunch pt. 1",
                isLunch: true,
                isBooked: sched.AM_10_Appt !== null,
                isAvail: sched.AM_10_Appt === null,
                isAM: true,
                apptId: sched.AM_10_Appt !== null ? sched.AM_10_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (30*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 6,
                title: sched.PM_6_Appt !== null ? sched.PM_6_Appt.apptReference + " - " + sched.PM_6_Appt.apptDescription : "Lunch pt. 2",
                isLunch: true,
                isBooked: sched.PM_6_Appt !== null,
                isAvail: sched.PM_6_Appt === null,
                isAM: false,
                apptId: sched.PM_6_Appt !== null ? sched.PM_6_Appt.apptId : 0,
                isOverride: true,
            });
            //
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 1,
                title: sched.PM_1_Appt !== null ? sched.PM_1_Appt.apptReference + " - " + sched.PM_1_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.PM_1_Appt !== null,
                isAvail: sched.PM_1_Appt === null,
                isAM: false,
                apptId: sched.PM_1_Appt !== null ? sched.PM_1_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 7,
                title: sched.PM_7_Appt !== null ? sched.PM_7_Appt.apptReference + " - " + sched.PM_7_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.PM_7_Appt !== null,
                isAvail: sched.PM_7_Appt === null,
                isAM: false,
                apptId: sched.PM_7_Appt !== null ? sched.PM_7_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 2,
                title: sched.PM_2_Appt !== null ? sched.PM_2_Appt.apptReference + " - " + sched.PM_2_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.PM_2_Appt !== null,
                isAvail: sched.PM_2_Appt === null,
                isAM: false,
                apptId: sched.PM_2_Appt !== null ? sched.PM_2_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 8,
                title: sched.PM_8_Appt !== null ? sched.PM_8_Appt.apptReference + " - " + sched.PM_8_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.PM_8_Appt !== null,
                isAvail: sched.PM_8_Appt === null,
                isAM: false,
                apptId: sched.PM_8_Appt !== null ? sched.PM_8_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 3,
                title: sched.PM_3_Appt !== null ? sched.PM_3_Appt.apptReference + " - " + sched.PM_3_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.PM_3_Appt !== null,
                isAvail: sched.PM_3_Appt === null,
                isAM: false,
                apptId: sched.PM_3_Appt !== null ? sched.PM_3_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 9,
                title: sched.PM_9_Appt !== null ? sched.PM_9_Appt.apptReference + " - " + sched.PM_9_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.PM_9_Appt !== null,
                isAvail: sched.PM_9_Appt === null,
                isAM: false,
                apptId: sched.PM_9_Appt !== null ? sched.PM_9_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 4,
                title: sched.PM_4_Appt !== null ? sched.PM_4_Appt.apptReference + " - " + sched.PM_4_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.PM_4_Appt !== null,
                isAvail: sched.PM_4_Appt === null,
                isAM: false,
                apptId: sched.PM_4_Appt !== null ? sched.PM_4_Appt.apptId : 0,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 10,
                title: sched.PM_10_Appt !== null ? sched.PM_10_Appt.apptReference + " - " + sched.PM_10_Appt.apptDescription : this.state.overrideSlotText,
                isLunch: false,
                isBooked: sched.PM_10_Appt !== null,
                isAvail: sched.PM_10_Appt === null,
                isAM: false,
                apptId: sched.PM_10_Appt !== null ? sched.PM_10_Appt.apptId : 0,
                isOverride: true,
            });
            startDate = endDate;
            endDate = new Date(startDate.getTime() + (sched.apptDuration*60000));
            events.push({
                start: startDate,
                end: endDate,
                apptSlot_Num: 5,
                title: sched.PM_5_Appt !== null ? sched.PM_5_Appt.apptReference + " - " + sched.PM_5_Appt.apptDescription : this.state.freeSlotText,
                isLunch: false,
                isBooked: sched.PM_5_Appt !== null,
                isAvail: sched.PM_5_Appt === null,
                isAM: false,
                apptId: sched.PM_5_Appt !== null ? sched.PM_5_Appt.apptId : 0,
            });
        }
        this.setState({
            events_total: events,
        });
    }
    //
    cancelBooking_Wrapper(){
        this.setState({
            cancelLoading: "loading disabled",
        });
        var result = this.cancelBooking(this.props.url);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            bookingResultIcon: "checkmark orange icon",
                            bookingResultHeader: "Cancel Success!",
                            bookingResultText: "Your chosen appointment has been successfully cancelled.",
                            bookedModalOpen: true,
                            apptBookedOK: true,
                            cancelLoading: "",
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Cancel Appt Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookedModalOpen: true,
                            cancelLoading: "",
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
    }
    //
    async cancelBooking(url){
        const response = await fetch(url + "/Schedule/CancelApptForPortal", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                apptId: this.state.cancelApptId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getMatterInfoFromFileId_Wrapper(url){
        if(this.state.fileId != null && this.state.fileId > 0){
            var result = this.getMatterInfoFromFileId(url);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        //console.log(data.body);  
                        this.setState({
                            fileInfo: data.body,
                            divFileInfo_CSS: "",
                            divMatterSearch_CSS: "IQ-Hidden",
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Booking Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookedModalOpen: true,
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                divFileInfo_CSS: "IQ-Hidden",
                divMatterSearch_CSS: "",
            });
        }
    }
    //
    handleMatterChange(event, data){
        //console.log("Matter change triggered...");
        this.setState({
            fileId: data.value,
        });
    }
    //
    handleSearchChange = (e, { searchQuery }) => {
        //console.log("Search query: " + searchQuery);
        if(searchQuery.length >= this.state.minSearchLength && searchQuery !== "001" && searchQuery !== "002"){
            this.setState({ 
                searchQuery: searchQuery,
                searching: true,
            });
            var result = this.getMattersFromReferenceSearch(this.props.url, searchQuery);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        //console.log(data.body);  
                        this.setState({                            
                            matterOptions: data.body,
                            searching: false,
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Booking Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookedModalOpen: true,
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                matterOptions: [],
                searching: false,
            });
        }
    }
    //
    async getMattersFromReferenceSearch(url, searchQuery){        
        const response = await fetch(url + "/Matters/GetMattersByPartialReference_ForDropdown", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                matRef: searchQuery,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    async getMatterInfoFromFileId(url){        
        const response = await fetch(url + "/Matters/GetMatterInfoFromFileId", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.state.fileId,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    bookAppt_Wrapper(val){
        var results = this.bookAppt(this.props.url);
        results.then((data) => {
            switch(data.httpCode){
                case 200:
                    //console.log("appt booking result: " + data.body);  
                    this.setState({
                        bookingResultIcon: "checkmark green icon",
                        bookingResultHeader: "Booking Success!",
                        bookingResultText: "Your chosen appointment has been successfully booked. You will receive confirmation of your booking via email.",
                        bookedModalOpen: true,
                        apptBookedOK: true,
                        bookingButton_CSS: "",
                    });
                    break;
                case 400:
                    console.log(data.body.Message);
                    this.setState({
                        bookingResultIcon: "warning circle red",
                        bookingResultHeader: "Booking Error!",
                        bookingResultText: data.body.Message + " Please attempt your booking again. If this error persists, then please contact our scheduling team on: 01452 376702",
                        bookedModalOpen: true,
                        bookingButton_CSS: "",
                    });
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async bookAppt(url, val){
        let newApptDate = new Date(this.state.event.start);
        console.log("appt date hour = " + newApptDate.getHours());
        //
        if(newApptDate.getFullYear() > 2000){
            const response = await fetch(url + "/Schedule/BookApptForPortal", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: localStorage.token,
                    agentUserId: this.props.agentId,
                    fileId: this.state.fileId,
                    apptDate: newApptDate,
                    hourOffset: newApptDate.getHours(),
                })
            });
            const responseObject = {
                httpCode: response.status,
                body: response.status === 200 || response.status === 400 ? await response.json() : ""
            }
            return responseObject;
        }
        else {
            const responseObject = {
                httpCode: "FAIL",
                body: "FAIL"
            }
            return responseObject;
        }
    }
    //
    onEventDrop(event, start, end, allDay){
        console.log(start);
    }
    //
    render() {
        let today = moment();
        let timeRange_Start = today.set('hour', 7).set('minutes', 30).toDate();
        let timeRange_End = today.set('hour', 18).set('minutes', 30).toDate();
        //
        if(this.state.value === 'calendar'){
            return (
                <Calendar
                    events={this.state.events_total}
                    // draggableAccessor={event => true}
                    onEventDrop={this.onEventDrop}
                    localizer={CalendarLocalizer}
                    resizable={false}
                    min={timeRange_Start}
                    max={timeRange_End}
                    formats={{timeGutterFormat: 'HH:mm',
                              eventTimeRangeFormat: ({
                                start,
                                end
                              }, culture, local) =>
                              local.format(start, 'HH:mm', culture) + '-' +
                              local.format(end, 'HH:mm', culture)
                            }}
                    messages={{allDay: '', time: ''}}
                    views={["week"]}
                    defaultView={'week'}
                    step={30}
                    timeslots={1}
                    showMultiDayTimes={false}
                    defaultDate={this.state.calendarDefaultDate}
                    onSelectEvent={ event => {
                            if(event.isAvail){
                                this.setState({
                                    value: 'selected',
                                    event: event,
                                    calendarDefaultDate: event.start,
                                    option1_date: event.start,
                                });
                            }
                            else if(event.isBooked){
                                this.setState({
                                    value: 'booked',
                                    event: event,
                                    bookingAptDate: event.start,
                                    fileId: event.fileId,
                                    apptSlot_Num: event.apptSlot_Num,
                                    isAM: event.isAM,
                                    eventDescription: event.title,
                                    cancelApptId: event.apptId,
                                });
                            }
                        }
                    }
                    eventPropGetter={
                        (event) => {
                            let lunchStyle = {
                                //backgroundColor: "#db2828", //red
                                backgroundColor: "#F4911E", //orange
                            };
                            let bookedStyle = {
                                backgroundColor: "#00395A",
                            };
                            let availStyle = {
                                //backgroundColor: "green",
                                backgroundColor: "#3CB371",
                            };
                            // let availAMStyle = {
                            //     backgroundColor: "#32CD32",
                            // };
                            let overrideStyle = {
                                backgroundColor: "#808080",
                            }
                            if(event.isLunch && !event.isBooked){
                                return {
                                    className: "lunch",
                                    style: lunchStyle,
                                }
                            }
                            else if(event.isBooked){
                                return {
                                    className: "booked",
                                    style: bookedStyle,
                                }
                            }
                            else if(event.isAvail && !event.isOverride){
                                // if(event.isAM){
                                //     return {
                                //         className: "emtpy",
                                //         style: availAMStyle,
                                //     }
                                // }
                                // else {
                                    return {
                                        className: "emtpy",
                                        style: availStyle,
                                    }
                                //}
                            }
                            else if(event.isOverride){
                                return {
                                    className: "Override",
                                    style: overrideStyle,
                                }
                            }
                            else return {}
                        }
                    }
                />
            )
        } else if(this.state.value === "selected") {
            let bookingAptDate = moment(this.state.event.start).format('MMMM Do YYYY');
            let bookingAptTime = moment(this.state.event.start).format('H:mm - ') + moment(this.state.event.end).format('H:mm a');
            return (
                <div>
                    <div className="has-text-right">
                        <i className="fa fa-times" aria-hidden="true" onClick={this._cancelBooking}></i>
                        <br/><br/>
                    </div>
                    <div className="has-text-centered has-text-weight-semibold">
                    <br/><br/>
                        <div className={this.state.divFileInfo_CSS}>
                            <h2>{"Matter selected: " + this.state.fileInfo}</h2>
                        </div>
                        <div className={this.state.divMatterSearch_CSS}>
                            <div className="Div-Matter-Dropdown">
                                <Form>
                                    <Form.Field>
                                        <div>
                                            <label>Enter Matter Reference:</label>
                                            <div className="Div-Matter-Dropdown-Inner">
                                                <Dropdown 
                                                    fluid={true} 
                                                    selection={true} 
                                                    search={true} 
                                                    placeholder="Enter Matter Reference..." 
                                                    value={this.state.fileId}
                                                    options={this.state.matterOptions}
                                                    onChange={this.handleMatterChange}
                                                    onSearchChange={this.handleSearchChange}
                                                    loading={this.state.searching}
                                                />
                                            </div>
                                        </div>
                                    </Form.Field>
                                </Form>
                            </div>
                        </div>
                        <h2>{bookingAptDate}</h2>
                        <h2 className={this.state.event.isOverride ? "H2-Selected-Override" : "Admin-Edit-Hide"}>Override Slot!</h2>
                        <h2>{bookingAptTime}</h2>
                    </div>
                    <div className="has-text-centered">
                        <br/><br/>
                        <Button className="red" onClick={() => {this.setState({ value: 'calendar', });}}>
                            <Icon className="reply icon" />
                            Back
                        </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <Button className={"green " + this.state.bookingButton_CSS} size="small" onClick={() => {
                            this.setState({
                                selectedOption: "1",
                                bookingButton_CSS: "loading disabled",
                            });
                            this.bookAppt_Wrapper();
                        }}><Icon className="checkmark"/>&nbsp;Book</Button>
                        <br/><br/><br/><br/>
                    </div>
                    <Modal className="Appt-Modal-Booked" open={this.state.bookedModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ bookedModalOpen: false})}>
                        <Header ><Icon className={this.state.bookingResultIcon}/>{this.state.bookingResultHeader}</Header>
                        <Modal.Content>
                            {this.state.bookingResultText}
                        </Modal.Content> 
                        <Modal.Actions>
                            <Button onClick={() => {
                                if(this.state.apptBookedOK) {
                                    //window.location.reload();
                                    console.log("has url id? " + (this.props.history.location.search.split("id=")[1] > 0));
                                    var urlHasFileId = (this.props.history.location.search.split("id=")[1] > 0)
                                    if(!urlHasFileId) {
                                        console.log("resetting file id info...");
                                        this.setState({
                                            fileId: 0,
                                            fileInfo: "",
                                            divFileInfo_CSS: "IQ-Hidden",
                                            divMatterSearch_CSS: "",
                                        });
                                    }
                                    this.props.loadAgentAppts(this.props.url);
                                }
                                this.setState({
                                    bookedModalOpen: false,
                                    apptBookedOK: false,
                                });
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            )
        } else if(this.state.value === "booked") {
            let bookingAptDate = moment(this.state.event.start).format('MMMM Do YYYY');
            let bookingAptTime = moment(this.state.event.start).format('H:mm - ') + moment(this.state.event.end).format('H:mm a');
            return (
                <div>
                    <div className="has-text-centered has-text-weight-semibold">
                        <br/><br/>
                        <h2>{this.state.eventDescription}</h2>
                        <h2>{bookingAptDate}</h2>
                        <h2>{bookingAptTime}</h2>
                    </div>
                    <div className="has-text-centered">
                        <br/><br/>
                        <Button className="red" onClick={() => {this.setState({ value: 'calendar', });}}>
                            <Icon className="reply icon" />
                            Back
                        </Button>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <Button className={"orange ui button " + this.state.cancelLoading} size="small" onClick={() => {
                            this.cancelBooking_Wrapper();
                        }}><Icon className="checkmark"/>&nbsp;Cancel</Button>
                        <br/><br/><br/><br/>
                    </div>
                    <Modal className="Appt-Modal-Booked" open={this.state.bookedModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ bookedModalOpen: false})}>
                        <Header ><Icon className={this.state.bookingResultIcon}/>{this.state.bookingResultHeader}</Header>
                        <Modal.Content>
                            {this.state.bookingResultText}
                        </Modal.Content> 
                        <Modal.Actions>
                            <Button onClick={() => {
                                if(this.state.apptBookedOK) {
                                    //window.location.reload();
                                    console.log("has url id? " + (this.props.history.location.search.split("id=")[1] > 0));
                                    var urlHasFileId = (this.props.history.location.search.split("id=")[1] > 0)
                                    if(!urlHasFileId) {
                                        console.log("resetting file id info...");
                                        this.setState({
                                            fileId: 0,
                                            fileInfo: "",
                                            divFileInfo_CSS: "IQ-Hidden",
                                            divMatterSearch_CSS: "",
                                        });
                                    }
                                    this.props.loadAgentAppts(this.props.url);
                                }
                                this.setState({
                                    bookedModalOpen: false,
                                    apptBookedOK: false,
                                }); 
                            }}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            )
        }
    }
}

export default BigCalendarWrapperNoOutlook;
