import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form, Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import './AdminPage.css';
import moment from 'moment';
import BigCalendarWrapperNoOutlook from './BigCalendarWrapperNoOutlook';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';

class AgentApptPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            loadedAgents: false,
            loadedPostcodes: false,
            selectedAgent: "",
            futureDayReach: 21,

            segment_editUser_agent: "IQ-Hidden",

            agentOptions: [],      
            formCSS: "Admin-Edit-Hide",
            dimmerActive: true,
            editApptsLoading: "",

            loadedRotas: false,
            showCalendar_CSS: "IQ-Hidden",

            editResultModalOpen: false,
            editResultModalIcon: "warning circle red",
            editResultModalHeader: "Agent Coverage Update Fail!",
            editResultModalText: "Default error text",

            loadedAppts: false,
            dataAgentApptList: [],

            createSchedule_CSS: "Admin-Edit-Hide",
            schedDate: null,
            schedCreatedOK: false,
            createScheduleButton_CSS: "",

            fileId: (this.props.history.location.search.split("id=")[1] > 0) ? this.props.history.location.search.split("id=")[1] : 0,
        }
        //
        this.valueChange = this.valueChange.bind(this);
        this.dataLoadedChecker = this.dataLoadedChecker.bind(this);
        //        
        this.loadExistingAgentList_Wrapper = this.loadExistingAgentList_Wrapper.bind(this);
        this.loadExistingAgentList = this.loadExistingAgentList.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        //
        this.loadAgentApptList_Wrapper = this.loadAgentApptList_Wrapper.bind(this);
        this.loadAgentApptList = this.loadAgentApptList.bind(this);
        //
        this.onChange_newSchedDate = this.onChange_newSchedDate.bind(this);
        this.onClickCreateScheduleButton = this.onClickCreateScheduleButton.bind(this);
        this.createSchedule = this.createSchedule.bind(this);
        //
        this.loadExistingAgentList_Wrapper(this.props.url);
        setTimeout(() => {
            this.dataLoadedChecker();
        }, 150)
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    //
    dataLoadedChecker(){
        if(this.state.loadedAgents){
            this.setState({
                dimmerActive: false,
            });
        }
        else {
            setTimeout(() => {
                this.dataLoadedChecker();
            }, 100)
        }
    }
    //
    //
    handleAgentChange(event, data){
        this.setState({
            selectedAgent: data.value,
            formCSS: "",
            dimmerActive: true,
            createSchedule_CSS: "",
        });
        console.log("agent id? " + data.value);
        //
        setTimeout(() => {
            this.setState({
                showCalendar_CSS: "",
                //dimmerActive: false,
            });
            //this.LOAD_EXISTING_APPTS_HERE(this.props.url);
            this.loadAgentApptList_Wrapper(this.props.url);
        }, 50)
    }
    //
    onChange_newSchedDate(value){
        var d = moment.utc(value, 'MM-DD-YYYY').toDate();
        console.log("new sched date = " + d);
        if(this.state.schedDate !== d){
            this.setState({
                schedDate: d,
            });
        }
    }
    //
    loadAgentApptList_Wrapper(url){
        this.setState({
            dimmerActive: true,
        });
        var result = this.loadAgentApptList(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        dataAgentApptList: data.body,
                        dimmerActive: false,
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadAgentApptList(url){
        const response = await fetch(url + "/Schedule/GetAgentApptsForPortal", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentUserId: this.state.selectedAgent,
                numDays: this.state.futureDayReach
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    loadExistingAgentList_Wrapper(url){
        var result = this.loadExistingAgentList(url);
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    this.setState({
                        agentOptions: data.body,
                        loadedAgents: true,
                    });
                    break;
                case 400:
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingAgentList(url){
        const response = await fetch(url + "/Account/GetListOfAllAgents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onClickCreateScheduleButton(){
        this.setState({
            createScheduleButton_CSS: "disabled loading",
        });
        let newSchedDate = new Date(this.state.schedDate);
        console.log("newSchedDate: " + newSchedDate);
        if(newSchedDate != null && newSchedDate.getFullYear() > 2000) { 
            var result = this.createSchedule(this.props.url);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            editResultModalIcon: "checkmark green icon",
                            editResultModalHeader: "Agent schedule creation Success!",
                            editResultModalText: "A schedule for the chosen date has been created for your selected agent.",
                            editResultModalOpen: true,
                            schedCreatedOK: true,
                            createScheduleButton_CSS: "",
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            editResultModalIcon: "warning circle red",
                            editResultModalHeader: "Agent schedule creation Error!",
                            editResultModalText: data.body.Message + " Please attempt to create the schedule again. If this error persists, then please contact our scheduling team on: 01452 376702",
                            editResultModalOpen: true,
                            createScheduleButton_CSS: "",
                        });
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                createScheduleButton_CSS: "",
            });
        }
    }
    //
    async createSchedule(url){
        const response = await fetch(url + "/Schedule/CreateScheduleForAgent", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                agentId: this.state.selectedAgent,
                scheduleDate: this.state.schedDate,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="address card large"/>&emsp;View/Edit Agent Appointments</h5></Label>
                    <br/><br/><br/>
                    <div>
                    <Form>
                        <Form.Field className="Admin-Div-Float-Left">
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select Agent</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown fluid={true} selection={true} search={true} placeholder="Select Agent..." value={this.state.selectedAgent} options={this.state.agentOptions} onChange={this.handleAgentChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                        <Form.Field className={"Admin-Div-Float-Right Admin-Div-CreateSchedule " + this.state.createSchedule_CSS}>
                            <label className="Admin-CreateScheduleLabel">Create Agent Schedule</label>
                            <div className="Admin-DivCalendar">
                                <Calendar
                                    id= "input_clients_1_DOB"
                                    format='ddd DD/MMM/YYYY'
                                    //date={this.state.moment.format('DD/MM/YYYY')}
                                    displayYrWithMonth={true}
                                    closeOnSelect={true}
                                    openOnInputFocus={true}
                                    minDate = {new Date()}
                                    readOnly={true}
                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                    date={this.state.schedDate}
                                    onChange={this.onChange_newSchedDate}
                                    locale="en-gb"
                                    //customIcon="calendar outline icon"
                                />
                            </div>
                            <Button className={"ui button blue Admin-Div-Float-Right Admin-CreateScheduleButton " + this.state.createScheduleButton_CSS} onClick={this.onClickCreateScheduleButton}>Create</Button>
                        </Form.Field>
                    </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/><br/><br/>
                    <div className={this.state.showCalendar_CSS}>
                        <BigCalendarWrapperNoOutlook 
                            url={this.props.url} 
                            calendarType="calendar" 
                            agentId={this.state.selectedAgent} 
                            apptList={this.state.dataAgentApptList} 
                            dayReach={this.state.futureDayReach}
                            fileId={this.state.fileId}
                            loadAgentAppts={this.loadAgentApptList_Wrapper}
                            history={this.props.history}
                        >
                        </BigCalendarWrapperNoOutlook>
                    </div>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.editResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ editResultModalOpen: false, editApptsLoading: ""})}>
                        <Header><Icon className={this.state.editResultModalIcon} />{this.state.editResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.editResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={
                                () => {
                                    this.setState({ editResultModalOpen: false}); 
                                    if(this.state.schedCreatedOK) {
                                        //window.location.reload();
                                        this.loadAgentApptList_Wrapper(this.props.url);
                                    };
                                }}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default AgentApptPage;