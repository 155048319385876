import React, { Component } from 'react';
import { Segment, Label, Button, Modal, Header, Icon, Form } from 'semantic-ui-react';

class CreateCompany extends Component{
    constructor(props){
        super(props);
        this.state = {
            companyNameError: false,
            companyName: "",
            createCompanyLoading: "",

            creationResultModalOpen: false,            
            creationResultModalIcon: "warning circle red",
            creationResultModalHeader: "Company Creation Fail!",
            creationResultModalText: "Default error text"
        }
        //
        this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
        this.createNewCompanyWrapper = this.createNewCompanyWrapper.bind(this);
        this.createCompany = this.createCompany.bind(this);
        this.formValidation = this.formValidation.bind(this);
    }
    //
    handleCompanyNameChange(event){
        this.setState({
            companyName: event.target.value
        });
        if(this.state.companyNameError && event.target.value !== ""){
            this.setState({
                companyNameError: false
            });
        }
    }
    //
    formValidation(){
        var valid = false;
        if(this.state.companyName !== ""){
            this.setState({
                companyNameError: false
            });
            valid = true;
        }
        else {
            this.setState({
                companyNameError: true                
            });
            valid = false;
        }
        return valid;
    }
    //
    createNewCompanyWrapper(url){
        this.setState({
            createCompanyLoading: "loading disabled"
        });
        if(this.formValidation()){
            console.log("validation passed");
            //
            var result = this.createCompany(url);
            result.then((data) => {
                console.log(data);
                switch(data.httpCode){
                    case 200:
                        this.setState({
                            creationResultModalIcon: "checkmark circle green",
                            creationResultModalHeader: "Success",
                            creationResultModalText: "Company Creation Succeeded!",
                            creationResultModalOpen: true,
                            createCompanyLoading: ""
                        });                        
                        break;
                    case 400:
                        this.setState({
                            creationResultModalIcon: "warning circle red",
                            creationResultModalHeader: "Error",
                            creationResultModalText: "Company Creation Failed!\n\n" + data.body.Message,
                            creationResultModalOpen: true,
                            createCompanyLoading: ""
                        });     
                        break;
                    case 409: //conflict
                        this.setState({
                            creationResultModalIcon: "warning circle red",
                            creationResultModalHeader: "Error",
                            creationResultModalText: "Cannot create company, as name is already in use!!\n\n",
                            creationResultModalOpen: true,
                            createCompanyLoading: ""
                        });     
                        break;    
                    default:
                        break;
                }                
            });
        }
        else {
            setTimeout(() => {
                this.setState({
                    createCompanyLoading: ""
                });
            }, 200)
        }
        
    }
    //
    async createCompany(url){
        const response = await fetch(url + "/Matters/CreateCompany", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                companyName: this.state.companyName,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="CreateCompany-Header"><Icon className="address book large"/>&emsp;Create New Company</h5></Label>
                    <br/><br/><br/>
                    <Form>                                
                        <div className="Admin-Div-Name-Region">
                            <Form.Field error={this.state.companyNameError}>
                            <div className="Admin-Div-FirstName-Outer">
                                <label>Company Name</label>
                                <div className="Admin-Div-FirstName-Input">
                                    <input placeholder="Company Name" value={this.state.companyName} onChange={this.handleCompanyNameChange}/>
                                </div>
                            </div>
                            </Form.Field>
                            <br/>                            
                        </div>
                    </Form>
                    <br/><br/>
                    <Button className={"ui button green " + this.state.createCompanyLoading} content="Create Company" onClick={() => this.createNewCompanyWrapper(this.props.url)}/>
                </Segment>
                <div>
                    <Modal className="Admin-Modal-CreateResult" open={this.state.creationResultModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ creationResultModalOpen: false, createCompanyLoading: ""})}>
                        <Header><Icon className={this.state.creationResultModalIcon} />{this.state.creationResultModalHeader}</Header>
                        <Modal.Content>
                            <div>{this.state.creationResultModalText.split("\n").map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}</div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button color='green' onClick={() => this.setState({ creationResultModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default CreateCompany