import React, { Component } from 'react';
import './AdminPage.css';
import { Form, Dropdown, Label } from 'semantic-ui-react';
import ClientCaseAutoAllocate from './ClientCaseAutoAllocate';


class ApptAutoAllocatePage extends Component{
    constructor(props){
        super(props);
        this.state = { 
            //companyOptions: this.getCompaniesWrapper(this.props.url),            
            //roleOptions: this.getRolesWrapper(this.props.url),
            div_appt_CSS: "IQ-Hidden",
            minSearchLength: 3,
            matterOptions: [],
            fileId: 0,
            searchQuery: "",
            searching: false,
        }
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getMattersFromReferenceSearch = this.getMattersFromReferenceSearch.bind(this);
        this.handleMatterChange = this.handleMatterChange.bind(this);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    handleSearchChange = (e, { searchQuery }) => {
        //console.log("Search query: " + searchQuery);
        if(searchQuery.length >= this.state.minSearchLength && searchQuery !== "001" && searchQuery !== "002"){
            this.setState({
                searchQuery: searchQuery,
                searching: true,
            });
            var result = this.getMattersFromReferenceSearch(this.props.url, searchQuery);
            result.then((data) => {
                switch(data.httpCode){
                    case 200:
                        //console.log(data.body);  
                        this.setState({                            
                            matterOptions: data.body,
                            searching: false,
                        });
                        break;
                    case 400:
                        console.log(data.body.Message);
                        this.setState({
                            bookingResultIcon: "warning circle red",
                            bookingResultHeader: "Booking Error!",
                            bookingResultText: data.body.Message + " If this error persists, then please contact our scheduling team on: 01452 376702",
                            bookApptModalOpen: false,
                            bookedModalOpen: true,
                        });
                        break;
                    case 401:
                        this.props.history.push("/");
                        break;
                    default:
                        break;
                }
            });
        }
        else {
            this.setState({
                matterOptions: [],
                searching: false,
            });
        }
    }
    //
    async getMattersFromReferenceSearch(url, searchQuery){        
        const response = await fetch(url + "/Matters/GetMattersByPartialReference_ForDropdown", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                matRef: searchQuery,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleMatterChange(event, data){
        console.log("FileID? " + data.value);
        this.setState({
            fileId: data.value,
            div_appt_CSS: "",
        });
    }
    //
    render(){
        
        return(
            <div>
                <div className="Div-Matter-Dropdown">
                    <Form>
                        <Form.Field inline>
                            <Label className="right pointing Admin-DivInlineBlock">Enter Matter Reference:</Label>
                            <div className="Div-Matter-Dropdown-Inner Admin-DivInlineBlock">
                                <Dropdown 
                                    fluid={true} 
                                    selection={true} 
                                    search={true} 
                                    placeholder="Enter Matter Reference..." 
                                    value={this.state.fileId}
                                    options={this.state.matterOptions}
                                    onChange={this.handleMatterChange}
                                    onSearchChange={this.handleSearchChange}
                                    loading={this.state.searching}
                                />
                            </div>
                        </Form.Field>
                    </Form>
                </div>
                <br/>
                <div className={"Admin-Segment-Wrapper " + this.state.div_appt_CSS}>
                    <hr/>
                    <ClientCaseAutoAllocate url={this.props.url} matterFileId={this.state.fileId} noOutlookAppts={true}></ClientCaseAutoAllocate>
                </div>
            </div>
        );
    }
}

export default ApptAutoAllocatePage