import React, { Component } from 'react';
import './IQPage.css';
import { Segment, Label, Button, Icon, Form, Dropdown, Dimmer, Loader, Grid, } from 'semantic-ui-react';
import moment from 'moment';
import '../node_modules/react-input-calendar/style/index.css';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css'

class IQPage5Misc extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment(),
            reference: "TEST001/1",
            fileId: "",
            miscDimmerActive: false,
            dimmerActionText: "Loading...",
            button_back_CSS: "red IQ-backButton",
            button_forward_CSS: "green IQ-forwardButton",
            
            //////////////////////////////
            // Step 5. Misc Details //
            //////////////////////////////
            //
            input_misc_signing_agent: "",
            segment_misc_signing_CSS: "IQ-Hidden",
            input_misc_signing_localexclusion: "",
            //
            button_misc_mortgageDocs_Checked_YES: false,
            button_misc_mortgageDocs_Checked_NO: false,
            button_misc_mortgageDocs_YES_CSS: "ui",
            button_misc_mortgageDocs_NO_CSS: "ui",
            //
            input_misc_signing_absences: "",
            //
            button_misc_permission_thirdParties_Checked_YES: false,
            button_misc_permission_thirdParties_Checked_NO: false,
            button_misc_permission_thirdParties_YES_CSS: "ui",
            button_misc_permission_thirdParties_NO_CSS: "ui",
            //
            button_misc_permission_amend_Checked_YES: false,
            button_misc_permission_amend_Checked_NO: false,
            button_misc_permission_amend_YES_CSS: "ui",
            button_misc_permission_amend_NO_CSS: "ui",
            //
            button_misc_charityLottery_Checked_YES: false,
            button_misc_charityLottery_Checked_NO: false,
            button_misc_charityLottery_YES_CSS: "ui",
            button_misc_charityLottery_NO_CSS: "ui",
        }

        this.signingOptions = [
            {
                text: 'Equilaw Premises (Gloucester)',
                value: 'EQUILAW',
            },
            {
                text: 'Local Solicitor',
                value: 'LOCAL SOLICITOR',
            },
            {
                text: 'Own Solicitor',
                value: 'Own Solicitor',
            },
            {
                text: 'Home Visit',
                value: 'HOME VISIT',
            },
            {
                text: 'Appt. To Be Confirmed...',
                value: 'APPOINMENT TBC',
            },
        ];
        //////////////////////////////
        // Step 5. Misc Details //
        //////////////////////////////
        //
        this.populateVarsData = this.populateVarsData.bind(this);
        //
        this.onChange_misc_signing_agent = this.onChange_misc_signing_agent.bind(this);
        this.onClick_misc_mortgageDocs_YES = this.onClick_misc_mortgageDocs_YES.bind(this);
        this.onClick_misc_mortgageDocs_NO = this.onClick_misc_mortgageDocs_NO.bind(this);
        this.onChange_misc_signing_absences = this.onChange_misc_signing_absences.bind(this);
        this.onClick_misc_permission_thirdParties_YES = this.onClick_misc_permission_thirdParties_YES.bind(this);
        this.onClick_misc_permission_thirdParties_NO = this.onClick_misc_permission_thirdParties_NO.bind(this);
        this.onClick_misc_permission_amend_YES = this.onClick_misc_permission_amend_YES.bind(this);
        this.onClick_misc_permission_amend_NO = this.onClick_misc_permission_amend_NO.bind(this);
        this.onChange_misc_signing_localexclusion = this.onChange_misc_signing_localexclusion.bind(this);
        //
        this.onClick_misc_charityLottery_YES = this.onClick_misc_charityLottery_YES.bind(this);
        this.onClick_misc_charityLottery_NO = this.onClick_misc_charityLottery_NO.bind(this);
        //
        this.saveData_Wrapper_Back = this.saveData_Wrapper_Back.bind(this);
        this.saveData_Wrapper_Forward = this.saveData_Wrapper_Forward.bind(this);
        //
        setTimeout(this.populateVarsData, 150);
    }

    ///////////////////////////////
    // Step 5. Misc Details //
    ///////////////////////////////
    //
    componentDidUpdate(prevProps) {
        if (this.props.varsList !== prevProps.varsList) {
            this.setState({
                //miscDimmerActive: true,
            })
            this.populateVarsData();
        }
    }
    //
    populateVarsData(){
        var varList = this.props.varsList;
        for(var i = 0; i < varList.length; i++){
            var ovCode = varList[i].m_Item1;
            var value = varList[i].m_Item2;
            if(value != null){
            switch(ovCode){
                //
                case "14":
                    this.setState({
                        input_misc_signing_agent: value,
                    });
                break;
                case "2078":
                    this.setState({
                        input_misc_signing_localexclusion: value,
                    });
                break;
                case "4087":
                    this.setState({
                        input_misc_signing_absences: value,
                    });
                break;
                //
                case "4070":
                    if(value === "Yes") this.onClick_misc_permission_thirdParties_YES();
                    else this.onClick_misc_permission_thirdParties_NO();
                break;
                case "4076":
                    if(value === "Yes") this.onClick_misc_permission_amend_YES();
                    else this.onClick_misc_permission_amend_NO();
                break;
                //
                //
                default:
                    break;
                }
            }
        }
        setTimeout(() => {
            this.setState({
                miscDimmerActive: false,
            })
        }, 200);        
    }
    //
    onChange_misc_signing_agent(event, data){
        if(data.value === "LOCAL SOLICITOR"){
            this.setState({
                input_misc_signing_agent: data.value,
                segment_misc_signing_CSS: "",
            });
        }
        else {
            this.setState({
                input_misc_signing_agent: data.value,
                segment_misc_signing_CSS: "IQ-Hidden",
            });
        }
    }
    //
    //
    onClick_misc_mortgageDocs_YES(){
        this.setState({
            button_misc_mortgageDocs_Checked_YES: true,
            button_misc_mortgageDocs_Checked_NO: false,
            button_misc_mortgageDocs_YES_CSS: "ui green",
            button_misc_mortgageDocs_NO_CSS: "ui",
        });
    }
    //
    onClick_misc_mortgageDocs_NO(){
        this.setState({
            button_misc_mortgageDocs_Checked_YES: false,
            button_misc_mortgageDocs_Checked_NO: true,
            button_misc_mortgageDocs_YES_CSS: "ui",
            button_misc_mortgageDocs_NO_CSS: "ui red",
        });
    }
    //
    onChange_misc_signing_absences(event){
        this.setState({
            input_misc_signing_absences: event.target.value
        });
    }
    //
    //
    onClick_misc_permission_thirdParties_YES(){
        this.setState({
            button_misc_permission_thirdParties_Checked_YES: true,
            button_misc_permission_thirdParties_Checked_NO: false,
            button_misc_permission_thirdParties_YES_CSS: "ui green",
            button_misc_permission_thirdParties_NO_CSS: "ui",
        });
    }
    //
    onClick_misc_permission_thirdParties_NO(){
        this.setState({
            button_misc_permission_thirdParties_Checked_YES: false,
            button_misc_permission_thirdParties_Checked_NO: true,
            button_misc_permission_thirdParties_YES_CSS: "ui",
            button_misc_permission_thirdParties_NO_CSS: "ui red",
        });
    }
    //
    //
    onClick_misc_permission_amend_YES(){
        this.setState({
            button_misc_permission_amend_Checked_YES: true,
            button_misc_permission_amend_Checked_NO: false,
            button_misc_permission_amend_YES_CSS: "ui green",
            button_misc_permission_amend_NO_CSS: "ui",
        });
    }
    //
    onClick_misc_permission_amend_NO(){
        this.setState({
            button_misc_permission_amend_Checked_YES: false,
            button_misc_permission_amend_Checked_NO: true,
            button_misc_permission_amend_YES_CSS: "ui",
            button_misc_permission_amend_NO_CSS: "ui red",
        });
    }
    //
    //
    onClick_misc_charityLottery_YES(){
        this.setState({
            button_misc_charityLottery_Checked_YES: true,
            button_misc_charityLottery_Checked_NO: false,
            button_misc_charityLottery_YES_CSS: "ui green",
            button_misc_charityLottery_NO_CSS: "ui",
        });
    }
    //
    onClick_misc_charityLottery_NO(){
        this.setState({
            button_misc_charityLottery_Checked_YES: false,
            button_misc_charityLottery_Checked_NO: true,
            button_misc_charityLottery_YES_CSS: "ui",
            button_misc_charityLottery_NO_CSS: "ui red",
        });
    }
    //
    onChange_misc_signing_localexclusion(event){
        this.setState({
            input_misc_signing_localexclusion: event.target.value,
        });
    }
    //
    //
    saveData_Wrapper_Forward(){
        this.setState({
            dimmerActionText: "Saving Data...",
            miscDimmerActive: true,
            button_forward_CSS: "green IQ-forwardButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, false);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        miscDimmerActive: false,
                        button_forward_CSS: "green IQ-forwardButton",
                    });        
                    this.props.forward();           
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    saveData_Wrapper_Back(){
        this.setState({
            dimmerActionText: "Saving Data...",
            miscDimmerActive: true,
            button_back_CSS: "red IQ-backButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, true);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        miscDimmerActive: false,
                        button_back_CSS: "red IQ-backButton",
                    }); 
                    this.props.back();                  
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async saveData(url, backward){
        const response = await fetch(url + "/IQ/SaveData_Step5", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.fileId,
                backward: backward,
                //
                misc_signing_agent: this.state.input_misc_signing_agent,
                misc_signing_localexclusion: this.state.input_misc_signing_localexclusion,
                misc_mortgageDocs: this.state.button_misc_mortgageDocs_Checked_YES ? true : (this.state.button_misc_mortgageDocs_Checked_NO ? false: null),
                misc_signing_absences: this.state.input_misc_signing_absences,
                //
                misc_permission_thirdParties: this.state.button_misc_permission_thirdParties_Checked_YES ? true : (this.state.button_misc_permission_thirdParties_Checked_NO ? false: null),
                misc_permission_amend: this.state.button_misc_permission_amend_Checked_YES ? true : (this.state.button_misc_permission_amend_Checked_NO ? false: null),
                //
                misc_charityLottery_optin: this.state.button_misc_charityLottery_Checked_YES ? true : (this.state.button_misc_charityLottery_Checked_NO ? false: null),
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>                
                {/* Step 5. Misc Details */}                            
                <Segment>
                    <Dimmer active={this.state.matterDataDimmerActive}>
                        <Loader>{this.state.dimmerActionText}</Loader>
                    </Dimmer>
                    <Grid columns={2} className="IQ-Grid">
                        <Grid.Row>
                            <Grid.Column>
                                <div>
                                    <div>                                        
                                        <div>
                                            <br/>
                                            <span>
                                                <p>Once we have received your mortgage offer, you will need to sign the documents in front of a Solictor.</p>
                                                <Label className="IQ-Label" pointing={"right"} content="Which representative will you be signing documentation with?"/>&emsp;
                                                <Dropdown placeholder="..." inline selection options={this.signingOptions} onChange={this.onChange_misc_signing_agent}/>
                                            </span>
                                        </div>
                                        <Segment className={this.state.segment_misc_signing_CSS}>
                                                <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Local Solicitor Exclusion</h4></Label>
                                                <br/>
                                                <br/>
                                                <Form>
                                                    <Form.Field>
                                                        <label>Are there any local solicitors that should not be used?</label>
                                                        <div className="IQ-Form-Field-Lg">
                                                            <input  className={this.state.passMatch} placeholder="Solicitor exlusion details" type="text" value={this.state.input_misc_signing_localexclusion} onChange={this.onChange_misc_signing_localexclusion} />
                                                        </div>
                                                    </Form.Field>
                                                </Form>
                                        </Segment>
                                        <br/>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"}>
                                            By default we send your mortgage documents via email.<br/><br/>Would you prefer instead to receive them via special delivery post? (additional charge of £15.00)
                                            </Label>&emsp;                                            
                                            <div className='ui buttons IQ-Div-Misc-MortgageDocs'>
                                                <Button className={this.state.button_misc_mortgageDocs_YES_CSS} role='button' onClick={this.onClick_misc_mortgageDocs_YES}>Yes</Button>
                                                <div className='or' />
                                                <Button className={this.state.button_misc_mortgageDocs_NO_CSS} role='button' onClick={this.onClick_misc_mortgageDocs_NO}>No</Button>
                                            </div>
                                        </div> 
                                        <br/>
                                        <br/>
                                        <Form>
                                            <Form.Field>
                                                <Label className="IQ-Label" content="Please tell us about any planned absences that would conflict with your signing appointment?"/>&emsp;
                                                <br/><br/>
                                                <div className="IQ-Form-Field-Lg">
                                                    <input  className={this.state.passMatch} placeholder="Planned absence details" type="text" value={this.state.input_misc_signing_absences} onChange={this.onChange_misc_signing_absences} />
                                                </div>
                                            </Form.Field>
                                        </Form>
                                        <br/>
                                        <hr/>
                                        <br/>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you give us permission to speak to third parties?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_misc_permission_thirdParties_YES_CSS} role='button' onClick={this.onClick_misc_permission_thirdParties_YES}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_misc_permission_thirdParties_NO_CSS} role='button' onClick={this.onClick_misc_permission_thirdParties_NO}>No</Button>
                                        </div>
                                        <br/>
                                        <br/>
                                        <Label className="IQ-Label" pointing={"right"} content="Do you give us permission to amend minor discrepancies?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_misc_permission_amend_YES_CSS} role='button' onClick={this.onClick_misc_permission_amend_YES}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_misc_permission_amend_NO_CSS} role='button' onClick={this.onClick_misc_permission_amend_NO}>No</Button>
                                        </div>
                                        <br/>
                                        <br/>
                                        <hr/>
                                        <br/>
                                        <Label className="IQ-Label" pointing={"right"} content="Would you like to support 'Dogs for Good' by entering the Equilaw Charity Lottery?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_misc_charityLottery_YES_CSS} role='button' onClick={this.onClick_misc_charityLottery_YES}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_misc_charityLottery_NO_CSS} role='button' onClick={this.onClick_misc_charityLottery_NO}>No</Button>
                                        </div>
                                        <br/>
                                        <Label className="IQ-Label" content="(Tickets are £3.00 and payment will be added to your final bill.)"/>
                                        <br/>
                                    </div>
                                    <br/>
                                    <hr/>
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br/>               
                    <Button className={this.state.button_back_CSS} onClick={this.saveData_Wrapper_Back}>Previous Step</Button>                    
                    <Button className={this.state.button_forward_CSS} onClick={this.saveData_Wrapper_Forward}>Submit</Button>
                    <br/><br/>
                </Segment>
            </div>
        );
    }
}

export default IQPage5Misc