import React, { Component } from 'react';
import { Segment, Label, Icon, Form, Dropdown, Dimmer, Loader, Button } from 'semantic-ui-react';
import './AdminPage.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';

var moment = require('moment');
if (!window.Promise) {
    window.Promise = Promise;
}
class AgentApptsForDate extends Component{
    constructor(props){
        super(props);
        this.editSegment = React.createRef();
        this.state = {
            schedDate: moment(new Date()),
            agentSchedules: [],
            dimmerActive: false,
            mileageDate: moment(new Date()),
            tableData: [],
            mileageDimmer_Active: false,
            showMileageDataButton_CSS: "ui button disabled",  
            existingAgentValue: "",
            agentOptions: [],
            loadedAgents: false,
            agentPicked: false,
        }
        //
        this.valueChange = this.valueChange.bind(this);
        //
        this.onChange_newSchedDate = this.onChange_newSchedDate.bind(this);
        this.getAgentScheduleSummaryForDate_Wrapper = this.getAgentScheduleSummaryForDate_Wrapper.bind(this);
        this.getAgentScheduleSummaryForDate = this.getAgentScheduleSummaryForDate.bind(this);
        //
        this.onChange_newMileageDate = this.onChange_newMileageDate.bind(this);
        this.getAgentMileageSummaryForMonth_Wrapper = this.getAgentMileageSummaryForMonth_Wrapper.bind(this);
        this.getAgentMileageSummaryForMonth = this.getAgentMileageSummaryForMonth.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.loadExistingAgentList_Wrapper = this.loadExistingAgentList_Wrapper.bind(this);
        this.loadExistingAgentList = this.loadExistingAgentList.bind(this);
        this.mileageButtonChecker = this.mileageButtonChecker.bind(this);
        //
        this.loadExistingAgentList_Wrapper(this.props.url);
        setTimeout(() => {
            this.dataLoadedChecker();
        }, 150)
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    //
    dataLoadedChecker() {
        if (this.state.loadedAgents) {
            this.setState({
                dimmerActive: false,
            });
        }
        else {
            setTimeout(() => {
                this.dataLoadedChecker();
            }, 100)
        }
    }
    //
    onChange_newSchedDate(value){
        //console.log("picker value: " + value.toString());
        this.setState({
            apptDimmer_Active: true,
        });
        var d = moment(value, 'MM-DD-YYYY').add(3, "h"); //offset time to avoid timezone & BST issues
        //console.log("new sched date = " + d.toString());
        //
        this.setState({
            schedDate: d,
        });
        //
        this.getAgentScheduleSummaryForDate_Wrapper(this.props.url, d);
    }
    //
    getAgentScheduleSummaryForDate_Wrapper(url, date){
        //console.log("sending passed date = " + date.toString());
        var result = this.getAgentScheduleSummaryForDate(url, date);
        result.then((data) => {
            this.setState({
                apptDimmer_Active: false,
            });
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        agentSchedules: data.body,
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async getAgentScheduleSummaryForDate(url, date){
        const response = await fetch(url + "/Schedule/GetAgentScheduleSummaryForDate", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                scheduleDate: date,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    onChange_newMileageDate(value){
        var d = moment(value, 'MM-DD-YYYY').add(3, "h"); //offset time to avoid timezone & BST issues
        console.log("new sched date = " + d.toString());
        //
        this.setState({
            mileageDate: d,
        });
    }
    //
    getAgentMileageSummaryForMonth_Wrapper(){
        //console.log("sending passed date = " + date.toString());
        console.log("new sched date = " + this.state.mileageDate.toString());
        var result = this.getAgentMileageSummaryForMonth(this.props.url, this.state.mileageDate);
        result.then((data) => {
            this.setState({
                mileageDimmer_Active: false,
                showMileageDataButton_CSS: "ui button",
            });
            console.log(data);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body.scheduleList
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async getAgentMileageSummaryForMonth(url, date){
        const response = await fetch(url + "/Matters/GetAgentMileageData_ForMonth", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                selectedDate: date,
                agentUserID: this.state.existingAgentValue,
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleAgentChange(event, data) {
        this.setState({
            existingAgentValue: data.value,
            agentPicked: true
        });
        setTimeout(() => {
            this.mileageButtonChecker();
        }, 100)
        
        console.log("agent user id? " + data.value);
    }
    //
    loadExistingAgentList_Wrapper(url) {
        var result = this.loadExistingAgentList(url);
        result.then((data) => {
            switch (data.httpCode) {
                case 200:
                    this.setState({
                        agentOptions: data.body,
                        loadedAgents: true,
                    });
                    break;
                case 400:
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async loadExistingAgentList(url) {
        const response = await fetch(url + "/Account/GetListOfAllAgents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    mileageButtonChecker(){
        if(this.state.agentPicked){
            this.setState({
                showMileageDataButton_CSS: "ui button",
            });
        }
    }
    //
    render(){
        const data = this.state.tableData.length > 0 ? this.state.tableData : [];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const columns = [{
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Schedule Date</h2 >,
            resizable: false,
            Cell: row => {
                var scheduleDate = new Date(row.original.scheduleDate);
                var scheduleDate_display = row.original.scheduleDate != null ? (scheduleDate.getDate() < 10 ? "0" + scheduleDate.getDate() : scheduleDate.getDate()) + "/" + months[scheduleDate.getMonth()] + "/" + scheduleDate.getFullYear() : "N/A";
                return (
                    <div>
                        <Segment className="" secondary={true} padded>
                            {scheduleDate_display}
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Number of Appts</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="" secondary={true} padded>
                            {row.original.numAppts}
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Appts with leg over 50</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="" secondary={true} padded>
                            {row.original.numApptsWithLegOver50}
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Total Mileage</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="" secondary={true} padded>
                            {row.original.totalMileage}
                        </Segment>
                    </div>
                )
            }
        }, {
            Header: <h2 className="Matters-Table-Heading">&nbsp;&nbsp;&nbsp;Leg over 50 miles</h2>,
            resizable: false,
            filterable: false,
            Cell: row => {
                return (
                    <div>
                        <Segment className="" secondary={true} padded>
                            {row.original.totalMileageLegOver50}
                        </Segment>
                    </div>
                )
            }
        }];
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="map large"/>&emsp;Agent Schedule Overview</h5></Label>
                    <br/><br/><br/>
                    <div>
                    <Form>
                        <Form.Field className={"Admin-Div-CreateSchedule"}>
                            <br/><br/><br/><br/>
                            <label className="Admin-CreateScheduleLabel">Select Date to View</label>
                            <div className="Admin-DivCalendar">
                                <Calendar
                                    id= "input_scheduleDate"
                                    format='ddd DD/MMM/YYYY'
                                    displayYrWithMonth={true}
                                    closeOnSelect={true}
                                    openOnInputFocus={true}
                                    //minDate = {(new Date()).getDate() + "/" + (new Date()).getMonth() + "/" + (new Date()).getFullYear()}
                                    minDate = {"1/11/2019"}
                                    readOnly={true}
                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                    date={this.state.schedDate}
                                    onChange={this.onChange_newSchedDate}
                                    locale="en-gb"
                                    //customIcon="calendar outline icon"
                                />
                            </div>
                        </Form.Field>
                    </Form>
                    </div>
                    <Dimmer active={this.state.dimmerActive}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/>
                    <div className={this.state.formCSS}>
                        <Segment attached="top">
                            <Dimmer active={this.state.apptDimmer_Active}>
                                <Loader>Loading</Loader>
                            </Dimmer>
                            <Form>
                                <Form.Field>
                                    <div className="Admin-Div-Company-Region div-postcode-wrapper">
                                        {this.state.agentSchedules.map(
                                            (item, i) => {
                                                return(
                                                <div className={i} key={i}>
                                                    <font>Agent:&emsp;{item.AgentName}&emsp;&emsp;&emsp;&emsp;Number of appts:&emsp;{item.ApptCount}</font>
                                                    {i !== this.state.agentSchedules.length-1 ? <hr/> : ""}
                                                </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </Form.Field>
                            </Form>
                        </Segment>
                        <br/><br/>
                    </div>
                </Segment>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="car"/>&emsp;Agent Mileage Report</h5></Label>
                    <br/><br/>
                    <div>
                        <Form>
                            <Form.Field>
                                <div className="Admin-Div-Company-Region">
                                    <div className="Admin-Div-Company-Outer">
                                        <label>Select Agent</label>
                                        <div className="Admin-Div-Company">
                                            <Dropdown fluid={true} selection={true} search={true} placeholder="Select Agent..." value={this.state.existingAgentValue} options={this.state.agentOptions} onChange={this.handleAgentChange} />
                                        </div>
                                    </div>
                                </div>
                            </Form.Field>
                            <Form.Field className={"Admin-Div-CreateSchedule"}>
                                <br/><br/><br/>
                                <label className="Admin-CreateScheduleLabel">Select Month to View</label>
                                <div className="Admin-DivCalendar">
                                    <Calendar
                                        id= "input_mileageDate"
                                        format='MMM/YYYY'
                                        displayYrWithMonth={true}
                                        closeOnSelect={true}
                                        openOnInputFocus={true}
                                        defaultView={1}
                                        minView={1}
                                        minDate = {"1/11/2019"}
                                        readOnly={true}
                                        inputFieldClass="input-calendar-value IQ-Calendar"
                                        date={this.state.mileageDate}
                                        onChange={this.onChange_newMileageDate}
                                        locale="en-gb"
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <Button className={"blue " + this.state.showMileageDataButton_CSS} onClick={() => { this.setState({ showMileageDataButton_CSS: "disabled loading", mileageDimmer_Active: true }); this.getAgentMileageSummaryForMonth_Wrapper(); }} >Show Mileage Data</Button>
                            </Form.Field>
                        </Form>
                    </div>
                    <Dimmer active={this.state.mileageDimmer_Active}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <br/><br/>
                    <Segment>
                        {data != null && data.length > 0 ? <ReactTable className="min-width: 20% !important" data={data} columns={columns} defaultPageSize={31} sortable={false} /> : <div></div>}
                    </Segment>
                </Segment>
            </div>
        );
    }
}

export default AgentApptsForDate;