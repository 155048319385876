import React, { Component } from 'react';
import './AdminPage.css';
import { Segment, Label, Icon } from 'semantic-ui-react';
import CreateUser from './Admin_CreateUser';
import EditUser from './Admin_EditUser';
import CreateCompany from './Admin_CreateCompany';
import MapSources from './Admin_MapSources';
import AgentCoveragePage from './Admin_AgentCoverage';
import AgentCoveragePageV2 from './Admin_AgentCoverage-v2';
import AgentApptPage from './Admin_AgentAppts';
import ApptAutoAllocatePage from './Admin_ApptAutoAllocate';
import SchedulingSettings from './Admin_SchedulingSettings';
import NovexUserMigration from './Admin_NovexUserMigration';

class AdminPage extends Component{
    constructor(props){
        super(props);
        this.state = { 
            //companyOptions: this.getCompaniesWrapper(this.props.url),            
            //roleOptions: this.getRolesWrapper(this.props.url),
        }        
        //
        this.getCompanies = this.getCompanies.bind(this);
        this.getCompaniesWrapper = this.getCompaniesWrapper.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.getRolesWrapper = this.getRolesWrapper.bind(this);
    }
    //
    getCompaniesWrapper(url){
        var result = this.getCompanies(url);
        var options = [];
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    for(let i = 0; i < data.body.length; i++){
                        if(data.body[i].id !== -99) options.push({ key: i, text: data.body[i].name, value: data.body[i].id });
                    }
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }      
        });
        return options;
    }
    //
    async getCompanies(url){
        const response = await fetch(url + "/Account/GetCompanies", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    getRolesWrapper(url){
        var result = this.getRoles(url);
        var options = [];
        result.then((data) => { 
            switch(data.httpCode){
                case 200:
                    for(let i = 0; i < data.body.length; i++){
                        options.push({ key: data.body[i].RoleId, text: data.body[i].RoleDescription, value: data.body[i].RoleId });
                    }
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
        return options;
    }
    //
    async getRoles(url){
        const response = await fetch(url + "/Account/GetRoles", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        const subPage = this.props.subPage;
        return(
            <div>
                <div className="Admin-Segment-Wrapper">
                    <Segment className="Admin-Style">
                        <Label className="Admin-Header-Segment" attached="top"><h3 className="Admin-Header"><Icon className="group big"/>&emsp;Admin Functions</h3></Label>
                        <br/><br/><br/>
                        {subPage === "createUser" ? <CreateUser companyOptions={this.getCompaniesWrapper(this.props.url)} roleOptions={this.getRolesWrapper(this.props.url)} history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "createCompany" ? <CreateCompany history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "editUser" ? <EditUser companyOptions={this.getCompaniesWrapper(this.props.url)} roleOptions={this.getRolesWrapper(this.props.url)} history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "mapSources" ? <MapSources companyOptions={this.getCompaniesWrapper(this.props.url)} history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "adminAgentCoverage" ? <AgentCoveragePage companyOptions={this.getCompaniesWrapper(this.props.url)} history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "adminAgentCoveragev2" ? <AgentCoveragePageV2 companyOptions={this.getCompaniesWrapper(this.props.url)} history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "adminAgentAppts" ? <AgentApptPage history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "adminApptAutoAllocate" ? <ApptAutoAllocatePage history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "schedulingSettings" ? <SchedulingSettings history={this.props.history} url={this.props.url}/> : ""}
                        {subPage === "novexMigration" ? <NovexUserMigration history={this.props.history} url={this.props.url}/> : ""}
                        <br/>
                    </Segment>
                </div>
            </div>
        );
    }
}

export default AdminPage