import React, { Component }  from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Polyline, Marker, InfoWindow } from 'react-google-maps';
import './MapMarker.css';
import { compose, withProps, withStateHandlers} from 'recompose';

const markerState = withStateHandlers({
    isOpen: true
}, {
    onToggleOpen: ({isOpen}) => () => ({isOpen: !isOpen})
})

const CustomMarker = compose(markerState)(props =>
    <Marker
        key={props.item.apptId}
        position={{ lat: props.item.postcodeLat, lng: props.item.postcodeLon}}
        apptNum={"#" + props.item.apptId}
        title={props.item.postcode}
        name={props.item.postcode}
        current={false}
        onClick={() => props.onToggleOpen() }
    >
        { props.isOpen &&
            <InfoWindow key={props.index} onCloseClick={() => props.onToggleOpen() }>
                <div>
                    {/* {props.item.apptReference === "START" ? "" : <h3 style={{ marginBottom: "-15px"}}>{"#" + (props.item.apptOrder)}</h3>}
                    {props.item.apptReference === "START" ? "" : <br/>}
                    {props.item.apptReference === "START" ? <h3 style={{ marginBottom: "-15px"}}>{props.item.apptReference + (props." / FINISH")}</h3> : props.item.apptReference}
                    {props.item.apptReference === "START" ? "" : <br/>}
                    {props.item.apptReference === "START" ? "" : props.item.surname} */}
                    {(props.item.apptReference === "START" || props.item.apptReference === "FINISH") ? "" : <h3 style={{ marginBottom: "-15px"}}>{"#" + (props.item.apptOrder)}</h3>}
                    {(props.item.apptReference === "START" || props.item.apptReference === "FINISH") ? "" : <br/>}
                    {props.item.apptReference === "START" ? <h3 style={{ marginBottom: "-15px"}}>{props.item.apptReference + ((props.item.usingStartPostcodeOverride || props.item.usingFinishPostcodeOverride) ? "" : " / FINISH")}</h3> : props.item.apptReference === "FINISH" ? <h3 style={{ marginBottom: "-15px"}}>{props.item.apptReference}</h3> : props.item.apptReference}
                    {(props.item.apptReference === "START" || props.item.apptReference === "FINISH") ? "" : <br/>}
                    {props.item.surname}
                    <br/>
                    {props.item.postcode}
                </div>
            </InfoWindow>
        }
    </Marker>
)

const MapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC8UK5QfaqlfF_r9e4OUG__h90qhUfTDGs&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: <div style={{ height: "700px" }} />,
        mapElement: <div style={{ height: "700px" }} />
    }),
    withScriptjs,
    withGoogleMap
    )(props =>
        <GoogleMap
            key={props.markers.length}
            defaultZoom={props.mapComponentZoom}
            defaultCenter={props.mapComponentCentre}
        >
            <Polyline
                path={props.polylineDecoded}
                geodesic={true}
                options={{
                    strokeColor: "#669DF6",
                    strokeOpacity: 0.85,
                    strokeWeight: 4,
                }}
            >
            </Polyline>
            {                    
                props.markers.map(
                    (item, i) =>
                    {
                        //return (item.apptReference !== "FINISH" ?  <CustomMarker key={i} item={item} index={i}></CustomMarker> : "")
                        return (item.apptReference !== "FINISH" && !item.cancelled ? <CustomMarker key={i} item={item} index={i}></CustomMarker> : ((item.usingStartPostcodeOverride || item.usingFinishPostcodeOverride) ? <CustomMarker key={i} item={item} index={i}></CustomMarker> : ""))
                    }
            )}
        </GoogleMap>
    );

class AdminGoogleMap extends Component{
    
    render(){
        return(
            <div>
                <MapComponent 
                    //googleAPIKey={this.state.googleAPIKey}
                    polylineDecoded={this.props.polylineDecoded}
                    markers={this.props.dataAgentApptList}
                    mapComponentZoom={this.props.mapZoom}
                    mapComponentCentre={this.props.mapCentre}
                />
            </div>
        )
    }
}
export default AdminGoogleMap;
