import React, { Component } from 'react';
import { Segment, Label, Icon } from 'semantic-ui-react';

class Terms extends Component{
    render(){
        return(
            <div>
                <Segment className="Admin-Style">
                    <Label className="Admin-Header-Segment" attached="top"><h3 className="Admin-Header"><Icon className="balance scale big"/>&emsp;Terms</h3></Label>
                        <br/><br/>
                        <h3>Disclaimer & Legals</h3>
                        <p>
                        It is a condition of our allowing you free access to the material on this website that you accept these terms and conditions.
                        </p>
                        <h3>Intellectual Property</h3>
                        <p>
                        The intellectual property rights in all material displayed on this website are owned by Equilaw Ltd. You may print or download extracts of the materials on this website for your personal use. Any other use of material on this website is strictly forbidden. You may not copy or disseminate printed or downloaded extracts. You will be liable for any damage or harm arising from any infringement of intellectual property rights, whether belonging to us or any third party, if that damage or harm results from your act, omission or default.
                        </p>
                        <h3>Legal, financial and other information provided</h3>
                        <p>
                        This website is for information purposes only. It is not intended to provide specific legal or financial advice, therefore you should not rely on it. Please contact us if you require specific advice.
                        <br/><br/>
                        Information is provided “as is” without warranty of any kind, either express or implied including, but not limited to, the implied warranties of merchantability, satisfactory quality, fitness for a particular purpose or non-infringement. All such warranties are excluded to the fullest extent permitted by law.
                        <br/><br/>
                        Information may be incomplete, out of date or inaccurate and may contain technical inaccuracies or typographical errors. Information may be changed or updated without notice.
                        <br/><br/>
                        We make no representations whatsoever about any other website which you may access through this one. When you access a website which does not belong to us, please understand that it is independent from us, and that we have no control over the contents of that website.
                        <br/><br/>
                        In addition, a link to a non-Equilaw website does not mean that we endorse or accept any responsibility for the content, or the use, of such website or the goods or services offered by the owners of such website.
                        <br/><br/>
                        We will not agree to framed links or links which give the impression we have approved or are associated with any other website.
                        </p>
                        <h3>Limitation on liability</h3>
                        <p>
                        In no event will we be liable to any party for any direct, indirect, special or other consequential damages for any use of this website or on any other hyper-linked website or arising in contract tort or otherwise including, without limitation, any lost profits, business interruption, loss of programs or other data on your information handling system or otherwise, even if we are expressly advised of the possibility of such damages.
                        <br/><br/>
                        We provide this website “as is” and “as available” and without any warranty or condition, express or implied. Without prejudice to the generality of the foregoing, we do not warrant that the website will operate without interruptions, be timely, secure or error-free and shall not be liable for any loss or damage arising from the unavailability of the website from time to time or from your inability to use the website.
                        </p>
                        <h3>Distance Selling</h3>
                        <p>
                        If we have concluded our contract (our instructions) without meeting you, for example by email, telephone or letter, of if our meeting to discuss or conclude the contract (your matter) takes place away from our business premises you may be entitled to a 14 day cooling off period during which you may cancel the contract under the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013.
                        <br/><br/>
                        If you would like us to start work before the cancellation period ends you will need to confirm this to us in writing so we will need you to sign below to confirm.
                        </p>
                        <h3>Complaints</h3>
                        <p>
                        If your complaint has not been resolved to your satisfaction within 8 weeks of making it, you may be able to complain to the Legal Ombudsman.  However the Ombudsman’s powers are limited in certain respects as they can only accept complaints from individuals and certain small businesses and organisations.  Full details of the kinds of complaints which the Ombudsman will accept can be found on their website at http://www.legalombudsman.org.uk and their address and contact details are: PO Box 6806 Wolverhampton WV1 9WJ (email: enquiries@legalombudsman.org.uk; tel: 0300 555 0333 between 8.30 am to 5.30 pm.
                        <br/><br/>
                        If you are entitled to make a complaint to the Ombudsman, please note that you must normally do so within 12 months of receiving a final written response from us about your complaint. Complaints must be made within 6 years from the date of the problem happening or 3 years from when you found out about the problem. The Ombudsman will not accept complaints where the act/omission/date of awareness was before 6 October 2010.  You should also note that the Ombudsman may not consider a complaint about a bill if you have applied to the court for it to be assessed.
                        </p>
                        <h3>Charity Lottery</h3>
                        <p>
                        This lottery is run as a Small Society Lottery, licenced by permit number 15/00791/LOTA and authorised by Cheltenham Borough Council under Schedule 11 of the Gambling Act 2005.  Entry is open to clients of Equilaw Limited (trading as Equilaw) provided that they are natural persons aged 16 or over at the time of entry.  Tickets are priced at £3.00 each and entries are limited to one per matter for each client.  Entry is conditional on submission of a signed entry confirmation and payment of the ticket price, included as part of the final bill on completion of the matter.  There is a single prize of £350.00 which may be taken in cash or offset against legal fees or other charges.  There is no alternative prize.  Winners will be chosen at random and all entrants will have an equal chance of winning.  Draws will take place every 3 months and winners will be notified in person.  There is no requirement for winners to attend our offices in order to claim their prize.  Equilaw Limited may publicise the lottery as it sees fit in order to raise the profile of the lottery and to increase awareness of and interest in the lottery itself as well as the aims of the charity (Maggie’s – Cheltenham) and purchase of a ticket confers agreement and consent for any winners to be mentioned by name in the context of such publicity unless the entrant has expressly notified Equilaw Limited to the contrary.  Signature and return of the entry form constitutes acceptance of the above terms and conditions of entry.
                        </p>
                </Segment>
                <br/><br/>
            </div>
        );
    }
}

export default Terms