import React, { Component } from 'react';
import './IQPage.css';
import { Segment, Label, Button, Icon, Form, Dimmer, Loader, Modal, Header, Input, Progress } from 'semantic-ui-react';
import moment from 'moment';
import Calendar from 'react-input-calendar';
import '../node_modules/react-input-calendar/style/index.css';
import axios from 'axios';

class IQPage3Occupiers extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment(),
            reference: "TEST001/1",
            fileId: "999",
            occupiersDimmerActive: false,
            dimmerActionText: "Loading...",
            button_back_CSS: "red IQ-backButton",
            button_forward_CSS: "green IQ-forwardButton",
            messageModalOpen: false,
            messageModalIcon_CSS: "warning circle icon",
            messageModalTitle: "",
            messageModalContent: "",
            uploaded: 0,
            uploadModalOpen: false,
            //
            //////////////////////////////
            // Step 3. Occupier Details //
            //////////////////////////////           
            button_occupiers_Checked_YES: false,
            button_occupiers_Checked_NO: false,
            button_occupiers_YES_CSS: "ui",
            button_occupiers_NO_CSS: "ui",
            segment_occupiers_CSS: "IQ-Hidden",
            button_occupiers_contact_Checked_YES: false,
            button_occupiers_contact_Checked_NO: false,
            button_occupiers_contact_YES_CSS: "ui",
            button_occupiers_contact_NO_CSS: "ui",            
            // OCCUPIER #1
            input_occupiers_1_Title: "",
            input_occupiers_1_Initials: "",
            input_occupiers_1_FirstName: "",
            input_occupiers_1_MiddleName: "",
            input_occupiers_1_Surname: "",
            input_occupiers_1_DOB: moment(),
            button_occupiers_1_capacity_Checked_YES: false,
            button_occupiers_1_capacity_Checked_NO: false,
            button_occupiers_1_capacity_YES_CSS: "ui",
            button_occupiers_1_capacity_NO_CSS: "ui",
            segment_occupiers_1_capacity_CSS: "IQ-Hidden",
            button_occupiers_1_capacity_evidence_upload_CSS: "ui blue",
            file_occupiers_1_capacity_evidence: "",
            file_occupiers_1_capacity_evidence_data: "",
            button_occupiers_1_capacity_carePlan_upload_CSS: "ui blue",
            file_occupiers_1_capacity_carePlan: "",
            file_occupiers_1_capacity_carePlan_data: "",
            // OCCUPIER #2
            segment_occupiers_2_CSS: "IQ-Hidden",
            button_occupiers_2_Expand_CSS: "ui plus blue IQ-Button-Plus",
            button_occupiers_2_Expand: false,
            icon_occupiers_2_Expand_CSS: "plus icon IQ-Icon-Offset",
            input_occupiers_2_Title: "",
            input_occupiers_2_Initials: "",
            input_occupiers_2_FirstName: "",
            input_occupiers_2_MiddleName: "",
            input_occupiers_2_Surname: "",
            input_occupiers_2_DOB: moment(),
            button_occupiers_2_capacity_Checked_YES: false,
            button_occupiers_2_capacity_Checked_NO: false,
            button_occupiers_2_capacity_YES_CSS: "ui",
            button_occupiers_2_capacity_NO_CSS: "ui",
            segment_occupiers_2_capacity_CSS: "IQ-Hidden",
            button_occupiers_2_capacity_evidence_upload_CSS: "ui blue",
            file_occupiers_2_capacity_evidence: "",
            file_occupiers_2_capacity_evidence_data: "",
            button_occupiers_2_capacity_carePlan_upload_CSS: "ui blue",
            file_occupiers_2_capacity_carePlan: "",
            file_occupiers_2_capacity_carePlan_data: "",
            // OCCUPIER #3
            segment_occupiers_3_CSS: "IQ-Hidden",
            button_occupiers_3_Expand_CSS: "ui plus blue IQ-Button-Plus",
            button_occupiers_3_Expand: false,
            icon_occupiers_3_Expand_CSS: "plus icon IQ-Icon-Offset",
            input_occupiers_3_Title: "",
            input_occupiers_3_Initials: "",
            input_occupiers_3_FirstName: "",
            input_occupiers_3_MiddleName: "",
            input_occupiers_3_Surname: "",
            input_occupiers_3_DOB: moment(),
            button_occupiers_3_capacity_Checked_YES: false,
            button_occupiers_3_capacity_Checked_NO: false,
            button_occupiers_3_capacity_YES_CSS: "ui",
            button_occupiers_3_capacity_NO_CSS: "ui",
            segment_occupiers_3_capacity_CSS: "IQ-Hidden",
            button_occupiers_3_capacity_evidence_upload_CSS: "ui blue",
            file_occupiers_3_capacity_evidence: "",
            file_occupiers_3_capacity_evidence_data: "",
            button_occupiers_3_capacity_carePlan_upload_CSS: "ui blue",
            file_occupiers_3_capacity_carePlan: "",
            file_occupiers_3_capacity_carePlan_data: "",
            // OCCUPIER #4
            segment_occupiers_4_CSS: "IQ-Hidden",
            button_occupiers_4_Expand_CSS: "ui plus blue IQ-Button-Plus",
            button_occupiers_4_Expand: false,
            icon_occupiers_4_Expand_CSS: "plus icon IQ-Icon-Offset",
            input_occupiers_4_Title: "",
            input_occupiers_4_Initials: "",
            input_occupiers_4_FirstName: "",
            input_occupiers_4_MiddleName: "",
            input_occupiers_4_Surname: "",
            input_occupiers_4_DOB: moment(),
            button_occupiers_4_capacity_Checked_YES: false,
            button_occupiers_4_capacity_Checked_NO: false,
            button_occupiers_4_capacity_YES_CSS: "ui",
            button_occupiers_4_capacity_NO_CSS: "ui",
            segment_occupiers_4_capacity_CSS: "IQ-Hidden",
            button_occupiers_4_capacity_evidence_upload_CSS: "ui blue",
            file_occupiers_4_capacity_evidence: "",
            file_occupiers_4_capacity_evidence_data: "",
            button_occupiers_4_capacity_carePlan_upload_CSS: "ui blue",
            file_occupiers_4_capacity_carePlan: "",
            file_occupiers_4_capacity_carePlan_data: "",
        }
        ////////////////////////////////
        // Step 3. Occupiers Details //
        ////////////////////////////////
        this.valueChange = this.valueChange.bind(this);
        this.upload_File = this.upload_File.bind(this);
        //
        this.populateVarsData = this.populateVarsData.bind(this);
        //
        this.onClick_occupiers_YES = this.onClick_occupiers_YES.bind(this);
        this.onClick_occupiers_NO = this.onClick_occupiers_NO.bind(this);
        this.onClick_occupiers_consent_YES = this.onClick_occupiers_consent_YES.bind(this);
        this.onClick_occupiers_consent_NO = this.onClick_occupiers_consent_NO.bind(this);
        // OCCUPIER #1
        this.onChange_occupiers_1_DOB = this.onChange_occupiers_1_DOB.bind(this);
        this.onClick_occupiers_1_capacity_YES = this.onClick_occupiers_1_capacity_YES.bind(this);
        this.onClick_occupiers_1_capacity_NO = this.onClick_occupiers_1_capacity_NO.bind(this);
        this.onClick_occupiers_1_capacity_evidence_upload = this.onClick_occupiers_1_capacity_evidence_upload.bind(this);
        this.onClick_occupiers_1_capacity_carePlan_upload = this.onClick_occupiers_1_capacity_carePlan_upload.bind(this);
        // OCCUPIER #2
        this.onClick_occupiers_2_Expand = this.onClick_occupiers_2_Expand.bind(this);
        this.onChange_occupiers_2_DOB = this.onChange_occupiers_2_DOB.bind(this);
        this.onClick_occupiers_2_capacity_YES = this.onClick_occupiers_2_capacity_YES.bind(this);
        this.onClick_occupiers_2_capacity_NO = this.onClick_occupiers_2_capacity_NO.bind(this);
        this.onClick_occupiers_2_capacity_evidence_upload = this.onClick_occupiers_2_capacity_evidence_upload.bind(this);
        this.onClick_occupiers_2_capacity_carePlan_upload = this.onClick_occupiers_2_capacity_carePlan_upload.bind(this);
        // OCCUPIER #3
        this.onClick_occupiers_3_Expand = this.onClick_occupiers_3_Expand.bind(this);
        this.onChange_occupiers_3_DOB = this.onChange_occupiers_3_DOB.bind(this);
        this.onClick_occupiers_3_capacity_YES = this.onClick_occupiers_3_capacity_YES.bind(this);
        this.onClick_occupiers_3_capacity_NO = this.onClick_occupiers_3_capacity_NO.bind(this);
        this.onClick_occupiers_3_capacity_evidence_upload = this.onClick_occupiers_3_capacity_evidence_upload.bind(this);
        this.onClick_occupiers_3_capacity_carePlan_upload = this.onClick_occupiers_3_capacity_carePlan_upload.bind(this);
        // OCCUPIER #4
        this.onClick_occupiers_4_Expand = this.onClick_occupiers_4_Expand.bind(this);
        this.onChange_occupiers_4_DOB = this.onChange_occupiers_4_DOB.bind(this);
        this.onClick_occupiers_4_capacity_YES = this.onClick_occupiers_4_capacity_YES.bind(this);
        this.onClick_occupiers_4_capacity_NO = this.onClick_occupiers_4_capacity_NO.bind(this);
        this.onClick_occupiers_4_capacity_evidence_upload = this.onClick_occupiers_4_capacity_evidence_upload.bind(this);
        this.onClick_occupiers_4_capacity_carePlan_upload = this.onClick_occupiers_4_capacity_carePlan_upload.bind(this);
        //
        this.saveData_Wrapper_Forward = this.saveData_Wrapper_Forward.bind(this);
        this.saveData_Wrapper_Back = this.saveData_Wrapper_Back.bind(this);
        //
        //
        setTimeout(this.populateVarsData, 150);
    }
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    componentDidUpdate(prevProps) {
        if (this.props.varsList !== prevProps.varsList) {
            this.setState({
                //occupiersDimmerActive: true,
            })
            this.populateVarsData();
        }
      }
    //
    populateVarsData(){
        var varList = this.props.varsList;
        for(var i = 0; i < varList.length; i++){
            var ovCode = varList[i].m_Item1;
            var value = varList[i].m_Item2;
            if(value != null){
            switch(ovCode){
                case "2653":
                    if(value === "Yes") this.onClick_occupiers_YES();
                    else this.onClick_occupiers_NO();
                break;
                //
                case "3901":
                    if(value === "Yes") this.onClick_occupiers_consent_YES();
                    else this.onClick_occupiers_consent_NO();
                break;
                case "1355":
                    this.setState({
                        input_occupiers_1_Title: value,
                    });
                break;
                case "2269":
                    this.setState({
                        input_occupiers_1_Initials: value,
                    });
                break;
                case "1357":
                    this.setState({
                        input_occupiers_1_Surname: value,
                    });
                break;
                case "85":
                    this.setState({
                        input_occupiers_1_MiddleName: value,
                    });
                break;
                case "1356":
                    this.setState({
                        input_occupiers_1_FirstName: value,
                    });
                break;
                case "1358":
                    var d1 = moment(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_occupiers_1_DOB: d1,
                    });
                break;
                case "4812":
                    if(value === "Yes") this.onClick_occupiers_1_capacity_YES();
                    else this.onClick_occupiers_1_capacity_NO();
                break;
                //
                //
                case "1359":
                    this.setState({
                        input_occupiers_2_Title: value,
                    });
                break;
                case "2270":
                    this.setState({
                        input_occupiers_2_Initials: value,
                    });
                break;
                case "1361":
                    this.setState({
                        input_occupiers_2_Surname: value,
                    });
                break;
                case "86":
                    this.setState({
                        input_occupiers_2_MiddleName: value,
                    });
                break;
                case "1360":
                    this.setState({
                        input_occupiers_2_FirstName: value,
                    });
                break;
                case "1362":
                    var d2 = moment(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_occupiers_2_DOB: d2,
                    });
                break;
                case "4813":
                    if(value === "Yes") this.onClick_occupiers_2_capacity_YES();
                    else this.onClick_occupiers_2_capacity_NO();
                break;
                //
                //
                case "1865":
                    this.setState({
                        input_occupiers_3_Title: value,
                    });
                break;
                case "2271":
                    this.setState({
                        input_occupiers_3_Initials: value,
                    });
                break;
                case "1867":
                    this.setState({
                        input_occupiers_3_Surname: value,
                    });
                break;
                case "87":
                    this.setState({
                        input_occupiers_3_MiddleName: value,
                    });
                break;
                case "1866":
                    this.setState({
                        input_occupiers_3_FirstName: value,
                    });
                break;
                case "1868":
                    var d3 = moment(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_occupiers_3_DOB: d3,
                    });
                break;
                case "4814":
                    if(value === "Yes") this.onClick_occupiers_3_capacity_YES();
                    else this.onClick_occupiers_3_capacity_NO();
                break;
                //
                //
                case "1869":
                    this.setState({
                        input_occupiers_4_Title: value,
                    });
                break;
                case "2272":
                    this.setState({
                        input_occupiers_4_Initials: value,
                    });
                break;
                case "1871":
                    this.setState({
                        input_occupiers_4_Surname: value,
                    });
                break;
                case "88":
                    this.setState({
                        input_occupiers_4_MiddleName: value,
                    });
                break;
                case "1870":
                    this.setState({
                        input_occupiers_4_FirstName: value,
                    });
                break;
                case "1872":
                    var d4 = moment(value, 'DD-MM-YYYY').toDate();
                    this.setState({
                        input_occupiers_4_DOB: d4,
                    });
                break;
                case "4815":
                    if(value === "Yes") this.onClick_occupiers_4_capacity_YES();
                    else this.onClick_occupiers_4_capacity_NO();
                break;
                //
                //
                default:
                    break;
                }
            }
        }
        setTimeout(() => {
            this.setState({
                occupiersDimmerActive: false,
            })
        }, 200);        
    }
    //    
    //
    onClick_occupiers_YES(){
        this.setState({
            button_occupiers_Checked_YES: true,
            button_occupiers_Checked_NO: false,
            button_occupiers_YES_CSS: "ui green",
            button_occupiers_NO_CSS: "ui",
            segment_occupiers_CSS: "",
        });
    }
    //
    onClick_occupiers_NO(){
        this.setState({
            button_occupiers_Checked_YES: false,
            button_occupiers_Checked_NO: true,
            button_occupiers_YES_CSS: "ui",
            button_occupiers_NO_CSS: "ui red",
            segment_occupiers_CSS: "IQ-Hidden",
        });
    }
    //    
    //
    onClick_occupiers_consent_YES(){
        this.setState({
            button_occupiers_consent_Checked_YES: true,
            button_occupiers_consent_Checked_NO: false,
            button_occupiers_consent_YES_CSS: "ui green",
            button_occupiers_consent_NO_CSS: "ui",
        });
    }
    //
    onClick_occupiers_consent_NO(){
        this.setState({
            button_occupiers_consent_Checked_YES: false,
            button_occupiers_consent_Checked_NO: true,
            button_occupiers_consent_YES_CSS: "ui",
            button_occupiers_consent_NO_CSS: "ui red",
        });
    } 
    //    
    ////////////////////////
    // OCCUPIER 1 DETAILS //
    ////////////////////////
    //
    onChange_occupiers_1_DOB(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_occupiers_1_DOB: d,
        });
    }
    //
    onClick_occupiers_1_capacity_YES(){
        this.setState({
            button_occupiers_1_capacity_Checked_YES: true,
            button_occupiers_1_capacity_Checked_NO: false,
            button_occupiers_1_capacity_YES_CSS: "ui green",
            button_occupiers_1_capacity_NO_CSS: "ui",
            segment_occupiers_1_capacity_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_occupiers_1_capacity_NO(){
        this.setState({
            button_occupiers_1_capacity_Checked_YES: false,
            button_occupiers_1_capacity_Checked_NO: true,
            button_occupiers_1_capacity_YES_CSS: "ui",
            button_occupiers_1_capacity_NO_CSS: "ui red",
            segment_occupiers_1_capacity_CSS: "",
        });
    } 
    onClick_occupiers_1_capacity_evidence_upload(){
        this.setState({
            button_occupiers_1_capacity_evidence_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_1_capacity_evidence_data, this.state.file_occupiers_1_capacity_evidence.name, 3, "occupiers_1_capacity_evidence", () => {
            this.setState({
                button_occupiers_1_capacity_evidence_upload_CSS: "ui blue",
            });
        });
    }
    onClick_occupiers_1_capacity_carePlan_upload(){
        this.setState({
            button_occupiers_1_capacity_carePlan_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_1_capacity_carePlan_data, this.state.file_occupiers_1_capacity_carePlan.name, 3, "occupiers_1_capacity_carePlan", () => {
            this.setState({
                button_occupiers_1_capacity_carePlan_upload_CSS: "ui blue",
            });
        });
    }
    //    
    ////////////////////////
    // OCCUPIER 2 DETAILS //
    ////////////////////////
    //
    onClick_occupiers_2_Expand(){        
        this.setState({
            segment_occupiers_2_CSS: !this.state.button_occupiers_2_Expand ? "" : "IQ-Hidden",
            button_occupiers_2_Expand_CSS: !this.state.button_occupiers_2_Expand ? "ui red IQ-Button-Plus" : "ui blue IQ-Button-Plus",
            icon_occupiers_2_Expand_CSS: !this.state.button_occupiers_2_Expand ? "minus icon IQ-Icon-Offset" : "plus icon IQ-Icon-Offset",
            button_occupiers_2_Expand: !this.state.button_occupiers_2_Expand,
        });
    }
    //
    onChange_occupiers_2_DOB(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_occupiers_2_DOB: d,
        });
    }
    //
    onClick_occupiers_2_capacity_YES(){
        this.setState({
            button_occupiers_2_capacity_Checked_YES: true,
            button_occupiers_2_capacity_Checked_NO: false,
            button_occupiers_2_capacity_YES_CSS: "ui green",
            button_occupiers_2_capacity_NO_CSS: "ui",
            segment_occupiers_2_capacity_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_occupiers_2_capacity_NO(){
        this.setState({
            button_occupiers_2_capacity_Checked_YES: false,
            button_occupiers_2_capacity_Checked_NO: true,
            button_occupiers_2_capacity_YES_CSS: "ui",
            button_occupiers_2_capacity_NO_CSS: "ui red",
            segment_occupiers_2_capacity_CSS: "",
        });
    } 
    onClick_occupiers_2_capacity_evidence_upload(){
        this.setState({
            button_occupiers_2_capacity_evidence_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_2_capacity_evidence_data, this.state.file_occupiers_2_capacity_evidence.name, 3, "occupiers_2_capacity_evidence", () => {
            this.setState({
                button_occupiers_2_capacity_evidence_upload_CSS: "ui blue",
            });
        });
    }
    onClick_occupiers_2_capacity_carePlan_upload(){
        this.setState({
            button_occupiers_2_capacity_carePlan_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_2_capacity_carePlan_data, this.state.file_occupiers_2_capacity_carePlan.name, 3, "occupiers_2_capacity_carePlan", () => {
            this.setState({
                button_occupiers_2_capacity_carePlan_upload_CSS: "ui blue",
            });
        });
    }
    //    
    ////////////////////////
    // OCCUPIER 3 DETAILS //
    ////////////////////////
    //
    onClick_occupiers_3_Expand(){        
        this.setState({
            segment_occupiers_3_CSS: !this.state.button_occupiers_3_Expand ? "" : "IQ-Hidden",
            button_occupiers_3_Expand_CSS: !this.state.button_occupiers_3_Expand ? "ui red IQ-Button-Plus" : "ui blue IQ-Button-Plus",
            icon_occupiers_3_Expand_CSS: !this.state.button_occupiers_3_Expand ? "minus icon IQ-Icon-Offset" : "plus icon IQ-Icon-Offset",
            button_occupiers_3_Expand: !this.state.button_occupiers_3_Expand,
        });
    }
    //
    onChange_occupiers_3_DOB(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_occupiers_3_DOB: d,
        });
    }
    //
    onClick_occupiers_3_capacity_YES(){
        this.setState({
            button_occupiers_3_capacity_Checked_YES: true,
            button_occupiers_3_capacity_Checked_NO: false,
            button_occupiers_3_capacity_YES_CSS: "ui green",
            button_occupiers_3_capacity_NO_CSS: "ui",
            segment_occupiers_3_capacity_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_occupiers_3_capacity_NO(){
        this.setState({
            button_occupiers_3_capacity_Checked_YES: false,
            button_occupiers_3_capacity_Checked_NO: true,
            button_occupiers_3_capacity_YES_CSS: "ui",
            button_occupiers_3_capacity_NO_CSS: "ui red",
            segment_occupiers_3_capacity_CSS: "",
        });
    } 
    onClick_occupiers_3_capacity_evidence_upload(){
        this.setState({
            button_occupiers_3_capacity_evidence_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_3_capacity_evidence_data, this.state.file_occupiers_3_capacity_evidence.name, 3, "occupiers_3_capacity_evidence", () => {
            this.setState({
                button_occupiers_3_capacity_evidence_upload_CSS: "ui blue",
            });
        });
    }
    onClick_occupiers_3_capacity_carePlan_upload(){
        this.setState({
            button_occupiers_3_capacity_carePlan_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_3_capacity_carePlan_data, this.state.file_occupiers_3_capacity_carePlan.name, 3, "occupiers_3_capacity_carePlan", () => {
            this.setState({
                button_occupiers_3_capacity_carePlan_upload_CSS: "ui blue",
            });
        });
    }
    //    
    ////////////////////////
    // OCCUPIER 4 DETAILS //
    ////////////////////////
    //
    onClick_occupiers_4_Expand(){        
        this.setState({
            segment_occupiers_4_CSS: !this.state.button_occupiers_4_Expand ? "" : "IQ-Hidden",
            button_occupiers_4_Expand_CSS: !this.state.button_occupiers_4_Expand ? "ui red IQ-Button-Plus" : "ui blue IQ-Button-Plus",
            icon_occupiers_4_Expand_CSS: !this.state.button_occupiers_4_Expand ? "minus icon IQ-Icon-Offset" : "plus icon IQ-Icon-Offset",
            button_occupiers_4_Expand: !this.state.button_occupiers_4_Expand,
        });
    }
    //
    onChange_occupiers_4_DOB(value){
        var d = moment(value, 'MM-DD-YYYY').toDate();
        this.setState({
            input_occupiers_4_DOB: d,
        });
    }
    //
    onClick_occupiers_4_capacity_YES(){
        this.setState({
            button_occupiers_4_capacity_Checked_YES: true,
            button_occupiers_4_capacity_Checked_NO: false,
            button_occupiers_4_capacity_YES_CSS: "ui green",
            button_occupiers_4_capacity_NO_CSS: "ui",
            segment_occupiers_4_capacity_CSS: "IQ-Hidden",
        });
    }
    //
    onClick_occupiers_4_capacity_NO(){
        this.setState({
            button_occupiers_4_capacity_Checked_YES: false,
            button_occupiers_4_capacity_Checked_NO: true,
            button_occupiers_4_capacity_YES_CSS: "ui",
            button_occupiers_4_capacity_NO_CSS: "ui red",
            segment_occupiers_4_capacity_CSS: "",
        });
    } 
    onClick_occupiers_4_capacity_evidence_upload(){
        this.setState({
            button_occupiers_4_capacity_evidence_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_4_capacity_evidence_data, this.state.file_occupiers_4_capacity_evidence.name, 3, "occupiers_4_capacity_evidence", () => {
            this.setState({
                button_occupiers_4_capacity_evidence_upload_CSS: "ui blue",
            });
        });
    }
    onClick_occupiers_4_capacity_carePlan_upload(){
        this.setState({
            button_occupiers_4_capacity_carePlan_upload_CSS: "ui blue loading",
            uploadModalOpen: true,
        });
        this.upload_File(this.state.file_occupiers_4_capacity_carePlan_data, this.state.file_occupiers_4_capacity_carePlan.name, 3, "occupiers_4_capacity_carePlan", () => {
            this.setState({
                button_occupiers_4_capacity_carePlan_upload_CSS: "ui blue",
            });
        });
    }
    //
    //
    upload_File(fileData, fileName, iqStep, iqUploadVar, callback){
        //
        if(fileData !== "" && fileName !== ""){
            axios.post(
                this.props.url + "/IQ/fileUpload",
                {
                    "token": localStorage.token,
                    "content": fileData,
                    "fileName": fileName,
                    "fileId": this.props.fileId,
                    "iqStep": iqStep,
                    "iqUploadVar": iqUploadVar,
                    "copyToDPS": true,
                },
                { 
                    onUploadProgress: ProgressEvent => {
                        this.setState({
                            uploaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                        })
                    },
                },
            )
            .then((response) => {
                console.log(response);
                this.setState({
                    messageModalIcon_CSS: "checkmark circle icon",
                    messageModalTitle: "Upload Success",
                    messageModalContent: "Your document has been successfully uploaded!",
                    messageModalOpen: true,
                    uploadModalOpen: false,
                });
                callback();
            })
            .catch((error) => {    
                //https://github.com/axios/axios#handling-errors
                console.log(error);
                switch(error.response.status){
                    case 401:
                        this.props.history.push("/");
                        break;
                    case 500:
                        this.setState({                            
                            messageModalIcon_CSS: "warning circle icon",
                            messageModalTitle: "Error",
                            messageModalContent: "A server error occured during your file upload.\r\n\r\nPlease try again, but if the error persists then please contact Equilaw",
                            messageModalOpen: true,
                            uploadModalOpen: false,
                        });
                        callback();
                        break;
                    default:
                        break;
                }
            });
        }
        else callback();
    }
    //
    //
    saveData_Wrapper_Forward(){
        this.setState({
            dimmerActionText: "Saving Data...",
            occupiersDimmerActive: true,
            button_forward_CSS: "green IQ-forwardButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, false);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        occupiersDimmerActive: false,
                        button_forward_CSS: "green IQ-forwardButton",
                    });        
                    this.props.forward();           
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    saveData_Wrapper_Back(){
        this.setState({
            dimmerActionText: "Saving Data...",
            occupiersDimmerActive: true,
            button_back_CSS: "red IQ-backButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, true);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        occupiersDimmerActive: false,
                        button_back_CSS: "red IQ-backButton",
                    }); 
                    this.props.back();                  
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async saveData(url, backward){
        const response = await fetch(url + "/IQ/SaveData_Step3", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.fileId,
                backward: backward,
                //
                hasOccupiers: this.state.button_occupiers_Checked_YES ? true : (this.state.button_occupiers_Checked_NO ? false: null),
                contactConsent: this.state.button_occupiers_consent_Checked_YES ? true : (this.state.button_occupiers_consent_Checked_NO ? false: null),
                occupier1_Title: this.state.input_occupiers_1_Title,
                occupier1_Initials: this.state.input_occupiers_1_Initials,
                occupier1_Surname: this.state.input_occupiers_1_Surname,
                occupier1_MiddleName: this.state.input_occupiers_1_MiddleName,
                occupier1_FirstName: this.state.input_occupiers_1_FirstName,
                occupier1_DOB: this.state.input_occupiers_1_DOB,
                occupier1_capacity: this.state.button_occupiers_1_capacity_Checked_YES ? true : (this.state.button_occupiers_1_capacity_Checked_NO ? false: null),
                //
                hasOccupier2: this.state.button_occupiers_2_Expand,
                occupier2_Title: this.state.input_occupiers_2_Title,
                occupier2_Initials: this.state.input_occupiers_2_Initials,
                occupier2_Surname: this.state.input_occupiers_2_Surname,
                occupier2_MiddleName: this.state.input_occupiers_2_MiddleName,
                occupier2_FirstName: this.state.input_occupiers_2_FirstName,
                occupier2_DOB: this.state.input_occupiers_2_DOB,
                occupier2_capacity: this.state.button_occupiers_2_capacity_Checked_YES ? true : (this.state.button_occupiers_2_capacity_Checked_NO ? false: null),
                //
                hasOccupier3: this.state.button_occupiers_3_Expand,
                occupier3_Title: this.state.input_occupiers_3_Title,
                occupier3_Initials: this.state.input_occupiers_3_Initials,
                occupier3_Surname: this.state.input_occupiers_3_Surname,
                occupier3_MiddleName: this.state.input_occupiers_3_MiddleName,
                occupier3_FirstName: this.state.input_occupiers_3_FirstName,
                occupier3_DOB: this.state.input_occupiers_3_DOB,
                occupier3_capacity: this.state.button_occupiers_3_capacity_Checked_YES ? true : (this.state.button_occupiers_3_capacity_Checked_NO ? false: null),
                //
                hasOccupier4: this.state.button_occupiers_4_Expand,
                occupier4_Title: this.state.input_occupiers_4_Title,
                occupier4_Initials: this.state.input_occupiers_4_Initials,
                occupier4_Surname: this.state.input_occupiers_4_Surname,
                occupier4_MiddleName: this.state.input_occupiers_4_MiddleName,
                occupier4_FirstName: this.state.input_occupiers_4_FirstName,
                occupier4_DOB: this.state.input_occupiers_4_DOB,
                occupier4_capacity: this.state.button_occupiers_4_capacity_Checked_YES ? true : (this.state.button_occupiers_4_capacity_Checked_NO ? false: null),
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>                
                {/* Step 3. Occupiers Details */}
                <Dimmer active={this.state.occupiersDimmerActive}>
                    <Loader>{this.state.dimmerActionText}</Loader>
                </Dimmer>
                <Label className="IQ-Label" pointing={"right"} content="Are there any occupiers at the property?"/>&emsp;
                <div className='ui buttons'>
                    <Button className={this.state.button_occupiers_YES_CSS} role='button' onClick={this.onClick_occupiers_YES}>Yes</Button>
                    <div className='or' />
                    <Button className={this.state.button_occupiers_NO_CSS} role='button' onClick={this.onClick_occupiers_NO}>No</Button>
                </div>
                <Segment className={this.state.segment_occupiers_CSS}>
                    <Label className="IQ-Label" pointing={"right"} content="Do we have your consent to contact the occupiers?"/>&emsp;
                    <div className='ui buttons'>
                        <Button className={this.state.button_occupiers_consent_YES_CSS} role='button' onClick={this.onClick_occupiers_consent_YES}>Yes</Button>
                        <div className='or' />
                        <Button className={this.state.button_occupiers_consent_NO_CSS} role='button' onClick={this.onClick_occupiers_consent_NO}>No</Button>
                    </div>
                    <Segment>
                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="users icon large"/>&emsp;Occupier 1 Details</h4></Label>                                   
                        <br/><br/>
                        <div>
                            <Form>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Title:"/>&emsp; 
                                    <div className="IQ-Form-Field-Xs">
                                        <input  className={this.state.passMatch} placeholder="Title" type="text" value={this.state.input_occupiers_1_Title} onChange={this.valueChange('input_occupiers_1_Title')} />
                                    </div>                                                                                    
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Initials:"/>&emsp; 
                                    <div className="IQ-Form-Field-Xs">
                                        <input  className={this.state.passMatch} placeholder="Initials" type="text" value={this.state.input_occupiers_1_Initials} onChange={this.valueChange('input_occupiers_1_Initials')} />
                                    </div>                                                                                    
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_occupiers_1_FirstName} onChange={this.valueChange('input_occupiers_1_FirstName')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_occupiers_1_MiddleNames} onChange={this.valueChange('input_occupiers_1_MiddleNames')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_occupiers_1_Surname} onChange={this.valueChange('input_occupiers_1_Surname')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <div>
                                        <div className="IQ-DivInline">
                                            <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                                            
                                        </div>                                                                            
                                        <Calendar
                                            id= "input_occupiers_1_DOB"
                                            format='ddd DD/MMM/YYYY'
                                            //date={this.state.moment.format('DD/MM/YYYY')}
                                            displayYrWithMonth={true}
                                            closeOnSelect={true}
                                            openOnInputFocus={true}
                                            readOnly={true}
                                            inputFieldClass="input-calendar-value IQ-Calendar"
                                            date={this.state.input_occupiers_1_DOB}
                                            onChange={this.onChange_occupiers_1_DOB}
                                            locale="en-gb"
                                            //customIcon="calendar outline icon"
                                        />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Does the occupier have the mental capacity to sign the occupier's waiver?"/>&emsp;
                                    <div className='ui buttons'>
                                        <Button className={this.state.button_occupiers_1_capacity_YES_CSS} role='button' onClick={this.onClick_occupiers_1_capacity_YES}>Yes</Button>
                                        <div className='or' />
                                        <Button className={this.state.button_occupiers_1_capacity_NO_CSS} role='button' onClick={this.onClick_occupiers_1_capacity_NO}>No</Button>
                                    </div>
                                </Form.Field>
                                <Segment className={this.state.segment_occupiers_1_capacity_CSS}>
                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: No mental capacity evidence</h4></Label>
                                    <div>
                                        <br/>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"} content="Please provide medical evidence of no mental capacity for this occupier."/>&emsp;
                                            <Input id="" className="ui" type="file" onChange={this.onChange_occupiers_1_capacity_evidence_file}/>&emsp;
                                            <Button className={this.state.button_occupiers_1_capacity_evidence_upload_CSS} content="Upload" onClick={this.onClick_occupiers_1_capacity_evidence_upload}/>
                                        </div>
                                        <br/>
                                        <div>
                                            <Label className="IQ-Label" pointing={"right"} content="Please provide evidence of a long term care plan for this occupier."/>&emsp;
                                            <Input id="" className="ui" type="file" onChange={this.onChange_occupiers_1_capacity_carePlan_file}/>&emsp;
                                            <Button className={this.state.button_occupiers_1_capacity_carePlan_upload_CSS} content="Upload" onClick={this.onClick_occupiers_1_capacity_carePlan_upload}/>
                                        </div>
                                    </div>
                                </Segment>
                            </Form>
                        </div>
                    </Segment>
                    <Segment className="IQ-Segment-Min">
                        <Label className="IQ-label" attached="top">
                            <h4 className="Matters-Header">                            
                                <Icon className="users icon large IQ-Segment-Header-Align"/>&emsp;Occupier 2 Details&emsp;
                                <Button className={this.state.button_occupiers_2_Expand_CSS} onClick={this.onClick_occupiers_2_Expand}><Icon className={this.state.icon_occupiers_2_Expand_CSS}></Icon></Button>
                            </h4>
                        </Label>
                        <br/>
                        <div className={this.state.segment_occupiers_2_CSS}>
                            <br/><br/>
                            <Form>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Title:"/>&emsp; 
                                    <div className="IQ-Form-Field-Xs">
                                        <input  className={this.state.passMatch} placeholder="Title" type="text" value={this.state.input_occupiers_2_Title} onChange={this.valueChange('input_occupiers_2_Title')} />
                                    </div>                                                                                    
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Initials:"/>&emsp; 
                                    <div className="IQ-Form-Field-Xs">
                                        <input  className={this.state.passMatch} placeholder="Initials" type="text" value={this.state.input_occupiers_2_Initials} onChange={this.valueChange('input_occupiers_2_Initials')} />
                                    </div>                                                                                    
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_occupiers_2_FirstName} onChange={this.valueChange('input_occupiers_2_FirstName')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_occupiers_2_MiddleNames} onChange={this.valueChange('input_occupiers_2_MiddleNames')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                    <div className="IQ-Form-Field-Sm">
                                        <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_occupiers_2_Surname} onChange={this.valueChange('input_occupiers_2_Surname')} />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <div>
                                        <div className="IQ-DivInline">
                                            <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                                            
                                        </div>                                                                            
                                        <Calendar
                                            id= "input_occupiers_2_DOB"
                                            format='ddd DD/MMM/YYYY'
                                            //date={this.state.moment.format('DD/MM/YYYY')}
                                            displayYrWithMonth={true}
                                            closeOnSelect={true}
                                            openOnInputFocus={true}
                                            readOnly={true}
                                            inputFieldClass="input-calendar-value IQ-Calendar"
                                            date={this.state.input_occupiers_2_DOB}
                                            onChange={this.onChange_occupiers_2_DOB}
                                            locale="en-gb"
                                            //customIcon="calendar outline icon"
                                        />
                                    </div>
                                </Form.Field>
                                <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Does the occupier have the mental capacity to sign the occupier's waiver?"/>&emsp;
                                    <div className='ui buttons'>
                                        <Button className={this.state.button_occupiers_2_capacity_YES_CSS} role='button' onClick={this.onClick_occupiers_2_capacity_YES}>Yes</Button>
                                        <div className='or' />
                                        <Button className={this.state.button_occupiers_2_capacity_NO_CSS} role='button' onClick={this.onClick_occupiers_2_capacity_NO}>No</Button>
                                    </div>
                                </Form.Field>
                            </Form>
                        </div>
                        <Segment className={this.state.segment_occupiers_2_CSS + " IQ-Segment-Min"}>
                            <Label className="IQ-label" attached="top">
                                <h4 className="Matters-Header">                            
                                    <Icon className="users icon large IQ-Segment-Header-Align"/>&emsp;Occupier 3 Details&emsp;
                                    <Button className={this.state.button_occupiers_3_Expand_CSS} onClick={this.onClick_occupiers_3_Expand}><Icon className={this.state.icon_occupiers_3_Expand_CSS}></Icon></Button>
                                </h4>
                            </Label>
                            <br/>
                            <div className={this.state.segment_occupiers_3_CSS}>
                                <br/><br/>
                                <Form>
                                    <Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="Title:"/>&emsp; 
                                        <div className="IQ-Form-Field-Xs">
                                            <input  className={this.state.passMatch} placeholder="Title" type="text" value={this.state.input_occupiers_3_Title} onChange={this.valueChange('input_occupiers_3_Title')} />
                                        </div>                                                                                    
                                    </Form.Field>
                                    <Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="Initials:"/>&emsp; 
                                        <div className="IQ-Form-Field-Xs">
                                            <input  className={this.state.passMatch} placeholder="Initials" type="text" value={this.state.input_occupiers_3_Initials} onChange={this.valueChange('input_occupiers_3_Initials')} />
                                        </div>                                                                                    
                                    </Form.Field>
                                    <Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                        <div className="IQ-Form-Field-Sm">
                                            <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_occupiers_3_FirstName} onChange={this.valueChange('input_occupiers_3_FirstName')} />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                        <div className="IQ-Form-Field-Sm">
                                            <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_occupiers_3_MiddleNames} onChange={this.valueChange('input_occupiers_3_MiddleNames')} />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                        <div className="IQ-Form-Field-Sm">
                                            <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_occupiers_3_Surname} onChange={this.valueChange('input_occupiers_3_Surname')} />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                        <div>
                                            <div className="IQ-DivInline">
                                                <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                                            
                                            </div>                                                                            
                                            <Calendar
                                                id= "input_occupiers_3_DOB"
                                                format='ddd DD/MMM/YYYY'
                                                //date={this.state.moment.format('DD/MM/YYYY')}
                                                displayYrWithMonth={true}
                                                closeOnSelect={true}
                                                openOnInputFocus={true}
                                                readOnly={true}
                                                inputFieldClass="input-calendar-value IQ-Calendar"
                                                date={this.state.input_occupiers_3_DOB}
                                                onChange={this.onChange_occupiers_3_DOB}
                                                locale="en-gb"
                                                //customIcon="calendar outline icon"
                                            />
                                        </div>
                                    </Form.Field>
                                    <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Does the occupier have the mental capacity to sign the occupier's waiver?"/>&emsp;
                                    <div className='ui buttons'>
                                        <Button className={this.state.button_occupiers_3_capacity_YES_CSS} role='button' onClick={this.onClick_occupiers_3_capacity_YES}>Yes</Button>
                                        <div className='or' />
                                        <Button className={this.state.button_occupiers_3_capacity_NO_CSS} role='button' onClick={this.onClick_occupiers_3_capacity_NO}>No</Button>
                                    </div>
                                </Form.Field>
                                </Form>
                            </div>
                            <Segment className={this.state.segment_occupiers_3_CSS + " IQ-Segment-Min"}>
                                <Label className="IQ-label" attached="top">
                                    <h4 className="Matters-Header">                            
                                        <Icon className="users icon large IQ-Segment-Header-Align"/>&emsp;Occupier 4 Details&emsp;
                                        <Button className={this.state.button_occupiers_4_Expand_CSS} onClick={this.onClick_occupiers_4_Expand}><Icon className={this.state.icon_occupiers_4_Expand_CSS}></Icon></Button>
                                    </h4>
                                </Label>
                                <br/>
                                <div className={this.state.segment_occupiers_4_CSS}>
                                    <br/><br/>
                                    <Form>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Title:"/>&emsp; 
                                            <div className="IQ-Form-Field-Xs">
                                                <input  className={this.state.passMatch} placeholder="Title" type="text" value={this.state.input_occupiers_4_Title} onChange={this.valueChange('input_occupiers_4_Title')} />
                                            </div>                                                                                    
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Initials:"/>&emsp; 
                                            <div className="IQ-Form-Field-Xs">
                                                <input  className={this.state.passMatch} placeholder="Initials" type="text" value={this.state.input_occupiers_4_Initials} onChange={this.valueChange('input_occupiers_4_Initials')} />
                                            </div>                                                                                    
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="First Name:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="First Name" type="text" value={this.state.input_occupiers_4_FirstName} onChange={this.valueChange('input_occupiers_4_FirstName')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Middle Name(s):"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Middle Name(s)" type="text" value={this.state.input_occupiers_4_MiddleNames} onChange={this.valueChange('input_occupiers_4_MiddleNames')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <Label className="IQ-Label" pointing={"right"} content="Surname:"/>&emsp; 
                                            <div className="IQ-Form-Field-Sm">
                                                <input  className={this.state.passMatch} placeholder="Surname" type="text" value={this.state.input_occupiers_4_Surname} onChange={this.valueChange('input_occupiers_4_Surname')} />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <div>
                                                <div className="IQ-DivInline">
                                                    <Label className="IQ-Label IQ-Calendar-Label" pointing={"right"} content="Date of Birth:"/>&emsp;                                                                            
                                                </div>                                                                            
                                                <Calendar
                                                    id= "input_occupiers_4_DOB"
                                                    format='ddd DD/MMM/YYYY'
                                                    //date={this.state.moment.format('DD/MM/YYYY')}
                                                    displayYrWithMonth={true}
                                                    closeOnSelect={true}
                                                    openOnInputFocus={true}
                                                    readOnly={true}
                                                    inputFieldClass="input-calendar-value IQ-Calendar"
                                                    date={this.state.input_occupiers_4_DOB}
                                                    onChange={this.onChange_occupiers_4_DOB}
                                                    locale="en-gb"
                                                    //customIcon="calendar outline icon"
                                                />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                    <Label className="IQ-Label" pointing={"right"} content="Does the occupier have the mental capacity to sign the occupier's waiver?"/>&emsp;
                                    <div className='ui buttons'>
                                        <Button className={this.state.button_occupiers_4_capacity_YES_CSS} role='button' onClick={this.onClick_occupiers_4_capacity_YES}>Yes</Button>
                                        <div className='or' />
                                        <Button className={this.state.button_occupiers_4_capacity_NO_CSS} role='button' onClick={this.onClick_occupiers_4_capacity_NO}>No</Button>
                                    </div>
                                </Form.Field>
                                    </Form>
                                </div>
                            </Segment>
                        </Segment>
                    </Segment>                    
                </Segment>
                <Modal className="IQ-Modal-Message" open={this.state.messageModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className={this.state.messageModalIcon_CSS}/>&emsp;{this.state.messageModalTitle}</Header>
                    <Modal.Content>
                        <div>
                            {this.state.messageModalContent}
                        </div>                                                
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>
                </Modal>
                <br/>
                <hr/>
                <br/>               
                <Button className={this.state.button_back_CSS} onClick={this.saveData_Wrapper_Back}>Previous Step</Button>                    
                <Button className={this.state.button_forward_CSS} onClick={this.saveData_Wrapper_Forward}>Next Step</Button>
                <br/><br/>
                <Modal className="IQ-Modal-Message" open={this.state.uploadModalOpen} closeOnDimmerClick={false}>
                    <Header className="Matters-Header"><Icon className="ui icon upload"/>&emsp;File Uploading...</Header>
                    <Modal.Content>
                        <div>
                            <Progress percent={Math.round(this.state.uploaded)} progress active color="green" max="100" />
                        </div>                                                
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button color='blue' onClick={() => this.setState({ messageModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button> */}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default IQPage3Occupiers