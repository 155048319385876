import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';
//import Pusher from './push';
//import Feeds from '@pusher/feeds';
//import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
import './Notification.css';

const maxCardItems = 5;

class Notification extends Component{    
    constructor(props){
        super(props);
        this.state = {
            pusher: new Pusher("5197d4b84bfcf330f9b3", {
                cluster: "eu",
                encrypted: true,
                //authEndpoint: this.props.url + '/Pusher/Auth',                
            }),
            // feedObject: new Feeds({
            //     instanceLocator: "v1:us1:985a83bc-7c3e-4229-8e22-b80508d89ba1",
            //     authEndpoint: this.props.url + "Pusher/GetToken",
            //     //authData: null,
            //     logLevel: 2, //1= Verbose, 2= Debug, 3= Info, 4= Warning, 5= Error                
            //   }),
            //feed: null,
            //sub: null,
            coreEventList: [],
            subItems: [],
            //statusColor: "red",
            //statusText: "Disconnected",
            channels: [],
            latestCardId: 0,
            cardCount: 0,
            cardGroupCSS: "portalCard_hidden",
            portalCards_HeaderCSS: "portalCards_Header_Hidden",
        };
        //
        this.handleDismiss = this.handleDismiss.bind(this);
        //this.initFeed = this.initFeed.bind(this);
        //this.initSub = this.initSub.bind(this);
        this.fetchPastEvents_Wrapper = this.fetchPastEvents_Wrapper.bind(this);
        this.fetchPastEvents = this.fetchPastEvents.bind(this);
        //
        this.initChannel = this.initChannel.bind(this);
        this.bindToChannelEvents = this.bindToChannelEvents.bind(this);
        this.fetchChannels = this.fetchChannels.bind(this);
        // this.state.pusher.connection.bind("connecting", () => {
        //     this.setState({
        //         statusColor: "yellow",
        //         statusText: "Connecting...",
        //     });
        // });
        // this.state.pusher.connection.bind("connected", () => {
        //     this.setState({
        //         statusColor: "green",
        //         statusText: "Connected",
        //     });
        // });
        //console.log("RoleID (Notification Page): " + localStorage.roleId);
        //
        if(localStorage.roleId !== "5"){
            setTimeout(() => {this.initChannel();}, 500);
            //setTimeout(() => {this.initFeed();}, 500);
            setTimeout(() => {this.fetchPastEvents_Wrapper();}, 500);
        }
    }
    //
    // initFeed(){
    //     this.setState({
    //         feed: this.state.feedObject.feed("private-test"),
    //     });
    //     setTimeout(() => {this.initSub();}, 500);
    // }
    //
    // initSub(){
    //     let _sub = this.state.feed.subscribe({
    //         previousItems: maxCardItems,
    //         onOpen: ({next_cursor, remaining}) => {
    //             // Keep track of next_cursor and remaining if you might want to paginate
    //             // back through previous items later on [optional]
    //         },
    //         onItem: item => {
    //             console.log(item);
    //             let items = [];
    //             if(this.state.subItems.length >= maxCardItems){                    
    //                 for(let i = 1; i < maxCardItems; i++){
    //                     items.push(this.state.subItems[i]);
    //                 }
    //             }
    //             else items = this.state.subItems;
    //             //
    //             items.push({
    //                 key: item.id,
    //                 header: item.data.header,
    //                 meta: item.data.meta,
    //                 description: item.data.description,
    //             });
    //             this.setState({
    //                 subItems: items,
    //                 cardGroupCSS: "portalCard_visible",
    //             })
    //         },
    //         onError: error => {
    //             console.error("Error with subscription: " + error);
    //         }
    //     })
    //     this.setState({
    //         sub: _sub,
    //     });
    // }
    //
    fetchPastEvents_Wrapper(){
        var result = this.fetchPastEvents(this.props.url);
        //
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                    var pastEventList = [];
                    //console.log("past event list length (from server): " + data.body.length);
                    for(let i = 0; i < data.body.length; i++){
                        //if(data.body == null) console.log("found null event");
                        //else console.log("event is not null");
                        pastEventList.push(data.body[i]);
                    }
                    this.setState({
                        coreEventList: pastEventList,
                    });
                    //
                    setTimeout(() => {this.updateCardList()}, 100);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    //console.log(data.body.Message);
                    break;
            }      
        });
    }
    //
    async fetchPastEvents(url){
        const response = await fetch(this.props.url + "/Pusher/GetPastEvents", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    updateCardList(){
        let items = [];
        //
        for(let i = 0; i < this.state.coreEventList.length; i++){
            //
            let d = new Date(this.state.coreEventList[i].milestoneDate);
            let days = d.getDate();
            let months = d.getMonth() < 10 ? "0" + (d.getMonth()+1) : (d.getMonth()+1);
            let year = d.getFullYear();
            let dateDisplay = days + "/" + months + "/" + year;
            //
            items.push({
                key: i,
                header: this.state.coreEventList[i].reference,
                meta: "",
                description: this.state.coreEventList[i].milestone + " - " + dateDisplay,
            });
        }
        //
        if(items.length > 0){
            this.setState({
                subItems: items,
                cardGroupCSS: "portalCard_visible",
                portalCards_HeaderCSS: "",
            })
        }
    }
    //
    initChannel(){
        var result = this.fetchChannels(this.props.url);
        //
        result.then((data) => {
            switch(data.httpCode){
                case 200:
                //console.log(data.body);
                    if(this.state.pusher != null){
                        var _channels = this.state.channels;
                        if(localStorage.roleId === "3" || localStorage.roleId === "5") _channels.push(this.state.pusher.subscribe(data.body));                        
                        else {
                            for(let i = 0; i < data.body.length; i++){
                                if(data.body[i].includes('@') && data.body[i] !== "@") _channels.push(this.state.pusher.subscribe(data.body[i]));                                
                            }
                        }
                        this.setState({
                            channels: _channels,
                        });
                    }
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    console.log(data.body.Message);
                    break;
            }      
        });
        //
        setTimeout(() => { this.bindToChannelEvents();}, 250);
    }
    //
    async fetchChannels(){
        const response = await fetch(this.props.url + "/Pusher/GetChannels", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    bindToChannelEvents(){
        if(this.state.channels != null & this.state.channels.length > 0){
            for(let i = 0; i < this.state.channels.length; i++){
                this.state.channels[i].bind_global((event, data) => {
                    // Add all events to global event list
                    var coreEvents = this.state.coreEventList;
                    for(let i = (coreEvents.length-1); i > -1; i--){
                        if(coreEvents[i].description === "undefined - NaN/NaN/NaN") { 
                            //remove offending null item
                            let tempArray = [];
                            for(let j = 0; j < coreEvents.length; j++){
                                if(j !== i) tempArray.push(coreEvents[j]);
                            }
                            coreEvents = tempArray;
                        }
                    }
                    coreEvents.push(data);
                    //
                    //now local event list is updated, sort the event list
                    for(let i = 0; i < coreEvents.length; i++){                        
                        //
                        coreEvents.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });
                    }
                    //now prune the local event list to max list length
                    var finalCoreEvents = [];
                    if(coreEvents.length >= maxCardItems){
                        for(let i = 0; i < maxCardItems; i++){
                            if(!(coreEvents[i].reference == null)) {
                                finalCoreEvents.push(coreEvents[i]);
                            }
                        }
                    }
                    else finalCoreEvents = coreEvents;
                    //

                    this.setState({
                        coreEventList: finalCoreEvents,
                    });
                    //
                    setTimeout(() => {this.updateCardList();}, 400);
                    // let items = [];
                    // if(this.state.subItems.length >= maxCardItems){                    
                    //     for(let i = 1; i < maxCardItems; i++){
                    //         items.push(this.state.subItems[i]);
                    //     }
                    // }
                    // else items = this.state.subItems;
                    // //
                    // items.push({
                    //     key: this.state.subItems.length,
                    //     header: data.matterRef,
                    //     meta: "",
                    //     description: data.milestone + " - " + data.milestoneDate,
                    // });
                    // this.setState({
                    //     subItems: items,
                    //     cardGroupCSS: "portalCard_visible",
                    // })
                });
            }            
        }
    }
    //
    handleDismiss(){
        console.log("dismissed!")
    }
    //
    render(){
        //setPusherClient(this.state.pusher);
        return(
            <div>
                {/* <Message header="Pusher App Connection Status" content={this.state.statusText} onDismiss={this.handleDismiss} color={this.state.statusColor} /> */}
                <h2 className={this.state.portalCards_HeaderCSS} >Last {maxCardItems} most recent updates (LIVE):</h2>
                <Card.Group className={this.state.cardGroupCSS} items={this.state.subItems} />
                {/* <Message header="Header Text" content="Notification text" onDismiss={this.handleDismiss} color="blue" /> */}
            </div>
        );
    }
}

export default Notification