import React, { Component } from 'react';
import './LoginHero.css';
import { Segment, Label, Button, Modal, Header, Icon, Input, Checkbox, Form, Message } from 'semantic-ui-react';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Promise from 'promise-polyfill';
import "isomorphic-fetch"

    if (!window.Promise) {
        window.Promise = Promise;
    }

class LoginHero extends Component{    
    constructor(props){
        super(props);
        this.state = {
            token: "",
            username: localStorage.username !== null && localStorage.username !== "" && localStorage.username !== "undefined" ? localStorage.username : "",
            password: "",
            remember: localStorage.remember !== null && localStorage.remember !== "" && localStorage.remember !== "undefined" ? (localStorage.remember === "true") : false,
            forgotModalOpen: false,
            usernameForgot: "",
            LoginLoading: "",
            loginFailModalOpen: false,
            loginFailModalText: "An error occured!",
            loginPrivacyModalOpen: false,
            gdprModalOpen: false,
            termsModalOpen: false,
            gdprAccepted: false,
            gdpr_AcceptLoading: "",
        }
        //
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRememberChange = this.handleRememberChange.bind(this);
        this.handleEnterPressed = this.handleEnterPressed.bind(this);
        this.handleForgotModal = this.handleForgotModal.bind(this);
        this.handleUsernameForgotChange = this.handleUsernameForgotChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.triggerGetToken = this.triggerGetToken.bind(this);
        this.redirectToMain = this.redirectToMain.bind(this);
        this.handlePrivacyModal = this.handlePrivacyModal.bind(this);
        this.handleShowGDPR = this.handleShowGDPR.bind(this);
        this.handleTermsOpen = this.handleTermsOpen.bind(this);
        this.updateGDPRStatus_Wrapper = this.updateGDPRStatus_Wrapper.bind(this);
        this.updateGDPRStatus = this.updateGDPRStatus.bind(this);
        this.GetUserRole = this.GetUserRole.bind(this);
        //
    };
    //
    handleUsernameChange(event){
        this.setState({username: event.target.value})
    }
    //
    handlePasswordChange(event){
        this.setState({password: event.target.value})
    }
    //
    handleRememberChange(event, data){
        this.setState({remember: data.checked})
        //
        localStorage.remember = data.checked;
        localStorage.username = this.state.username;
    }
    //
    handleEnterPressed(event){
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the 'Enter' keycode
            this.triggerGetToken();
        }
    }
    //
    triggerGetToken(){
        this.setState({
            LoginLoading: "loading disabled"
        });        
        //console.log("running login token callback...");
        const loginResponse = this.props.getToken(this.state.username, this.state.password, this.props.url);
        loginResponse.then((data) => {
            //console.log(data.httpCode);
            //console.log(data.body);
            switch(data.httpCode){
                case 200:
                    if(data.body === "user must reset password"){
                        this.setState({                        
                            loginFailModalText: 'Your password needs to be reset.\n Please use the "Forgotton your password?" link to reset your password, then try again.',
                            loginFailModalOpen: true
                        });
                    }
                    else{
                        localStorage.token = data.body;
                        localStorage.currentUser = this.state.username;
                        this.getGDPRStatus_Wrapper(this.props.url, function(result){
                            if(this.state.gdprAccepted){
                                setTimeout(this.redirectToMain, 200);
                            }
                            else this.handleShowGDPR();
                        }.bind(this));
                    }    
                    //
                    const roleResponse = this.GetUserRole(this.props.url);
                    roleResponse.then((roleData) => {
                        //console.log(roleData.body);
                        switch(roleData.httpCode){
                            case 200:
                                console.log("saving roleID: " + roleData.body.roleId);
                                localStorage.roleId = roleData.body.roleId;
                                localStorage.companyId = roleData.body.companyId;
                                break;
                            default:
                                break;
                        };
                    });
                    break;
                case 400:
                    this.setState({
                        loginFailModalText: 'Your login details were incorrect.\n Please check your provided username and password, then try again.',
                        loginFailModalOpen: true
                    });
                    break;
                case 401:
                    this.setState({
                        loginFailModalText: 'Your login details were incorrect.\n Please check your provided username and password, then try again.',
                        loginFailModalOpen: true
                    });
                    break;
                case 409:
                    this.setState({
                        loginFailModalText: 'Your account has been suspended. Please contact Equilaw I.T. for re-activation.',
                        loginFailModalOpen: true
                    });
                    break;
                default:
                    this.setState({
                        loginFailModalText: 'An unknown error occurred. Please contact Equilaw I.T..',
                        loginFailModalOpen: true
                    });
                    break;
            };
            this.setState({
                LoginLoading: ""
            });
        });
    }
    //
    async GetUserRole(url){
        const response = await fetch(url + "/Account/GetUserRoleId", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    redirectToMain(){
        this.props.history.push("/matters");
    }
    //
    handleForgotModal(event){
        event.preventDefault();
        this.setState({
            forgotModalOpen: !this.forgotModalOpen
        })
    }
    //
    handleUsernameForgotChange(event){
        this.setState({usernameForgot: event.target.value})
    }
    //
    handlePrivacyModal(event){
        event.preventDefault();
        this.setState({
            loginPrivacyModalOpen: !this.state.loginPrivacyModalOpen
        })
    }
    //
    updateGDPRStatus_Wrapper(url){
        this.setState({
            gdpr_AcceptLoading: "disabled loading",
        });        
        var result = this.updateGDPRStatus(url);
        result.then((data) => {
            if(data.body){ 
                this.setState({
                    gdprAccepted: true,
                });
                setTimeout(this.redirectToMain, 200);
            }
        });
    }
    //
    async updateGDPRStatus(url){
        const response = await fetch(url + "/Account/UpdateGDPRStatus", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                GDPRStatus: true,
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    async resetPassword(username){
        if(username !== null && username !== "" && username !== "undefined"){
            fetch(this.props.url + "/Account/SendPasswordResetLink_NoSession", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: username
                })            
            });
            this.setState({ forgotModalOpen: false})
        }
    }
    //
    getGDPRStatus_Wrapper(url, callback){
        //console.log("getting gdpr status");
        var result = this.getGDPRStatus(url);        
        result.then((data) => {
            //console.log("gdpr status = " + data.body);
            if(data.body){ 
                this.setState({
                    gdprAccepted: true,
                });
            }
            setTimeout(callback(this.props, this.state, data.body), 100);
        });
    }
    //
    async getGDPRStatus(url){
        const response = await fetch(url + "/Account/UserGDPRStatus", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleShowGDPR(){
        this.setState({
            gdprModalOpen: !this.state.gdprModalOpen,
        });
    }
    //
    handleTermsOpen(event){
        event.preventDefault();
        this.setState({
            termsModalOpen: !this.state.termsModalOpen,
        });
    }
    //
    render(){
        return(
            <div>
                <br/><br/><br/><br/><br/><br/>
                <div className="Login-Segment-Wrapper">
                    <Segment className="Login-Style">
                        <Label className="Login-Header-Segment" attached="top"><h3 className="Login-Header">Login to Equilaw Portal</h3></Label>
                        <br/><br/><br/>
                        <Form>
                            <Form.Field>
                                <label>Username</label>
                                <input placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} onKeyPress={this.handleEnterPressed.bind(this)} />
                            </Form.Field>
                            <Form.Field>
                                <label>Password</label>
                                <input placeholder="Password" type="password" value={this.state.password} onChange={this.handlePasswordChange} onKeyPress={this.handleEnterPressed.bind(this)} />
                            </Form.Field>
                        </Form>
                        <br/>
                        <div>
                            <font className="Login-Remember-Label">Remember Me?&emsp;</font>
                            <Checkbox className="Login-Remember-Checkbox" toggle checked={this.state.remember} onChange={this.handleRememberChange}/>
                        </div>                    
                        <br/>
                        <Button floated="right" className={"Login-Button-Submit " + this.state.LoginLoading} onClick={() => {this.triggerGetToken();}}>Login</Button>
                        <br/>
                        <a className="Login-Forgotten-Link" href="/" onClick={this.handleForgotModal}>Forgotten your password?</a>
                        {/* <button className="button-as-link" onClick={this.handleForgotModal}>Forgotten your password?</button> */}
                        <br/><br/>
                        <Message>
                            We don't use cookies! But we do keep session relevant data in your browser's HTML5 local storage.<br/> For more information please see our privacy policy <a className="Login-anchor-pointer" href="/" onClick={this.handlePrivacyModal}>here</a>
                        </Message>
                        {/* <Message>
                            <Button content="Show GDPR" onClick={this.handleShowGDPR}/>
                        </Message> */}
                    </Segment>
                </div>
                <Modal style={{position: 'static'}} open={this.state.loginFailModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ loginFailModalOpen: false})}>
                    <Header ><Icon className='warning circle red'/>Login Error!</Header>
                    <Modal.Content>
                        <div>{this.state.loginFailModalText.split("\n").map((item, i) => {
                            return <p key={i}>{item}</p>;
                        })}</div>
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button color='green' onClick={() => this.setState({ loginFailModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>
                </Modal>
                <Modal style={{position: 'static'}} open={this.state.forgotModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ forgotModalOpen: false})}>
                    <Header icon='mail' content='Forgotten your password?' />
                    <Modal.Content>
                        <font>By clicking "YES" you will be emailed a link to reset your password.<br/>Please use the same email as linked to your Equilaw Portal account.</font><br/><br/>
                        <Label className="Login-Modal-Forgot-Label" pointing="right">Username:</Label>
                        &emsp;
                        <Input className="Login-Modal-Forgot-Username" onChange={this.handleUsernameForgotChange} /><br/><br/>
                        <font>Are you sure that you wish to reset your password?</font>
                        <br/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button floated="left" onClick={() => this.setState({ forgotModalOpen: false})} ><Icon name='remove' />&nbsp;Cancel</Button>
                        <Button color='green' onClick={() => this.resetPassword(this.state.usernameForgot)}><Icon name='checkmark' />&nbsp;Yes</Button>
                    </Modal.Actions>
                </Modal>
                <Modal style={{position: 'static'}} open={this.state.loginPrivacyModalOpen} closeOnDimmerClick={true} onClose={() => this.setState({ loginPrivacyModalOpen: false})}>
                    <Modal.Content>
                        <PrivacyPolicy />
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button color='green' onClick={() => this.setState({ loginPrivacyModalOpen: false})}><Icon name='checkmark' />&nbsp;Ok</Button>
                    </Modal.Actions>                   
                </Modal>
                <Modal style={{position: 'static'}} open={this.state.gdprModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ gdprModalOpen: false})}>
                    <Header icon='bullhorn' content='GDPR Notification' />
                    <Modal.Content>
                        <font>                            
                        New data protection laws mean that we need your permission to contact you, even if you’ve given us permission before.
                        <br/><br/>
                        With the new General Data Protection Regulations (GDPR) coming into effect on the 25th May 2018, we are now required to ask everyone to opt-in to receiving notifications from us in the future.
                        <br/><br/>
                        To ensure we comply with the new regulations, and because our ability to maintain contact with you is key to using this Portal, you will only be able to use this service by agreeing to our terms and conditions and allowing us to contact you.  
                        <br/><br/> 
                        <a className="Login-anchor-pointer" href="/" onClick={this.handleTermsOpen}>Terms and Conditions</a>
                        <br/><br/>
                        You will only need to accept once, and can withdraw your permission at any time by opting-out on the 'Settings' page.
                        <br/><br/>
                        <i>Please note that withdrawing your acceptance of the terms and conditions will prevent you from using this service.</i>
                        </font>
                        <br/><br/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button floated="left" color="red" onClick={() => this.setState({ gdprModalOpen: false, LoginLoading: ""})} ><Icon name='remove' />&nbsp;I do not Accept</Button>
                        <Button color='green' className={this.state.gdpr_AcceptLoading} onClick={() => this.updateGDPRStatus_Wrapper(this.props.url)}><Icon name='checkmark' />&nbsp;I Agree</Button>
                    </Modal.Actions>
                </Modal>
                <Modal style={{position: 'static'}} open={this.state.termsModalOpen} closeOnDimmerClick={false} onClose={() => this.setState({ termsModalOpen: false})}>
                    <Modal.Content>
                        <Terms />
                    </Modal.Content> 
                    <Modal.Actions>
                        <Button onClick={() => this.setState({ termsModalOpen: false})}>Close</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default LoginHero;