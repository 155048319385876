import React, { Component } from 'react';
//import './TSP.css';
import { Button, } from 'semantic-ui-react';
import MapContainer from './MapContainer';
//import { Map, GoogleApiWrapper } from 'google-maps-react';

// const mapStyles = {
//     width: '100%',
//     height: '100%'
//   };

class TSP extends Component{
    constructor(props){
        super(props);
        this.state={
            gcpAPIKey: "AIzaSyBakhnokqY53Nr6atFt-GVhQNMLgd06s7E",
            baseURL: "https://maps.googleapis.com/maps/api/directions/json?",
        }
        //
        this.routeTest_Wrapper = this.routeTest_Wrapper.bind(this);
        this.routeTest = this.routeTest.bind(this);
    }

    routeTest_Wrapper(){
        var url = this.state.baseURL + "origin=Equilaw,GL34AH&destination=14+Darmouth+Street,ST163TU&waypoints=optimize:true|46B+Cavendish+Street,WR53DU|37Twixtbears,GL205BT|&key=";
        var results = this.routeTest(url);
        results.then((data) => {
            //console.log(data.body.varsList);
            switch(data.httpCode){
                case 200:
                    console.log(data);                       
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    //this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }

    async routeTest(url){
        var url_Final = url + this.state.gcpAPIKey;
        console.log(url_Final);
        const response = await fetch(url_Final, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Accept': 'application/json',
                //'Content-Type': 'application/json',
            },
            // body: JSON.stringify({
            //     token: localStorage.token
            // })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }

    render(){
        return(
            <div>
                <Button className="blue ui button" onClick={this.routeTest_Wrapper}>Test</Button>
                <MapContainer></MapContainer>
            </div>
        );
    }
}

export default TSP

