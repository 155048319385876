import React, { Component } from 'react';
import { Segment, Label, Button, Icon, Form, Dropdown } from 'semantic-ui-react';
import './Admin_MapSources.css';

class MapSources extends Component{
    constructor(props){
        super(props);
        this.state = {
            companyOptions: this.props.companyOptions,
            companyValue: 0,
            sourceCSS: "MapSources-Form-Hide",
            updateLoading: "",
            sourceOptions_Master: [],
            sourceOptions: [],
            sourcesValue: [],
        };
        //
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
        this.fetchAllSources_Wrapper = this.fetchAllSources_Wrapper.bind(this);
        this.fetchAllSources = this.fetchAllSources.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSourceChange = this.handleSourceChange.bind(this);
        this.handleRebuildSources = this.handleRebuildSources.bind(this);
        this.updateMappings_Wrapper = this.updateMappings_Wrapper.bind(this);
        this.updateMappings = this.updateMappings.bind(this);
        //  
        this.fetchAllSources_Wrapper(this.props.url, () => { this.handleRebuildSources(() => {}); });      
    }
    //
    fetchAllSources_Wrapper(url, callback){
        var result = this.fetchAllSources(url);
        result.then((data) => {            
            this.setState({
                sourceOptions_Master: data.body,
            });
            callback();
        });
    }
    //
    async fetchAllSources(url){
        console.log("using companyId " + this.state.companyValue);
        const response = await fetch(url + "/Matters/GetAllSources", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                companyid: this.state.companyValue,
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    handleRebuildSources(callback){
        var sourceOptions = [];
        for(let i = 0; i < this.state.sourceOptions_Master.length; i++){
            if(this.state.sourceOptions_Master[i].companyid === this.state.companyValue || this.state.sourceOptions_Master[i].companyid === 0) sourceOptions.push(this.state.sourceOptions_Master[i]);
        }
        //
        var selectedSources = [];
        for(let i = 0; i < this.state.sourceOptions_Master.length; i++){
            if(this.state.sourceOptions_Master[i].companyid === this.state.companyValue) selectedSources.push(this.state.sourceOptions_Master[i].value);
        }
        //
        this.setState({
            sourceOptions: sourceOptions,
            sourcesValue: selectedSources,
        });
        //
        callback();
    }
    //
    handleCompanyChange(event, data){
        if(data.value > 0){
            this.setState({
                companyValue: data.value,
            });
            setTimeout(() => {
                this.handleRebuildSources(() => {
                    this.setState({
                        sourceCSS: "",
                    });
                });
            },50);
        }
    }
    //
    handleSourceChange(event, data){
        this.setState({
            sourcesValue: data.value,
        });
    }
    //
    handleUpdateClick(){
        this.setState({
            updateLoading: "disabled loading"
        });
        this.updateMappings_Wrapper(this.props.url);
    }
    //
    updateMappings_Wrapper(url){        
        var result = this.updateMappings(url);
        result.then((data) => {            
            window.location.reload();
        });
    }
    //
    async updateMappings(url){
        const response = await fetch(url + "/Matters/UpdateSourceMappings", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                companyId: this.state.companyValue,
                sourceMaps: this.state.sourcesValue,
            })
        });
        const responseObject = {
          httpCode: response.status,
          body: response.status === 200 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    render(){
        return(
            <div>
                <Segment>
                    <Label attached="top"><h5 className="Settings-Header"><Icon className="sitemap large"/>&emsp;Map Sources to Companies</h5></Label>
                    <br/><br/><br/>
                    <Form>
                        <Form.Field>
                            <div className="Admin-Div-Company-Region">
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select Company to Map to</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown disabled={this.state.userIsStaff} fluid={true} selection={true} search={true} placeholder="Select Company..." value={this.state.companyValue} options={this.state.companyOptions} onChange={this.handleCompanyChange}/>
                                    </div>
                                </div>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div className={this.state.sourceCSS}>
                                <div className="Admin-Div-Company-Outer">
                                    <label>Select Sources:</label>
                                    <div className="Admin-Div-Company">
                                        <Dropdown multiple={true} disabled={this.state.userIsStaff} fluid={true} selection={true} search={true} placeholder="Select Sources..." value={this.state.sourcesValue} options={this.state.sourceOptions} onChange={this.handleSourceChange}/> 
                                        {/* onChange={this.handleSourceChange} */}
                                    </div>
                                </div>
                                <br/>
                                <Button className={"blue " + this.state.updateLoading} content="Update Mappings" onClick={this.handleUpdateClick}/>
                            </div>
                        </Form.Field>
                    </Form>
                </Segment>
            </div>
        );
    }
}

export default MapSources