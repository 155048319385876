import React, { Component } from 'react';
import './IQPage.css';
import { Segment, Label, Button, Icon, Form, Dropdown, Dimmer, Loader, Grid, } from 'semantic-ui-react';
import moment from 'moment';
import '../node_modules/react-input-calendar/style/index.css';
import '../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import 'react-table/react-table.css'

class IQPage4Financial extends Component{
    constructor(props){
        super(props);
        this.state = {
            moment: moment(),
            reference: "TEST001/1",
            fileId: "",
            financialDimmerActive: false,
            dimmerActionText: "Loading...",
            button_back_CSS: "red IQ-backButton",
            button_forward_CSS: "green IQ-forwardButton",
            
            //////////////////////////////
            // Step 4. Financial Details //
            //////////////////////////////
            input_financial_borrowingamount: "",
            input_financial_transfer_value: "",
            segment_financial_transfer_CSS: "IQ-Hidden",
            input_financial_payeeAccountName: "",
            input_financial_payeeBankName: "",
            input_financial_sortCode1: "",
            input_financial_sortCode2: "",
            input_financial_sortCode3: "",
            input_financial_sortCode4: "",
            input_financial_sortCode5: "",
            input_financial_sortCode6: "",
            input_financial_payeeAccountNumber: "",
            //
            input_financial_soleOrJointAccount: "",
            input_financial_soleAccountDetail: "",
            segment_financial_soleAccountDetails_CSS: "IQ-Hidden",
            input_financial_useOfFunds_value: "",
            segment_financial_useOfFunds_CSS: "IQ-Hidden",
            textarea_financial_useOfFunds_details: "",
            //
            button_financial_shortfall_Checked_YES: false,
            button_financial_shortfall_Checked_NO: false,
            button_financial_shortfall_YES_CSS: "ui",
            button_financial_shortfall_NO_CSS: "ui",
            segment_financial_shortfall_CSS: "IQ-Hidden",
            //
            input_financial_shortfall_shortfallAmount: "",
            input_financial_shortfall_funding: "",
            input_financial_useOfFunds_other: "",
            //
            button_financial_gifting_Checked_YES: false,
            button_financial_gifting_Checked_NO: false,
            button_financial_gifting_YES_CSS: "ui",
            button_financial_gifting_NO_CSS: "ui",
            input_financial_gifting_giftingAmount: "",
            segment_financial_gifting_CSS: "IQ-Hidden",
            segment_financial_gifting_who_CSS: "IQ-Hidden",
            input_financial_gifting_who_value: "",
            input_financial_gifting_who_other_value: "",            
            //
            button_financial_settle_Checked_YES: false,
            button_financial_settle_Checked_NO: false,
            button_financial_settle_YES_CSS: "ui",
            button_financial_settle_NO_CSS: "ui",
            //
            button_financial_funeralFees_Checked_YES: false,
            button_financial_funeralFees_Checked_NO: false,
            button_financial_funeralFees_YES_CSS: "ui",
            button_financial_funeralFees_NO_CSS: "ui",
        }
        this.receiveFundsOptions = [            
            {
                text: 'Telegraphic Transfer',
                value: 'Telegraphic Transfer',
            },
            {
                text: 'Cheque',
                value: 'Cheque',
            },
        ];
        this.soleAccountOptions = [            
            {
                text: 'Joint',
                value: 'joint',
            },
            {
                text: 'Sole',
                value: 'sole',
            },
        ];
        this.soleAccountDetailOptions = [            
            {
                text: 'First Client',
                value: 'First Client',
            },
            {
                text: 'Second Client',
                value: 'Second Client',
            },
        ];
        this.useOfFundsOptions = [
            {
                text: 'Home improvements',
                value: 'Home improvements',
            },{
                text: 'Gift to family/friends',
                value: 'Gift to family/friends',
            },{
                text: 'Funds to live on',
                value: 'Funds to live on',
            },{
                text: 'New Car',
                value: 'New Car',
            },{
                text: 'Travelling/holiday',
                value: 'Travelling/holiday',
            },{
                text: 'Paying off existing mortgage/debts',
                value: 'Paying off existing mortgage/debts',
            },{
                text: 'Other',
                value: 'Other',
            },
        ];
        this.giftingWhoOptions = [
            {
                text: "",
                value: "",
            },
            {
                text: 'Brother',
                value: 'Brother',
            },
            {
                text: 'Daughter',
                value: 'Daughter',
            },
            {
                text: 'Sister',
                value: 'Sister',
            },
            {
                text: 'Son',
                value: 'Son',
            },
            {
                text: 'Other',
                value: 'Other',
            },
        ];
        //////////////////////////////
        // Step 4. Financial Details //
        //////////////////////////////
        //
        this.valueChange = this.valueChange.bind(this);
        this.buttonChange_YES = this.buttonChange_YES.bind(this);
        this.buttonChange_NO = this.buttonChange_NO.bind(this);
        //
        this.populateVarsData = this.populateVarsData.bind(this);
        //
        this.onChange_financial_receiveFunds = this.onChange_financial_receiveFunds.bind(this);
        this.onChange_sortCode1 = this.onChange_sortCode1.bind(this);
        this.onChange_sortCode2 = this.onChange_sortCode2.bind(this);
        this.onChange_sortCode3 = this.onChange_sortCode3.bind(this);
        this.onChange_sortCode4 = this.onChange_sortCode4.bind(this);
        this.onChange_sortCode5 = this.onChange_sortCode5.bind(this);
        this.onChange_sortCode6 = this.onChange_sortCode6.bind(this);
        this.changeTabIndex = this.changeTabIndex.bind(this);
        this.onChange_financial_soleOrJointAccount = this.onChange_financial_soleOrJointAccount.bind(this);
        this.onChange_financial_soleAccount_detail = this.onChange_financial_soleAccount_detail.bind(this);
        //
        //this.onClick_financial_shortfall_YES = this.onClick_financial_shortfall_YES.bind(this);
        //this.onClick_financial_shortfall_NO = this.onClick_financial_shortfall_NO.bind(this);
        //
        this.onChange_financial_useOfFunds = this.onChange_financial_useOfFunds.bind(this);
        //
        //this.onClick_financial_gifting_YES = this.onClick_financial_gifting_YES.bind(this);
        //this.onClick_financial_gifting_NO = this.onClick_financial_gifting_NO.bind(this);
        this.onChange_financial_gifting_who = this.onChange_financial_gifting_who.bind(this);
        //
        //this.onClick_financial_settle_YES = this.onClick_financial_settle_YES.bind(this);
        //this.onClick_financial_settle_NO = this.onClick_financial_settle_NO.bind(this);
        //
        this.saveData_Wrapper_Back = this.saveData_Wrapper_Back.bind(this);
        this.saveData_Wrapper_Forward = this.saveData_Wrapper_Forward.bind(this);
        //
        //
        setTimeout(this.populateVarsData, 150);
    }

    ///////////////////////////////
    // Step 4. Financial Details //
    ///////////////////////////////
    //
    /////////////////////////////////////////////////////////////////
    valueChange = key => e => this.setState({[key]: e.target.value});
    /////////////////////////////////////////////////////////////////
    //
    componentDidUpdate(prevProps) {
        if (this.props.varsList !== prevProps.varsList) {
            this.setState({
                //financialDimmerActive: true,
            })
            this.populateVarsData();
        }
      }
    //
    populateVarsData(){
        var varList = this.props.varsList;
        for(var i = 0; i < varList.length; i++){
            var ovCode = varList[i].m_Item1;
            var value = varList[i].m_Item2;
            if(value != null){
            switch(ovCode){
                //
                case "4084":
                    this.setState({
                        input_financial_borrowingamount: value,
                    });
                break;
                case "3899":
                    this.setState({
                        input_financial_transfer_value: value,
                    });
                    this.onChange_financial_receiveFunds(null, {value: value});
                break;
                case "1275":
                    this.setState({
                        input_financial_payeeAccountName: value,
                    });
                break;
                case "1283":
                    this.setState({
                        input_financial_payeeBankName: value,
                    });
                break;
                case "1277":
                    this.setState({
                        input_financial_sortCode1: value,
                    });
                break;
                case "1278":
                    this.setState({
                        input_financial_sortCode2: value,
                    });
                break;
                case "1279":
                    this.setState({
                        input_financial_sortCode3: value,
                    });
                break;
                case "1280":
                    this.setState({
                        input_financial_sortCode4: value,
                    });
                break;
                case "1281":
                    this.setState({
                        input_financial_sortCode5: value,
                    });
                break;
                case "1282":
                    this.setState({
                        input_financial_sortCode6: value,
                    });
                break;
                case "1276":
                    this.setState({
                        input_financial_payeeAccountNumber: value,
                    });
                break;
                case "1359":
                    this.setState({
                        input_financial_soleOrJointAccount: value,
                    });
                    this.onChange_financial_soleOrJointAccount(null, {value: value});
                break;
                case "2030":
                    this.setState({
                        input_financial_soleAccountDetail: value,
                    });
                    this.onChange_financial_soleAccount_detail(null, {value: value});
                break;
                //
                case "4314":
                    this.setState({
                        input_financial_useOfFunds_value: value,
                    });
                    this.onChange_financial_useOfFunds(null, {value: value});
                break;
                case "4315":
                    this.setState({
                        input_financial_useOfFunds_other: value,
                    });
                break;
                //
                case "4098":
                    if(value === "Yes") this.buttonChange_YES('financial_shortfall', false, null);
                    else this.buttonChange_NO('financial_shortfall', true, null);
                break;
                case "1831":
                    this.setState({
                        input_financial_shortfall_shortfallAmount: value,
                    });
                break;
                case "4085":
                    this.setState({
                        input_financial_shortfall_funding: value,
                    });
                break;
                //
                case "IsGifting":
                    if(value.toLowerCase() === "true") this.buttonChange_YES('financial_gifting', false, null);
                    else this.buttonChange_NO('financial_gifting', true, null);
                break;
                //
                case "FundsDetails":
                    this.setState({
                        textarea_financial_useOfFunds_details: value,
                    });
                break;
                //
                case "2312":
                    this.setState({
                        input_financial_gifting_giftingAmount: value,
                    });
                break;
                case "2313":
                console.log("gifting who? " + value);
                if(value === "Brother" || value ==="Daughter" || value === "Sister" || value === "Son"){
                    this.setState({
                        input_financial_gifting_who_value: value,
                    });
                    this.onChange_financial_gifting_who(null, {value: value});
                }
                else {
                    this.setState({
                        input_financial_gifting_who_value: "Other",
                        segment_financial_gifting_who_CSS: "",
                        input_financial_gifting_who_other_value: value,
                    });
                    this.onChange_financial_gifting_who(null, {value: "Other"});
                }
                
                break;
                //
                case "4088":
                    if(value === "Yes") this.buttonChange_YES('financial_settle', null, null);
                    else this.buttonChange_NO('financial_settle', null, null);
                break;
                //
                //
                default:
                    break;
                }
            }
        }
        setTimeout(() => {
            this.setState({
                financialDimmerActive: false,
            })
        }, 200);        
    }
    //
    buttonChange_YES(key, hide, style) {
        this.setState({
            ['button_' + key + '_Checked_YES']: true,
            ['button_' + key + '_Checked_NO']: false,
            ['button_' + key + '_YES_CSS']: "ui green",
            ['button_' + key + '_NO_CSS']: "ui",
        });
        if(hide != null){
            this.setState({
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),
            });
        }
    };
    /////////////////////////////////////////////////////////////////
    buttonChange_NO = (key, hide, style) => {
        this.setState({
            ['button_' + key + '_Checked_YES']: false,
            ['button_' + key + '_Checked_NO']: true,
            ['button_' + key + '_YES_CSS']: "ui",
            ['button_' + key + '_NO_CSS']: "ui red",
        });
        if(hide != null){
            this.setState({
                ['segment_' + key + "_CSS"]: (hide ? "IQ-Hidden" :  (style != null ? style : "")),
            });
        }
    };
    //
    onChange_financial_receiveFunds(event, data){
        if(data.value === "Telegraphic Transfer"){
            this.setState({
                input_financial_transfer_value: data.value,
                segment_financial_transfer_CSS: "",
            });
        }
        else {
            this.setState({
                input_financial_transfer_value: data.value,
                segment_financial_transfer_CSS: "IQ-Hidden",
            });
        }       
    }
    //
    onChange_sortCode1(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode1: event.target.value
            });
            if(event.target.value.length === 1) this.changeTabIndex(event, 1);
        }
    }
    //
    onChange_sortCode2(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode2: event.target.value
            });
            if(event.target.value.length === 1) this.changeTabIndex(event, 1);
            else if(event.target.value.length === 0) this.changeTabIndex(event, -1);
        }
    }
    //
    onChange_sortCode3(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode3: event.target.value
            });
            if(event.target.value.length === 1) this.changeTabIndex(event, 1);
            else if(event.target.value.length === 0) this.changeTabIndex(event, -1);
        }
    }
    //
    onChange_sortCode4(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode4: event.target.value
            });
            if(event.target.value.length === 1) this.changeTabIndex(event, 1);
            else if(event.target.value.length === 0) this.changeTabIndex(event, -1);
        }
    }
    //
    onChange_sortCode5(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode5: event.target.value
            });
            if(event.target.value.length === 1) this.changeTabIndex(event, 1);
            else if(event.target.value.length === 0) this.changeTabIndex(event, -1);
        }
    }
    //
    onChange_sortCode6(event){
        if(event.target.value.length < 2 && "1234567890".includes(event.target.value)){
            this.setState({
                input_financial_sortCode6: event.target.value
            });
            if(event.target.value.length === 0) this.changeTabIndex(event, -1);
        }
    }
    //
    changeTabIndex(event, modifier){
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + modifier].focus();
    }
    //    
    onChange_financial_soleOrJointAccount(event, data){
        if(data.value === "sole"){
            this.setState({
                input_financial_soleOrJointAccount: data.value,
                segment_financial_soleAccountDetails_CSS: "",
            });
        }
        else {
            this.setState({
                input_financial_soleOrJointAccount: data.value,
                segment_financial_soleAccountDetails_CSS: "IQ-Hidden",
            });
        }       
    }
    //    
    onChange_financial_soleAccount_detail(event, data){
        this.setState({
            input_financial_soleAccountDetail: data.value,
        });     
    }
    //
    //
    // onClick_financial_shortfall_YES(){
    //     this.setState({
    //         button_financial_shortfall_Checked_YES: true,
    //         button_financial_shortfall_Checked_NO: false,
    //         button_financial_shortfall_YES_CSS: "ui green",
    //         button_financial_shortfall_NO_CSS: "ui",
    //         segment_financial_shortfall_CSS: "",
    //     });
    // }
    // //
    // onClick_financial_shortfall_NO(){
    //     this.setState({
    //         button_financial_shortfall_Checked_YES: false,
    //         button_financial_shortfall_Checked_NO: true,
    //         button_financial_shortfall_YES_CSS: "ui",
    //         button_financial_shortfall_NO_CSS: "ui red",
    //         segment_financial_shortfall_CSS: "IQ-Hidden",
    //     });
    // }
    //
    onChange_financial_useOfFunds(event, data){
        if(data.value === "Other"){
            this.setState({
                input_financial_useOfFunds_value: data.value,
                segment_financial_useOfFunds_CSS: "",
            });
        }
        else {
            this.setState({
                input_financial_useOfFunds_value: data.value,
                segment_financial_useOfFunds_CSS: "IQ-Hidden",
            });
        }       
    }
    //
    //
    // onClick_financial_gifting_YES(){
    //     this.setState({
    //         button_financial_gifting_Checked_YES: true,
    //         button_financial_gifting_Checked_NO: false,
    //         button_financial_gifting_YES_CSS: "ui green",
    //         button_financial_gifting_NO_CSS: "ui",
    //         segment_financial_gifting_CSS: "",
    //     });
    // }
    // //
    // onClick_financial_gifting_NO(){
    //     this.setState({
    //         button_financial_gifting_Checked_YES: false,
    //         button_financial_gifting_Checked_NO: true,
    //         button_financial_gifting_YES_CSS: "ui",
    //         button_financial_gifting_NO_CSS: "ui red",
    //         segment_financial_gifting_CSS: "IQ-Hidden",
    //     });
    // }
    //
    onChange_financial_gifting_who(event, data){
        console.log(data);
        if(data.value === "Other"){
            this.setState({
                input_financial_gifting_who_value: data.value,
                segment_financial_gifting_who_CSS: "",
            });
        }
        else {
            this.setState({
                input_financial_gifting_who_value: data.value,
                segment_financial_gifting_who_CSS: "IQ-Hidden",
            });
        }       
    }
    //
    //
    // onClick_financial_settle_YES(){
    //     this.setState({
    //         button_financial_settle_Checked_YES: true,
    //         button_financial_settle_Checked_NO: false,
    //         button_financial_settle_YES_CSS: "ui green",
    //         button_financial_settle_NO_CSS: "ui",
    //         segment_financial_settle_CSS: "",
    //     });
    // }
    // //
    // onClick_financial_settle_NO(){
    //     this.setState({
    //         button_financial_settle_Checked_YES: false,
    //         button_financial_settle_Checked_NO: true,
    //         button_financial_settle_YES_CSS: "ui",
    //         button_financial_settle_NO_CSS: "ui red",
    //         segment_financial_settle_CSS: "IQ-Hidden",
    //     });
    // }    
    //
    //
    handleMomentChange = moment => {
        this.setState({ moment });
    };
    //
    //
    saveData_Wrapper_Forward(){
        this.setState({
            dimmerActionText: "Saving Data...",
            financialDimmerActive: true,
            button_forward_CSS: "green IQ-forwardButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, false);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        financialDimmerActive: false,
                        button_forward_CSS: "green IQ-forwardButton",
                    });        
                    this.props.forward();           
                    break;
                case 400:
                    console.log(data.body.Message); 
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    saveData_Wrapper_Back(){
        this.setState({
            dimmerActionText: "Saving Data...",
            financialDimmerActive: true,
            button_back_CSS: "red IQ-backButton loading disabled",
        }); 
        var results = this.saveData(this.props.url, true);
        results.then((data) => {
            //console.log(data.body[1]);
            switch(data.httpCode){
                case 200:
                    this.setState({
                        tableData: data.body,
                        financialDimmerActive: false,
                        button_back_CSS: "red IQ-backButton",
                    }); 
                    this.props.back();                  
                    break;
                case 400:
                    console.log(data.body.Message);
                    break;
                case 401:
                    this.props.history.push("/");
                    break;
                default:
                    break;
            }
        });
    }
    //
    async saveData(url, backward){
        console.log("save log: " + this.state.input_financial_gifting_who_other_value);
        const response = await fetch(url + "/IQ/SaveData_Step4", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: localStorage.token,
                fileId: this.props.fileId,
                backward: backward,
                //
                financial_borrowingAmount: this.state.input_financial_borrowingamount,
                financial_transfer_value: this.state.input_financial_transfer_value,
                financial_payeeAccountName: this.state.input_financial_payeeAccountName,
                financial_payeeBankName: this.state.input_financial_payeeBankName,
                financial_sortCode1: this.state.input_financial_sortCode1,
                financial_sortCode2: this.state.input_financial_sortCode2,
                financial_sortCode3: this.state.input_financial_sortCode3,
                financial_sortCode4: this.state.input_financial_sortCode4,
                financial_sortCode5: this.state.input_financial_sortCode5,
                financial_sortCode6: this.state.input_financial_sortCode6,
                financial_payeeAccountNumber: this.state.input_financial_payeeAccountNumber,
                //
                financial_soleOrJointAccount: this.state.input_financial_soleOrJointAccount,
                financial_soleAccountDetail: this.state.input_financial_soleAccountDetail,
                financial_useOfFunds: this.state.input_financial_useOfFunds_value,
                financial_useOfFunds_other: this.state.input_financial_useOfFunds_other,
                //
                financial_shortfall: this.state.button_financial_shortfall_Checked_YES ? true : (this.state.button_financial_shortfall_Checked_NO ? false: null),
                financial_shortfall_shortfallAmount: this.state.input_financial_shortfall_shortfallAmount,
                financial_shortfall_funding: this.state.input_financial_shortfall_funding,
                //
                financial_gifting_giftingAmount: this.state.input_financial_gifting_giftingAmount,
                financial_gifting_Checked: this.state.button_financial_gifting_Checked_YES ? true : (this.state.button_financial_gifting_Checked_NO ? false: null),
                financial_gifting_who_value: this.state.input_financial_gifting_who_value === "Other" ? this.state.input_financial_gifting_who_other_value : this.state.input_financial_gifting_who_value,
                //
                financial_settle_Checked: this.state.button_financial_settle_Checked_YES ? true : (this.state.button_financial_settle_Checked_NO ? false: null),
                //
                financial_funeralFees_Checked: this.state.button_financial_funeralFees_Checked_YES ? true : (this.state.button_financial_funeralFees_Checked_NO ? false: null),
                financial_useOfFunds_Details: this.state.textarea_financial_useOfFunds_details
                //
            })
        });
        const responseObject = {
            httpCode: response.status,
            body: response.status === 200 || response.status === 400 ? await response.json() : ""
        }
        return responseObject;
    }
    //
    //
    render(){
        return(
            <div>                
                {/* Step 4. Financial Details */}                            
                <Segment>
                    <Dimmer active={this.state.financialDimmerActive}>
                        <Loader>{this.state.dimmerActionText}</Loader>
                    </Dimmer>
                    <Grid columns={2} className="IQ-Grid">
                        <Grid.Row>
                            <Grid.Column>
                                <div>
                                    <div>
                                        <div>
                                            <Form>
                                                <Form.Field>
                                                    <Label className="IQ-Label" pointing={"right"} content="What is the approx. amount you are borrowing?"/>&emsp;
                                                    <div className="IQ-Form-Field-Xs">
                                                        <input  className={this.state.passMatch} placeholder="Borrowing Amount?" type="text" value={this.state.input_financial_borrowingamount} onChange={this.valueChange('input_financial_borrowingamount')} />
                                                    </div>
                                                </Form.Field>
                                            </Form>
                                        </div>
                                        <br/>
                                        <hr/>
                                        <div>
                                            <br/>
                                            <span>
                                                <Label className="IQ-Label" pointing={"right"} content="How would you like to receive the Equity Release Funds:"/>&emsp;
                                                <Dropdown placeholder="..." inline selection options={this.receiveFundsOptions} value={this.state.input_financial_transfer_value} onChange={this.onChange_financial_receiveFunds}/>
                                            </span>
                                        </div>
                                        <Segment className={this.state.segment_financial_transfer_CSS}>                                            
                                                <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Transfer of Funds</h4></Label>
                                                <br/>
                                                <br/>
                                                <Label className="IQ-Label" content="Please provide account details for the Equity Release Funds transfer:"/>
                                                <br/><br/>
                                                <Form>
                                                    <Form.Field>
                                                        <label>Payee (Receiving) Account Name</label>
                                                        <div className="IQ-Form-Field-Lg">
                                                            <input  className={this.state.passMatch} placeholder="Payee Account Name" type="text" value={this.state.input_financial_payeeAccountName} onChange={this.valueChange('input_financial_payeeAccountName')} />
                                                        </div>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>Payee Bank Name</label>
                                                        <div className="IQ-Form-Field-Lg">
                                                            <input  className={this.state.passMatch} placeholder="Payee Bank Name" type="text" value={this.state.input_financial_payeeBankName} onChange={this.valueChange('input_financial_payeeBankName')} />
                                                        </div>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>Sort Code</label>
                                                        <div className="IQ-Form-Field-Lg">
                                                            <input className="IQ-Input-SortCode-Left" type="text" value={this.state.input_financial_sortCode1} onChange={this.onChange_sortCode1} />
                                                            <input className="IQ-Input-SortCode-Right" type="text" value={this.state.input_financial_sortCode2} onChange={this.onChange_sortCode2}/>
                                                            &emsp;
                                                            <font className="IQ-Font-SortCode">-</font>
                                                            &emsp;
                                                            <input className="IQ-Input-SortCode-Left" type="text" value={this.state.input_financial_sortCode3} onChange={this.onChange_sortCode3}/>
                                                            <input className="IQ-Input-SortCode-Right" type="text" value={this.state.input_financial_sortCode4} onChange={this.onChange_sortCode4}/>
                                                            &emsp;
                                                            <font className="IQ-Font-SortCode">-</font>
                                                            &emsp;
                                                            <input className="IQ-Input-SortCode-Left" type="text" value={this.state.input_financial_sortCode5} onChange={this.onChange_sortCode5}/>
                                                            <input className="IQ-Input-SortCode-Right" type="text" value={this.state.input_financial_sortCode6} onChange={this.onChange_sortCode6}/>
                                                            &emsp;
                                                        </div>
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>Payee (Receiving) Account Number</label>
                                                        <div className="IQ-Form-Field-Lg">
                                                            <input  className={this.state.passMatch} placeholder="Payee Account Number" type="text" value={this.state.input_financial_payeeAccountNumber} onChange={this.valueChange('input_financial_payeeAccountNumber')} />
                                                        </div>
                                                    </Form.Field>
                                                    <div>
                                                        <br/>
                                                        <span>
                                                            <Label className="IQ-Label" pointing={"right"} content="Is this a Sole or Joint Account?:"/>&emsp;
                                                            <Dropdown placeholder="..." inline selection options={this.soleAccountOptions} value={this.state.input_financial_soleOrJointAccount} onChange={this.onChange_financial_soleOrJointAccount}/>
                                                        </span>
                                                    </div>                                                    
                                                    <br/>
                                                    <Segment className={this.state.segment_financial_soleAccountDetails_CSS}>                                            
                                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Sole Account Details</h4></Label>
                                                        <br/>
                                                        <br/>
                                                        <div>
                                                        <br/>
                                                        <span>
                                                            <Label className="IQ-Label" pointing={"right"} content="To which Client does the account belong?:"/>&emsp;
                                                            <Dropdown placeholder="..." inline selection options={this.soleAccountDetailOptions} value={this.state.input_financial_soleAccountDetail} onChange={this.onChange_financial_soleAccount_detail}/>
                                                        </span>
                                                    </div> 
                                                    </Segment>
                                                </Form>
                                        </Segment> 
                                        <br/>
                                        <hr/>
                                        <br/>                                       
                                        <Form>
                                            <div>
                                                <span>
                                                    <Label className="IQ-Label" pointing={"right"} content="How will you be using the Equity Release funds?:"/>&emsp;
                                                    <Dropdown placeholder="..." inline selection options={this.useOfFundsOptions} value={this.state.input_financial_useOfFunds_value} onChange={this.onChange_financial_useOfFunds}/>
                                                </span>
                                            </div>
                                            <Segment className={this.state.segment_financial_useOfFunds_CSS}>                                            
                                                <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Use of Funds</h4></Label>
                                                <br/>
                                                <br/>
                                                <label>Please categorise how you will use the funds:</label>
                                                <div className="IQ-Form-Field-Lg">
                                                    <input className="IQ-Input-UseOfFunds" placeholder="Funds usage details" value={this.state.input_financial_useOfFunds_other} onChange={this.valueChange('input_financial_useOfFunds_other')} />
                                                </div>
                                            </Segment>
                                            <br/>
                                            <div>
                                                <span>
                                                    <Label className="IQ-Label" pointing={"below"} content="Additional details for how you will be using the Equity Release funds:"/>&emsp;
                                                    <textarea value={this.state.textarea_financial_useOfFunds_details} onChange={this.valueChange('textarea_financial_useOfFunds_details')}></textarea>
                                                </span>
                                            </div>
                                        </Form>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <Form>
                                        <Form.Field>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Are you expecting there to be a shortfall of funds?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_financial_shortfall_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('financial_shortfall', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_financial_shortfall_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('financial_shortfall', true, null)}}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_financial_shortfall_CSS}>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Shortfall</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div>
                                                            {/* <Label className="IQ-Label" pointing={"right"} content="Shortfall Amount?"/>&emsp; */}
                                                            <label>Shortfall Amount?</label>
                                                            <div className="IQ-Form-Field-Lg">
                                                                <input  className={this.state.passMatch} placeholder="Shortfall Amount?" type="text" value={this.state.input_financial_shortfall_shortfallAmount} onChange={this.valueChange('input_financial_shortfall_shortfallAmount')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <label>How are you intending to fund the shortfall?</label>
                                                    <div className="IQ-Form-Field-Lg">
                                                        <input  className={this.state.passMatch} placeholder="Shortfall funding" type="text" value={this.state.input_financial_shortfall_funding} onChange={this.valueChange('input_financial_shortfall_funding')} />
                                                    </div>                                                
                                                </Segment>
                                            </div>
                                        </Form.Field>
                                    </Form>
                                    <br/>                                    
                                </div>
                            </Grid.Column>
                            <Grid.Column>
                                <div>                                
                                    <br/>
                                    <Form>
                                        <Form.Field>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Are you gifting any of the funds?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_financial_gifting_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('financial_gifting', false, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_financial_gifting_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('financial_gifting', true, null)}}>No</Button>
                                                </div>
                                                <Segment className={this.state.segment_financial_gifting_CSS}>
                                                    <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Gifting</h4></Label>
                                                    <div>
                                                        <br/>
                                                        <div>
                                                            {/* <Label className="IQ-Label" pointing={"right"} content="Shortfall Amount?"/>&emsp; */}
                                                            <label>Amount Gifting:</label>
                                                            <div className="IQ-Form-Field-Lg">
                                                                <input  className={this.state.passMatch} placeholder="Amount Gifting" type="text" value={this.state.input_financial_gifting_giftingAmount} onChange={this.valueChange('input_financial_gifting_giftingAmount')} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <span>
                                                        <Label className="IQ-Label" pointing={"right"} content="Who are you gifting funds to?:"/>&emsp;
                                                        <Dropdown placeholder="..." inline selection options={this.giftingWhoOptions} value={this.state.input_financial_gifting_who_value} onChange={this.onChange_financial_gifting_who}/>
                                                    </span>
                                                    <Segment className={this.state.segment_financial_gifting_who_CSS}>
                                                        <Label className="IQ-label" attached="top"><h4 className="Matters-Header"><Icon className="question circle icon large"/>&emsp;More information required: Gifting Recipient</h4></Label>
                                                        <div>
                                                            <br/>
                                                            <div>
                                                                <label>Please provide details of who will recieve the gift:</label>
                                                                <div className="IQ-Form-Field-Lg">
                                                                    <input  className={this.state.passMatch} placeholder="Gifting Recipient" type="text" value={this.state.input_financial_gifting_who_other_value} onChange={this.valueChange('input_financial_gifting_who_other_value')} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Segment>
                                                </Segment>
                                            </div>
                                        </Form.Field>
                                    </Form>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <Form>
                                        <Form.Field>
                                            <div>
                                                <Label className="IQ-Label" pointing={"right"} content="Are you happy for us to settle the fee charged by your Financial Advisor (if using one)?"/>&emsp;
                                                <div className='ui buttons'>
                                                    <Button className={this.state.button_financial_settle_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('financial_settle', null, null)}}>Yes</Button>
                                                    <div className='or' />
                                                    <Button className={this.state.button_financial_settle_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('financial_settle', null, null)}}>No</Button>
                                                </div>
                                            </div>
                                        </Form.Field>
                                    </Form>
                                    <br/>
                                    <hr/>
                                    <br/>
                                    <div>
                                        <Label className="IQ-Label" pointing={"right"} content="Are Estate planning fees or Funeral fees to be paid from Equity Release funds?"/>&emsp;
                                        <div className='ui buttons'>
                                            <Button className={this.state.button_financial_funeralFees_YES_CSS} role='button' onClick={(e) => {this.buttonChange_YES('financial_funeralFees', null, null)}}>Yes</Button>
                                            <div className='or' />
                                            <Button className={this.state.button_financial_funeralFees_NO_CSS} role='button' onClick={(e) => {this.buttonChange_NO('financial_funeralFees', null, null)}}>No</Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br/>               
                    <Button className={this.state.button_back_CSS} onClick={this.saveData_Wrapper_Back}>Previous Step</Button>                    
                    <Button className={this.state.button_forward_CSS} onClick={this.saveData_Wrapper_Forward}>Next Step</Button>
                    <br/><br/>
                </Segment>
            </div>
        );
    }
}

export default IQPage4Financial